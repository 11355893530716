import Immutable from 'immutable'
import {
  NEW_USER_MODAL,
  MIGRATE_USER_MODAL,
  NOTIFICATION_MODAL,
  REDIRECT_MODAL,
  TEMPORARY_PROFILE_MODAL,
  TEMPORARY_PROFILE_SAVE_MODAL,
  URL_COPY_MODAL,
  ALMA_TUNNUS_VERIFICATION_MODAL,
} from './Modals'
import { selectDeviceType } from '../../selectors/statusSelector'

export const ADD_MODAL = 'ADD_MODAL'
export const REMOVE_MODAL = 'REMOVE_MODAL'

export function displayRedirectModal(itemId, share = false) {
  return addModal(REDIRECT_MODAL, Immutable.Map({
    itemId,
    share,
  }))
}

export function displayTemporaryProfileInfoModal() {
  return addModal(TEMPORARY_PROFILE_MODAL)
}

export function displayTemporaryProfileSaveModal() {
  return addModal(TEMPORARY_PROFILE_SAVE_MODAL)
}

export function displayNotificationModal(message, title) {
  return addModal(NOTIFICATION_MODAL, Immutable.Map({
    message: message,
    title: title,
  }))
}

export function displayNewUserModal(message, title) {
  return addModal(NEW_USER_MODAL, Immutable.Map({
    message: message,
    title: title,
  }))
}

export function displayMigrateUserModal(title) {
  return addModal(MIGRATE_USER_MODAL, Immutable.Map({
    title: title,
  }))
}

export function displayAlmaTunnusVerificationModal() {
  return addModal(ALMA_TUNNUS_VERIFICATION_MODAL)
}

export function displayUrlCopyModal(url, title) {
  return addModal(URL_COPY_MODAL, Immutable.Map({
    url: url,
    title: title,
  }))
}

function addModal(modalId, modalData) {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_MODAL,
      modalId,
      modalData,
    })

    const state = getState()

    if (selectDeviceType(state) === 'ios') {
      window.document.body.style.position = 'fixed'
      window.document.body.style.width = '100%'
    }
  }
}

export function removeModal(modalId) {
  return (dispatch, getState) => {
    dispatch({
      type: REMOVE_MODAL,
      modalId,
    })

    const state = getState()

    // iOS11 has a bug, where body keeps scrolling while modal open.
    // As a temp fix, we apply a hacky fix to body element when modal is added/removed.
    if (selectDeviceType(state) === 'ios') {
      if (state.modals.get('queue').size <= 0) {
        window.document.body.style.position = 'static'
        window.document.body.style.width = 'auto'
      }
    }
  }
}
