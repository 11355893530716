import { getBreakingNews } from '../../api/api'
import { addBlacklistParamsToRequest } from '../item/itemsActions'
import { logException } from '../../sentry'
import { isTimestampFresh } from '../../lib/utils'
import { selectUserJWT } from '../../selectors/userSelector'

export const FETCH_BREAKING_NEWS = 'FETCH_BREAKING_NEWS'
export const FETCH_BREAKING_NEWS_FAILURE = 'FETCH_BREAKING_NEWS_FAILURE'
export const FETCH_BREAKING_NEWS_SUCCESS = 'FETCH_BREAKING_NEWS_SUCCESS'


export function fetchBreakingNews(maxStaleSecs = 0) {
  return (dispatch, getState) => {
    const state = getState()
    const lastFetchTimestamp = state.breakingNews.get('timestamp')
    const loading = state.breakingNews.get('loading')
    if (loading || isTimestampFresh(lastFetchTimestamp, maxStaleSecs)) {
      return
    }

    dispatch({
      type: FETCH_BREAKING_NEWS,
    })

    const params = {limit: 2}

    addBlacklistParamsToRequest(state, params)

    const jwt = selectUserJWT(state)

    return getBreakingNews(jwt, params).then(response => {
      dispatch({
        type: FETCH_BREAKING_NEWS_SUCCESS,
        response,
        timestamp: Date.now(),
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: FETCH_BREAKING_NEWS_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}
