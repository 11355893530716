import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import React, { Component } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router'
import '../../styles/modal.pcss'
import { MIGRATE_USER_MODAL } from './Modals'
import GenericModal from './GenericModal'
import * as keyCodes from '../../lib/keyCodes'
import { userMigrate } from '../../user/almalogin/almaUserActions'
import { getAccessToken } from '../../user/almalogin/AlmaLogin'
import { getUserInfo } from '../../user/almalogin/AlmaLogin'

export default class MigrateUserModal extends Component {
  constructor() {
    super()

    this.state = {
      email: '',
      emailOrUsername: '',
      usernameValid: false,
      password: '',
      passwordValid: false,
      triedSubmitting: false,
    }
  }

  async componentDidMount() {
    const userInfo = await getUserInfo()
    this.setState({ email: userInfo.email })
  }

  render() {
    const { onClose, title } = this.props
    return (
      <GenericModal
        modalId={ MIGRATE_USER_MODAL }
        onClose={ onClose }
        okButtonTxt='Lähetä'
        onOkClick={ this.handleSubmit }
        buttonDisabled={ !this.formIsValid() }
        title={ title }
        requireUserInput={ false }
      >
        { this.renderLoginForm() }
      </GenericModal>
    )
  }

  renderLoginForm = () => {

    return (
      <div className='login-form'>
        <form className='form' onSubmit={ this.handleSubmit } noValidate>
          <p>
            Anna vanhan Ampparit-profiilisi nimi tai sähköpostiosoite ja salasana. Profiilin asetukset
            yhdistetään Alma-tunnukseesi<br /><i>{ this.state.email }</i>
          </p>
          <div className='form-group'>
            <div className='input-container'>
              <label className='sr-only' htmlFor='emailOrUsername'>Sähköposti / Profiilin nimi</label>
              <input
                type='text'
                id='emailOrUsername'
                name='emailOrUsername'
                onChange={ this.handleUsernameChange }
                onInput={ this.handleUsernameChange }
                onKeyUp={ this.handleKeyUp }
                placeholder='Profiilin nimi tai Sähköposti'
                className={ classnames({
                  'has-error': (this.state.triedSubmitting && !this.state.usernameValid),
                }) }
                aria-invalid={ this.state.triedSubmitting && !this.state.usernameValid ? 'true' : 'false' }
                required
              />
              { this.state.triedSubmitting && !this.state.usernameValid && (
                <div className='input-error'>Täytä sähköposti</div>
              ) }
            </div>
          </div>

          <div className='form-group'>
            <div className='input-container'>
              <label className='sr-only' htmlFor='password'>Salasana</label>
              <input
                type='password'
                id='password'
                name='password'
                placeholder='Salasana'
                onChange={ this.handlePasswordChange }
                onInput={ this.handlePasswordChange }
                onKeyUp={ this.handleKeyUp }
                className={ classnames({
                  'has-error': (this.state.triedSubmitting && !this.state.passwordValid),
                }) }
                aria-invalid={ this.state.triedSubmitting && !this.state.passwordValid ? 'true' : 'false' }
                required
              />

              { this.state.triedSubmitting && !this.state.passwordValid && (
                <div className='input-error'>Täytä salasana</div>
              ) }
            </div>
          </div>

          <div className='login-links'>
            <Link className='forgotten-password-link' to='/unohtunut-salasana' onClick={ this.handleClose }>Unohtuiko salasana?</Link>
          </div>
        </form>
      </div>
    )
  }

  handleKeyUp = e => {
    if (e.keyCode === keyCodes.enter) {
      this.handleSubmit()
    }
  }

  handleUsernameChange = e => {
    this.setState({
      emailOrUsername: e.target.value,
      usernameValid: e.target.value.length > 0,
    })
  }

  handlePasswordChange = e => {
    this.setState({
      password: e.target.value,
      passwordValid: e.target.value.length > 0,
    })
  }

  formIsValid = () => {
    return this.state.usernameValid && this.state.passwordValid
  }

  handleSubmit = async () => {
    const { dispatch } = this.props

    if (this.formIsValid()) {
      this.setState({
        triedSubmitting: false,
      })
      const accessToken = await getAccessToken()
      dispatch(userMigrate(accessToken, this.state.emailOrUsername, this.state.password))
      this.handleClose()
    } else {
      this.setState({
        triedSubmitting: true,
      })
    }
  }

  handleClose = () => {
    if (this.props.onClose) this.props.onClose('migrateUserModal')
  }

}

MigrateUserModal.propTypes = exact({
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  dispatch: PropTypes.func,
})
