import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'
import { useClickAway } from '../../lib/utils'
import { siteUrl, facebookAppId } from '../../../config/config'
import ShareButtonUrl from './ShareButtonUrl'
import ShareButtonTwitter from './ShareButtonTwitter'
import ShareButtonFacebook from './ShareButtonFacebook'
import ShareButtonMail from './ShareButtonMail'
import ShareButtonWhatsapp from './ShareButtonWhatsapp'
import '../../styles/social.pcss'

const ItemShareWidget = ({ id, itemTitle, dispatch }) => {
  const [shareMenuVisible, setShareMenuVisible] = useState(false)
  const ref = useRef()

  useClickAway(ref, () => {
    if (shareMenuVisible) {
      setShareMenuVisible(false)
    }
  })

  const shareLink = siteUrl + '/?share=' + id

  const handleToggleActions = e => {
    setShareMenuVisible(!shareMenuVisible)
  }

  return (
    <div ref={ ref } className={ classnames('share-widget item-actions-share-container', { 'share-widget_open': shareMenuVisible }) }>
      <button
        type='button'
        className='share-text'
        onClick={ handleToggleActions }
        tabIndex='0'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 512 512'
          height={ 22 }
          width={ 22 }
        >
          <path d='M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z' />
        </svg>
      </button>

      { shareMenuVisible &&
        <div className='share-menu-container'>
          <span className='share-menu-wrapper'>
            <span className='share-menu-title'>Jaa uutinen</span>
            <div className='share-menu'>
              <ShareButtonTwitter dispatch={ dispatch } link={ shareLink } itemId={ id } itemTitle={ itemTitle } />
              <ShareButtonFacebook dispatch={ dispatch } link={ shareLink } itemId={ id } facebookAppId={ facebookAppId } />
              <ShareButtonMail dispatch={ dispatch } link={ shareLink } itemId={ id } itemTitle={ itemTitle } />
              <ShareButtonWhatsapp dispatch={ dispatch } link={ shareLink } itemId={ id } itemTitle={ itemTitle } />
              <ShareButtonUrl dispatch={ dispatch } link={ shareLink } itemId={ id } itemTitle={ itemTitle } />
            </div>
          </span>
        </div>
      }
    </div>
  )
}

ItemShareWidget.propTypes = exact({
  id: PropTypes.string.isRequired,
  itemTitle: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
})

export default ItemShareWidget
