import Immutable from 'immutable'
import { combineReducers } from 'redux-immutable'
import * as actions from './modalActions'

function queue(state = Immutable.List(), action) {
  switch (action.type) {
    case actions.ADD_MODAL:
      return state.push(Immutable.Map({
        id: action.modalId,
        data: action.modalData,
      }))

    case actions.REMOVE_MODAL:
      return state.filter(modal => modal.get('id') !== action.modalId)

    default:
      return state
  }
}

export default combineReducers({
  queue,
})
