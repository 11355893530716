import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

export default class WhitelistToggle extends PureComponent {
  render() {
    const {
      isWhitelisted,
      onWhitelist,
      onUnwhitelist,
      name,
    } = this.props

    if (isWhitelisted) {
      return (
        <button
          className='whitelist-toggle whitelist-toggle_active'
          onClick={ onUnwhitelist }
          type='button'
          aria-label={ `Poista ${name} omista aiheista` }
          title={ `Poista ${name} omista aiheista` }
        >
          <i className='fa fa-fw fa-times' aria-hidden='true' />&#8201;Älä seuraa
        </button>
      )
    } else {
      return (
        <button
          className='whitelist-toggle'
          onClick={ onWhitelist }
          type='button'
          aria-label={ `Lisää ${name} omiin aiheisiin` }
          title={ `Lisää ${name} omiin aiheisiin` }
        >
          <i className='fa fa-fw fa-check' aria-hidden='true' />&#8201;Seuraa
        </button>
      )
    }
  }
}

WhitelistToggle.propTypes = exact({
  isWhitelisted: PropTypes.bool.isRequired,
  onWhitelist: PropTypes.func.isRequired,
  onUnwhitelist: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
})
