import Immutable from 'immutable'
import * as actions from './almaUserActions'

const initialState = Immutable.Map({
  emailVerified: false,
  logoutRequested: false,
  loginRequested: false,
  registerRequested: false,
  loggedIn: false,
})

function almaUser(state = initialState, action) {
  switch (action.type) {
    case actions.LOGIN_ALMA_USER:
      return state
        .set('loginRequested', true)
        .set('logoutRequested', false)
        .set('registerRequested', false)

    case actions.LOGIN_ALMA_USER_SUCCESS:
      return state
        .set('emailVerified', action.emailVerified)
        .set('loggedIn', true)
        .set('loginRequested', false)

    case actions.LOGIN_ALMA_USER_FAILURE:
      return state
        .set('loginRequested', false)

    case actions.LOGOUT_ALMA_USER:
      return state
        .set('logoutRequested', true)
        .set('loginRequested', false)

    case actions.LOGOUT_ALMA_USER_SUCCESS:
      return state
        .set('emailVerified', false)
        .set('logoutRequested', false)
        .set('loggedIn', false)

    case actions.LOGOUT_ALMA_USER_FAILURE:
      return state
        .set('logoutRequested', false)

    case actions.REGISTER_ALMA_ACCOUNT:
      return state
        .set('registerRequested', true)

    default:
      return state
  }
}

export default almaUser

