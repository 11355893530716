import * as actions from './sourceApplicationActions'
import Immutable from 'immutable'

const initialState = Immutable.Map({
  loading: false,
  error: false,
  sent: false,
})

function sourceApplication(state = initialState, action) {
  switch (action.type) {
    case actions.SEND_SOURCE_APPLICATION:
      return state.set('loading', true)

    case actions.SEND_SOURCE_APPLICATION_SUCCESS:
      return state
        .set('loading', false)
        .set('sent', true)
        .set('error', false)

    case actions.SEND_SOURCE_APPLICATION_FAILURE:
      return state
        .set('loading', false)
        .set('error', true)

    default:
      return state
  }
}

export default sourceApplication
