import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import Autosuggest from 'react-autosuggest'
import classnames from 'classnames'
import { selectCategoryByName } from '../../selectors/categoriesSelector'
import { push } from 'react-router-redux'
import { filterSuggestions, renderSuggestion } from '../../lib/autocomplete'
import find from 'lodash/find'
import './searchbox.pcss'
import '../../styles/autocomplete.pcss'

const defaultState = {
  value: '',
  suggestions: [],
  error: false,
}

@connect((state) => {
  const { categories } = state
  return {
    state: {
      categories: categories,
    },
  }
})

/**
 * Renders an input box and on submit attempts to match user input to provided data json
 * and then tries to find a category with a matching name.
 *
 * Match is first attempted with "name" and if that produces no results, with "parent".
 * If a match is found, redirects user to that category.
 *
 * Data format:
 * [{
 *   'name': 'Hämeenlinna',
 *   'parent': 'Kanta-Häme'
 * },
 * {
 *   'name': 'Heinola',
 *   'parent': 'Päijät-Häme'
 * }]
 */

export default class MunicipalitySearchBox extends Component {

  static propTypes = exact({
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        parent: PropTypes.string,
      })
    ).isRequired,
    state: PropTypes.shape({
      categories: PropTypes.shape({
        categories: ImmutablePropTypes.map.isRequired,
        loading: PropTypes.bool.isRequired,
        timestamp: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
  })

  constructor() {
    super()

    this.state = defaultState

  }

  render() {
    const { value, suggestions, error } = this.state

    const {
      className,
      title,
      placeholder,
    } = this.props

    const inputProps = {
      placeholder,
      value,
      onChange: this.onChange,
    }

    return (
      <section className={ classnames('searchbox', className ) }>
        <div className='searchbox-header'>{ title }</div>
        <div className={ classnames('searchbox-content') }>
          <form autoComplete='off'>
            <div className='autocomplete'>
              <Autosuggest
                suggestions={ suggestions }
                onSuggestionsFetchRequested={ this.handleSuggestionsFetchRequested }
                onSuggestionsClearRequested={ this.handleSuggestionsClearRequested }
                getSuggestionValue={ this.getSuggestionValue }
                renderSuggestion={ renderSuggestion }
                inputProps={ inputProps }
              />
            </div>
            <button className='button button_slim button_overlap-left' type='submit' onClick={ this.handleSubmit }>Etsi</button>
          </form>
          { error &&
            <p style={ { padding: '5px'} }>
              <i className={ classnames('fa fa-exclamation-circle') } /> Haulla ei löytynyt uutisia.
            </p>
          }
        </div>
      </section>
    )
  }

  onChange = (e, { newValue, method }) => {
    this.setState({
      value: newValue,
    })
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const { data } = this.props
    this.setState({
      suggestions: filterSuggestions(data, value),
    })
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (this.state.value === '') {
      return // this could potentially trigger its own error message
    }

    const validSelection = this.findSuggestion(this.state.value)

    if (!validSelection) {
      // User input didn't match any data item, nothing more to do here
      this.setState({
        error: true,
      })
      return
    }

    const category = this.getCategory(validSelection)

    if (category) {
      this.resetState()
      this.props.dispatch(push(`/${category.get('slug')}`))
    } else {
      this.setState({
        error: true,
      })
    }
  }

  getCategory(validSelection) {
    const { state } = this.props

    let category = selectCategoryByName(state, validSelection.name)

    // No matching category?! Let's try with parent instead
    if (!category && validSelection.parent) {
      category = selectCategoryByName(state, validSelection.parent)
    }

    return category
  }

  getSuggestionValue(suggestion) {
    return suggestion.name
  }

  /**
   * Attempts to find a matching data item based on user input
   * @param {string} value User input
   * @return {object|null}
   */
  findSuggestion(value) {
    const { data } = this.props
    return find(
      data,
      item => item.name.toLowerCase() === value.toLowerCase()
    )
  }

  resetState = () => {
    this.setState(defaultState)
  }

}
