import { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

/**
 * ----------------------------------- ATTENTION! -----------------------------------
 * Always implement this component with parent or wrap in div to prevent DOM problems
*/

export default class Resize extends Component {
  constructor() {
    super()
    this.state = {
      shouldRender: false,
    }
    this.handleResize = this.handleResize.bind(this)
  }

  handleResize() {
    const windowWidth = window.document.documentElement.clientWidth
    if (!windowWidth) return

    const shouldRender = (windowWidth >= this.props.minBreakpoint && windowWidth <= this.props.maxBreakpoint)
    this.setState({shouldRender: shouldRender})
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    return this.state.shouldRender ? this.props.children : null
  }
}

Resize.propTypes = exact({
  minBreakpoint: PropTypes.number,
  maxBreakpoint: PropTypes.number,
  children: PropTypes.node.isRequired,
})

Resize.defaultProps = {
  minBreakpoint: 0,
  maxBreakpoint: Number.POSITIVE_INFINITY,
}
