import Immutable from 'immutable'

import { LOCATION_CHANGE } from 'react-router-redux'

import {
  FETCH_VERSION,
  FETCH_VERSION_SUCCESS,
  FETCH_VERSION_FAILURE,
  SET_WEBSOCKET_CONNECTED,
  SET_DEVICE_INFO,
  SET_STATUS,
} from './statusActions'

import {
  FETCH_ITEMS_SUCCESS,
  FETCH_CATEGORY_ITEMS_SUCCESS,
  FETCH_TAG_ITEMS_SUCCESS,
} from '../components/item/itemsActions'


export const WEBSOCKET_STARTING = 'starting'
export const WEBSOCKET_CONNECTED = 'connected'
export const WEBSOCKET_FAILED = 'failed'
export const WEBSOCKET_RECOVERED = 'recovered'

const initialState = Immutable.Map({
  status: 200,
  path: '/',
  websocket: WEBSOCKET_STARTING,
  version: null,
  versionTimestamp: null,
  versionLoading: false,
  deviceType: null,
})

export default function statusReducer(state = initialState, action) {
  switch (action.type) {

    case SET_WEBSOCKET_CONNECTED:
      if (!action.connected) {
        return state.set('websocket', WEBSOCKET_FAILED)
      }
      if (state.get('websocket') === WEBSOCKET_STARTING) {
        return state.set('websocket', WEBSOCKET_CONNECTED)
      }
      return state.set('websocket', WEBSOCKET_RECOVERED)

    case FETCH_ITEMS_SUCCESS:
    case FETCH_CATEGORY_ITEMS_SUCCESS:
    case FETCH_TAG_ITEMS_SUCCESS:
      if (state.get('websocket') === WEBSOCKET_RECOVERED) {
        return state.set('websocket', WEBSOCKET_CONNECTED)
      }
      return state

    case SET_STATUS:
      return state.set('status', action.status)

    case LOCATION_CHANGE:
      if (state.get('path') === action.payload.pathname) return state

      return state.set('status', 200).set('path', action.payload.pathname)

    case FETCH_VERSION:
      return state.set('versionLoading', true)

    case FETCH_VERSION_SUCCESS:
      return state
        .set('version', action.version)
        .set('versionTimestamp', action.timestamp)
        .set('versionLoading', false)

    case FETCH_VERSION_FAILURE:
      return state.set('versionLoading', false)

    case SET_DEVICE_INFO: {
      const ua = action.userAgentString ?? ''
      if (ua.match('Android')) {
        return state.set('deviceType', 'android')
      }
      if (ua.match(/\biPhone.*Mobile|\biPod|\biPad/)) {
        return state.set('deviceType', 'ios')
      }
      return state.set('deviceType', 'desktop')
    }
    default:
      return state
  }
}
