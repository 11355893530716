import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { connect } from 'react-redux'
import { Radio, RadioGroup } from 'react-radio-group'
import Button from '../../components/ui/Button'
import { validateEmail } from '../../user/validate'
import { sendAccountRemovalRequest } from './accountRemovalActions'
import './infoPageForms.pcss'

@connect((state) => {
  return {
    loading: state.accountRemoval.get('loading'),
    sent: state.accountRemoval.get('sent'),
    error: state.accountRemoval.get('error'),
  }
})

export default class AccountRemovalForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      emailValue: props.email,
      emailValid: props.email ? true : null, // prefilled email is always valid
      removalTypeValue: '',
      removalTypeValid: null,
      messageValue: '',
    }
  }

  static propTypes = exact({
    loggedIn: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    sent: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
  })


  render() {
    const { loggedIn, loading, sent, error } = this.props
    const { emailValue, emailValid, removalTypeValue, removalTypeValid, messageValue } = this.state

    if (sent) {
      return (
        <section className='info-page-form-thanks'>
          <h2>Poistopyyntö lähetetty</h2>
          <p>Olemme sinuun yhteydessä mahdollisimman pian</p>
        </section>
      )
    }

    if (error) {
      return (
        <section className='info-page-form-error'>
          <h2>Virhe!</h2>
          <p>
            Lomakkeen lähetys epäonnistui. Voit yrittää myöhemmin uudelleen tai pyytää poistamista sähköpostitse
            <a href='mailto:ampparit@ampparit.com?Subject=Ampparit%20profiilin%20poisto'>ampparit@ampparit.com</a>
          </p>
        </section>
      )
    }

    return (
      <form className='info-page-form' noValidate onSubmit={ this.handleSubmit }>
        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='email'>Profiilin sähköpostiosoite</label>
          </div>
          <div className='form-group-inputs'>
            <input
              type='email'
              id='email'
              placeholder='esimerkki@esimerkki.com'
              value={ emailValue }
              onChange={ this.handleEmailChange }
              onInput={ this.handleEmailChange }
              onBlur={ this.handleEmailChange }
              disabled={ loggedIn }
            />
            { emailValid === false && (
              <div className='form-validation-message'>Sähköpostiosoite puuttuu tai on virheellinen</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            Poistopyyntöni koskee
          </div>
          <div className='form-group-inputs'>
            <RadioGroup name='removal-type' selectedValue={ removalTypeValue } onChange={ this.handleRemovalTypeChange }>
              <label><Radio value='Vain ampparit-profiili' /> Vain Ampparit-profiilia</label>
              <label><Radio value='Ampparit- ja Almatunnus' /> Ampparit-profiilia ja Alma-tunnusta</label>
            </RadioGroup>
            { removalTypeValid === false && (
              <div className='form-validation-message'>Valitse jompi kumpi vaihtoehto</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='message'>Lisätietoja (valinnainen)</label>
          </div>
          <div className='form-group-inputs'>
            <textarea id='message' onChange={ this.handleMessageChange } value={ messageValue }></textarea>
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
          </div>
          <div className='form-group-inputs'>
            <Button type='submit' loading={ loading } text='Lähetä poistopyyntö' />
          </div>
        </div>
      </form>
    )
  }

  handleEmailChange = e => {
    const value = e.target.value
    const valid = validateEmail(value.trim()) || (e.type === 'blur' ? false : null)
    this.setState({
      emailValue: value,
      emailValid: valid,
    })
  }

  handleRemovalTypeChange = value => {
    this.setState({removalTypeValue: value, removalTypeValid: true})
  }

  handleMessageChange = e => {
    this.setState({messageValue: e.target.value})
  }

  formIsValid() {
    const stateUpdate = {
      emailValid: !!this.state.emailValid,
      removalTypeValid: !!this.state.removalTypeValid,
    }
    this.setState(stateUpdate)
    return Object.values(stateUpdate).every(v => v === true)
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.formIsValid() || this.props.loading) {
      return
    } else {
      this.submit()
    }
  }

  submit() {
    const { emailValue, messageValue, removalTypeValue } = this.state
    const emailBody = {
      'Poistettava tunnus': emailValue,
      'Mitä poistetaan': removalTypeValue,
      'Lisätietoja': messageValue,
    }
    this.props.dispatch(sendAccountRemovalRequest(emailValue, emailBody))
  }
}
