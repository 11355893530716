import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { itemIdsToItems } from '../components/item/itemsTransformers'
import {
  displayRecommendedCategories,
  rejectRecommendedCategories,
  acceptRecommendedCategories,
} from './userActions'
import SimpleItem from '../components/item/SimpleItem'
import './categoryRecommendation.pcss'


@connect((state) => {
  const { user } = state
  const itemIds = user.getIn(['recommendedCategories', 'itemsIds'])
  const items = itemIds && itemIdsToItems(state, itemIds)
  return {
    category: user.getIn(['recommendedCategories', 'category']),
    items,
  }
})

export default class CategoryRecommendations extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showRecommendations: false,
    }
  }

  static propTypes = exact({
    category: ImmutablePropTypes.map,
    items: ImmutablePropTypes.list,
    dispatch: PropTypes.func.isRequired,
  })

  componentDidMount() {
    const { category, items, dispatch } = this.props
    this.setState({
      showRecommendations: category && category.size > 0 && items,
    })

    if (category && category.size > 0 && items) {
      dispatch(displayRecommendedCategories(category.get('id')))
    }
  }

  render() {
    const { dispatch, category, items } = this.props

    if (!this.state.showRecommendations) return null

    const categoryName = category.get('properName')

    return (
      <div className='recommendations'>
        <div className='recommendations-header'>
          <div className='recommendations-header__title'>
            { `Suosittelemme: ${categoryName}` }
          </div>

          <button
            type='button' className='recommendations-header__accept' onClick={ this.handleAccept }
            title={ `Seuraa kategoriaa ${categoryName}` } aria-label={ `Seuraa kategoriaa ${categoryName}` }
          >
            <span className='fa fa-check' aria-hidden='true' /> <span aria-hidden='true'>Seuraa</span>
          </button>

          <button
            type='button' className='recommendations-header__reject' onClick={ this.handleReject }
            title='Hylkää kategoriaehdotus' aria-label='Hylkää kategoriaehdotus'
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
              { /* !Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. */ }
              <path fill='currentColor' d='M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z' />
            </svg>
          </button>
        </div>

        <div className='recommendations-content'>
          <div className='recommendations-content__news'>
            { items.map((item, i) => (
              <SimpleItem
                key={ item.get('id') }
                dispatch={ dispatch }
                item={ item }
                trackingClass='recommended-news'
                gaItemClickUiElement='Kategoriasuositus'
              />
            )) }
          </div>
        </div>
      </div>

    )
  }

  handleAccept = () => {
    const { dispatch, category } = this.props
    dispatch(acceptRecommendedCategories(category.get('id')))

    this.setState({
      showRecommendations: false,
    })
  }

  handleReject = () => {
    const { dispatch, category } = this.props
    dispatch(rejectRecommendedCategories(category.get('id')))

    this.setState({
      showRecommendations: false,
    })
  }
}
