import { getWeather } from '../../../api/api'
import { logException } from '../../../sentry'
import { isTimestampFresh } from '../../../lib/utils'

export const FETCH_CURRENT_WEATHER = 'FETCH_CURRENT_WEATHER'
export const FETCH_CURRENT_WEATHER_FAILURE = 'FETCH_CURRENT_WEATHER_FAILURE'
export const FETCH_CURRENT_WEATHER_SUCCESS = 'FETCH_CURRENT_WEATHER_SUCCESS'


/**
 * Fetch weather for menubar widget. Fetches and stores minimal amount of data.
 */
export function fetchCurrentWeather(maxStaleSecs = 0) {
  return (dispatch, getState) => {
    const state = getState()
    const locationId = state.user.get('defaultLocation')
    const lastFetchLocationId = state.currentWeather.get('locationId')
    const lastFetchTimestamp = state.currentWeather.get('timestamp')
    const loading = state.currentWeather.get('loading')
    if (lastFetchLocationId === locationId && (isTimestampFresh(lastFetchTimestamp, maxStaleSecs) || loading)) {
      return
    }

    dispatch({
      type: FETCH_CURRENT_WEATHER,
    })

    const params = { id: locationId, hours: 1 }
    return getWeather(params).then(response => {
      if (!response?.data?.length) { // response.data.length should never be missing but sometimes is. Figure out why, fix API and remove null safety.
        console.error(response)
        throw new Error('Invalid data length for current weather')
      }
      dispatch({
        type: FETCH_CURRENT_WEATHER_SUCCESS,
        response,
        timestamp: Date.now(),
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: FETCH_CURRENT_WEATHER_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}
