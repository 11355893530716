import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import classnames from 'classnames'
import { useClickAway } from '../../lib/utils'
import {
  blacklistSource,
  unblacklistSource,
  subscribeSource,
  unSubscribeSource,
} from '../userActions'
import './sourceItem.pcss'

const SourceItem = ({ source, isBlacklisted, isSubscribed, dispatch }) => {
  const [showMenu, setShowMenu] = useState(false)
  const sourceOptionsMenuRef = useRef(null)

  useClickAway(sourceOptionsMenuRef, () => {
    setShowMenu(false)
  })

  const handleToggleClick = () => {
    setShowMenu(!showMenu)
  }

  const handleSourceBlacklist = () => {
    dispatch(blacklistSource(source.id))
    setShowMenu(false)
  }

  const handleSourceUnblacklist = () => {
    dispatch(unblacklistSource(source.id))
    setShowMenu(false)
  }

  const handleShowPaywallItems = () => {
    dispatch(subscribeSource(source.id))
    setShowMenu(false)
  }

  const handleHidePaywallItems = () => {
    dispatch(unSubscribeSource(source.id))
    setShowMenu(false)
  }

  return (
    <div className={ classnames('source-item', { 'source-item__blacklisted': isBlacklisted }) }>
      <a
        className={ classnames('source-item__link', { 'source-item__link-blacklisted': isBlacklisted }) }
        href={ source.link }
        target='_blank'
        rel='noopener noreferrer'
        title={ `Lähteen ${source.name} kotisivu` }
      >
        { source.name }
      </a>

      <div className='source-item__status'>

        { isSubscribed && !isBlacklisted ?
          <span className={ classnames('source-item__status', 'source-item__status-paywalled') }>
            <span className='fa-stack' aria-hidden='true'>
              <i className='fa fa-circle' aria-hidden='true' />
              <i className='fa fa-eur fa-stack-1x fa-inverse' aria-hidden='true' />
            </span>
            Näytetään maksulliset jutut
          </span>
          :
          source.hasPaywall && !isBlacklisted &&
            <span className={ classnames('source-item__status', 'source-item__status-paywalled') }>
              <span className='fa-stack error' aria-hidden='true'>
                <i className='fa fa-circle' aria-hidden='true' />
                <i className='fa fa-eur fa-stack-1x fa-inverse' aria-hidden='true' />
              </span>
              <span className='source-item__status-paywalled_text'>Maksulliset jutut piilotettu</span>
            </span>
        }
        { isBlacklisted ?
          <span className='source-item__status source-item__status-blacklisted'>Piilotettu</span> : null
        }
      </div>

      <button
        className='source-item__toggle'
        type='button'
        onClick={ handleToggleClick }
        aria-label={ `Muokkaa lähteen ${source.name} asetuksia` }
        title={ `Muokkaa lähteen ${source.name} asetuksia` }
      >
        <i className={ showMenu ? 'fa fa-chevron-up' : 'fa fa-chevron-down' } aria-hidden='true' />
      </button>

      { showMenu ?
        <div className='source-item__menu-container' ref={ sourceOptionsMenuRef }>

          { source.hasPaywall ? (
            !isSubscribed ?
              <button
                type='button'
                className={ classnames('source-item__menu-container-button', { 'error': isBlacklisted }) }
                disabled={ isBlacklisted }
                title={ isBlacklisted ? 'Lähde on piilotettu' : 'Näytä lähteen maksumuurijutut' }
                onClick={ handleShowPaywallItems }
              >
                <span className='source-item__menu-container-button-text'>Näytä lähteen maksulliset jutut</span>
              </button> :
              <button
                type='button'
                className={ classnames('source-item__menu-container-button', { 'error': isBlacklisted }) }
                disabled={ isBlacklisted }
                onClick={ handleHidePaywallItems }
              >
                Piilota lähteen maksulliset jutut
              </button>
          ) : null
          }

          { isBlacklisted ?
            <button type='button' className='source-item__menu-container-button' onClick={ handleSourceUnblacklist }>
              Näytä lähde uutislistoilla
            </button> :
            <button type='button' className='source-item__menu-container-button' onClick={ handleSourceBlacklist }>
              Piilota lähde uutislistoilla
            </button>
          }

        </div> : null
      }

    </div>
  )
}

SourceItem.propTypes = exact({
  source: ImmutablePropTypes.record.isRequired,
  isBlacklisted: PropTypes.bool.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
})

export default SourceItem
