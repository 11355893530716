import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classnames from 'classnames'
import moment from 'moment-timezone'
import injectTrackers from '../../ad/injectTrackers'
import { TICKER_COOKIE_PREFIX, closeTicker } from './tickerActions'
import { useCookies } from 'react-cookie'
import config from '../../../config/config'
import './ticker.pcss'

/**
 * Ticker component used on top of items list and on the bottom of page
 */
const Ticker = ({ dispatch, ticker }) => {
  const [countdownMoment, setCountdownMoment] = useState(null)
  const refTrackers = useRef(null)
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [changePosition, setChangePosition] = useState(true)
  const [cookies, setCookie, removeCookie] = useCookies() // eslint-disable-line no-unused-vars

  const handleScroll = useCallback(() => {
    if (!window.navigator.userAgent.includes('DuckDuckGo')) {
      const currentScrollPos = window.scrollY
      setChangePosition(prevScrollPos > currentScrollPos || currentScrollPos < 10)
      setPrevScrollPos(currentScrollPos)
    }
  }, [prevScrollPos])

  /**
   * Search for {{countdown:YYYY-MM-DDTHH:MM}} placeholders in ticker text
   * and return the time as MomentJS object. Null if no countdown found.
   */
  const parseCountdown = useCallback(() => {
    const text = ticker.get('text')
    const found = text.match(/\{\{countdown:([0-9:T +-]+)\}\}/)
    if (found) {
      const countdownMoment = moment(found[1])
      if (countdownMoment.isValid()) {
        return countdownMoment
      }
    }
    return null
  }, [ticker])

  /**
   * Calculate remaining countdown time and return it as string of format "1h 20m 13s" or
   * "59m 20s" (hours are ommited once they hit zero). Returns empty string if countdown
   * has expired or there is no countdown at all.
   */
  const calculateCountdownRemaining = useCallback(() => {
    if (countdownMoment) {
      const duration = moment.duration(countdownMoment - moment())
      const hours = Math.floor(duration.asHours())
      const minutes = duration.minutes()
      const seconds = duration.seconds()
      if (hours > 0) {
        return `${hours}h ${minutes}m ${seconds}s`
      }
      if (duration.asSeconds() >= 0) {
        return `${minutes}m ${seconds}s`
      }
      if (duration.asSeconds() >= -10) {
        return '0m 0s' // Show this for 10 seconds
      }
    }
    return ''
  }, [countdownMoment])

  const handleClose = useCallback(() => {
    const { id, replayHours } = ticker.toJS()
    setCookie(TICKER_COOKIE_PREFIX + id, 'true', {...config.cookieOptions, maxAge: replayHours * 3600})
    dispatch(closeTicker(id))
  }, [dispatch, ticker, setCookie])

  /**
   * Update countdown text on page. We do this in a non-react way by directly modifying DOM.
   * Originally we used component state and let the component re-render on its own, but this
   * turned out to have terrible jitter. For example on firefox, when navigating to a new page
   * and ads reloading, the countdown would stall for multiple seconds.
   * Keep this function as light as possible, as it gets called 60 times per second.
   */
  const updateCountdownRemaining = useCallback(() => {
    const target = window.document.getElementById('countdown-remaining')
    if (target !== null) {
      const remaining = calculateCountdownRemaining()
      if (remaining === '') {
        handleClose()
        return
      }
      if (target.textContent !== remaining) {
        target.textContent = remaining
      }
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(updateCountdownRemaining)
      }
      else {
        setTimeout(updateCountdownRemaining, 100)
      }
    }
  }, [calculateCountdownRemaining, handleClose])

  useEffect(() => {
    const trackingCode = ticker.get('trackingCode')
    injectTrackers(trackingCode, refTrackers.current)
    setCountdownMoment(parseCountdown())
  }, [ticker, parseCountdown])

  useEffect(() => {
    if (countdownMoment) {
      updateCountdownRemaining()
    }
  }, [countdownMoment, updateCountdownRemaining])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

  }, [handleScroll])

  const { position, text, image, link, bgColor, fgColor } = ticker.toJS()
  let tickerText = text

  // Text and image contained in Anchor or Div, depending on whether there is a link
  const ContentTag = link ? 'a' : 'div'
  const linkProps = link ? { href: link, target: '_blank', rel: 'noopener' } : {}
  if (link && (link.startsWith('/') || link.startsWith('https://www.ampparit.com'))) {
    linkProps.target = '_top'
  }

  // Render nothing for expired countdowns
  if (countdownMoment && countdownMoment.isBefore()) {
    return null
  }

  // Replace countdown placeholder with current remaining time
  if (countdownMoment) {
    const remaining = `<span id='countdown-remaining'>${calculateCountdownRemaining()}</span>`
    tickerText = tickerText.replace(/\{\{countdown:([0-9:T +-]+)\}\}/, remaining)
  }

  return (
    <div
      className={ classnames('ticker', `ticker--${position}`, `gtm-${position}-ticker`, { 'mobile-nav-position': position === 'bottom' && changePosition }) }
      style={ { backgroundColor: bgColor, color: fgColor } }
    >
      <ContentTag className='ticker__content-wrapper' { ...linkProps }>
        { !!image && <img className='ticker__image' src={ image } alt='' /> }
        <span
          className='ticker__text'
          dangerouslySetInnerHTML={ { __html: tickerText } }
        />
      </ContentTag>
      <button type='button' className='ticker__close-button' onClick={ handleClose } aria-label='Sulje'>
        <span className='fa fa-times' aria-hidden='true' />
      </button>
      <div ref={ refTrackers } className='impressions'></div>
    </div>
  )
}

Ticker.propTypes = exact({
  dispatch: PropTypes.func.isRequired,
  ticker: ImmutablePropTypes.map.isRequired,
})

export { Ticker }
