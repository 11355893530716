import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import classnames from 'classnames'
import GeneralSidebar from '../components/layout/GeneralSidebar'
import { validatePassword } from './validate'
import { newPassword } from './userActions'
import Button from '../components/ui/Button'
import * as keyCodes from '../lib/keyCodes'
import '../styles/form.pcss'

@connect(state => {
  return {
    newPasswordStatus: state.user.get('userManagement').get('newPasswordStatus'),
  }
})
export default class NewPasswordView extends Component {
  constructor() {
    super()

    this.state = {
      password: '',
      passwordRepeat: '',
      passwordRepeatValid: false,
      passwordValid: false,
      triedSubmitting: false,
    }
  }

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    newPasswordStatus: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }),
  }

  render() {
    const { newPasswordStatus } = this.props

    return (
      <div className='container' id='sticky-ad-bottom-boundary'>
        <div className='main-container'>
          <div className='content'>
            <Helmet title='Uusi salasana' />

            <h1 className='center-text'>Aseta uusi salasana</h1>

            <div className='form-container'>
              <form
                className='form' method='post' noValidate
                onSubmit={ this.handleSubmit }
              >
                <div className='form-group'>
                  <label htmlFor='new-password'>Salasana</label>

                  <div className='input-container'>
                    <input
                      type='password'
                      id='new-password'
                      autoComplete='new-password'
                      name='new-password'
                      onChange={ this.handlePasswordChange }
                      onInput={ this.handlePasswordChange }
                      onKeyUp={ this.handleKeyUp }
                      className={ classnames({
                        'has-error': this.state.triedSubmitting && !this.state.passwordValid,
                      }) }
                    />

                    { this.state.triedSubmitting && !this.state.passwordValid && (
                      <div className='input-error'>Salasanan on oltava vähintään 6 merkkiä pitkä</div>
                    ) }
                  </div>
                </div>

                <div className='form-group'>
                  <label htmlFor='password-repeat'>Salasana uudelleen</label>

                  <div className='input-container'>
                    <input
                      type='password'
                      id='password-repeat'
                      name='password-repeat'
                      onChange={ this.handlePasswordRepeatChange }
                      onInput={ this.handlePasswordRepeatChange }
                      onKeyUp={ this.handleKeyUp }
                      className={ classnames({
                        'has-error': this.state.triedSubmitting && !this.state.passwordRepeatValid,
                      }) }
                    />

                    { this.state.triedSubmitting && !this.state.passwordRepeatValid && (
                      <div className='input-error'>Salasanat eivät täsmää</div>
                    ) }
                  </div>
                </div>

                <div className='form-group'>
                  <span className='label' />

                  <div className='input-container'>
                    <Button
                      type='submit'
                      text='Aseta salasana'
                      disabled={ !this.formIsValid() || newPasswordStatus === 'failure' || newPasswordStatus === 'success' }
                      loading={ newPasswordStatus === 'loading' }
                    />

                    { newPasswordStatus === 'failure' && (
                      <div className='input-error'>Tapahtui virhe. Linkki voi olla vanhentunut. Kokeile tilata uusi salasanan vaihtolinkki <Link to='/unohtunut-salasana'>täältä</Link>.</div>
                    ) }
                    { newPasswordStatus === 'success' && (
                      <div className='input-success'>Salasana on vaihdettu</div>
                    ) }
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className='sidebar-container'>
            <GeneralSidebar />
          </div>
        </div>
      </div>
    )
  }

  handleKeyUp = e => {
    if (e.keyCode === keyCodes.enter) {
      this.setState({
        triedSubmitting: true,
      })
    }
  }

  handlePasswordChange = e => {
    e.persist()

    this.setState({
      password: e.target.value,
      passwordValid: validatePassword(e.target.value),
    })
  }

  handlePasswordRepeatChange = e => {
    e.persist()

    this.setState({
      passwordRepeat: e.target.value,
      passwordRepeatValid: e.target.value === this.state.password,
    })
  }

  formIsValid = () => {
    // Passwords dont match
    if (this.state.password !== this.state.passwordRepeat) {
      return false
    }

    // At least one of the passwords is invalid
    if (!this.state.passwordValid || !this.state.passwordRepeatValid) {
      return false
    }

    return true
  }

  handleSubmit = e => {
    e.preventDefault()

    this.submit()
  }

  submit() {
    if (this.formIsValid()) {
      const { dispatch, params } = this.props

      this.setState({
        triedSubmitting: false,
      })

      dispatch(newPassword(params.token, this.state.password))
    }
  }

  handleNewPasswordClick = e => {
    this.submit()
  }
}

