import { escapeRegexChars } from './utils'

export function filterSuggestions(suggestions, value) {
  const escapedValue = escapeRegexChars(value.trim())

  if (escapedValue === '') {
    return []
  }

  const regex = new RegExp('^' + escapedValue, 'i')

  return suggestions.filter(suggestion => regex.test(suggestion.name))
}


export function renderSuggestion(suggestion) {
  return suggestion.name
}
