import React, { Component } from 'react'
import { Link } from 'react-router'
import classnames from 'classnames'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import ArrowToggle from '../../components/util/ArrowToggle'
import { setShowBlacklistedPages } from '../../components/menu/menuActions'
import { refreshAllAds } from '../../ad/AlmaAd'
import { selectSideMenuLinks } from '../../selectors/menuSelectors'
import { selectBlacklistedCategoryIds } from '../../selectors/categoriesSelector'
import { selectLocationPath } from '../../selectors/statusSelector'
import { selectUserWhitelistedCategoryIds } from '../../selectors/userSelector'

// Special cases
const CATEGORY_ID_SPORTS = 109
const CATEGORY_ID_LOCAL_NEWS = 54
const LINK_SPORT_RESULTS = '/urheilu/tulospalvelu'


/**
 * This is a recursive component, do not use @connect decorator here. Doing so will
 * cause a stack overflow. Export the root component with explicit connect call instead.
 */
export class SidebarMenuList extends Component {
  static propTypes = exact({
    menuLinks: ImmutablePropTypes.list.isRequired,
    path: PropTypes.string.isRequired,
    blacklistedCategoryIds: ImmutablePropTypes.set.isRequired,
    whitelistedCategoryIds: ImmutablePropTypes.set.isRequired,
    dispatch: PropTypes.func.isRequired,
    className: PropTypes.string,
  })

  static defaultProps = {
    className: '',
  }

  render() {
    const {
      menuLinks,
      path,
      blacklistedCategoryIds,
      whitelistedCategoryIds,
      dispatch,
      className,
    } = this.props

    return (
      <ul className={ `menu-list ${className}` }>
        { menuLinks.map(menuLink => (
          <SidebarMenuItem
            key={ menuLink.get('to') }
            menuLink={ menuLink }
            path={ path }
            blacklistedCategoryIds={ blacklistedCategoryIds }
            whitelistedCategoryIds={ whitelistedCategoryIds }
            dispatch={ dispatch }
          />
        )) }
      </ul>
    )
  }
}


class SidebarMenuItem extends Component {
  static propTypes = exact({
    path: PropTypes.string.isRequired,
    blacklistedCategoryIds: ImmutablePropTypes.set.isRequired,
    whitelistedCategoryIds: ImmutablePropTypes.set.isRequired,
    dispatch: PropTypes.func.isRequired,
    menuLink: ImmutablePropTypes.record.isRequired,
  })


  render() {
    const {
      path,
      blacklistedCategoryIds,
      whitelistedCategoryIds,
      menuLink,
      dispatch,
    } = this.props

    const hasSubPages = menuLink.pages && !menuLink.pages.isEmpty()
    const showBlacklistedPages = menuLink.showBlacklistedPages
    const subMenuClasses = {
      'menu-list--show-non-blacklisted': hasSubPages && !showBlacklistedPages && menuLink.categoryId !== CATEGORY_ID_LOCAL_NEWS,
      'menu-list--show-only-whitelisted': hasSubPages && !showBlacklistedPages && menuLink.categoryId === CATEGORY_ID_LOCAL_NEWS,
    }

    const rowClasses = {
      'menu-item': true,
      'menu-item--active': this.isActive(),
      'menu-item--blacklisted': this.isBlacklisted(),
      'menu-item--whitelisted': this.isWhitelisted(),
    }

    return (
      <li className={ classnames(rowClasses) }>
        <div className='menu-item__row'>
          <Link className='menu-item__link' to={ menuLink.to } onClick={ this.handleRefreshAdsIfOnSamePage } >
            { menuLink.menuTitle }
          </Link>
          { this.hasBlacklistedChildren() && (
            <ArrowToggle
              toggleUp={ showBlacklistedPages }
              onToggleAction={ this.handleShowBlacklistedPages() }
              screenReaderMessage='Listaa alakategoriat'
            />
          ) }
        </div>

        { hasSubPages &&
          <SidebarMenuList
            className={ classnames(subMenuClasses) }
            menuLinks={ menuLink.pages }
            path={ path }
            blacklistedCategoryIds={ blacklistedCategoryIds }
            whitelistedCategoryIds={ whitelistedCategoryIds }
            dispatch={ dispatch }
          />
        }
      </li>
    )
  }

  handleShowBlacklistedPages = (value, to) => () => {
    const { menuLink, dispatch } = this.props
    const { showBlacklistedPages, to } = menuLink
    dispatch(setShowBlacklistedPages(!showBlacklistedPages, to))
  }

  handleRefreshAdsIfOnSamePage = () => {
    if (this.props.menuLink.to === this.props.path) {
      refreshAllAds()
    }
  }

  isActive = () => {
    const { menuLink, path } = this.props
    return menuLink.to === path
  }

  isWhitelisted = () => {
    const { menuLink, whitelistedCategoryIds } = this.props
    return whitelistedCategoryIds.contains(menuLink.categoryId)
  }

  isBlacklisted = () => {
    const { menuLink, blacklistedCategoryIds } = this.props
    return blacklistedCategoryIds.contains(menuLink.categoryId) || (blacklistedCategoryIds.contains(CATEGORY_ID_SPORTS) && menuLink.to === LINK_SPORT_RESULTS)
  }

  hasBlacklistedChildren = () => {
    const { menuLink, blacklistedCategoryIds } = this.props
    if (!menuLink.pages) {
      return false
    }
    return menuLink.categoryId === CATEGORY_ID_LOCAL_NEWS || menuLink.pages.some(subPage => blacklistedCategoryIds.has(subPage.categoryId))
  }
}


export default connect((state) => {
  return {
    path: selectLocationPath(state),
    menuLinks: selectSideMenuLinks(state),
    blacklistedCategoryIds: selectBlacklistedCategoryIds(state),
    whitelistedCategoryIds: selectUserWhitelistedCategoryIds(state),
  }
})(SidebarMenuList)
