import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Link } from 'react-router'
import ItemsEmpty from './ItemsEmpty'

export default class PersonalItemsEmpty extends PureComponent {
  render() {
    const { loggedIn } = this.props

    return (
      <ItemsEmpty title='No mutta,'>
        { loggedIn ?
          <p>
            seuraamillasi aiheilla ja kategorioilla ei löydy tällä hetkellä juttuja.
            Voit määrittää <Link className='text-link' to='/asetukset/kategoriat'>profiilisi asetuksissa</Link>,
            mitä haluat Omalla listalla seurata.
          </p>
          :
          <p>
            Seuraamillasi aiheilla ja kategorioilla ei löydy tällä hetkellä juttuja.
            Voit lisätä Omiin aiheisiin sinua kiinnostavaa sisältöä avaamalla valikko aiheen tai kategorian vieressä olevalla
            <span className='fa fa-ellipsis-v text-icon' />-merkillä ja valitsemalla seuraa.
          </p>
        }
      </ItemsEmpty>
    )
  }
}

PersonalItemsEmpty.propTypes = exact({
  loggedIn: PropTypes.bool.isRequired,
})
