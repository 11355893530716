import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import socketio from 'socket.io-client'
import config from '../../config/config'
import { setWebsocketConnected } from '../status/statusActions'
import { updateTimestamp } from '../time/timeActions'
import { ingestWebsocketTickers } from '../components/ticker/tickerActions'
import { ingestWebsocketItem } from '../components/item/itemsActions'


/**
 * Component that handles websocket communication. Renders nothing.
 * @todo add logic to automatically disconnect or at the very least
 * suppress automatic reconnects when browser tab has been in background
 * for a long time.
 * @todo more data-events: client version, current weather (helsinki), trending
 */
export default class WebsocketClient extends React.Component {
  static propTypes = exact({
    dispatch: PropTypes.func.isRequired,
  })

  render() {
    return null
  }

  componentDidMount() {
    const { dispatch } = this.props

    const io = this.io = socketio(config.socket, {
      transports: ['websocket'],
      upgrade: false,
      reconnectionDelay: 2000,
      reconnectionDelayMax: 60000,
    })

    /**
     * Socket.IO internal events
     */
    io.on('connect', () => {
      dispatch(setWebsocketConnected(true))
    })
    io.on('connect_error', () => {
      dispatch(setWebsocketConnected(false))
    })
    io.on('disconnect', () => {
      dispatch(setWebsocketConnected(false))
    })

    /**
     * Ampparit data events
     */
    io.on('timestamp', (timestamp) => {
      dispatch(updateTimestamp(timestamp))
    })
    io.on('tickers', (tickers) => {
      dispatch(ingestWebsocketTickers(tickers))
    })
    io.on('items', (items) => {
      items.forEach(item => {
        dispatch(ingestWebsocketItem(item))
      })
    })

    /**
     * Reconnect with a random 2-20 second delay when websocket server announces restart
     */
    io.on('restarting', () => {
      io.disconnect()
      const delay = Math.floor(Math.random() * 18000) + 2000
      window.setTimeout(() => {
        io.connect()
      }, delay)
    })

    /**
     * Update newslist timestamp positions using client time when websocket is unavailable
     */
    this.timestampInterval = window.setInterval(() => {
      if (io.disconnected) {
        dispatch(updateTimestamp(Date.now()))
      }
    }, 60000)
  }


  /**
   * As a direct child in App.jsx this component should never unmount but let's cleanup
   * properly just in case
   */
  componentWillUnmount() {
    this.io.disconnect()
    window.clearInterval(this.timestampInterval)
  }

}
