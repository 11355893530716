import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'

export default class Button extends Component {

  constructor(props) {
    super(props)
    this.button = React.createRef()
  }

  focusButton() {
    /**
     * Gives focus for button if needed for accessibility reasons
     * Example in implemented component: this.editButton.current.focusButton()
     *
    */
    this.button.current.focus()
  }

  render() {
    const {
      children,
      loading,
      text,
      disabled,
      type,
      className,
    } = this.props

    return (
      <button
        type={ type }
        disabled={ loading || disabled }
        onClick={ this.props.onClick }
        className={ classnames('button', className) }
        ref={ this.button }
      >
        <span>
          { !loading && (
            text ? <span>{ text }</span> : children
          ) }

          { loading && (
            <span className='spin' aria-hidden='true'><i className='fa fa-spinner' aria-hidden='true' /></span>
          ) }
        </span>
      </button>
    )
  }
}

Button.propTypes = exact({
  children: PropTypes.node,
  loading: PropTypes.bool,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
})
