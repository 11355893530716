import React, { PureComponent } from 'react'
import { Link } from 'react-router'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import './widgetBox.pcss'

export default class WidgetBox extends PureComponent {
  render() {
    const {
      className,
      title,
      linkTo,
      linkToHelpText,
      widgetId,
      children,
    } = this.props

    return (
      <section className={ classnames('widget-box', className) }>
        { linkTo ? (
          <h3 className='widget-box__header' id={ widgetId }>
            <Link to={ linkTo } title={ linkToHelpText }>
              <span>{ title }</span>
              <i className='fa-stack link-to'>
                <i className='fa fa-circle-thin fa-stack-1x' />
                <i className='fa fa-angle-right fa-stack-1x' />
              </i>
            </Link>
          </h3>
        ) : (
          <h3 className='widget-box__header' id={ widgetId }>
            { title }
          </h3>
        ) }
        <div className='widget-box__content'>
          { children }
        </div>
      </section>
    )
  }
}


WidgetBox.propTypes = exact({
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  linkTo: PropTypes.string,
  linkToHelpText: PropTypes.string,
  widgetId: PropTypes.string,
  className: PropTypes.string,
})
