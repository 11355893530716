import * as actions from './trendingActions'
import Immutable from 'immutable'
import { TagRecord } from './tagsReducer'
import { CategoryRecord } from './categoryReducer'

const initialState = Immutable.Map({
  categories: Immutable.OrderedSet(),
  tags: Immutable.OrderedSet(),
  loading: false,
  failedToLoad: false,
  timestamp: null,
})

export default function trending(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_TRENDING:
      return state
        .set('loading', true)
        .set('failedToLoad', false)

    case actions.FETCH_TRENDING_FAILURE:
      return state
        .set('loading', false)
        .set('timestamp', null)
        .set('failedToLoad', true)

    case actions.FETCH_TRENDING_SUCCESS:
      return state
        .set('loading', false)
        .set(
          'tags',
          Immutable.OrderedSet(
            action.response.data.tags.map(tag => {
              const { id, slug, h1, name, breaking } = tag

              return new TagRecord({
                id,
                slug,
                h1,
                name,
                breaking,
              })
            })
          )
        )
        .set(
          'categories',
          Immutable.OrderedSet(
            action.response.data.categories.map(category => {
              const { id, name, properName, parentId, treeLevel, metaDescription, title, h1, relatedCategoryIds } = category

              return new CategoryRecord({
                id: id,
                slug: name,
                name: properName,
                parentId: parentId,
                treeLevel: treeLevel,
                desc: metaDescription,
                title: title,
                h1: h1,
                relatedCategoryIds: Immutable.OrderedSet(relatedCategoryIds),
              })
            })
          )
        )
        .set('timestamp', action.timestamp)

    default:
      return state
  }
}
