import React, { Component } from 'react'
import { Link } from 'react-router'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import classnames from 'classnames'
import './mainNavigation.pcss'
import { connect } from 'react-redux'
import { selectTopNaviLinks } from '../../selectors/menuSelectors'


@connect((state) => {
  return {
    links: selectTopNaviLinks(state),
  }
}, {})

export default class MainNavigation extends Component {
  static propTypes = exact({
    links: ImmutablePropTypes.listOf(
      ImmutablePropTypes.record
    ).isRequired,
  })

  render() {
    const { links } = this.props

    return (
      <nav aria-label='Osiot'>
        <ul className='main-navigation'>
          { links.map(link => (
            <li key={ link.to } className={ classnames({active: link.topNaviActive}) }>
              <Link to={ link.to }>{ link.topNaviTitle }</Link>
            </li>
          )) }
        </ul>
      </nav>
    )
  }
}
