import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import React, { PureComponent } from 'react'
import './searchTrigger.pcss'

export default class SearchTrigger extends PureComponent {
  render() {

    return (
      <div className='search-trigger'>
        <button
          type='button'
          className='search-trigger__input'
          onClick={ this.props.onInputClick }
          aria-label='Hae uutisia'
        >
          <span className='search-icon fa fa-search' aria-hidden='true' />
          <span className='search-trigger__input-placeholder' aria-hidden='true'>Hae uutisia</span>
        </button>
      </div>
    )
  }
}

SearchTrigger.propTypes = exact({
  onInputClick: PropTypes.func.isRequired,
})
