import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Link } from 'react-router'
import listEmptyImg from '../../assets/list-empty.png'
import listEmptySmallImg from '../../assets/list-empty-s.png'

export default class ItemsEmpty extends Component {
  render() {
    const { title } = this.props

    return (
      <div className='items-list-empty'>
        <span className='title'>
          { title }
        </span>
        <span className='content'>
          <div>
            { this.props.children }
            <Link className='text-link' to='/' title='Siirry etusivulle'>
              Palaa tästä Amppareiden etusivulle.
              <span className='list-empty-img'>
                <img className='desktop' src={ listEmptyImg } alt='' />
                <img className='mobile' src={ listEmptySmallImg } alt='' />
              </span>
            </Link>
          </div>
        </span>
      </div>
    )
  }
}

ItemsEmpty.propTypes = exact({
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
})
