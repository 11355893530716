import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import MainContent from '../../components/wrappers/MainContent'
import { registerAlmaTunnus } from '../../user/almalogin/almaUserActions'
import { selectIsUserLoggedIn } from '../../selectors/userSelector'
import benefit1 from '../../assets/login-benefit-1.png'
import benefit2 from '../../assets/login-benefit-2.png'
import './infoViews.pcss'

const LoginBenefits = ({ dispatch, loggedIn }) => {

  const handleRegister = () => {
    dispatch(registerAlmaTunnus())
  }

  return (
    <MainContent>
      <article className='info-page'>
        <Helmet title='Tietoa kirjautumisesta' />
        <h1>Ota Amppareista kaikki irti - kirjaudu ja muokkaa uutislistoja</h1>

        <section className='info-box'>
          <p>
            Haluatko seurata tiettyä uutistapahtumaa tai piilottaa tietyn median uutiset Amppareista?
            Kirjautumalla Amppareihin saat käyttöösi monia ominaisuuksia, joilla voit rakentaa uutislistat juuri sinulle sopiviksi.
          </p>

          <p>Ampparit tarjoaa kirjautuneelle käyttäjälle lukuisia tapoja muokata uutislistoja. Luomalla Alma-tunnuksen voit esimerkiksi:</p>
          <ul>
            <li>Valita, mitä medioita haluat uutislistoillasi nähdä</li>
            <li>Suodattaa pois uutisia, joita et halua lukea</li>
            <li>Seurata juuri sinua kiinnostavia aiheita</li>
          </ul>
        </section>

        <section className='info-box info-box-image' aria-hidden='true'>
          <img src={ benefit1 } alt='Kirjautumisen etu kuva 1' style={ { width: '100%', maxWidth: '400px', margin: '10px 10px 0 0' } } />
          <img src={ benefit2 } alt='Kirjautumisen etu kuva 2' style={ { width: '100%', maxWidth: '445px', margin: '10px 10px 0 0' } } />
        </section>

        { !loggedIn ?
          <section className='info-box'>
            <p>
              Oman Ampparit-profiilin käyttö onnistuu, kun luot Alma-tunnuksen. Pääset luomaan sen alta:
            </p>

            <div>
              <button
                type='button'
                className='button button--primary button--large'
                onClick={ handleRegister }
                aria-label='Luo tunnus'
              >
                Luo tunnus
              </button>
            </div>
          </section>
          : null
        }

        <section className='info-box'>
          <h2>Alma-tunnus</h2>
          <p>
            Alma-tunnus on Alma Median palveluiden yhteinen käyttäjätunnus. Luomalla
            tunnuksen käytät helpommin Alman eri palveluita yhdellä kirjautumisella.
          </p>

          <p>
            Saat käyttöön tilaamiesi medioiden laajan uutistarjonnan talouden, politiikan ja
            vapaa-ajan aiheista, sekä voit käyttää helposti monipuolisia asumisen, autoilun
            ja työnhaun palveluita.
          </p>

          <p>
            Pääset helposti yhdellä tunnuksella Alman palveluihin, sovelluksiin,
            keskustelupalstoille sekä tilaamiisi medioihin. Voit sujuvasti siirtyä Alman
            verkkopalvelusta toiseen ja saat enemmän juuri sinulle kohdennettua sisältöä.
          </p>

          <p>
            Tunnuksen käyttäminen on turvallista ja helppoa.
          </p>

          <p>
            Lue lisää Alma-tunnuksesta täältä:
            <br />
            <a href='https://www.almamedia.fi/alma-tunnus/' target='_blank' rel='noopener noreferrer'>https://www.almamedia.fi/alma-tunnus/</a>
          </p>
        </section>

        <section className='info-box'>
          <p>
            Kysyttävää kirjautumisesta, profiilista tai Alma-tunnuksesta? <Link to='/palaute'>Ota yhteyttä.</Link>
          </p>

        </section>

      </article>
    </MainContent>
  )
}

LoginBenefits.propTypes = {  // eslint-disable-line react/prefer-exact-props
  dispatch: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  loggedIn: selectIsUserLoggedIn(state),
})

export default connect(mapStateToProps)(LoginBenefits)
