import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import moment from 'moment-timezone'
import chunk from 'lodash/chunk'
import classNames from 'classnames'
import wind from './icons/wind-arrow.svg'
import drop from './icons/drop.svg'
import rain from './icons/rain.svg'


// Define a function to calculate the average or sum of a property in a list of Immutable Maps
const calculateAverageOrSum = (data, property, isSum = false) => {
  const total = data.reduce((acc, item) => {
    const value = parseFloat(item.get(property))
    return typeof value === 'number' ? acc + value : acc
  }, 0)

  return isSum ? total : total / (data.length || 1)
}

const WeatherHourly = ({locationData, index, onComponentClick}) => {
  // Calculate the averages for each group of forecasts
  const forecastAverages = useMemo(() => {
    // Split the forecasts into groups of three
    return chunk(locationData.getIn([index, 'forecasts']).toArray(), 3).map((threeHourly) => {
      const averageTemp = calculateAverageOrSum(threeHourly, 'temperature')
      const averageFeelsLike = calculateAverageOrSum(threeHourly, 'feelsLike')
      const averageWindSpeed = Math.round(calculateAverageOrSum(threeHourly, 'windSpeed'))
      const averagePrecipitation = Math.round(calculateAverageOrSum(threeHourly, 'precipitation', true) * 10) / 10
      const averagePrecipitationProbability = Math.round(calculateAverageOrSum(threeHourly, 'precipitationProbability'))
      const time = moment(threeHourly[0].get('datetime'))
      const temperature = (averageTemp > 0 ? '+' : '') + Math.floor(averageTemp)
      const feelsLike = (averageFeelsLike > 0 ? '+' : '') + Math.floor(averageFeelsLike)

      return {
        averageTemp,
        averageFeelsLike,
        averageWindSpeed,
        averagePrecipitation,
        averagePrecipitationProbability,
        time,
        temperature,
        feelsLike,
        threeHourly,
      }
    })
  }, [locationData, index])

  return (
    <button className='weekly-weather-day__hourly' onClick={ onComponentClick }>
      { forecastAverages.map(({ time, temperature, feelsLike, averageWindSpeed, averagePrecipitation, averagePrecipitationProbability, threeHourly }) => (
        <li className='weekly-weather-day__hourly-hour' key={ time.valueOf() }>
          <div className='weather-time'>
            <time title={ time.format('dd D.M. H') } dateTime={ time.toISOString() }>
              { time.format('HH') }
            </time>
          </div>
          <span className='weather-symbol'>
            <span className={ classNames(threeHourly[0].get('symbol')) } />
          </span>

          <div className='weather-temperature-container'>
            <div className='weather-temperature'>{ temperature + String.fromCharCode(176) }</div>
            <div className='weather-temperature-feelslike'>{ 'Tuntuu: ' + feelsLike + String.fromCharCode(176) }</div>
          </div>

          <div className='weather-wind'>
            <span
              className={ classNames(
                'weather-wind__image__wrapper',
                'weather-wind-direction',
                threeHourly[0].get('windDirection')
              ) }
              aria-hidden='true'
            >
              <img
                alt='wind-arrow'
                src={ wind }
                width={ 15 }
                height={ 15 }
                loading='lazy'
              />
            </span>
            <span className='weather-value'>{ averageWindSpeed + ' m/s' }</span>
          </div>

          <div className='weather-min-max-rain-change'>
            <span className='weather-min-max-rain-change__image__wrapper'>
              <img
                className='weather-min-max-rain-change__image'
                alt='drop'
                src={ drop }
                width={ 15 }
                height={ 15 }
                loading='lazy'
              />
            </span>
            <span className='weather-value'>{ averagePrecipitation + ' mm' }</span>
          </div>

          <div className='weather-precipitation-probability'>
            <span className='weather-precipitation-probability__image__wrapper'>
              <img
                alt='rain'
                src={ rain }
                width={ 15 }
                height={ 15 }
                loading='lazy'
              />
            </span>
            <span className='weather-value'>{ averagePrecipitationProbability + ' %' }</span>
          </div>
        </li>
      ))
      }
    </button>
  )
}

WeatherHourly.propTypes = exact({
  locationData: ImmutablePropTypes.list,
  index: PropTypes.number,
  onComponentClick: PropTypes.func,
})

export default WeatherHourly
