import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import './searchResultsEmpty.pcss'

export default class SearchResultsEmpty extends PureComponent {
  render() {
    const { errorLength } = this.props

    return (
      <div className='search-results-empty'>
        {
          errorLength ? [
            <span key={ 1 } className='search-results-empty__icon fa fa-search' aria-hidden='true'></span>,
            <h3 key={ 2 } className='search-results-empty__title'>Hae uutisia suoraan ylävalikosta</h3>,
          ] : [
            <span key={ 1 } className='search-results-empty__icon fa fa-eye-slash' aria-hidden='true'></span>,
            <h3 key={ 2 } className='search-results-empty__title'>Ei hakutuloksia</h3>,
          ]
        }
        <p className='search-results-empty__text'>
          Ampparit haulla löydät tarkalleen mitä etsit hakusanoillasi:
          jos kirjoitat useamman sanan, näet ainoastaan uutiset joissa esiintyy
          kaikki syöttämäsi kriteerit.
        </p>
      </div>
    )
  }
}

SearchResultsEmpty.propTypes = exact({
  errorLength: PropTypes.bool.isRequired,
})
