import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classnames from 'classnames'
import SimpleItem from '../item/SimpleItem'
import Loading from '../util/Loading'
import UnableToLoad from '../messages/UnableToLoad'
import { Link } from 'react-router'

export default class NewsListBoxItems extends PureComponent {
  render () {
    const {
      dispatch,
      className,
      trackingClass,
      title,
      linkTo,
      linkToTitle,
      items,
      loading,
      failedToLoad,
      emptyText,
    } = this.props

    return (
      <div className={ classnames('news-list-box', className ) }>
        <div className='news-list-box-header'>
          <Link to={ linkTo } title={ linkToTitle }>
            <span>{ title }</span>
            <i className='fa-stack link-to'>
              <i className='fa fa-circle-thin fa-stack-1x' />
              <i className='fa fa-angle-right fa-stack-1x' />
            </i>
          </Link>
        </div>

        <div className='news-list-box-content'>
          <Loading loading={ loading }>
            { failedToLoad && items.size === 0 &&
              <UnableToLoad errorText='Uutisia ei saatu ladattua.' />
            }

            { emptyText && !failedToLoad && items.size === 0 &&
              <UnableToLoad errorText={ emptyText } />
            }

            <div className='news'>
              { items.map((item, i)=> (
                <SimpleItem
                  key={ item.get('id') }
                  dispatch={ dispatch }
                  item={ item }
                  trackingClass={ trackingClass }
                  gaItemClickUiElement='Mobiilin uutisboksi'
                />
              )) }
            </div>
          </Loading>
        </div>

      </div>
    )
  }
}

NewsListBoxItems.propTypes = exact({
  dispatch: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  trackingClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkToTitle: PropTypes.string.isRequired,
  items: ImmutablePropTypes.orderedSet.isRequired,
  loading: PropTypes.bool.isRequired,
  failedToLoad: PropTypes.bool.isRequired,
  emptyText: PropTypes.string.isRequired,
})
