import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router'
import moment from 'moment-timezone'
import classnames from 'classnames'
import { SVG_ICON_EYE } from '../util/svgIcons'
import './itemDetails.pcss'

export default class ItemDetails extends PureComponent {
  render() {
    const { item, isToday } = this.props
    const paywalled = item.get('paywalled', false)
    const source = item.get('source')
    const categorySlug = item.getIn(['category', 'slug'], 'omat')
    const categoryName = item.getIn(['category', 'name'], 'Oma syöte')
    const dateTime = moment(item.get('timestamp') * 1000)
    const isCategoryLong = categoryName.length > 12
    const isSourceLong = source && source.length > 14

    return (
      <div className={ classnames('item-details', { 'item-details_paywalled': paywalled }) }>
        <time
          className='item-details__detail item-details__detail_time'
          title={ dateTime.format('dd D.M. H:mm') }
          dateTime={ dateTime.toISOString() }
        >
          { dateTime.format(isToday ? 'H:mm' : 'D.M.') }
        </time>
        { source &&
          <span className={ classnames('item-details__detail item-details__detail_source', {'item-details__detail--shrink': isSourceLong}) }>{ source }</span>
        }

        <Link
          to={ '/' + categorySlug }
          className={ classnames('item-details__detail item-details__detail_category', {'item-details__detail--shrink': isCategoryLong}) }
        >
          { categoryName }
        </Link>

        { !item.get('custom') &&
          <span className='item-details__detail item-details__detail_clicks'>
            <svg aria-hidden='true'>
              <use href={ `#${SVG_ICON_EYE}` } />
            </svg>
            { item.get('clicks') }
          </span>
        }

      </div>
    )
  }
}

ItemDetails.propTypes = exact({
  item: ImmutablePropTypes.map.isRequired,
  isToday: PropTypes.bool.isRequired,
})
