export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'
export const GENERIC_NOTIFICATION = 'GENERIC_NOTIFICATION'

export function removeNotification(id) {
  return {
    type: REMOVE_NOTIFICATION,
    id,
  }
}

export function genericNotification(message, level, id) {
  return dispatch => {
    dispatch({
      type: GENERIC_NOTIFICATION,
      message: message,
      level: level || 'success',
      id: id || null,
    })
  }
}
