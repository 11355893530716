import { getCategories } from '../../api/api'
import { logException } from '../../sentry'

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE'

export function fetchCategories() {
  return (dispatch) => {
    dispatch({
      type: FETCH_CATEGORIES,
    })

    return getCategories().then(response => {
      dispatch({
        type: FETCH_CATEGORIES_SUCCESS,
        response,
        timestamp: Date.now(),
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_CATEGORIES_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}
