import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import SearchTrigger from '../../views/Search/SearchTrigger'

export default class MenuSearchToggle extends PureComponent {
  render() {
    const { searchOpen, onSearchOpen } = this.props

    return (
      <div className='menu-search'>
        { !searchOpen && (
          <SearchTrigger onInputClick={ onSearchOpen } />
        ) }
      </div>
    )
  }
}

MenuSearchToggle.propTypes = exact({
  searchOpen: PropTypes.bool.isRequired,
  onSearchOpen: PropTypes.func.isRequired,
})
