import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

export default class TimeLabel extends Component {
  render() {
    const { labelText, visible } = this.props

    return (
      <div className='time-label-wrapper'>
        { visible && (
          <div className='time-label'>
            <span>{ labelText }</span>
          </div>
        ) }
      </div>
    )
  }
}

TimeLabel.propTypes = exact({
  labelText: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
})
