import { getTickers } from '../../api/api'
import { logException } from '../../sentry'
import { Cookies } from 'react-cookie'
import { isTimestampFresh } from '../../lib/utils'

export const FETCH_TICKERS = 'FETCH_TICKERS'
export const FETCH_TICKERS_SUCCESS = 'FETCH_TICKERS_SUCCESS'
export const FETCH_TICKERS_FAILURE = 'FETCH_TICKERS_FAILURE'
export const CLOSE_TICKER = 'CLOSE_TICKER'

export const TICKER_COOKIE_PREFIX = 'closedTicker_'


export function ingestWebsocketTickers(tickers) {
  const cookies = new Cookies()
  return {
    type: FETCH_TICKERS_SUCCESS,
    tickers: tickers,
    cookies: cookies,
    timestamp: Date.now(),
  }
}


export function fetchTickers(maxStaleSecs = 0, cookies = null, tickerSlug = null) {
  return (dispatch, getState) => {
    const state = getState()
    const lastFetchTimestamp = state.tickers.timestamp
    const loading = state.tickers.loading
    if (loading || isTimestampFresh(lastFetchTimestamp, maxStaleSecs)) {
      return
    }

    dispatch({
      type: FETCH_TICKERS,
    })

    return getTickers(tickerSlug).then(response => {
      dispatch({
        type: FETCH_TICKERS_SUCCESS,
        tickers: response.data.data,
        cookies: cookies,
        timestamp: Date.now(),
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: FETCH_TICKERS_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}

export function closeTicker(tickerId) {
  return {
    type: CLOSE_TICKER,
    tickerId: tickerId,
  }
}
