import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import Button from '../../components/ui/Button'
import Toggle from 'react-toggle'
import Select from 'react-select'
import { addFeed, fetchUserProfile, removeFeed, setShowUserFeedsBoxInSidebar, updateFeed } from '../userActions'
import { selectCategories } from '../../selectors/categoriesSelector'
import { validateURL } from '../validate'
import { selectUserCustomFeeds, selectShowUserFeedsSidebar } from '../../selectors/userSelector'
import '../../styles/settings.pcss'
import './userFeedsView.pcss'

const UserFeedsView = () => {
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState('')
  const inputRef = useRef(null)

  const categories = useSelector(selectCategories)
  const userFeeds = useSelector(selectUserCustomFeeds)

  const userFeedsBoxInSidebar = useSelector(selectShowUserFeedsSidebar)

  useEffect(() => {
    dispatch(fetchUserProfile(5))
  }, [dispatch])

  const handleShowUserFeedsInSidebarToggle = () => {
    dispatch(setShowUserFeedsBoxInSidebar(!userFeedsBoxInSidebar))
  }

  const handleAddFeed = () => {
    const url = inputRef.current.value.trim()

    if (!validateURL(url)) {
      setErrorMessage('Tarkista osoitteen muoto.')
      return
    }

    setErrorMessage('')
    dispatch(addFeed(url))
  }

  // For some reason, API only allows top and 2nd level categories for feeds
  const categoryOptions = categories.toList()
    .filter((category) => category.get('treeLevel') < 3)
    .sort((a, b) => (a.name > b.name) - (a.name < b.name))
    .map((category) => ({ value: category.id, label: category.name }))
    .toArray()

  return (
    <div>
      <Helmet title='Omat syötteet' />

      <section className='content'>
        <div className='feeds-box-display'>
          <h3>Näytä omia syötteitä sivun oikeassa sivupalkissa</h3>
          <div>
            <label htmlFor='show-sidebar-feeds'>Syötteet näytetään sivupalkissa</label>
            <Toggle
              checked={ userFeedsBoxInSidebar }
              onChange={ handleShowUserFeedsInSidebarToggle }
              id='show-sidebar-feeds'
            />
          </div>
        </div>

        <hr />

        <div>
          <h3>Omat syötteet</h3>
          <p>
            Omat syötteet näkyvät uusimpien uutisten joukossa, sivupalkissa ja Omat-sivulla.
            Voit halutessasi valita syötteelle kategorian, jossa syöte lisäksi näkyy.
          </p>

          <div className='settings-input-error'>{ errorMessage }</div>

          <div className='add-feed'>
            <input
              aria-label='Lisättävän RSS-syötteen osoite'
              className='add-feed__input'
              ref={ inputRef }
              placeholder='Lisää uusi syöte'
              type='url'
            />
            <Button className='add-feed__button' type='button' text='Lisää' onClick={ handleAddFeed } />
          </div>

          <div className='add-feed-description'>
            RSS-syöte, esim. http://feeds.bbci.co.uk/news/rss.xml
          </div>

          { userFeeds.toArray().map((feed) => (
            <Feed
              key={ feed.id }
              feed={ feed }
              categoryOptions={ categoryOptions }
              dispatch={ dispatch }
            />
          )) }
        </div>
      </section>
    </div>
  )
}

const selectStyles = {
  control: (styles) => ({ ...styles, color: '#000000' }),
  option: (styles) => ({ ...styles, color: '#000000' }),
}

const Feed = ({ dispatch, feed, categoryOptions }) => {
  const handleRemove = () => {
    dispatch(removeFeed(feed.id))
  }

  const handleCategoryChange = (selection) => {
    const categoryId = selection ? selection.value : 0
    dispatch(updateFeed(feed.id, categoryId))
  }

  const selectedOption = categoryOptions.find((option) => option.value === feed.categoryId)

  return (
    <div className='user-feed'>
      <div className='user-feed__data'>
        <div className='user-feed__url'>{ feed.url }</div>
        <div className='user-feed__category'>
          <Select
            aria-label={ 'Valitse kategoria syötteelle ' + (feed.name || feed.url) }
            isClearable={ true }
            placeholder='Valitse kategoria...'
            value={ selectedOption }
            options={ categoryOptions }
            onChange={ handleCategoryChange }
            styles={ selectStyles }
          />
        </div>
      </div>
      <button
        type='button'
        className='user-feed__remove fa fa-times'
        onClick={ handleRemove }
        title='Poista syöte'
        aria-label='Poista syöte'
      />
    </div>
  )
}

Feed.propTypes = exact({
  feed: ImmutablePropTypes.record.isRequired,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
})

export default UserFeedsView
