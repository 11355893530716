import Immutable from 'immutable'

import {
  FETCH_LOGO,
  FETCH_LOGO_SUCCESS,
  FETCH_LOGO_FAILURE,
} from './BrandLogoActions'

export const logoInitialState = Immutable.Map({
  image: null,
  loading: false,
  failedToLoad: false,
  timestamp: null,
})

export function brandLogo(state = logoInitialState, action) {
  switch (action.type) {

    case FETCH_LOGO:
      return state
        .set('loading', true)
        .set('failedToLoad', false)

    case FETCH_LOGO_SUCCESS:
      return state
        .set('image', action.response?.data[0]?.image)
        .set('timestamp', action.timestamp)
        .set('failedToLoad', false)
        .set('loading', false)

    case FETCH_LOGO_FAILURE:
      return state
        .set('timestamp', action.timestamp)
        .set('failedToLoad', true)
        .set('loading', false)

    default:
      return state
  }
}

export default brandLogo
