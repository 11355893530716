import React, { useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import Dropdown from 'react-dropdown'
import { List } from 'immutable'
import { setSelectedLanguage } from '../item/itemsActions'

const languageOptions = List([{
  type: 'group',
  name: 'Valitse kieli',
  items: [
    { label: 'Suomenkieliset',    value: 'fi' },
    { label: 'Kaikki (fi/en/sv)', value: 'any' },
  ],
}])

const LanguageDropdown = memo(({ onChange, selectedLanguage }) => (
  <Dropdown
    options={ languageOptions.toJS() }
    onChange={ onChange }
    value={ selectedLanguage }
    placeholder='Valitse kieli'
  />
))
LanguageDropdown.displayName = 'LanguageDropdown'

LanguageDropdown.propTypes = exact({
  onChange: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
})


const LanguagePicker = ({dispatch, selectedLanguage}) => {
  const changeDropdownValue = useCallback(({ value }) => { dispatch(setSelectedLanguage(value)) }, [dispatch])
  return (
    <div className='language-selector-container'>
      <LanguageDropdown
        selectedLanguage={ selectedLanguage }
        onChange={ changeDropdownValue }
      />
    </div>
  )
}

export default LanguagePicker

LanguagePicker.propTypes = exact({
  dispatch: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
})
