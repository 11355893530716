import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { Link } from 'react-router'
import WidgetBox from '../../components/widgets/WidgetBox'
import Switch from '../../components/ui/Switch'
import './personalItemsInstructions.pcss'

export default class PersonalItemsInstructions extends PureComponent {
  render() {
    const { loggedIn, trendingTags, trendingCategories } = this.props
    return (
      <section className='content content_padded personal-items-instructions'>
        <div className='personal-items-instructions__row'>
          <div className='personal-items-instructions__column'>
            <h2 className='personal-items-instructions__heading'>Juuri sinulle tärkeät aiheet</h2>
          </div>
          <div className='personal-items-instructions__column'>
            { loggedIn ?
              <p className='personal-items-instructions__text'>
                Omat aiheet on näkymä, jossa sinä päätät, mitä uutisia näet.
                Aloita valitsemalla profiilin asetuksista aiheita, jotka kiinnostavat sinua.
                Voit seurata mitä tahansa aihetta avaamalla valikko aiheen tai kategorian vieressä olevalla
                <span className='fa fa-ellipsis-v text-icon' />-merkillä ja valitsemalla seuraa. Voit myös lisätä listaan
                omia lähteitä <Link to='/asetukset/omat-syotteet'>Omat syötteet -asetussivulla</Link>
              </p>
              :
              <p className='personal-items-instructions__text'>
                Omat aiheet on näkymä, jossa sinä päätät, mitä uutisia näet.
                Selaa Amppareiden uutislistoja ja Pinnalla-boksia ja lisää aiheita Omiin
                aiheisiin avaamalla valikko aiheen tai kategorian vieressä olevalla
                <span className='fa fa-ellipsis-v text-icon' />-merkillä ja valitsemalla seuraa. Kirjautuneena käyttäjänä voit
                myös lisätä Omat aiheet -listaan omia lähteitä.
              </p>
            }
          </div>
        </div>
        <div className='personal-items-instructions__row'>
          <div className='personal-items-instructions__column'>
            <WidgetBox title='Suosittuja aiheita'>
              { trendingTags.map(tag => (
                <Switch key={ tag.get('id') } tag={ tag } />
              )) }
            </WidgetBox>
          </div>
          <div className='personal-items-instructions__column'>
            <WidgetBox title='Suosittuja kategorioita'>
              { trendingCategories.map(category => (
                <Switch key={ category.get('id') } tag={ category } />
              )) }
            </WidgetBox>
          </div>
        </div>
      </section>
    )
  }
}

PersonalItemsInstructions.propTypes = exact({
  loggedIn: PropTypes.bool.isRequired,
  trendingTags: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    whitelisted: PropTypes.bool.isRequired,
    blacklisted: PropTypes.bool.isRequired,
  })).isRequired,
  trendingCategories: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    whitelisted: PropTypes.bool.isRequired,
    blacklisted: PropTypes.bool.isRequired,
  })).isRequired,
})
