import { searchWeatherLocations } from '../../api/api'
import { fetchWeatherByCoordinates } from '../../views/Weather/weatherActions'
import { logException } from '../../sentry'

export const UPDATE_COORDINATES = 'UPDATE_COORDINATES'
export const FETCH_SEARCH_LOCATION = 'FETCH_SEARCH_LOCATION'
export const FETCH_SEARCH_LOCATION_SUCCESS = 'FETCH_SEARCH_LOCATION_SUCCESS'
export const FETCH_SEARCH_LOCATION_FAILURE = 'FETCH_SEARCH_LOCATION_FAILURE'
export const FETCH_SEARCH_LOCATION_ERROR_LENGTH = 'FETCH_SEARCH_LOCATION_ERROR_LENGTH'
export const CLEAR_LOCATION_SEARCH = 'CLEAR_LOCATION_SEARCH'
export const SET_DEFAULT_LOCATION = 'SET_DEFAULT_LOCATION'
export const GET_GEOLOCATION = 'GET_GEOLOCATION'
export const GET_GEOLOCATION_SUCCESS = 'GET_GEOLOCATION_SUCCESS'
export const GET_GEOLOCATION_FAILURE = 'GET_GEOLOCATION_FAILURE'

export function searchLocation(searchString) {
  return dispatch => {
    dispatch({
      type: FETCH_SEARCH_LOCATION,
    })

    if (!searchString || searchString.length < 2) {
      dispatch({
        type: FETCH_SEARCH_LOCATION_ERROR_LENGTH,
        searchString,
      })
      return
    }

    return searchWeatherLocations(searchString).then(response => {
      dispatch({
        type: FETCH_SEARCH_LOCATION_SUCCESS,
        response,
        timestamp: Date.now(),
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: FETCH_SEARCH_LOCATION_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}

export function clearLocationSearch() {
  return {
    type: CLEAR_LOCATION_SEARCH,
  }
}

export function updateCoordinates(coords) {
  return {
    type: UPDATE_COORDINATES,
    coords,
  }
}

export function requestGeolocation() {
  return (dispatch, getState) => {
    dispatch({
      type: GET_GEOLOCATION,
    })

    return window.navigator.geolocation.getCurrentPosition((position) => {
      dispatch(updateCoordinates({lat: position.coords.latitude, lon: position.coords.longitude}))
      dispatch(fetchWeatherByCoordinates({lat: position.coords.latitude, lon: position.coords.longitude}))

      dispatch({
        type: GET_GEOLOCATION_SUCCESS,
      })
    }, (error) => {
      dispatch({
        type: GET_GEOLOCATION_FAILURE,
        error,
      })
    })
  }
}
