import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'

export default class ItemBatchToggle extends Component {
  render() {
    const {
      hasBatches,
      batchVisible,
      onItemBatchSwitch,
    } = this.props

    return (
      <div className='item-batch-toggle'>
        { hasBatches && (
          <span>
            <span className='sr-only'>Seuraava uutinen on julkaistu useassa eri lähteessä.</span>
            <button
              type='button'
              className='item-batch-toggle__button'
              onClick={ onItemBatchSwitch }
              role='switch'
              aria-checked={ batchVisible }
            >
              <span className={ classnames('fa', {'fa-plus': !batchVisible, 'fa-minus': batchVisible }) } aria-hidden='true' />
              <span className='sr-only'>Listaa uutisen kaikki versiot</span>
            </button>
          </span>
        ) }
      </div>
    )
  }
}

ItemBatchToggle.propTypes = exact({
  hasBatches: PropTypes.bool.isRequired,
  batchVisible: PropTypes.bool.isRequired,
  onItemBatchSwitch: PropTypes.func.isRequired,
})
