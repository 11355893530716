import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import React, { Component } from 'react'
import classNames from 'classnames'
import './carousel.pcss'

export default class Carousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: props.activeIndex,
      showLeftButton: false,
      showRightButton: false,
    }
    this.refCarouselContainer = React.createRef()
  }

  componentDidMount() {
    this.toggleScrollButtons()
    window.addEventListener('resize', this.handleResize)
    this.ensureVisibleItem(this.state.activeIndex)
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeIndex !== prevProps.activeIndex) {
      this.setState({
        activeIndex: this.props.activeIndex,
      })
      this.ensureVisibleItem(this.props.activeIndex)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const { className, children } = this.props
    const { showLeftButton, showRightButton, activeIndex } = this.state

    return (
      <div className={ className }>
        {
          showLeftButton &&
            <button type='button' className='carousel-left' onClick={ this.handleLeftScrollClick } aria-hidden='true'>
              <span className='fa fa-angle-left' aria-hidden='true' />
            </button>
        }
        <ol className='carousel-items' onScroll={ this.handleScroll } ref={ this.refCarouselContainer }>
          { children.map((child, i) => {
            return React.cloneElement(child, Object.assign({}, child.props, {
              className: classNames(child.props.className, { active: i === activeIndex }),
              onClick: this.handleCarouselItemClick,
              'data-index': i,
            }))
          }) }
        </ol>
        {
          showRightButton &&
            <button type='button' className='carousel-right' onClick={ this.handleRightScrollClick } aria-hidden='true'>
              <span className='fa fa-angle-right' aria-hidden='true' />
            </button>
        }
      </div>
    )
  }

  /**
   * Update state to show and hide [<] [>] buttons as carousel is scrolled. There is a two pixel
   * "fuzz"-factor in these calculations because browsers don't always provide exact numbers and
   * this gives better user experience.
   */
  toggleScrollButtons = () => {
    const { scrollLeft, scrollWidth, clientWidth } = this.refCarouselContainer.current
    const scrollMax = scrollWidth - clientWidth
    this.setState({
      showLeftButton: scrollLeft > 2,
      showRightButton: scrollLeft < scrollMax - 2,
    })
  }

  handleScroll = this.toggleScrollButtons

  /**
   * Make sure active item does not get hidden by changing screen size
   */
  handleResize = () => {
    this.ensureVisibleItem(this.state.activeIndex)
    this.toggleScrollButtons()
  }

  /**
   * Make sure clicked carousel item is fully visible and call onChange callback.
   */
  handleCarouselItemClick = (e) => {
    const itemIndex = parseInt(e.currentTarget.dataset.index, 10)
    this.setState({
      activeIndex: itemIndex,
    })
    this.ensureVisibleItem(itemIndex)
    this.props.onChange(itemIndex)
  }

  /**
   * Ensure that carousel item at index is fully visible
   */
  ensureVisibleItem = (index) => {
    const item = this.refCarouselContainer.current.children[index]
    if (!item) return

    const leftOffset = item.offsetLeft
    const rightOffset = leftOffset + item.offsetWidth

    const { scrollLeft, clientWidth } = this.refCarouselContainer.current
    const scrollRight = scrollLeft + clientWidth

    if (scrollLeft > leftOffset || scrollRight < rightOffset) {
      item.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' })
    }
  }

  handleRightScrollClick = () => {
    const { scrollLeft, scrollWidth, clientWidth } = this.refCarouselContainer.current
    const scrollMax = scrollWidth - clientWidth
    const scrollPos = Math.min(scrollMax, Math.ceil(scrollLeft + clientWidth * 0.7))
    this.refCarouselContainer.current.scroll({ left: scrollPos, behavior: 'smooth' })
  }

  handleLeftScrollClick = () => {
    const { scrollLeft, clientWidth } = this.refCarouselContainer.current
    const scrollPos = Math.max(0, Math.floor(scrollLeft - clientWidth * 0.7))
    this.refCarouselContainer.current.scroll({ left: scrollPos, behavior: 'smooth' })
  }
}

Carousel.propTypes = exact({
  activeIndex: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
})

Carousel.defaultProps = {
  activeIndex: -1,
  className: 'carousel',
  onChange: (itemIndex) => {},
}
