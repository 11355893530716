import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import React, { Component } from 'react'
import Toggle from 'react-toggle'
import { Cookies } from 'react-cookie'
import '../../styles/modal.pcss'
import { NEW_USER_MODAL } from './Modals'
import GenericModal from './GenericModal'
import { displayMigrateUserModal } from './modalActions'
import { almaRegister, logoutAlmaTunnus } from '../../user/almalogin/almaUserActions'
import { isTempProfileModified } from '../../user/user'
import { getAccessToken } from '../../user/almalogin/AlmaLogin'


export default class NewUserModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tempProfileToggle: true,
      isTempProfileModified: false,
    }
  }

  render() {
    const { onClose, message, title } = this.props

    return (
      <GenericModal
        modalId={ NEW_USER_MODAL }
        onClose={ onClose }
        title={ title }
        okButtonTxt='Luo uusi Ampparit-profiili'
        onOkClick={ this.handleOkClick }
        onCancelClick={ this.handleCancelClick }
        cancelButtonTxt='Minulla on jo Ampparit-profiili ja haluan yhdistää sen'
        requireUserInput={ false }
        onRequestClose={ this.handleCloseClick }
      >
        { message }

        { this.state.isTempProfileModified &&
        <div className='temp-profile'>
          <span className='temp-profile-info'>
            Olet tehnyt muokkauksia väliaikaisen profiilin asetuksiin.
            Haluatko tuoda nämä asetukset uuteen käyttäjäprofiiliisi?
          </span>
          <label className='temp-profile-toggle-label'>
            <span>Tuo muokatut asetukset</span>
            <Toggle
              checked={ this.state.tempProfileToggle }
              onChange={ this.handleToggleChange }
            />
          </label>

          <hr />
        </div>
        }
      </GenericModal>
    )
  }

  componentDidMount() {
    const cookies = new Cookies()
    this.setState({
      isTempProfileModified: isTempProfileModified(cookies),
    })
  }


  handleOkClick = async () => {
    const accessToken = await getAccessToken()
    const importTempProfile = this.state.tempProfileToggle && this.state.isTempProfileModified
    this.props.dispatch(almaRegister(accessToken, importTempProfile))
  }

  handleCancelClick = () => {
    this.props.dispatch(displayMigrateUserModal('Anna Ampparit-profiilin tiedot'))
  }

  handleCloseClick = () => {
    this.props.dispatch(logoutAlmaTunnus())
  }

  handleToggleChange = e => {
    this.setState({
      tempProfileToggle: !this.state.tempProfileToggle,
    })
  }

}

NewUserModal.propTypes = exact({
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
})
