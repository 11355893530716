import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Link } from 'react-router'
import ItemsEmpty from './ItemsEmpty'

export default class TagItemsEmpty extends Component {
  render() {
    const { tagName, tagSlug } = this.props

    return (
      <ItemsEmpty title='No mutta,'>
        <p>
          aihetunnisteella <Link className='text-link' to={ '/t/' + tagSlug }>{ '#' + tagName }</Link> ei ole
          tällä hetkellä juttuja saatavilla.<br />Kokeilethan muita aihetunnisteita!
        </p>
      </ItemsEmpty>
    )
  }
}

TagItemsEmpty.propTypes = exact({
  tagName: PropTypes.string.isRequired,
  tagSlug: PropTypes.string.isRequired,
})
