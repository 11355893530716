import * as actions from './breakingNewsActions'
import * as userActions from '../../user/userActions'
import Immutable from 'immutable'

const initialState = Immutable.Map({
  itemIds: Immutable.OrderedSet(),
  loading: false,
  timestamp: null,
  failedToLoad: false,
  shouldUpdate: false,
})

export default function breakingNews(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BREAKING_NEWS:
      return state
        .set('loading', true)
        .set('failedToLoad', false)
        .set('shouldUpdate', false)

    case actions.FETCH_BREAKING_NEWS_FAILURE:
      return state
        .set('loading', false)
        .set('timestamp', action.timestamp)
        .set('failedToLoad', true)
        .set('shouldUpdate', false)

    case actions.FETCH_BREAKING_NEWS_SUCCESS:
      return state
        .set('loading', false)
        .set('itemIds', Immutable.OrderedSet(action.response.data.map(item => item.id)))
        .set('timestamp', action.timestamp)
        .set('shouldUpdate', false)

    case userActions.LOGOUT_USER_SUCCESS:
    case userActions.SET_TEMPORARY_PROFILE:
    case userActions.SET_ACTIVE_USER_PROFILE:
      return state.set('shouldUpdate', true)

    default:
      return state
  }
}
