import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import React, { Component } from 'react'
import '../../styles/modal.pcss'
import { REDIRECT_MODAL } from './Modals'
import GenericModal from './GenericModal'
import Countdown from '../../components/util/Countdown'

export const COOKIE_REDIRECT_MODAL_DISPLAYED = 'redirectModalDisplayed'

export default class RedirectModal extends Component {
  render() {
    const { onClose } = this.props
    return (
      <GenericModal
        modalId={ REDIRECT_MODAL }
        onClose={ onClose }
        okButtonTxt='Jatka uutiseen'
        onOkClick={ this.handleOkClick }
        cancelButtonTxt='Jää amppareihin'
      >
        <Countdown time={ 10000 } timeStep={ 1000 } onComplete={ this.handleCountdownComplete }>
          <span>s</span>
        </Countdown>
        <p>
          Sinut ohjataan hetken kuluttua automaattisesti valitsemaasi uutiseen.
        </p>
        <br />
        <p>
          Halutessasi voit jäädä uudistuneeseen Ampparit.comiin tutustumaan reaaliaikaiseen uutistarjontaan!
        </p>
      </GenericModal>
    )
  }

  handleCountdownComplete = () => {
    this.redirect()
  }

  handleOkClick = () => {
    this.redirect()
  }

  redirect() {
    window.location.href = this.props.redirectUrl
  }
}

RedirectModal.propTypes = exact({
  redirectUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
})
