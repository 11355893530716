import React, {Component} from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import UserEmailField from './UserEmailField'
import Button from '../components/ui/Button'
import * as keyCodes from '../lib/keyCodes'
import {validateEmail} from './validate'
import classNames from 'classnames'

export default class UserEmailForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      emailValid: false,
      triedSubmitting: false,
    }
  }

  render() {
    const { triedSubmitting, emailValid, email } = this.state

    const {
      loading,
      failure,
      emailNotFound,
      submitText,
      className,
      errorMessageOverrides,
    } = this.props

    return (
      <form className={ classNames('form', className) } method='post' noValidate onSubmit={ this.handleSubmit }>

        <div className='form-group'>
          <UserEmailField
            onChange={ this.handleEmailChange }
            onInput={ this.handleEmailChange }
            onKeyUp={ this.handleKeyUp }
            hasError={  (triedSubmitting && !emailValid) || failure || emailNotFound }
            hasSuccess={ email.length > 0 && emailValid && !failure }
            value={ email }
            errors={ this.getErrors() }
            errorMessageOverrides={ errorMessageOverrides }
          />
        </div>

        { this.props.children }

        <div className='form-group'>
          <Button
            type='submit'
            text={ submitText }
            disabled={ !emailValid }
            loading={ loading }
          />
        </div>

      </form>
    )
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.emailValid) {
      this.setState({
        triedSubmitting: false,
      })

      this.props.onSubmit(this.state.email)
    }
  }

  handleKeyUp = e => {
    if (e.keyCode === keyCodes.enter) {
      this.setState({
        triedSubmitting: true,
      })
    }
  }

  handleEmailChange = e => {
    e.persist()

    this.setState({
      email: e.target.value,
      emailValid: validateEmail(e.target.value),
      triedSubmitting: false,
    })
  }

  getErrors() {
    const { emailNotFound, failure } = this.props
    const errors = []

    if (this.state.triedSubmitting && !this.state.emailValid) {
      errors.push('INVALID_EMAIL')
    }

    if (emailNotFound) {
      errors.push('EMAIL_NOT_FOUND')
    }

    if (failure && !emailNotFound) {
      errors.push('SUBMIT_ERROR')
    }

    return errors
  }
}

UserEmailForm.propTypes = exact({
  loading: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  emailNotFound: PropTypes.bool.isRequired,
  submitText: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorMessageOverrides: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
})
