import { sendAdminEmail } from '../../api/api'
import { logException } from '../../sentry'

export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS'
export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE'
export const RESET_FEEDBACK = 'RESET_FEEDBACK'

export function sendFeedback(replyToEmail, postData) {
  return (dispatch) => {
    dispatch({
      type: SEND_FEEDBACK,
    })

    return sendAdminEmail('Ampparit palaute', replyToEmail, postData).then(response => {
      dispatch({
        type: SEND_FEEDBACK_SUCCESS,
        response,
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: SEND_FEEDBACK_FAILURE,
        error,
      })
    })
  }
}

export function resetFeedback() {
  return (dispatch) => {
    dispatch({
      type: RESET_FEEDBACK,
    })
  }
}
