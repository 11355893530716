const getItems = state => state.items.items

export const getItemsByIds = (state, itemIds) => itemIds.map(itemId => getItems(state).get(itemId))

/**
 * @param state
 * @param {string} itemId
 * @returns {?ItemRecord}
 */
export function selectItemById(state, itemId) {
  return state.items.items.get(itemId)
}
