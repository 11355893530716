import React from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'

const Star = ({ className }) => (
  <svg
    className={ className }
    xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'
    fill='none'
    aria-hidden='true'
  >
    <path d='M14 5L16.781 10.9243L23 11.8801L18.5 16.489L19.562 23L14 19.9243L8.438 23L9.5 16.489L5 11.8801L11.219 10.9243L14 5Z' stroke='none' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

Star.propTypes = exact({
  className: PropTypes.string,
})

export default Star
