import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Helmet } from 'react-helmet'
import Item from '../../components/item/Item'
import Loading from '../../components/util/Loading'
import SidebarMenu from '../../components/layout/SidebarMenu'
import GeneralSidebar from '../../components/layout/GeneralSidebar'
import classnames from 'classnames'
import ItemsListHeader from '../../components/lists/ItemsListHeader'
import MainContent from '../../components/wrappers/MainContent'
import {
  ListAd,
  MobileLeaderboardAd,
  MobileListAd,
  LIST_AD_1,
  MOBILE_LIST_2,
  MOBILE_LIST_3,
  MOBILE_LIST_4,
  MOBILE_LIST_5,
} from '../../ad/Ad'

const GroupedItems = ({
  dispatch,
  groups,
  loading,
  timestamp,
  title,
  meta,
  loggedIn,
}) => {

  const renderAd = (i, index) => {
    if (i === 0 && index === 9)  return <ListAd adUnitId={ LIST_AD_1 } />            // Desktop ad
    if (i === 0 && index === 4)  return <MobileLeaderboardAd />                      // Mobile ad
    if (i === 0 && index === 14) return <MobileListAd adUnitId={ LIST_AD_1 } />      // Mobile ad
    if (i === 1 && index === 4)  return <MobileListAd adUnitId={ MOBILE_LIST_2 } />  // Mobile ad
    if (i === 1 && index === 14) return <MobileListAd adUnitId={ MOBILE_LIST_3 } />  // Mobile ad
    if (i === 2 && index === 4)  return <MobileListAd adUnitId={ MOBILE_LIST_4 } />  // Mobile ad
    if (i === 2 && index === 14) return <MobileListAd adUnitId={ MOBILE_LIST_5 } />  // Mobile ad
    return null
  }

  const sectionByGroup = (group, i) => {
    const itemsInGroup = [...group.items].map((item, index) => {
      const itemComponent = (
        <Item
          key={ item.get('id') }
          item={ item }
          timestamp={ timestamp }
          dispatch={ dispatch }
          loggedIn={ loggedIn }
        />
      )

      return (
        <Fragment key={ `grouped-item-${i}-${index}` }>
          { itemComponent }
          { renderAd(i, index) }
        </Fragment>
      )
    })

    return (
      <div key={ i } className='items-list grouped-items-section'>
        <h3>{ group.title }</h3>
        { itemsInGroup }
      </div>
    )
  }

  return (
    <div className='container item-list'>
      <Helmet
        title={ title }
        meta={ meta }
      />
      <div className='main-container'>
        <SidebarMenu />

        <MainContent className={ classnames({'content_no-bg': !loading}) }>
          <div id='sticky-ad-bottom-boundary'>
            <ItemsListHeader
              heading={ title }
              dispatch={ dispatch }
              showLanguageOptions={ false }
            />
            <Loading loading={ loading } >
              { !loading && groups.map((group, i) => sectionByGroup(group, i)) }
            </Loading>
          </div>
        </MainContent>

        <div className='sidebar-container'>
          <GeneralSidebar />
        </div>
      </div>

    </div>
  )
}

GroupedItems.propTypes = { // eslint-disable-line react/prefer-exact-props
  dispatch: PropTypes.func.isRequired,
  groups: ImmutablePropTypes.list.isRequired,
  loading: PropTypes.bool.isRequired,
  timestamp: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  meta: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string.isRequired,
    name: PropTypes.string,
    property: PropTypes.string,
  })).isRequired,
  loggedIn: PropTypes.bool.isRequired,
}

export default GroupedItems
