import React, { Component } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { provideHooks } from 'redial'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import Loading from '../../components/util/Loading'
import './weather.pcss'
import { fetchWeather, setSelectedWeather } from './weatherActions'
import { MobileListAd, BOX_AD_CONTENT_1 } from '../../ad/Ad'
import { selectIsUserLoggedIn } from '../../selectors/userSelector'
import { clearLocationSearch, searchLocation } from '../../components/location/locationActions'
import LocationSearchDialog from '../../components/location/LocationSearchDialog'
import CurrentWeather from './CurrentWeather'
import GeneralSidebar from '../../components/layout/GeneralSidebar'
import UnableToLoad from '../../components/messages/UnableToLoad'
import debounce from 'lodash/debounce'
import { Link, withRouter } from 'react-router'
import {
  addUserWeatherLocation,
  setUserDefaultWeatherLocation,
  setUserWeatherLocations,
} from '../../user/userActions'
import { setStatus } from '../../status/statusActions'
import MainContent from '../../components/wrappers/MainContent'
import { createUrl } from '../../lib/utils'
import shareImg from '../../assets/ampparit-share-saa.jpg'
import SidebarMenu from '../../components/layout/SidebarMenu'
import Star from './icons/star'
import { genericNotification } from '../../components/notification/notificationActions'
import Home from './icons/home'
import WeeklyWeather from './WeeklyWeather'

@connect(( state, ownProps ) => {
  const selectedLocation = parseInt(state.weather.get('selected'))
  const data = state.weather
  const selectedLocationData = data.get(selectedLocation)
  const selectedLocationName = selectedLocationData ? selectedLocationData.get('data').first().get('locationName') : ''
  return {
    selectedLocationName: selectedLocationName,
    selectedLocationId: selectedLocation,
    data: data,
    loading: state.weather.get('loading'),
    failedToLoad: state.weather.get('failedToLoad'),
    location: state.location,
    userLocations: state.user.get('locations'),
    userDefaultLocation: parseInt(state.user.get('defaultLocation')),
    locationLoading: state.location.get('loading'),
    cookies: ownProps.cookies,
    loggedIn: selectIsUserLoggedIn(state),
  }
})

@provideHooks({
  fetch: ({dispatch, params, getState}) => {

    const { user } = getState()

    let location = params.location

    // only allow slugs (no id's)
    if (location && !isNaN(location)) {
      dispatch(setStatus(404))
      return
    }

    // When location is not available via. url, then use the default location ID
    let useDefaultLocationId = false
    if (!location) {
      useDefaultLocationId = true
      location = user.get('defaultLocation')
    }

    return Promise.all([
      dispatch(fetchWeather(location)).then(() => {
        const list = getState().location.get('list')

        let selectedLocationId
        if (!useDefaultLocationId) {
          const urlLocation = list.find((item) => item.get('slug') === location)
          if (urlLocation) {
            selectedLocationId = urlLocation.get('id')
          }
        } else {
          selectedLocationId = location
        }

        if (!selectedLocationId) {
          return dispatch(setStatus(404))
        }

        return dispatch(setSelectedWeather(selectedLocationId))
      }),
    ])
  },
})

class WeatherView extends Component {
  constructor() {
    super()

    this.state = {
      displayFavoriteLocations: false,
    }

    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
    this.handleLocationSearchDialogChange = debounce(this.handleLocationSearchDialogChange, 100)
  }

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    selectedLocationName: PropTypes.string.isRequired,
    selectedLocationId: PropTypes.number.isRequired,
    data: ImmutablePropTypes.map.isRequired,
    loading: PropTypes.bool.isRequired,
    failedToLoad: PropTypes.bool.isRequired,
    location: ImmutablePropTypes.map.isRequired,
    userLocations: ImmutablePropTypes.orderedSet.isRequired,
    userDefaultLocation: PropTypes.number.isRequired,
    locationLoading: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    loggedIn: PropTypes.bool.isRequired,
  }

  isWeatherFrontPage() {
    return this.props.path.split('/').filter(part => part.length > 0).length === 1
  }

  getTitle() {
    return this.isWeatherFrontPage() ? 'Sää' : 'Sää ' + this.props.selectedLocationName
  }


  getMetaTitle() {
    return this.isWeatherFrontPage() ? 'Sää' : 'Sää ' + this.props.selectedLocationName
  }

  getDescription () {
    return this.isWeatherFrontPage()
      ? 'Ampparit.comin sääpalvelusta löydät paikkakuntakohtaiset säätiedot ja ennusteet. Tarkista helposti, miltä 10 vrk sää näyttää paikkakunnallasi.'
      : 'Ampparit.comin sääpalvelusta löydät paikkakuntakohtaiset säätiedot ja ennusteet. Tarkista helposti, miltä paikkakunnan ' + this.props.selectedLocationName + ' 10 vrk sää näyttää.'
  }

  render() {
    const {
      selectedLocationName,
      selectedLocationId,
      data,
      failedToLoad,
      loading,
      location,
      locationLoading,
      dispatch,
      userLocations,
      userDefaultLocation,
      loggedIn,
    } = this.props

    const {
      displayFavoriteLocations,
    } = this.state

    const locationData = Immutable.getIn(data, [selectedLocationId, 'data'], Immutable.List())
    const forecasts = locationData.isEmpty() ? Immutable.List() : locationData.getIn([0, 'forecasts']).concat( locationData.getIn([1, 'forecasts']) )
    const isFavorite = userLocations.some(loc => loc.get('id') === selectedLocationId)
    const isDefault = selectedLocationId === userDefaultLocation

    const title = this.getTitle()
    const metaTitle = this.getMetaTitle()
    const description = this.getDescription()

    return (
      <div className='container' id='sticky-ad-bottom-boundary'>
        <div className='main-container'>
          <SidebarMenu />
          <MainContent>
            <Helmet
              title={ metaTitle }
              meta={ [
                {name: 'description', content: description},
                {property: 'og:title', content: 'Sää \u2013 Ampparit.com'},
                {property: 'og:image', content: createUrl(shareImg)},
                {property: 'og:description', content: 'Ampparit.comin sääpalvelusta löydät paikkakuntakohtaiset säätiedot ja ennusteet. Tarkista helposti, miltä 10 vrk sää näyttää paikkakunnallasi! Palvelusta näet myös tuntikohtaisen ennusteen, sademäärät ja ilmankosteuden tiedot.'},
              ] }
            />

            <MobileListAd adUnitId={ BOX_AD_CONTENT_1 } />

            <div className='weather-title'>
              <h1>{ title }</h1>

              <div className='weather-title__actions'>
                <button type='button' className='weather-title__actions-button' onClick={ this.handleDisplayFavoriteLocations } aria-label='Avaa suosikki sijainnit'>
                  <Star className={ classNames('weather-title__actions-icon', {'display-favorites': displayFavoriteLocations}) } />
                  <span className='weather-title__actions-title'>Suosikit</span>
                </button>
              </div>
            </div>

            <LocationSearchDialog
              dispatch={ dispatch }
              results={ location.get('searchResults') }
              onChange={ this.handleLocationSearchDialogChange }
              onResult={ this.handleLocationSearchDialogResult }
              onFavoriteClick={ this.handleFavoriteClick }
              loading={ locationLoading }
              userLocations={ userLocations }
            />

            { displayFavoriteLocations &&
            <div className='weather-favorite-locations'>
              <div className='weather-favorite-locations__current-location'>
                <span className='weather-favorite-locations__current-location-title'>Nykyinen sijainti</span>
                <span className='weather-favorite-locations__current-location-name-container'>
                  <span className='weather-favorite-locations__current-location-name'>{ selectedLocationName }</span>

                  <button
                    type='button'
                    className='weather-favorite-locations__current-location__button'
                    onClick={ this.handleSetDefaultClick } data-location-id={ selectedLocationId }
                    aria-label='Aseta oletus sijainti'
                  >
                    <Home className={ classNames('weather-favorite-locations__current-location__button-home', {'default-current-location': isDefault} ) } />
                  </button>

                  <button
                    type='button'
                    className='weather-favorite-locations__current-location-button'
                    onClick={ this.handleFavoriteClick } data-location-id={ selectedLocationId }
                    aria-label='Lisää suosikkeihin'
                  >
                    <Star
                      className={ classNames('weather-favorite-locations__current-location__button-star', {'current-favorited-location': isFavorite} ) }
                    />
                  </button>
                </span>
              </div>

              <div className='weather-favorite-locations__list-container'>
                <span className='weather-favorite-locations__list-title'>Suosikit</span>
                <ul className='weather-favorite-locations__list'>
                  { this.renderLocationList() }
                </ul>
              </div>
            </div>
            }

            <div className='weather-container'>
              <Loading loading={ loading }>
                <div className={ classNames('weather-main-container', { 'weather-loading': loading } ) }>

                  { forecasts && !failedToLoad &&
                  <CurrentWeather
                    forecasts={ forecasts }
                    location={ selectedLocationName }
                    locationId={ selectedLocationId }
                    onFavoriteClick={ this.handleFavoriteClick }
                    isFavorite={ isFavorite }
                    loggedIn={ loggedIn }
                  />
                  }
                  { failedToLoad && <UnableToLoad errorText='Säätietoja ei saatu ladattua' /> }
                </div>
              </Loading>
            </div>

            { forecasts && !failedToLoad &&
            <WeeklyWeather
              loading={ loading }
              locationData={ locationData }
            />
            }

            <p className='weather-data-from'>Säädata © Foreca</p>
          </MainContent>

          <div className='sidebar-container'>
            <GeneralSidebar />
          </div>
        </div>
      </div>
    )
  }

  renderLocationList() {
    const { userLocations, userDefaultLocation } = this.props

    return (
      userLocations.map(location => {
        const isDefault = location.get('id') === parseInt(userDefaultLocation)

        return (
          <li
            key={ location.get('slug') }
            className='weather-favorite-locations__list-item'
            data-location={ location.get('id') }
          >
            <Link to={ '/saa/' + location.get('slug') } className='weather-favorite-locations__list-item-link'>
              { location.get('name') }
            </Link>
            <button
              onClick={ this.handleSetDefaultClick }
              data-location-id={ location.get('id') }
              className='weather-favorite-locations__list-item__button'
              aria-label='Aseta oletus sijainti'
            >
              <Home className={ classNames('weather-favorite-locations__list-item__button-home', {'default-favorited-location': isDefault}  ) } />
            </button>
            <button
              onClick={ this.handleFavoriteClick }
              data-location-id={ location.get('id') }
              className='weather-favorite-locations__list-item__button'
              aria-label='Lisää suosikkeihin'
            >
              <Star className={ classNames('weather-favorite-locations__list-item__button-star', {'default-favorited-location': isDefault}) } />
            </button>
          </li>
        )
      })
    )
  }

  handleDisplayFavoriteLocations = () => {
    const { loggedIn, dispatch } = this.props

    if (loggedIn) {
      this.setState({displayFavoriteLocations: !this.state.displayFavoriteLocations})
    } else {
      dispatch(genericNotification(
        'Kirjaudu sisään nähdäksesi suosikkisi',
        'info',
        'DisplayFavoriteLocations'
      ))
    }
  }

  handleLocationSearchDialogChange = (searchString) => {
    this.props.dispatch(searchLocation(searchString))
  }

  handleLocationSearchDialogResult = (result) => {
    if (result) {
      const { dispatch } = this.props
      const locationSlug = result.get('slug')
      dispatch(push(`/saa/${locationSlug}`))
      dispatch(clearLocationSearch())
    }
  }

  handleFavoriteClick(e, preventRemoval = false) {
    const {
      dispatch,
      userLocations,
      userDefaultLocation,
      loggedIn,
    } = this.props

    const clickedLocationId = parseInt(e.currentTarget.dataset.locationId, 10)
    const clickedLocationSlug = e.currentTarget.dataset.locationSlug
    const isLocationInFavorites = userLocations.some(loc => loc.get('id') === clickedLocationId)

    if (!loggedIn) {
      dispatch(genericNotification(
        'Kirjaudu sisään lisätäksesi suosikkeihin',
        'info',
        'FavoriteClick'
      ))
    } else if (!isLocationInFavorites) {
      dispatch(addUserWeatherLocation(clickedLocationId))
      if (clickedLocationSlug) {
        dispatch(push(`/saa/${clickedLocationSlug}`))
        dispatch(clearLocationSearch())
      }
    } else if (!preventRemoval && clickedLocationId !== userDefaultLocation) {
      const newLocationIds = userLocations
        .map(loc => loc.get('id'))
        .filter(locId => locId !== clickedLocationId)
        .toArray()
      dispatch(setUserWeatherLocations(newLocationIds))
    }
  }

  handleSetDefaultClick = (e) => {
    const locationId = parseInt(e.currentTarget.dataset.locationId, 10)
    this.props.dispatch(setUserDefaultWeatherLocation(locationId))
  }
}

export default withRouter(WeatherView)
