import React, { Component } from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ModalButton from './ModalButton'
import '../../styles/modal.pcss'
import debounce from 'lodash/debounce'

export default class GenericModal extends Component {

  constructor() {
    super()

    this.state = {
      isOpen: true,
      alignToTop: false,
    }

    this.handleResize = debounce(this.handleResize.bind(this), 100)

    this.modal = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const {
      modalId,
      title,
      children,
      okButtonTxt,
      cancelButtonTxt,
      onAfterOpen,
      buttons,
      buttonDisabled,
      requireUserInput,
      footer,
    } = this.props

    const {
      isOpen,
      alignToTop,
    } = this.state

    const okButton = okButtonTxt ?
      CreateButton(okButtonTxt, this.handleOkClick, ['positive'], buttonDisabled) : null
    const cancelButton = cancelButtonTxt ?
      CreateButton(cancelButtonTxt, this.handleCancelClick, ['negative'], buttonDisabled ) : null

    const allButtons = buttons ? buttons : []

    if (okButton) allButtons.push(okButton)
    if (cancelButton) allButtons.push(cancelButton)

    return (
      <Modal
        isOpen={ isOpen }
        onAfterOpen={ onAfterOpen }
        onRequestClose={ this.handleCloseRequest }
        contentLabel='Modal'
        ref={ this.modal }
        className={ {
          base: classNames('reactModal', modalId),
          afterOpen: 'afterOpen',
          beforeClose: 'beforeClose',
        } }
        overlayClassName={ {
          base: classNames(
            'reactModalOverlay', modalId, { 'alignToTop': alignToTop }
          ),
          afterOpen: 'afterOpen',
          beforeClose: 'beforeClose',
        } }
      >
        { !requireUserInput && this.closeButtonElement() }
        { title && <h1 className='modal-title'>{ title }</h1> }
        { children && <div className='modal-content'>{ children }</div> }
        { allButtons.length > 0 &&
          <ul className='buttons'>
            { allButtons.map(function (button, key) {
              return <li key={ key }>{ button }</li>
            }) }
          </ul>
        }
        { footer && <div className='modal-footer'>{ footer }</div> }
      </Modal>
    )
  }

  closeButtonElement() {
    return (
      <button type='button' onClick={ this.handleCloseRequest } className='close-button'>
        <span className='fa fa-close' />
        <span className='sr-only'>Sulje ponnahdusikkuna</span>
      </button>
    )
  }

  handleCloseRequest = () => {
    const { requireUserInput, onRequestClose } = this.props

    if (!requireUserInput) {
      this.close()
      if (onRequestClose) {
        onRequestClose()
      }
    }
  }

  handleOkClick = () => {
    const { okClickCloses, onOkClick } = this.props

    if (okClickCloses === true) {
      this.close()
    }

    if (onOkClick) {
      onOkClick()
    }
  }

  handleCancelClick = () => {
    const { cancelClickCloses, onCancelClick } = this.props

    if (cancelClickCloses === true) {
      this.close()
    }

    if (onCancelClick) {
      onCancelClick()
    }
  }

  handleResize = () => {
    const windowHeight = window.innerHeight
    const margin = 100
    const modalHeight = this.modal.current.portal.content.clientHeight + margin
    this.setState({
      alignToTop: windowHeight < modalHeight,
    })
  }

  close() {
    this.setState({ isOpen: false })

    const {
      onClose,
      modalId,
    } = this.props

    onClose(modalId)
  }

  open() {
    this.setState({ isOpen: true })
  }
}

export function CreateButton(text, callback, classes, buttonDisabled, ...args) {
  return (
    <ModalButton
      text={ text }
      callback={ callback }
      classes={ classes }
      disabled={ buttonDisabled }
      { ...args }
    />
  )
}

GenericModal.defaultProps = {
  okClickCloses: true,
  cancelClickCloses: true,
  requireUserInput: true,
}

GenericModal.propTypes = exact({
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  okButtonTxt: PropTypes.string,
  cancelButtonTxt: PropTypes.string,
  onAfterOpen: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  buttonDisabled: PropTypes.bool,
  requireUserInput: PropTypes.bool,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  onOkClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  okClickCloses: PropTypes.bool,
  cancelClickCloses: PropTypes.bool,
  onRequestClose: PropTypes.func,
})
