import { Cookies } from 'react-cookie'
import { loginWithAlmaTunnus, almaTunnusMigrate, almaTunnusRegister } from '../../api/api'
import {
  displayMigrateUserModal,
  displayNewUserModal,
  displayNotificationModal,
  displayAlmaTunnusVerificationModal,
} from '../../components/modal/modalActions'
import { genericNotification } from '../../components/notification/notificationActions'
import {
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  setUserProfile,
  setLoggedInUser,
  afterLoginRefresh,
  TEMP_PROFILE_COOKIE_NAME,
} from '../userActions'
import { logException } from '../../sentry'
import config from '../../../config/config'
import {
  selectUserAlmaIdHash,
  selectUserEmail,
  selectUserHash,
  selectUserId,
  selectUserName,
} from '../../selectors/userSelector'

export const REGISTER_ALMA_ACCOUNT = 'REGISTER_ALMA_ACCOUNT'

export const LOGIN_ALMA_USER = 'LOGIN_ALMA_USER'
export const LOGIN_ALMA_USER_SUCCESS = 'LOGIN_ALMA_USER_SUCCESS'
export const LOGIN_ALMA_USER_FAILURE = 'LOGIN_ALMA_USER_FAILURE'

export const REGISTER_ALMA_USER = 'REGISTER_ALMA_USER'
export const REGISTER_ALMA_USER_SUCCESS = 'REGISTER_ALMA_USER_SUCCESS'
export const REGISTER_ALMA_USER_FAILURE = 'REGISTER_ALMA_USER_FAILURE'

export const MIGRATE_ALMA_USER = 'MIGRATE_ALMA_USER'
export const MIGRATE_ALMA_USER_SUCCESS = 'MIGRATE_ALMA_USER_SUCCESS'
export const MIGRATE_ALMA_USER_FAILURE = 'MIGRATE_ALMA_USER_FAILURE'

export const LOGOUT_ALMA_USER = 'LOGOUT_ALMA_USER'
export const LOGOUT_ALMA_USER_SUCCESS = 'LOGOUT_ALMA_USER_SUCCESS'
export const LOGOUT_ALMA_USER_FAILURE = 'LOGOUT_ALMA_USER_FAILURE'


/**
 * Kick off the process of manually logging a user through AlmaTunnus.
 * This will redirect user to login page and upon successfull login
 * redirect back to site and login to ampparit profile.
 */
export function loginAlmaTunnus() {
  return dispatch => {
    dispatch({
      type: LOGIN_ALMA_USER,
    })
  }
}


/**
 * Kick off the process of logging user out of AlmaTunnus.
 * @note Logging out of AlmaTunnus alone is not enough. If ampparit user session is not
 * cleared via call to /api/logout/, the user remains fully logged in to ampparit.com.
 * Due to this, AlmaTunnus logout will even not trigger for as long as ampparit user is
 * logged in. So you must also call `dispatch(logoutAmpparit())` if the user is logged in.
 */
export function logoutAlmaTunnus() {
  return dispatch => {
    dispatch({
      type: LOGOUT_ALMA_USER,
    })
  }
}

export function registerAlmaTunnus() {
  return dispatch => {
    dispatch({
      type: REGISTER_ALMA_ACCOUNT,
    })
  }
}


/**
 * Login to ampparit profile with the information received from a successful
 * AlmaTunnus login.
 */
export function setAlmaTunnusUser(accessToken, email, emailVerified, showLoginNotification = false) {
  return dispatch => {
    dispatch({
      type: LOGIN_ALMA_USER_SUCCESS,
      emailVerified,
    })

    if (!emailVerified) {
      dispatch(displayAlmaTunnusVerificationModal())
      return
    }

    return loginWithAlmaTunnus(accessToken)
      .then(response => {
        dispatch(loginUser(response, showLoginNotification))
      })
      .catch(error => {
        const status = error?.response?.status
        if (status === 404) {
          dispatch(displayNewUserModal(`Ei löytynyt Alma-tunnuksen ${email} sähköpostiosoitetta vastaavaa Ampparit-profiilia, tehdäänkö uusi?`, 'Uusi Ampparit käyttäjä?'))
        } else if (status === 403) { // Bad accessToken (expired, malformed, bad signature, etc)
          dispatch(displayNotificationModal(
            'Yritä ladata sivu uudestaan. Ota yhteyttä ylläpitoon ampparit@ampparit.com mikäli virhe toistuu',
            'Kirjautumisessa tuli ongelma (virhe #403)'
          ))
        } else {
          logException(error)
          dispatch(displayNotificationModal(
            `Tapahtui virhe '${error.message}'. Yritä ladata sivu uudestaan. Ota yhteyttä ylläpitoon ampparit@ampparit.com mikäli virhe toistuu`,
            'Kirjautumisessa tuli ongelma'
          ))
        }
        dispatch({
          type: LOGIN_ALMA_USER_FAILURE,
          error,
        })
      })
  }
}


/**
 * Create new Ampparit user and profile from AlmaTunnus accessToken
 */
export function almaRegister(accessToken, importTempProfile) {
  return dispatch => {
    dispatch({
      type: REGISTER_ALMA_USER,
    })
    const cookies = new Cookies()
    const tempProfile = importTempProfile ? cookies.get(TEMP_PROFILE_COOKIE_NAME) : null

    return almaTunnusRegister(accessToken, tempProfile)
      .then((response) => {
        dispatch({
          type: REGISTER_ALMA_USER_SUCCESS,
          response,
        })
        dispatch(genericNotification(
          'Uuden tunnuksen rekisteröinti onnistui',
          'info',
          LOGIN_USER_SUCCESS
        ))
        dispatch(loginUser(response, true))
        return response
      }).catch((error) => {
        logException(error)
        dispatch({
          type: REGISTER_ALMA_USER_FAILURE,
          error,
        })
        dispatch(displayNotificationModal(
          `Tapahtui virhe '${error.message}'. Yritä ladata sivu uudestaan. Ota yhteyttä ylläpitoon: ampparit@ampparit.com mikäli virhe toistuu`,
          'Tunnuksen rekisteröinnissä tuli ongelma'
        ))
      })
  }
}


/**
 * Migrate old Ampparit user and profile AlmaTunnus login
 */
export function userMigrate(accessToken, emailOrUsername, password ) {
  return dispatch => {
    dispatch({
      type: MIGRATE_ALMA_USER,
    })

    return almaTunnusMigrate(accessToken, emailOrUsername, password)
      .then((response) => {
        dispatch({
          type: MIGRATE_ALMA_USER_SUCCESS,
        })
        dispatch(genericNotification(
          'Vanhan tunnuksen yhdistäminen onnistui',
          'info',
          LOGIN_USER_SUCCESS
        ))
        dispatch(loginUser(response, true))
      }).catch((error) => {
        dispatch({
          type: MIGRATE_ALMA_USER_FAILURE,
          error,
        })
        const profileNotExist = error?.response?.status === 401

        if (profileNotExist) {
          dispatch(genericNotification(
            'Vanhaa profiilia ei löytynyt annetuilla tiedoilla',
            'error',
            LOGIN_USER_FAILURE
          ))
          dispatch(displayMigrateUserModal('Anna Ampparit profiilin sähköpostiosoite ja salasana'))
        } else {
          logException(error)
          dispatch(displayNotificationModal(
            'Ota yhteyttä ylläpitoon: ampparit@ampparit.com',
            'Tunnuksen yhdistämisessä tuli ongelma.'
          ))
        }
      })
  }
}


/**
 * Update ampparit user and profile state with data from
 * login (or migrate or register) response.
 */
function loginUser(response, showLoginNotification) {
  return (dispatch, getState) => {
    const { jwt, id, email, name, userHash, almaIdHash, profile } = response.data

    // No jwt returned means login failed
    if (!jwt) {
      dispatch({
        type: LOGIN_USER_FAILURE,
        error: response,
      })
      return
    }

    // Don's show login notification for example after AlmaTunnus automatically logs in after SSR
    if (showLoginNotification) {
      dispatch({
        type: LOGIN_USER_SUCCESS,
      })
    }

    // Sometimes we are logging in a user that is already logged in. This happens
    // on initial page view, if SSR was already made with the user's profile data.
    // We want to avoid unnecessary state updates that would cause network fetches
    // and user interface rerenders.
    const state = getState()
    const oldId = selectUserId(state)
    const oldName = selectUserName(state)
    const oldEmail = selectUserEmail(state)
    const oldHash = selectUserHash(state)
    const oldAlmaIdHash = selectUserAlmaIdHash(state)
    if (id !== oldId || name !== oldName || email !== oldEmail || userHash !== oldHash || almaIdHash !== oldAlmaIdHash) {
      dispatch(setLoggedInUser(id, name, email, profile, userHash, almaIdHash, jwt))
      dispatch(setUserProfile(profile))
      dispatch(afterLoginRefresh())
    }

    new Cookies().remove(TEMP_PROFILE_COOKIE_NAME, config.cookieOptions)
  }
}

