import React, { PureComponent } from 'react'
import moment from 'moment-timezone'
import momentFi from 'moment/locale/fi'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import namedays from '../../static/namedays'
import './menuNamedays.pcss'

moment.updateLocale('fi', momentFi)

export default class MenuNamedays extends PureComponent {
  render() {
    const { timestamp } = this.props
    const today = moment(timestamp).format('dd D.M.')

    return (
      <div className='menu-namedays'>
        <span className='menu-namedays__date'>{ today.charAt(0).toUpperCase() + today.slice(1) }</span>
        <span className='menu-namedays__names' title={ namedays.get(moment(timestamp).format('DDMM')) }>
          { namedays.get(moment(timestamp).format('DDMM')).split(' ').slice(0, 3).join(' ') }
        </span>
      </div>
    )
  }
}

MenuNamedays.propTypes = exact({
  timestamp: PropTypes.number.isRequired,
})
