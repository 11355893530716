import React, { useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { loginAlmaTunnus } from '../../user/almalogin/almaUserActions'
import './notificationBubble.pcss'

const NotificationBubble = ({ message, onNotificationClick, showNotification, dispatch }) => {

  const notificationBubbleRef = useRef(null)

  const notificationClick = useCallback(() => {
    dispatch(loginAlmaTunnus())
    onNotificationClick()
  }, [dispatch, onNotificationClick])

  useEffect(() => {
    if (showNotification) {

      const handleOutsideClick = (e) => {
        if (!notificationBubbleRef.current.contains(e.target)) {
          onNotificationClick()
        }
      }

      window.document.addEventListener('mousedown', handleOutsideClick)

      return () => {
        window.document.removeEventListener('mousedown', handleOutsideClick)
      }
    }
  }, [showNotification, onNotificationClick])

  return showNotification && (
    <button
      className='notification-bubble'
      onClick={ notificationClick }
      ref={ notificationBubbleRef }
    >
      { message }
    </button>
  )
}

NotificationBubble.propTypes = exact({
  message: PropTypes.string.isRequired,
  showNotification: PropTypes.bool.isRequired,
  onNotificationClick: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
})

export default NotificationBubble
