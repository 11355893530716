import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import '../../styles/toggle.pcss'
import {
  changeLayoutWidth,
  changeTheme,
  changeFrontPage,
  LAYOUT_WIDTH_FULL,
  LAYOUT_WIDTH_NORMAL,
  LAYOUT_WIDTH_WIDE,
} from '../userActions'
import { Radio, RadioGroup } from 'react-radio-group'
import { selectUserLayoutWidth } from '../../selectors/userSelector'
import { almaTunnusConfig } from '../../../config/config'

@connect((state) => {
  const { user } = state
  return {
    selectedTheme: user.get('theme'),
    selectedLayoutWidth: selectUserLayoutWidth(state),
    selectedFrontPage: user.get('frontPage'),
  }
})

export default class GeneralSettingsView extends Component {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    selectedTheme: PropTypes.string.isRequired,
    selectedLayoutWidth: PropTypes.string.isRequired,
    selectedFrontPage: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  render() {
    const {
      selectedTheme,
      selectedLayoutWidth,
      selectedFrontPage,
    } = this.props

    return (
      <div>
        <Helmet title='Yleiset' />

        <section className='content padded'>
          <div className='personal'>
            <div className='container'>
              <div>
                <h3>Aloitussivu</h3>

                <p>Tällä asetuksella voit vaihtaa sivuston aloitusnäkymän</p>

                <RadioGroup
                  name='front-page'
                  selectedValue={ selectedFrontPage }
                  onChange={ this.handleFrontPageChange }
                >
                  <label>
                    <Radio value='/' />
                    Etusivu
                  </label>
                  <br />
                  <label>
                    <Radio value='/omat' />
                    Omat aiheet
                  </label>
                  <br />
                  <label>
                    <Radio value='/uusimmat' />
                    Uusimmat
                  </label>
                  <br />
                  <label>
                    <Radio value='/luetuimmat' />
                    Luetuimmat
                  </label>
                  <br />
                  <label>
                    <Radio value='/aanestetyimmat' />
                    Äänestetyimmät
                  </label>
                </RadioGroup>
              </div>

              <hr />

              <div>
                <h3>Valitse teema</h3>

                <p>Tällä asetuksella voit muuttaa, miltä Ampparit.com näyttää sinun laitteellasi</p>

                <RadioGroup
                  name='theme'
                  selectedValue={ selectedTheme }
                  onChange={ this.handleThemeChange }
                >
                  <label>
                    <Radio value='classic' />
                    Classic
                  </label>
                  <br />
                  <label>
                    <Radio value='default' />
                    Vaalea
                  </label>
                  <br />
                  <label>
                    <Radio value='night' />
                    Tumma
                  </label>
                  <br />
                  <label>
                    <Radio value='matrix' />
                    Tumma & vihreä
                  </label>
                </RadioGroup>
              </div>

              <hr />

              <div>
                <h3>Sivun leveys</h3>

                <p>Tällä asetuksella voit muuttaa sivupohjan maksimileveyttä</p>

                <RadioGroup
                  name='page-width'
                  selectedValue={ selectedLayoutWidth }
                  onChange={ this.handleLayoutWidthChange }
                >
                  <label>
                    <Radio value={ LAYOUT_WIDTH_NORMAL } />
                    Oletus - 1200px
                  </label>
                  <br />
                  <label>
                    <Radio value={ LAYOUT_WIDTH_WIDE } />
                    Leveä - 1600px
                  </label>
                  <br />
                  <label>
                    <Radio value={ LAYOUT_WIDTH_FULL } />
                    Täysleveä
                  </label>
                </RadioGroup>
              </div>

              <hr />

              <div className='forgotten-password'>
                <h3>Vaihda salasana</h3>
                <a className='button button_large' href={ `${almaTunnusConfig.selfService}/unohtunut-salasana?clientId=${almaTunnusConfig.clientID}` } target='_blank' rel='noopener noreferrer'>
                  Vaihda salasana täällä
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  handleFrontPageChange = (value) => {
    this.props.dispatch(changeFrontPage(value))
  }

  handleThemeChange = (value) => {
    this.props.dispatch(changeTheme(value))
  }

  handleLayoutWidthChange = (value) => {
    this.props.dispatch(changeLayoutWidth(value))
  }

}
