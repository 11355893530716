import { sendAdminEmail } from '../../api/api'
import { logException } from '../../sentry'

export const SEND_SOURCE_APPLICATION = 'SEND_SOURCE_APPLICATION'
export const SEND_SOURCE_APPLICATION_SUCCESS = 'SEND_SOURCE_APPLICATION_SUCCESS'
export const SEND_SOURCE_APPLICATION_FAILURE = 'SEND_SOURCE_APPLICATION_FAILURE'


export function sendSourceApplication(replyToEmail, postData) {
  return (dispatch) => {
    dispatch({
      type: SEND_SOURCE_APPLICATION,
    })

    return sendAdminEmail('Ampparit lähdehakemus', replyToEmail, postData).then(response => {
      dispatch({
        type: SEND_SOURCE_APPLICATION_SUCCESS,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: SEND_SOURCE_APPLICATION_FAILURE,
        error,
      })
    })
  }
}
