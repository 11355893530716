import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { fetchCurrentWeather } from './currentWeatherActions'
import './currentWeatherNavBar.pcss'


@connect((state) => {
  const { currentWeather } = state
  return {
    failedToLoad: currentWeather.get('failedToLoad'),
    locationName: currentWeather.get('locationName'),
    temperature: currentWeather.get('temperature'),
    symbol: currentWeather.get('symbol'),
  }
})

/**
 * Self contained weather widget for top menu.
 */
export default class CurrentWeatherNavBar extends Component {
  static propTypes = exact({
    failedToLoad: PropTypes.bool.isRequired,
    locationName: PropTypes.string.isRequired,
    temperature: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
  })

  componentDidMount() {
    // Timer runs very rapidly so widget can update quickly after window is unhidden or
    // user changes home weather location. Fetches do not actually happen this often
    this.updateInterval = setInterval(this.dispatchRefresh, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval)
    this.updateInterval = null
  }

  dispatchRefresh = () => {
    if (!window.document.hidden) {
      this.props.dispatch(fetchCurrentWeather(120))
    }
  }

  render() {
    const { failedToLoad, locationName, temperature, symbol } = this.props

    if (failedToLoad) {
      return (
        <span className='menu-weather__error' title='Säätietoja ei saatu ladattua'>
          <i className='fa fa-exclamation' />
        </span>
      )
    }

    const signed = temperature > 0 ? `+${temperature}` : `${temperature}`
    return (
      <div className='menu-weather'>
        <div className='menu-weather__symbol-container'>
          <div className='weather-symbol'>
            <span className={ symbol } />
          </div>
        </div>
        <div className='menu-weather__data'>
          <div className='menu-weather__temp'>{ signed }<span className='menu-weather__degree'> &deg;C</span></div>
          <div className='menu-weather__location'>{ locationName }</div>
        </div>
      </div>
    )
  }
}
