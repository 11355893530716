import Immutable from 'immutable'

/* This file is only called from tagsSelectors.js, maybe combine the two and make transformers non-exported? */

export function tagsToTagItems(tags, userTags) {
  return tags.map(tag => tagToTagItem(tag, userTags))
}

export function tagsToCountedTagItems(tags, userTags) {
  return tags.map((tag) => {
    const extraAttributes = {
      itemsCount: tag.get('itemsCount', 0),
      whiteListedCount: tag.get('whiteListedCount', 0),
      blackListedCount: tag.get('blackListedCount', 0),
    }
    return tagToTagItem(tag, userTags, extraAttributes)
  })
}

export function tagToTagItem(tag, userTags, extraAttributes = {}) {
  if (!tag) return null

  return Immutable.Map({
    id: tag.get('id'),
    name: tag.get('name'),
    h1: tag.get('h1'),
    slug: tag.get('slug'),
    whitelisted: userTags.get('whitelisted').contains(tag.get('id')),
    blacklisted: userTags.get('blacklisted').contains(tag.get('id')),
    type: 'tag',
    breaking: tag.get('breaking'),
    ...extraAttributes,
  })
}
