import Immutable from 'immutable'
import { FETCH_CURRENT_WEATHER, FETCH_CURRENT_WEATHER_FAILURE, FETCH_CURRENT_WEATHER_SUCCESS } from './currentWeatherActions'

const initialState = Immutable.Map({
  locationName: null,
  locationId: null,
  temperature: null,
  symbol: null,
  timestamp: 0,
  loading: false,
  failedToLoad: false,
})

export default function currentWeatherReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_WEATHER:
      return state
        .set('loading', true)

    case FETCH_CURRENT_WEATHER_FAILURE:
      return state
        .set('failedToLoad', true)
        .set('loading', false)
        .set('timestamp', action.timestamp)

    case FETCH_CURRENT_WEATHER_SUCCESS: {
      // Based on old code it looks like first day's forecasts array could be empty in some cases..?
      const days = action.response.data
      const forecast = days[0].forecasts.length > 0 ? days[0].forecasts[0] : days[1].forecasts[0]

      return state
        .set('locationName', days[0].locationName)
        .set('locationId', parseInt(days[0].locationId, 10))
        .set('temperature', Math.floor(forecast.temperature))
        .set('symbol', forecast.symbol)
        .set('timestamp', action.timestamp)
        .set('failedToLoad', false)
        .set('loading', false)
    }
    default:
      return state
  }
}
