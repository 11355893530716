export const enter = 13
export const space = 32
export const downArrow = 40
export const upArrow = 38
export const leftArrow = 37
export const rightArrow = 39
export const shift = 16
export const ctrl = 17
export const alt = 18
export const leftCmd = 91
export const rightCmd = 93
export const capsLock = 20
export const tab = 9
export const backspace = 8
export const del = 46
export const esc = 27
export const home = 36
export const end = 35
