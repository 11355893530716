import React, { memo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import config from '../../config/config'
import Resize from './Resize'
import './banners.pcss'

const CooperationBannerIframe = ({ src, title }) => (
  <iframe
    className='cooperationbanner-iframe'
    src={ src }
    loading='lazy'
    sandbox='allow-scripts allow-popups allow-popups-to-escape-sandbox'
    scrolling='no'
    title={ title }
  />
)

CooperationBannerIframe.propTypes = exact({
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
})

export const CooperationBannerBox = memo(({ src, title, iframeTitle, path, children, wrappingClass }) => {

  return config.displayCooperationBanner ? (
    <div className='resize-container'>
      <Resize minBreakpoint={ 897 } >
        <section className='sidebox'>
          <h3 className='sidebox-header'>{ title }</h3>
          <div className={ `cooperationbanner-sidebox-container ${wrappingClass}` }>
            { src ? <CooperationBannerIframe src={ src } title={ iframeTitle || title } />
              : children }
          </div>
        </section>
      </Resize>
    </div>
  ) : null
}, (prevProps, nextProps) => prevProps.path === nextProps.path)

CooperationBannerBox.displayName = 'CooperationBannerBox'

CooperationBannerBox.propTypes = exact({
  src: PropTypes.string,
  title: PropTypes.string.isRequired,
  iframeTitle: PropTypes.string,
  path: PropTypes.string.isRequired,
  children: PropTypes.node,
  wrappingClass: PropTypes.string,
})

export const CooperationBannerList = memo(({ src, title, iframeTitle, path, children }) => {
  return config.displayCooperationBanner ? (
    <div className='resize-container'>
      <Resize maxBreakpoint={ 896 } >
        <div className='time-label time-label_cooperationbanner'>
          <span className='time-label_cooperationbanner'>{ title }</span>
        </div>
        <div className='cooperationbanner-newslist-container'>
          { src ? <CooperationBannerIframe src={ src } title={ iframeTitle || title } />
            : children }
        </div>
      </Resize>
    </div>
  ) : null
}, (prevProps, nextProps) => prevProps.path === nextProps.path)

CooperationBannerList.displayName = 'CooperationBannerList'

CooperationBannerList.propTypes = exact({
  src: PropTypes.string,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  iframeTitle: PropTypes.string,
  children: PropTypes.node,
})
