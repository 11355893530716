export const SVG_ICON_PAYWALL = 'svg-paywall-flag'
export const SVG_ICON_EYE = 'svg-click-count'
export const SVG_ICON_ELLIPSIS = 'svg-item-actions'
export const SVG_ICON_LOGIN = 'svg-login'

export const SVG_INLINE_EMBED = `
<div style='display: none'>
  <svg id='${SVG_ICON_PAYWALL}' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' width='17' height='17' viewBox='0 0 17 20' fill='none' stroke-width='3'>
    <path d='M1.5 13.837v-7.98l7-4.117 7 4.118v7.979l-7 4.392-7-4.392Z' stroke='#F7CA45'/>
    <path d='M9.205 6.822c-.476 0-.87.139-1.178.416-.308.274-.507.672-.597 1.196h2.36v1.03H7.34l-.01.206v.275l.01.194h2.08v1.043H7.44c.2.949.827 1.423 1.881 1.423.559 0 1.096-.11 1.612-.334v1.5c-.454.231-1.026.346-1.717.346-.957 0-1.744-.26-2.362-.78-.617-.519-1.005-1.237-1.166-2.155h-.802v-1.043h.691a2.221 2.221 0 0 1-.023-.364l.011-.31h-.68V8.434h.78c.145-.946.533-1.692 1.166-2.239.633-.55 1.424-.826 2.373-.826.734 0 1.422.16 2.063.48l-.575 1.36a5.558 5.558 0 0 0-.755-.281 2.535 2.535 0 0 0-.733-.106Z' fill='#F7CA45'/>
  </svg>

  <svg id='${SVG_ICON_EYE}' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path d='M2.143 10S5 4.286 10 4.286 17.857 10 17.857 10 15 15.714 10 15.714 2.143 10 2.143 10Z' stroke='#999' stroke-linecap='round' stroke-linejoin='round'/>
    <path d='M10 12.143a2.143 2.143 0 1 0 0-4.286 2.143 2.143 0 0 0 0 4.286Z' stroke='#999' stroke-linecap='round' stroke-linejoin='round'/>
  </svg>

  <svg id='${SVG_ICON_ELLIPSIS}' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' width='6' height='24' viewBox='0 0 6 24' fill='none'>
    <circle cx='3' cy='3' r='3' fill='#B3B3B3' />
    <circle cx='3' cy='12' r='3' fill='#B3B3B3' />
    <circle cx='3' cy='21' r='3' fill='#B3B3B3' />
  </svg>

  <svg id='${SVG_ICON_LOGIN}' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' width='20' height='20' viewBox='0 0 512 512'>
    <path d='M406.5 399.6c-19.1-46.7-65-79.6-118.5-79.6h-64c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256c0-114.9 93.1-208 208-208s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7c-32 20.1-69.8 31.7-110.4 31.7s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3h64c38.8 0 71.2 27.6 78.5 64.3zM256 512a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0-176 0z'/>
  </svg>

</div>`
