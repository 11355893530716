import * as actions from './searchActions'
import Immutable from 'immutable'
import { LOCATION_CHANGE } from 'react-router-redux'

const initialState = Immutable.Map({
  itemIds: Immutable.OrderedSet(),
  totalCount: 0,
  inProgress: false,
  errorLength: false,
  loadingNext: false,
  searchString: '',
  searchTimestamp: '',
  isOpen: false,
})

export default function searchReducer(state = initialState, action) {

  switch (action.type) {
    case actions.FETCH_SEARCH_SUCCESS: {
      const resultIds = action.response.data.results.items.map(item => item.id)
      return state
        .set('itemIds', Immutable.OrderedSet(resultIds))
        .set('totalCount', action.response.data.results.numberOfResults)
        .set('inProgress', false)
        .set('errorLength', false)
    }
    case actions.FETCH_SEARCH_NEXT_SUCCESS: {
      const resultIds = action.response.data.results.items.map(item => item.id)
      const joinedIds = state.get('itemIds').union(resultIds)
      return state
        .set('itemIds', joinedIds)
        .set('totalCount', action.response.data.results.numberOfResults)
        .set('loadingNext', false)
    }
    case actions.FETCH_SEARCH:
      return state
        .set('inProgress', true)
        .set('searchString', action.searchString)
        .set('errorLength', false)
        .set('searchTimestamp', action.timestamp)

    case actions.FETCH_SEARCH_NEXT:
      return state
        .set('loadingNext', true)

    case actions.FETCH_SEARCH_ERROR_LENGTH:
      return state
        .set('errorLength', true)
        .set('searchString', action.searchString)

    case actions.FETCH_SEARCH_FAILURE:
      return state
        .set('inProgress', false)
        .set('errorLength', false)

    case actions.CHANGE_SEARCH_STRING:
      return state.set('searchString', action.string)

    case actions.OPEN_SEARCH:
      return state.set('isOpen', true)

    case actions.CLOSE_SEARCH:
      return state.set('isOpen', false)

    case LOCATION_CHANGE:
      if (action.payload.pathname !== '/haku') {
        return state
          .set('isOpen', false)
          .set('itemIds', Immutable.OrderedSet())
          .set('totalCount', 0)
          .set('inProgress', false)
          .set('errorLength', false)
          .set('searchString', '')
      }
      return state.set('searchString', action.payload.query.q || '')

    default:
      return state
  }
}
