import moment from 'moment-timezone'
import invert from 'lodash/invert'
import sanitize from '../../views/Search/sanitize'

export const TV_FILTER_CHANNEL_ALL = 'all'
export const TV_FILTER_CHANNEL_FREE = 'free'
export const TV_FILTER_CHANNEL_PAID = 'paid'
export const TV_FILTER_CHANNEL_FAVORITES = 'favorites'

export const TV_FILTER_CHANNEL_DEFAULT = TV_FILTER_CHANNEL_ALL

export const TV_FILTER_PROGRAM_TIME_COMING = 'coming'
export const TV_FILTER_PROGRAM_TIME_NOW = 'now'
export const TV_FILTER_PROGRAM_TIME_ALL = 'day'
export const TV_FILTER_PROGRAM_TIME_EVENING = 'evening'
export const TV_FILTER_PROGRAM_TIME_NIGHT = 'night'

export const TV_FILTER_PROGRAM_DEFAULT = TV_FILTER_PROGRAM_TIME_NOW

export const TV_FILTER_PROGRAM_TYPE_ALL = 'all'
export const TV_FILTER_PROGRAM_TYPE_MOVIES = 'movies'
export const TV_FILTER_PROGRAM_TYPE_SPORT = 'sport'

export const TV_FILTER_PROGRAM_TYPE_DEFAULT = TV_FILTER_PROGRAM_TYPE_ALL

const CHANNEL_FILTER_TRANSLATION_MAP = {
  'kaikki': TV_FILTER_CHANNEL_ALL,
  'ilmaiset': TV_FILTER_CHANNEL_FREE,
  'maksulliset': TV_FILTER_CHANNEL_PAID,
  'omat': TV_FILTER_CHANNEL_FAVORITES,
}

const PROGRAM_FILTER_TRANSLATION_MAP = {
  'tulevat': TV_FILTER_PROGRAM_TIME_COMING,
  'nyt': TV_FILTER_PROGRAM_TIME_NOW,
  'paiva': TV_FILTER_PROGRAM_TIME_ALL,
  'ilta': TV_FILTER_PROGRAM_TIME_EVENING,
  'yo': TV_FILTER_PROGRAM_TIME_NIGHT,
}

const PROGRAM_TYPE_FILTER_TRANSLATION_MAP = {
  'kaikki': TV_FILTER_PROGRAM_TYPE_ALL,
  'elokuvat': TV_FILTER_PROGRAM_TYPE_MOVIES,
  'urheilu': TV_FILTER_PROGRAM_TYPE_SPORT,
}

export const translateChannelFilter = (option, defaultValue = TV_FILTER_CHANNEL_DEFAULT) => {
  return CHANNEL_FILTER_TRANSLATION_MAP[option] || defaultValue
}

export const untranslateChannelFilter = (option, defaultValue = TV_FILTER_CHANNEL_DEFAULT) => {
  return invert(CHANNEL_FILTER_TRANSLATION_MAP)[option] || defaultValue
}

export const translateProgramTimeFilter = (option, defaultValue = TV_FILTER_PROGRAM_DEFAULT) => {
  return PROGRAM_FILTER_TRANSLATION_MAP[option] || defaultValue
}

export const untranslateProgramTimeFilter = (option, defaultValue = TV_FILTER_PROGRAM_DEFAULT) => {
  return invert(PROGRAM_FILTER_TRANSLATION_MAP)[option] || defaultValue
}

export const translateProgramTypeFilter = (option, defaultValue = TV_FILTER_PROGRAM_TYPE_DEFAULT) => {
  return PROGRAM_TYPE_FILTER_TRANSLATION_MAP[option] || defaultValue
}

export const untranslateProgramTypeFilter = (option, defaultValue = TV_FILTER_PROGRAM_TYPE_DEFAULT) => {
  return invert(PROGRAM_TYPE_FILTER_TRANSLATION_MAP)[option] || defaultValue
}

/**
 * day and coming filters are both the same as for some reason data for the day has nothing to do with
 * these filters, instead if you want the whole days programs, you query the API with a date. If you
 * do not give the API query a date, the endpoint will just return coming programs. This took way too long to find out... -Yacine
 */
const programTimeFilters = {
  now: (program, date, now) => moment(program.get('timestamp') * 1000).isBefore(moment(now).add(2, 'hours')), // this is partially prefiltered by endpoint (no past programs)
  coming: program => true, // this is prefiltered by endpoint (no past programs)
  day: program => true,
  evening: (program, date) => moment(program.get('timestamp') * 1000).isBetween(moment(date).add(16, 'hours').add(59, 'minutes'), moment(date).add(22, 'hours')),
  night: (program, date) => moment(program.get('timestamp') * 1000).isBetween(moment(date).add(21, 'hours').add(59, 'minutes'), moment(date).add(25, 'hours')),
}

const filterPrograms = (programming, filter, date, now) => {
  return programming.map(
    channelContainer => channelContainer.set(
      'programs',
      channelContainer.get('programs').filter(program => filter(program, date, now))
    )
  )
}

export function getProgramTimeFilter(name, date, now) {
  const filter = programTimeFilters[name] || programTimeFilters.coming
  return (programming) => filterPrograms(programming, filter, date, now)
}

const filterProgramsByType = (programming, filter) => {
  return programming.map(
    channelContainer => channelContainer.set(
      'programs',
      channelContainer.get('programs').filter(program => filter(program))
    )
  )
}

const filterProgramsByText = (programming, text) => {

  text = sanitize(text)

  if (!text || text === '') return programming

  text = text.toLowerCase()

  // split the search text in parts to search by individual words as well
  let parts = text.split(' ')

  // drop out too short words to not match too loosely
  parts = parts.filter(part => part.length > 2)

  // push the original full search text to be searched as well
  // the full search text can still be less than 3 chars long eg. "f1"
  parts.push(text)

  return programming.map(
    channelContainer => channelContainer.set(
      'programs',
      channelContainer.get('programs').filter(program => {
        return parts.find((part) => {
          // search from title
          const title = sanitize(program.get('title'))
          if (title && title.toLowerCase().includes(part)) return true

          // search from description
          const description = sanitize(program.get('description'))
          if (description && description.toLowerCase().includes(part)) return true

          return false
        })

      })
    )
  )
}

const programTypeFilters = {
  all: program => true,
  movies: program => program.get('movie'),
  sport: program => program.get('sport'),
}

export function getProgramTypeFilter(type) {
  const filter = programTypeFilters[type] || programTypeFilters.all
  return (programming) => filterProgramsByType(programming, filter)
}

const channelFilters = {
  all: channel => true,
  paid: channel => channel.get('pay'),
  free: channel => !channel.get('pay'),
  favorites: (channel, favorites) => {
    return favorites && favorites.size && favorites.find(channelId => channelId === channel.get('id'))
  },
}

export function getChannelFilter(name) {
  const filter = channelFilters[name] || channelFilters.all

  return (programming, favorites = null) => {
    return programming.filter(channelContainer => filter(channelContainer.get('channel'), favorites))
  }
}

export function getTextFilter(text) {
  return (programming) => filterProgramsByText(programming, text)
}

const programDateMap = {
  now: 'default',
  coming: 'default',
  day: 'date',
  evening: 'date',
  night: 'date',
  movies: 'default',
  all: 'date',
}

export const getTVFiltersToday = () => moment().format('YYYY-MM-DD')

export const getTVFiltersTodayByProgram = (programTimeFilter) => {
  if (programDateMap[programTimeFilter] === 'default') {
    return null
  } else {
    return getTVFiltersToday()
  }
}
