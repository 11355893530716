import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import React, { Component } from 'react'
import '../../styles/modal.pcss'
import { NOTIFICATION_MODAL } from './Modals'
import GenericModal from './GenericModal'

export default class NotificationModal extends Component {
  render() {
    const { onClose, message, title } = this.props
    return (
      <GenericModal
        modalId={ NOTIFICATION_MODAL }
        onClose={ onClose }
        title={ title }
        okButtonTxt='Ok, selvä juttu!'
      >
        { message }
      </GenericModal>
    )
  }
}

NotificationModal.propTypes = exact({
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
})
