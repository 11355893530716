import { getAllTags } from '../../api/api'
import { logException } from '../../sentry'

export const FETCH_ALL_TAGS = 'FETCH_ALL_TAGS'
export const FETCH_ALL_TAGS_SUCCESS = 'FETCH_ALL_TAGS_SUCCESS'
export const FETCH_ALL_TAGS_FAILURE = 'FETCH_ALL_TAGS_FAILURE'

export function fetchAllTags() {
  return (dispatch) => {
    dispatch({
      type: FETCH_ALL_TAGS,
    })

    return getAllTags().then(response => {
      dispatch({
        type: FETCH_ALL_TAGS_SUCCESS,
        response,
        timestamp: Date.now(),
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_ALL_TAGS_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}

