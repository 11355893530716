import { useEffect, useRef } from 'react'
import { siteUrl } from '../../config/config'
import moment from 'moment-timezone'

export function createUrl(path, query = {}) {
  return siteUrl + buildPathWithQuery(path, query)
}

export function buildPathWithQuery(path, query) {
  const params = new URLSearchParams(query)
  if (query == null || params.size === 0) {
    return path
  }
  if (path.endsWith('?')) {
    return path + params.toString()
  }
  if (path.includes('?')) {
    return path + '&' + params.toString()
  }
  return path + '?' + params.toString()
}


export function isUUID(value) {
  return typeof value === 'string' && value.match(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/)
}


export function isTimestampFresh(lastFetchTimestamp, maxStaleSecs) {
  if (!lastFetchTimestamp || !maxStaleSecs) {
    return false
  }
  // Initial fetches happen in SSR and timestamps are in server time (synchronized regularly).
  // If user device's clock is behind server time by more than ten seconds we force a refresh
  // to align timestamps with their device clock.
  if (lastFetchTimestamp > Date.now() + 10000) {
    return false
  }
  return (lastFetchTimestamp + maxStaleSecs * 1000) > Date.now()
}



/**
 * Util to set (or replace) class in class names string based on unique prefix.
 * eg. 'th-classic' will be replaced if prefix 'th-' is provided with any name.
 */
export function classNamesSetClass(classNames, prefix, name) {
  const classes = classNames.split(' ')

  const themeIndex = classes.findIndex(item => item.indexOf(prefix) === 0)
  if (themeIndex !== -1) {
    classes[themeIndex] = prefix + name
  } else {
    classes.unshift(prefix + name)
  }

  return classes.join(' ').trim()
}

/**
 * Util to escape all regex characters in a string
 * https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
 */
export function escapeRegexChars(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

/**
 * Util to group array data by key
 * @param key Key to group by
 * @param data Array of data
 */
export function groupDataBy(key, data) {
  return data.reduce(function (item, i) {
    (item[ i[ key ] ] = item[ i[ key ] ] || []).push(i)
    return item
  }, {})
}

/**
 * Util to group data by a datetime field
 * @param key Key of datetime field
 * @param data Array of data
 */
export function groupDataByDate(key, data) {
  return data.reduce(function (item, i) {
    const date = moment.utc(i[key]).local().startOf('day').format('YYYY-MM-DD HH:mm:ss'); // semicolon is needed
    (item[ date ] = item[ date ] || []).push(i)
    return item
  }, {})
}

/**
 * Check if first given version is greater than the second given version
 * @param ver1
 * @param ver2
 * @returns {boolean}
 */
export function semverGreaterThan(ver1, ver2) {
  return semverCompare(ver1, ver2) === 1
}

/**
 * Simple comparison for semver strings. Only supports versions with numeric major, minor and patch versions (for example 1.3.2)
 * (based on https://github.com/substack/semver-compare)
 *
 * @param ver1 {string} Semver string a
 * @param ver2 {string} Semver string b
 * @returns {number} 1 if a > b, -1 if b > a and 0 if equal
 */
export function semverCompare(ver1, ver2) {
  const versionParts1 = ver1.split('.')
  const versionParts2 = ver2.split('.')

  for (let i = 0; i < 3; i++) {
    const versionPart1 = Number(versionParts1[i])
    const versionPart2 = Number(versionParts2[i])
    if (versionPart1 > versionPart2) return 1
    if (versionPart2 > versionPart1) return -1
    if (!Number.isNaN(versionPart1) && Number.isNaN(versionPart2)) return 1
    if (Number.isNaN(versionPart1) && !Number.isNaN(versionPart2)) return -1
  }
  return 0
}


/**
 * Basic wildcard matcher that only supports trailing *-wildcard
 * basicWildcardMatch('/*',  '/')       -> true
 * basicWildcardMatch('/*',  '/any')    -> true
 * basicWildcardMatch('/a*', '/any')    -> true
 * basicWildcardMatch('/any', '/any')   -> true
 * basicWildcardMatch('/any*', '/any')  -> true
 * basicWildcardMatch('/any/*', '/any') -> false
 * basicWildcardMatch('any', '/any')    -> false
 * @param {string} pattern pattern, optionally containing trailing *
 * @param {string} text the piece of text to compare to.
 */
export function basicWildcardMatch(pattern, text) {
  if (pattern === text) {
    return true
  }
  if (pattern.endsWith('*')) {
    const patternPrefix = pattern.substring(0, pattern.length - 1)
    return text.indexOf(patternPrefix) === 0
  }
  return false
}


/**
 * Custom hook that triggers a callback when a click event occurs outside of a specified element.
 * @param {React.RefObject} ref - The ref to the element.
 * @param {function} handler - The callback function to be called when a click event occurs outside of the element.
 */
export function useClickAway(ref, handler) {
  const savedHandler = useRef()

  // Update saved handler if it changes.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const eventListener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      savedHandler.current(event)
    }

    // Add event listener
    window.document.addEventListener('mousedown', eventListener)
    window.document.addEventListener('touchstart', eventListener)

    // Cleanup event listeners on unmount
    return () => {
      window.document.removeEventListener('mousedown', eventListener)
      window.document.removeEventListener('touchstart', eventListener)
    }
  }, [ref]) // Re-run if ref changes
}
