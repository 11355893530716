import { useEffect } from 'react'
import { AlmaAccountCore } from '@almamedia/alma-account-core'
import { AlmaAccountAnalytics } from '@almamedia/alma-account-analytics'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { almaTunnusConfig } from '../../../config/config'
import { browserHistory } from 'react-router'
import { setAlmaTunnusUser } from './almaUserActions'
import { canUseDOM } from 'exenv'
import { logException } from '../../sentry'


const core = canUseDOM ? AlmaAccountCore.getInstance() : null

const analytics = canUseDOM ?  AlmaAccountAnalytics.getInstance({
  clientId: almaTunnusConfig.clientID,
}) : null

let loginInProgress = false
let logoutInProgress = false


const saveOriginPath = () => {
  try {
    window.sessionStorage.setItem('originPath', window.location.pathname + window.location.search)
  } catch (error) {
    logException(error)
  }
}

const restoreOriginPath = () => {
  try {
    const redirectPath = window.sessionStorage.getItem('originPath')
    window.sessionStorage.removeItem('originPath')
    if (redirectPath) {
      browserHistory.replace(redirectPath, browserHistory.getCurrentLocation().pathname)
    }
  } catch (error) {
    logException(error)
  }
}

// Get access token from AlmaTunnus, and return where needed
export const getAccessToken = async () => {
  const token = await core.getAccessToken()
  return token
}

// Get user info from AlmaTunnus, and return where needed
export const getUserInfo = async () => {
  const user = await core.getUserInfo()
  return user
}

const authCallback = async (dispatch) => {
  if (await core.isAuthenticated()) {
    const user = await getUserInfo()

    analytics.sendUserEvent({user})

    const { email, email_verified } = user
    const accessToken = await getAccessToken()
    await dispatch(setAlmaTunnusUser(accessToken, email, email_verified, true))
    restoreOriginPath()
  }
}

const AlmaLogin = ({ dispatch, loginRequested, logoutRequested, registerRequested, loggedIn }) => {

  useEffect(() => {

    const almaTunnusAction = async () => {
      if (core == null || loginInProgress || logoutInProgress) {
        return
      }

      await core.init({ ...almaTunnusConfig })
      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        await core.handleAuthentication()
        await authCallback(dispatch)
      }
      else {
        const user = await core.dailySessionRenewal()
        if (user) {
          analytics.sendUserEvent({user})
        }
      }

      // Login user and redirect to path where login was requested
      if (loginRequested && !loggedIn) {
        loginInProgress = true
        saveOriginPath()
        await core.login({
          authorizationParams: {
            theme: 'light',
            lang: 'fi',
            source: 'ampparit-login',
          },
        })
      }

      // Register new user
      if (registerRequested && !loggedIn) {
        loginInProgress = true
        await core.login({
          authorizationParams: {
            theme: 'light',
            lang: 'fi',
            screen_hint: 'signup',
            source: 'ampparit-register',
          },
        })
      }

      // Logout user
      if (logoutRequested) {
        logoutInProgress = true
        // Send logout event to analytics
        const user = await getUserInfo()
        if (user) {
          await analytics.sendLogout({
            almaIDHash: user['https://almamedia.net/almaIDHash'],
            connection: user['https://almamedia.net/connection'],
            source: 'ampparit-logout',
          })
        }
        await core.logout(almaTunnusConfig.redirectUri)
      }
    }

    almaTunnusAction().catch((error) => logException(error))
  }, [dispatch, loginRequested, logoutRequested, registerRequested, loggedIn])

  return null
}

AlmaLogin.propTypes = exact({
  dispatch: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  loginRequested: PropTypes.bool,
  logoutRequested: PropTypes.bool,
  registerRequested: PropTypes.bool,
})

export default AlmaLogin
