/**
 * Parse different tracking elements from trackingCode string, and append them
 * as children to parentElement. Simple `parentElement.innerHTML = trackingCode`
 * will not work, as `<script>` elements added that way do not get executed by
 * browsers. Dynamic scripts MUST be created with document.createElement.
 *
 * @param {string} trackingCode
 * @param {Element} parentElement
 * @returns {void}
 */
export default function injectTrackers(trackingCode, parentElement) {
  if (!trackingCode || !parentElement) return

  // Append tracking elements to a document fragment first
  const trackers = window.document.createDocumentFragment()

  // Use browser to parse raw trackingCode
  const div = window.document.createElement('div')
  div.innerHTML = trackingCode
  const rawChildren = Array.from(div.children)

  // Recreate elements with createElement
  rawChildren.forEach(childTag => {
    let trackerElement = null
    if (childTag.tagName === 'SCRIPT') {
      trackerElement = window.document.createElement('script')
      if (childTag.src) {
        trackerElement.src = childTag.src
      } else {
        const text = childTag.textContent || ''
        const inlineScript = window.document.createTextNode(text)
        trackerElement.appendChild(inlineScript)
      }
    } else {
      trackerElement = window.document.createElement('div')
      trackerElement.className = 'impressions__tracker'
      trackerElement.innerHTML = childTag.outerHTML
    }
    trackers.appendChild(trackerElement)
  })

  // Move trackers from document fragment to DOM
  parentElement.appendChild(trackers)
}
