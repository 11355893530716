import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import MainContent from '../../components/wrappers/MainContent'
import { selectIsUserLoggedIn, selectUserEmail } from '../../selectors/userSelector'
import AccountRemovalForm from './AccountRemovalForm'
import './infoViews.pcss'

@connect((state) => {
  return {
    loggedIn: selectIsUserLoggedIn(state),
    email: selectUserEmail(state),
  }
})

export default class AccountRemovalView extends Component {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    loggedIn: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
  }

  render() {
    const { loggedIn, email } = this.props

    return (
      <MainContent>
        <article className='info-page'>
          <Helmet title='Ampparit-profiilin poisto' />

          <h1>Ampparit-profiilin poisto</h1>

          <p>
            Tällä lomakkeella voit pyytää käyttäjätietojesi poistamista.
          </p>
          { !loggedIn && (
            <p>
              Et ole tällä hetkellä kirjautuneena. Voit lähettää poistopyynnön täyttämällä sähköpostiosoitteesi.
            </p>
          ) }
          { loggedIn && (
            <p>
              Olet kirjautuneena profiililla <i>{ email }</i>. Jos haluat poistaa toisen profiilin, kirjaudu ensin ulos.
            </p>
          ) }
          <p>
            Olemme sinuun vielä yhteydessä varmistaaksemme, että poistopyyntö on tullut osoitteen omistajalta.
          </p>
          <p>
            Voit halutessasi antaa lisätietoja tai palautetta poistopyynnön yhteydessä, esim. poiston syystä.
          </p>

          <AccountRemovalForm loggedIn={ loggedIn } email={ email } />
        </article>
      </MainContent>
    )
  }
}
