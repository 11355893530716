import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserProfile } from '../userActions'
import { selectWhitelistedTagItems, selectBlacklistedTagItems, selectTagsLoading } from '../../selectors/tagsSelector'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import Switch from '../../components/ui/Switch'
import '../../styles/settings.pcss'
import Loading from '../../components/util/Loading'
import GenericMessage from '../../components/messages/GenericMessage'

const TagSettingsView = () => {
  const dispatch = useDispatch()

  const loading = useSelector(selectTagsLoading)
  const whitelistedTagItems = useSelector(selectWhitelistedTagItems)
  const blacklistedTagItems = useSelector(selectBlacklistedTagItems)

  useEffect(() => {
    dispatch(fetchUserProfile(5))
  }, [dispatch])

  return (
    <div>
      <Helmet title='Aihetunnisteet' />

      <section className='content'>
        <div className='profile-settings-info-text'>
          <span>
            Katso, mitkä aiheet olet piilottanut profiilistasi tai mitä aiheita seuraat Omalla listallasi. Voit piilottaa ja tykätä aiheista myös <Link className='text-link' to='/aihetunnisteet'>aihetunnisteet sivulla</Link>.
          </span>
        </div>
        <div className='personal'>
          <div className='sources'>
            <div className='box-header'>
              <span>Seuratut aihetunnisteet</span>
            </div>
            <Loading loading={ loading }>
              { whitelistedTagItems.count() <= 0 && (
                <GenericMessage type='empty' message='Ei seurattuja aihetunnisteita.' />
              ) }
              { whitelistedTagItems.map((tag) => (
                <Switch key={ tag.get('id') } tag={ tag } />
              )) }
            </Loading>
          </div>

          <div className='width100'>
            <div className='hidden-sources'>
              <div className='box-header'>Piilotetut aihetunnisteet</div>
              <Loading loading={ loading }>
                { blacklistedTagItems.count() <= 0 && (
                  <GenericMessage type='empty' message='Ei piilotettuja aihetunnisteita.' />
                ) }
                { blacklistedTagItems.map((tag) => (
                  <Switch key={ tag.get('id') } tag={ tag } />
                )) }
              </Loading>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default TagSettingsView
