import React from 'react'
import { Link } from 'react-router'
import './socialMediaLinks.pcss'

const SocialMediaLinks = () => (
  <section className='sidebox'>
    <h3 className='sidebox-header'>Seuraa Amppareita somessa</h3>
    <div className='sidebox-content'>
      <div className='social-media-links'>
        <a
          target='_blank' className='some-fb gtm-social-fb' href='https://www.facebook.com/ampparit'
          rel='noopener noreferrer' aria-label='Seuraa Amppareita Facebookissa'
        >
          <i className='fa fa-facebook-square gtm-social-fb' aria-hidden='true' />
        </a>
        <a
          target='_blank' className='some-tw gtm-social-tw' href='https://twitter.com/ampparitcom'
          rel='noopener noreferrer' aria-label='Seuraa Amppareita Twitterissä'
        >
          <i className='fa fa-twitter-square gtm-social-tw' aria-hidden='true' />
        </a>
      </div>
      <div className='social-media-feedback-link'>
        <Link className='button' to='/palaute'>Anna palautetta</Link>
      </div>
    </div>
  </section>
)

export default SocialMediaLinks
