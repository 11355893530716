import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Switch from '../../components/ui/Switch'
import ItemVotingWidget from './ItemVotingWidget'
import ItemShareWidget from '../../components/social/ItemShareWidget'
import { blacklistSource, unblacklistSource } from '../../user/userActions'
import { genericNotification } from '../notification/notificationActions'
import { selectUserBlacklistedSourceIds } from '../../selectors/userSelector'
import './itemActionsWidget.pcss'
import ItemReportWidget from './ItemReportWidget'


const ItemActionsWidget = ({ item, dispatch, loggedIn, blacklistedSourceIds }) => {

  const itemId = item.get('id')
  const source = item.get('source')
  const title = item.get('title')
  const currentVote = item.get('currentVote')
  const currentReport = item.get('currentReport')
  const isCustom = item.get('custom')
  const sourceId = item.get('sourceId')
  const tags = item.get('tags')
  const category = item.get('category')
  const isBlacklisted = blacklistedSourceIds.includes(sourceId)

  const handleSourceBlacklist = useCallback(() => {
    if (!loggedIn) {
      dispatch(genericNotification('Kirjaudu sisään, jotta voit piilottaa lähteitä', 'info'))
      return
    }

    dispatch(blacklistSource(sourceId))
  }, [dispatch, sourceId, loggedIn])

  const handleUndoBlacklist = useCallback(() => {
    dispatch(unblacklistSource(sourceId))
  }, [dispatch, sourceId])

  return (
    <div className='item-actions-container'>
      <div className='item-actions-top-container'>
        <div className='item-actions-top-container-left' >
          <div className='item-actions-category-container'>
            { category &&
            <Switch tag={ category } />
            }

            { !category && isCustom &&
            <span className='custom-feed-info'>Oma syöte</span>
            }

            { tags.map(tag =>
              <Switch key={ tag.get('id') } tag={ tag } />
            ) }
          </div>
        </div>

        <div className='item-actions-top-container-right'>
          { !isCustom &&
          <ItemShareWidget
            itemTitle={ title }
            id={ itemId }
            dispatch={ dispatch }
          />
          }

          { !isCustom &&
            <ItemReportWidget
              currentReport={ currentReport }
              itemId={ itemId }
              dispatch={ dispatch }
            />
          }

          { !isCustom &&
          <ItemVotingWidget
            minusVotes={ item.get('minus_votes') }
            plusVotes={ item.get('plus_votes') }
            currentVote={ currentVote }
            itemId={ itemId }
            loggedIn={ loggedIn }
            dispatch={ dispatch }
          />
          }
        </div>
      </div>
      { !isCustom &&
      <div className='item-actions-bottom-container'>
        <button
          className={ `source-blacklist-button${isBlacklisted ? '-blacklisted' : ''}` }
          onClick={ isBlacklisted ? handleUndoBlacklist : handleSourceBlacklist }
        >
          <span>{ isBlacklisted ? 'Peruuta piilotus:' : 'Piilota lähde' } { source }</span>
        </button>
      </div>
      }
    </div>
  )
}

ItemActionsWidget.propTypes = exact({
  item: ImmutablePropTypes.map.isRequired,
  dispatch: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  blacklistedSourceIds: ImmutablePropTypes.set,
})

const mapStateToProps = (state) => ({
  blacklistedSourceIds: selectUserBlacklistedSourceIds(state),
})

export default connect(mapStateToProps)(ItemActionsWidget)
