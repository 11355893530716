import { createSelector } from 'reselect'
import { selectUserSubscribedSourceIds } from './userSelector'


function selectNavigationItems(state) {
  return state.menu.get('tree')
}


/**
 * Selects only the records that are categories. Identified by having non-null categoryId.
 * Blacklisted categories are not filtered out.
 */
export const selectCategoryTree = createSelector(
  selectNavigationItems,
  (items) => {
    items = items.filter(item => item.categoryId != null)
    items = items.map(rootItem => {
      const pages = rootItem.pages.filter(page => page.categoryId != null)
      return rootItem.set('pages', pages)
    })
    return items
  }
)

/**
 * Selects records that are to be shown in the side menu. Blacklisted categories are not
 * filtered out but "Maksulliset" aggregate category is if user has no subscriptions.
 */
export const selectSideMenuLinks = createSelector(
  selectNavigationItems,
  selectUserSubscribedSourceIds,
  (items, subs) => {
    const hasSubs = subs.size > 0
    return items.filter(item => !!item.menuTitle && (hasSubs || item.to !== '/maksulliset'))
  }
)

/**
 * Selects records that are to be shown in the mobile menu.
 */
export const selectMobMenuLinks = selectSideMenuLinks

/**
 * Select records that are to be shown in desktop top navigation.
 */
export const selectTopNaviLinks = createSelector(
  selectNavigationItems,
  (items) => {
    return items.filter(item => !!item.topNaviTitle)
  }
)
