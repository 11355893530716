import { getAppVersion } from '../api/api'
import { logException } from '../sentry'
import { isTimestampFresh, semverGreaterThan } from '../lib/utils'

export const SET_STATUS = 'SET_STATUS'
export const SET_WEBSOCKET_CONNECTED = 'SET_WEBSOCKET_CONNECTED'
export const SET_DEVICE_INFO = 'SET_DEVICE_INFO'

export const FETCH_VERSION = 'FETCH_VERSION'
export const FETCH_VERSION_SUCCESS = 'FETCH_VERSION_SUCCESS'
export const FETCH_VERSION_FAILURE = 'FETCH_VERSION_FAILURE'

/*
 * @todo separate app version stuff in to appVersionActions, appVersionReducer
 */

export function fetchAppVersion(maxStaleSecs = 0) {
  return (dispatch, getState) => {
    const state = getState()
    const lastFetchTimestamp = state.status.get('versionTimestamp')
    const loading = state.status.get('versionLoading')
    if (loading || isTimestampFresh(lastFetchTimestamp, maxStaleSecs)) {
      return
    }

    dispatch({
      type: FETCH_VERSION,
    })
    return getAppVersion().then(response => {
      const stateVersion = getState().status.get('version')
      const serverVersion = response.data.version

      if (stateVersion !== null && semverGreaterThan(serverVersion, stateVersion)) {
        window.location.reload()
        return
      }

      dispatch({
        type: FETCH_VERSION_SUCCESS,
        version: serverVersion,
        timestamp: Date.now(),
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: FETCH_VERSION_FAILURE,
        error,
      })
    })
  }
}

export function setStatus(status = 200) {
  return {
    type: SET_STATUS,
    status: status,
  }
}

export function setWebsocketConnected(connected) {
  return {
    type: SET_WEBSOCKET_CONNECTED,
    connected,
  }
}

export function setDeviceInfo(userAgentString) {
  return {
    type: SET_DEVICE_INFO,
    userAgentString: userAgentString,
  }
}
