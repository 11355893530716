import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import {
  selectCategoryHierarchy,
  selectRelatedCategories,
  selectChildCategories,
} from '../../selectors/categoriesSelector'
import { categoriesToCategoryItems } from '../item/categoryTransformers'
import Switch from '../ui/Switch'
import MunicipalitySearchBox from '../widgets/MunicipalitySearchBox'
import municipalities from '../../static/municipalities'
import './newsListWidgets.pcss'


@connect((state, ownProps) => {
  const { category } = ownProps

  const categoryHierarchy = selectCategoryHierarchy(state, category)
  const userCategories = state.user.get('categories')

  // Get related categories to be shown above news list. For Level 2 categories
  // we automatically use its Level 3 children, if explicit list does not exist
  let relatedCategories = selectRelatedCategories(state, category)
  if (relatedCategories.size === 0 && category && category.treeLevel === 2) {
    relatedCategories = selectChildCategories(state, category)
  }

  return {
    relatedCategories,
    categoryHierarchy,
    userCategories,
  }
})


export default class NewsListWidgets extends PureComponent {
  static propTypes = exact({
    dispatch: PropTypes.func.isRequired,
    categoryHierarchy: ImmutablePropTypes.list.isRequired,
    relatedCategories: ImmutablePropTypes.orderedSet.isRequired,
    userCategories: ImmutablePropTypes.map.isRequired,
    category: ImmutablePropTypes.record.isRequired, // eslint-disable-line react/no-unused-prop-types -- used in connect
  })

  render() {
    const {
      dispatch,
      categoryHierarchy,
      relatedCategories,
      userCategories,
    } = this.props

    const relatedCategoryItems = categoriesToCategoryItems(relatedCategories, userCategories)

    const renderHierarchy = categoryHierarchy.size === 3
    const renderRelated = !renderHierarchy && relatedCategories && relatedCategories.size > 0
    const renderMunicipalitySearch = categoryHierarchy.getIn([0, 'slug']) === 'paikallisuutiset'

    return (
      <div className='newslist-widgets'>
        { (renderHierarchy || renderRelated) &&
          <div className='related-categories'>
            { renderHierarchy &&
              <div className='breadcrumbs'>
                <Link to={ '/' + categoryHierarchy.getIn([0, 'slug']) }>{ categoryHierarchy.getIn([0, 'name']) }</Link>
                { ' ' }<i className='fa fa-angle-double-right' aria-hidden='true'></i>{ ' ' }
                <Link to={ '/' + categoryHierarchy.getIn([1, 'slug']) }>{ categoryHierarchy.getIn([1, 'name']) }</Link>
              </div>
            }
            { renderRelated &&
              <h2 className='sr-only'>Alakategoriat</h2>
            }
            { renderRelated &&
              relatedCategoryItems.map((catItem) => (
                <Switch key={ catItem.get('id') } tag={ catItem } />
              ))
            }
          </div>
        }
        { renderMunicipalitySearch &&
          <MunicipalitySearchBox
            dispatch={ dispatch }
            title='Etsi kuntasi uutiset'
            placeholder='Syötä kunta'
            className='search-province-news'
            data={ municipalities }
          />
        }
      </div>
    )
  }

}
