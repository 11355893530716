import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import moment from 'moment-timezone'
import classNames from 'classnames'
import Carousel from '../../components/carousel/Carousel'
import wind from './icons/wind-arrow.svg'
import drop from './icons/drop.svg'
import rain from './icons/rain.svg'
import Star from './icons/star'

const CurrentWeather = ({ forecasts, location, onFavoriteClick, isFavorite, locationId, loggedIn }) => {
  const dates = []

  return (
    <div className='current-weather-wrapper'>
      <div className='current-weather'>
        <div className='current-weather__location-container'>
          <span className='current-weather__location'>{ location }</span>
          <button
            type='button'
            className='current-weather__favorite'
            onClick={ onFavoriteClick }
            data-location-id={ locationId }
            aria-label='Lisää suosikkeihin'
          >
            <Star
              className={ classNames('current-weather__favorite-icon', { 'active': isFavorite && loggedIn } ) }
            />
          </button>
        </div>
        <div className='current-weather__details-wrapper'>

          <div className='current-weather__details'>
            <div className='current-weather__temperature-container'>
              <span className='current-weather__temperature'>
                { Math.round(forecasts.getIn([0, 'temperature'])) + String.fromCharCode(176) }
              </span>
              <span className='current-weather__temperature-feelslike'>
                { 'Tuntuu kuin ' +
                Math.round(forecasts.getIn([0, 'feelsLike'])) +
                String.fromCharCode(176) +
                'C' }
              </span>
            </div>
            <div className='current-weather__symbol weather-symbol'>
              <span className={ classNames(forecasts.getIn([0, 'symbol'])) } />
            </div>
          </div>

          <div className='current-weather__additional-info'>
            <div className='current-weather__wind weather-wind'>
              <span
                className={ classNames('weather-wind-direction', forecasts.getIn([0, 'windDirection'])) }
                aria-hidden='true'
              >
                <img alt='' src={ wind } />
              </span>
              <span className='weather-value'>{ Math.round(forecasts.getIn([0, 'windSpeed'])) + ' m/s' }</span>
            </div>
            <div className='current-weather__precipitation'>
              <span className='weather-lighter weather-precipitation-amount'>
                <img alt='' src={ drop } />
                <span className='weather-value'>{ Math.round(forecasts.getIn([0, 'precipitation']) * 10) / 10 + ' mm' }</span>
              </span>
            </div>
            <div className='current-weather__precipitation-probability'>
              <span className='weather-lighter weather-precipitation-probability-amount'>
                <img alt='' src={ rain } />
                <span className='weather-value'>{ Math.round(forecasts.getIn([0, 'precipitationProbability'])) + ' %' }</span>
              </span>
            </div>
          </div>

        </div>
      </div>

      <Carousel className='weather-hour-selector'>
        { forecasts.map((hourly) => {
          const time = moment(hourly.get('datetime'))
          if (time.hour() === 0) {
            dates.push(time.format('dd'))
          }
          const temperature = (hourly.get('temperature') > 0 ? '+' : '') + Math.floor(hourly.get('temperature'))
          const feelsLike = (hourly.get('feelsLike') > 0 ? '+' : '') + Math.floor(hourly.get('feelsLike'))

          return (
            <li className='weather-hour' key={ time.valueOf() }>
              <div className='weather-time'>
                <span className='weather-time-date'>
                  { dates.includes(time.format('dd')) ? `${time.format('dd')} ` : '' }
                </span>
                <time title={ time.format('dd D.M. H') } dateTime={ time.toISOString() }>
                  { time.format('HH') }
                </time>

              </div>
              <div className='weather-symbol'>
                <span className={ classNames(hourly.get('symbol')) } />
              </div>
              <div className='weather-temperature'>
                <span>{ temperature + String.fromCharCode(176) }</span>
                <span className='weather-lighter weather-temperature-feelslike'>
                  { feelsLike + String.fromCharCode(176) }
                </span>
              </div>
              <div className='weather-wind'>
                <span
                  className={ classNames(
                    'weather-wind-direction',
                    hourly.get('windDirection')
                  ) }
                  aria-hidden='true'
                >
                  <img alt='' src={ wind } width={ 12 } height={ 12 } />
                </span>
                <span className='weather-value'>{ Math.round(hourly.get('windSpeed')) }</span>
              </div>
              <div className='weather-precipitation'>
                <span className='weather-lighter weather-precipitation-amount'>
                  { Math.round(hourly.get('precipitation') * 10) / 10 + ' mm' }
                </span>
              </div>
              <div className='weather-precipitation-probability'>
                { Math.round(hourly.get('precipitationProbability')) + ' %' }
              </div>
            </li>
          )
        }) }
      </Carousel>

    </div>
  )
}

CurrentWeather.propTypes = exact({
  forecasts: ImmutablePropTypes.list.isRequired,
  location: PropTypes.string.isRequired,
  onFavoriteClick: PropTypes.func.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  locationId: PropTypes.number.isRequired,
  loggedIn: PropTypes.bool.isRequired,
})

export default CurrentWeather
