import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { itemShareIntent } from '../item/itemsActions'

export default class ShareButtonFacebook extends PureComponent {
  render() {
    const { link, facebookAppId } = this.props
    const href = 'https://www.facebook.com/dialog/share?app_id=' + facebookAppId + '&display=page&locale=fi_FI&href=' + link
    const title = 'Jaa uutinen Facebookissa'

    return (
      <a
        className='fa-stack share-facebook gtm-share'
        target='_blank'
        rel='noopener noreferrer'
        href={ href }
        title={ title }
        onClick={ this.handleClick }
      >
        <span className='sr-only'>{ title }</span>
        <span className='fa fa-circle fa-stack-2x gtm-share' aria-hidden='true' />
        <span className='fa fa-facebook fa-stack-1x gtm-share' aria-hidden='true' />
      </a>
    )
  }

  handleClick = () => {
    const { itemId, dispatch } = this.props
    dispatch(itemShareIntent(itemId, 'facebook'))
  }
}

ShareButtonFacebook.propTypes = exact({
  link: PropTypes.string.isRequired,
  facebookAppId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
})
