import React, {Component} from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'

const defaultErrors = {
  INVALID_EMAIL: 'Kirjoita sähköposti oikeassa muodossa',
  EMAIL_NOT_FOUND: 'Antamaasi sähköpostiosoitetta ei löytynyt',
  SUBMIT_ERROR: 'Tapahtui virhe. Yritä myöhemmin uudelleen.',
}

export default class UserEmailField extends Component {
  render() {
    const {
      onChange,
      onInput,
      onKeyUp,
      hasError,
      hasSuccess,
      errors,
      value,
    } = this.props

    return  (
      <React.Fragment>
        <label htmlFor='email'>Sähköposti</label>

        <div className='input-container'>
          <input
            type='email'
            id='email'
            name='email'
            onChange={ onChange }
            onInput={ onInput }
            onKeyUp={ onKeyUp }
            placeholder='matti.meikalainen@example.com'
            value={ value }
            className={ classnames({
              'has-error': hasError,
              'has-success': hasSuccess,
            }) }
          />

          { errors.map((error, key) => (
            <div className='input-error' key={ key }>{ this.getErrorMessage(error) }</div>
          )) }
        </div>
      </React.Fragment>
    )
  }

  getErrorMessage( key ) {
    const { errorMessageOverrides } = this.props
    return errorMessageOverrides && errorMessageOverrides[key] ? errorMessageOverrides[key] : defaultErrors[key]
  }
}

UserEmailField.propTypes = exact({
  onChange: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  hasSuccess: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  errorMessageOverrides: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string.isRequired,
})
