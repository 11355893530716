import Immutable from 'immutable'
import { itemIdsToItems } from '../components/item/itemsTransformers'
import { selectIsUserLoggedIn } from '../selectors/userSelector'

export default function groupedConnect(state, groupedItems, title, to) {

  const groups = groupedItems.get('groups').map(group => {
    const { itemIds, title } = group

    return {
      title,
      items: itemIdsToItems(state, itemIds),
    }
  })

  return {
    groups,
    loading: groupedItems.get('loading'),
    timestamp: state.time.get('timestamp'),
    activePage: Immutable.Map({
      title: title,
      to: to,
    }),
    shouldRefresh: groupedItems.get('shouldRefresh'),
    loggedIn: selectIsUserLoggedIn(state),
  }
}
