import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import './searchStatusIndicator.pcss'

export default class SearchStatusIndicator extends PureComponent {
  render() {
    const {
      inProgress,
      errorText,
      resultCount,
      searchIsActive,
      onResultClick,
    } = this.props

    return (
      <span className='search-status-indicator'>
        { searchIsActive && !inProgress && !errorText && (
          <button type='button' className='search-status-indicator__count' onClick={ onResultClick }>
            { resultCount === 1 ? '1 tulos' : `${resultCount} tulosta` }
          </button>
        ) }

        { searchIsActive && !inProgress && errorText && (
          <span className='search-status-indicator__error'>{ errorText }</span>
        ) }

        { !errorText && inProgress && (
          <span className='search-status-indicator__spinner spin' aria-hidden='true'><i className='fa fa-spinner' aria-hidden='true' /></span>
        ) }
      </span>
    )
  }

}

SearchStatusIndicator.propTypes = exact({
  inProgress: PropTypes.bool.isRequired,
  errorText: PropTypes.string.isRequired,
  resultCount: PropTypes.number.isRequired,
  searchIsActive: PropTypes.bool.isRequired,
  onResultClick: PropTypes.func.isRequired,
})
