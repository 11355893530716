import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Component } from 'react'
import { Link } from 'react-router'

export default class SkipLink extends Component {
  render() {
    const {
      target,
      text,
    } = this.props

    return (
      <Link className='sr-only sr-only-focusable skip-link' href={ target }>{ text ? text : 'Siirry sisältöön' }</Link>
    )
  }

}

SkipLink.propTypes = exact({
  target: PropTypes.string.isRequired,
  text: PropTypes.string,
})
