import * as actions from './feedbackActions'
import Immutable from 'immutable'

const initialState = Immutable.Map({
  loading: false,
  error: false,
  sent: false,
})

function feedback(state = initialState, action) {
  switch (action.type) {
    case actions.SEND_FEEDBACK:
      return state.set('loading', true)

    case actions.SEND_FEEDBACK_SUCCESS:
      return state
        .set('loading', false)
        .set('sent', true)
        .set('error', false)

    case actions.SEND_FEEDBACK_FAILURE:
      return state
        .set('loading', false)
        .set('sent', false)
        .set('error', true)

    case actions.RESET_FEEDBACK:
      return state
        .set('loading', false)
        .set('sent', false)
        .set('error', false)

    default:
      return state
  }
}

export default feedback
