import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Link } from 'react-router'
import { displayNewUserModal } from '../components/modal/modalActions'
import '../styles/login-overlay.pcss'
import Loading from '../components/util/Loading'
import { logoutAmpparit } from './userActions'
import { logoutAlmaTunnus } from './almalogin/almaUserActions'

export default class LoginOverlay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logoutInProgress: false,
    }
  }


  render() {
    const { isOpen, emailVerified, loggedIn, email } = this.props
    const { logoutInProgress } = this.state

    return (
      <div className='login-overlay'>
        { isOpen &&
          <div className='login-overlay__outer-wrapper'>
            <button type='button' className='close-login-overlay' onClick={ this.handleOverlayToggle }>
              <span className='fa fa-times' />
              <span className='close-text'>Sulje</span>
            </button>
            <div className='login-overlay__inner-wrapper'>
              { (loggedIn || emailVerified) &&
                <div className='login-overlay__content'>
                  <div className='user-select-container'>
                    <h2>Käyttäjäprofiili</h2>
                    <div className='selected-profiles'>
                      { loggedIn || logoutInProgress ?
                        <div className='user-login-info'>
                          <div className='user-login-info-text'>Olet kirjautuneena Alma-tunnuksella:<br />
                            <span className='user-login-info-email'>{ email || 'Kirjataan ulos, sivu päivittyy . . .' }</span>
                          </div>
                          <Link className='button button_large' to='/asetukset/yleiset' onClick={ this.handleOverlayToggle }>
                            <span className='button-icon fa fa-user-circle'> </span>
                            <span className='button-text'>Asetukset</span>
                          </Link>
                        </div>
                        :
                        <div className='user-login-info'>
                          <div className='user-login-info-text error'>
                            Et ole kirjautuneena Ampparit-profiiliin!<br />
                            Luo profiili tai kirjaudu ulos.
                          </div>
                          <button type='button' className='button button_large' onClick={ this.handleOverlayToggle }>
                            <span className='button-icon fa fa-user-circle'> </span>
                            <span className='button-text'>Luo Ampparit-profiili</span>
                          </button>
                        </div>
                      }
                      <button type='button' className='button button_large' onClick={ this.handleLogout }>
                        <Loading loading={ logoutInProgress }>
                          <span className='button-icon fa fa-power-off'> </span>
                          <span className='button-text'>Kirjaudu ulos</span>
                        </Loading>
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    )
  }

  handleOverlayToggle = () => {
    const { loggedIn, dispatch } = this.props

    if (!loggedIn) {
      dispatch(displayNewUserModal('Ei löytynyt sähköpostia vastaavaa Ampparit-profiilia, tehdäänkö uusi?', 'Uusi Ampparit käyttäjä?'))
    }

    this.props.onToggle()
  }

  handleLogout = async () => {
    const { dispatch } = this.props
    this.setState({ logoutInProgress: true })
    await dispatch(logoutAmpparit())
    dispatch(logoutAlmaTunnus())
  }
}

LoginOverlay.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  emailVerified: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
})
