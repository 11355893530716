import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { Link } from 'react-router'
import ItemsEmpty from './ItemsEmpty'

export default class CategoryItemsEmpty extends Component {
  render() {
    const { category } = this.props

    return (
      <ItemsEmpty title='No mutta,'>
        <p>
          tällä hetkellä valitsemassasi kategoriassa <Link className='text-link' to={ '/' + category.get('slug') }>{ category.get('name') }</Link> ei ole juttuja saatavilla.<br />
          Kokeilethan muita kategorioita!
        </p>
      </ItemsEmpty>
    )
  }
}

CategoryItemsEmpty.propTypes = exact({
  category: ImmutablePropTypes.record.isRequired,
})
