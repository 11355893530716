import React from 'react'
import Immutable from 'immutable'
import Item from '../components/item/Item'

/**
 * Inject components into a list of Items.
 *
 * @example
 *   injectAdsToList(segmentedItems, [{
 *     component: (
 *        <MobileLeaderboardAd path={ path } tag={ tag } category={ category }
 *           counter={ 0 } lazyLoad={ false }
 *        />
 *     ),
 *     index: 4
 *   }]
 *
 * @param {Immutable.List} list
 * @param {Object[]} ads Array of ad objects with component and index properties
 * @returns {Immutable.List}
 */
export default function injectAdsToList(list, ads) {
  list = Immutable.List(list)

  if (list.isEmpty()) {
    return list
  }

  ads.forEach(({component, index}) => {
    if (!component) return

    const correctIndex = findCorrectIndex(list, index)

    list = list.insert(correctIndex, React.cloneElement(component, {
      key: 'ad-' + index,
    }))
  })

  return list
}

/**
 * Finds the actual index for component injection. The positioning is done based on Items,
 * but since the list contains other types of components as well, the index needs to be adjusted.
 *
 * @param {Immutable.List} list
 * @param {number} index The amount of Items before the injected component
 * @returns {number} The actual index used for the component injection
 */
function findCorrectIndex(list, index) {

  let itemCount = 0

  let correctIndex = list.takeUntil(item => {
    if (item.type === Item) {
      itemCount++
    }
    return itemCount === index
  }).count()

  // Adding one to the index as the component should appear _after_ the configured amount of Items
  correctIndex++

  return correctIndex
}
