import React from 'react'
import Immutable from 'immutable'
import moment from 'moment-timezone'
import TimeLabel from '../components/item/TimeLabel'

moment.updateLocale('fi', {
  relativeTime: {
    future: '%s kuluttua',
    past: '%s',
    s:  'nyt',
    m:  '1 min',
    mm: '%d min',
    h:  '1 tunti',
    hh: '%d tuntia',
    d:  '1 vrk',
    dd: '%d vrk',
  },
})

const timeSteps = [
  moment.duration(5, 'minutes'),
  moment.duration(10, 'minutes'),
  moment.duration(15, 'minutes'),
  moment.duration(30, 'minutes'),
  moment.duration(1, 'hour'),
  moment.duration(2, 'hours'),
  moment.duration(4, 'hours'),
  moment.duration(8, 'hours'),
  moment.duration(12, 'hours'),
  moment.duration(24, 'hours'),
  moment.duration(2, 'days'),
]

/**
 * Creates a list of Item components and injects time labels into it based on how
 * long ago they were published.
 *
 * @param {Immutable.OrderedSet} items Set of items
 * @param {number} timestamp Current time
 * @param {function} createItem Function to create a news item
 * @returns {Immutable.List}
 */
export default function createItemsWithLabels (items, timestamp, createItem) {
  const now = moment(timestamp)
  let list = Immutable.List()
  let steps = Immutable.List(timeSteps)
  let i = 0

  items.forEach(item => {
    const time = moment(item.get('timestamp') * 1000)
    let visible = false
    let labelText = ''

    // Matches the item's timestamp to the time steps and returns the index of the last passing match
    // or -1 if no match is found (ie. the time label has already been added)
    const index = steps.findLastIndex(step => {
      return time.isBefore(moment(now).subtract(step))
    })

    if (index > -1) {
      visible = true
      labelText = steps.get(index).humanize()
      steps = steps.slice(index + 1)
    }

    list = list.push(
      React.createElement(TimeLabel, {
        labelText: labelText,
        key: 'label_' + i,
        visible: visible,
      })
    )

    list = list.push(createItem(item))
    i++
  })

  return list
}
