/* global __REDUX_DEVTOOLS_EXTENSION__ */
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { thunk } from 'redux-thunk'
import { routerMiddleware, routerReducer } from 'react-router-redux'
import transit from 'transit-immutable-js'
import * as reducers from './reducers'
import records from './records'
import { getBeaconMiddleware } from './middleware/GTM'
import config from '../config/config'
import { canUseDOM } from 'exenv'

export default function createReduxStore(stateJSON, history) {

  const initialState = transit.withRecords(records).fromJSON(stateJSON)

  const reducer = combineReducers({...reducers, routing: routerReducer})

  const middleWares = []
  if (canUseDOM) {
    middleWares.push(applyMiddleware(getBeaconMiddleware(), thunk))
  }
  middleWares.push(applyMiddleware(routerMiddleware(history), thunk))
  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function' && config.webpack.enableReduxDevtools) {
    middleWares.push(__REDUX_DEVTOOLS_EXTENSION__())
  }

  return createStore(reducer, initialState, compose(...middleWares))
}
