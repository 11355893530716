
import { getWeather } from '../../api/api'
import { logException } from '../../sentry'

export const FETCH_WEATHER = 'FETCH_WEATHER'
export const FETCH_WEATHER_FAILURE = 'FETCH_WEATHER_FAILURE'
export const FETCH_WEATHER_SUCCESS = 'FETCH_WEATHER_SUCCESS'
export const FETCH_WEATHER_BY_COORDINATES = 'FETCH_WEATHER_BY_COORDINATES'
export const FETCH_WEATHER_BY_COORDINATES_FAILURE = 'FETCH_WEATHER_BY_COORDINATES_FAILURE'
export const FETCH_WEATHER_BY_COORDINATES_SUCCESS = 'FETCH_WEATHER_BY_COORDINATES_SUCCESS'
export const SET_SELECTED_WEATHER = 'SET_SELECTED_WEATHER'

/**
 * Fetch weather
 * @param weatherLocation This can be ID or Slug
 */
export function fetchWeather(weatherLocation) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_WEATHER,
    })

    const params = isNaN(weatherLocation) ? { slug: weatherLocation } : { id: weatherLocation }

    return getWeather(params).then(response => {
      if (response.data.length <= 0) {
        throw new Error('Invalid weather data length')
      }
      dispatch({
        type: FETCH_WEATHER_SUCCESS,
        response,
        timestamp: Date.now(),
      })
    }).catch(error => {
      // 404s are normal when searching by slug (url /saa/whatever), so ignore them
      if (!(isNaN(weatherLocation) && error.response && error.response.status === 404)) {
        logException(error)
      }
      dispatch({
        type: FETCH_WEATHER_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}

export function fetchWeatherByCoordinates(coords) {
  return dispatch => {
    dispatch({
      type: FETCH_WEATHER_BY_COORDINATES,
    })

    return getWeather(coords).then(response => {
      if (response.data.length <= 0) {
        throw new Error('Invalid weather data length')
      }
      dispatch({
        type: FETCH_WEATHER_BY_COORDINATES_SUCCESS,
        response,
        timestamp: Date.now(),
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: FETCH_WEATHER_BY_COORDINATES_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}


export function setSelectedWeather(locationId) {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_WEATHER,
      locationId,
    })
  }
}
