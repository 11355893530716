import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { provideHooks } from 'redial'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

// import {
//   fetchTvPrograms,
//   setChannelFilter,
//   setDateFilter,
//   setProgramTimeFilter,
//   setProgramTypeFilter,
//   setTextFilter,
// } from './tvActions'
// import { setStatus } from '../../status/statusActions'
// import {
//   getChannelFilter,
//   getProgramTimeFilter,
//   getProgramTypeFilter,
//   getTextFilter,
//   getTVFiltersTodayByProgram,
//   translateChannelFilter,
//   translateProgramTimeFilter,
//   translateProgramTypeFilter,
//   TV_FILTER_CHANNEL_ALL,
//   TV_FILTER_PROGRAM_TIME_COMING,
//   TV_FILTER_PROGRAM_TIME_NOW,
//   TV_FILTER_PROGRAM_TIME_ALL,
// } from './filters'
// import TvControls from './TvControls'
// import TelevisionProgramming from './TelevisionProgramming'

import './television.pcss'
import { selectIsUserLoggedIn } from '../../selectors/userSelector'

import MainContent from '../../components/wrappers/MainContent'
import GeneralSidebar from '../../components/layout/GeneralSidebar'
import { createUrl } from '../../lib/utils'
import shareImg from '../../assets/ampparit-share-tv.jpg'
import { selectCategoryBySlug } from '../../selectors/categoriesSelector'
import { fetchPopular } from '../../components/item/popularActions'
import TopNotification from '../../user/TopNotification'
import TELKKU_MOB from '../../assets/telkku-mobi.png'
import TELKKU_DESK from '../../assets/telkku-desk.png'

@provideHooks({
  fetch: ({ dispatch }) => {
    const promises = []

    promises.push(dispatch(fetchPopular(300, { categoryId: 99 })))
    return Promise.all(promises)  },
})

@connect((state) => {
  return {
    loggedIn: selectIsUserLoggedIn(state),
    category: selectCategoryBySlug(state, 'televisio'),
  }
})

export default class TelevisionView extends Component {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    loggedIn: PropTypes.bool.isRequired,
    category: ImmutablePropTypes.record,
  }

  render() {
    const {
      loggedIn,
      category,
    } = this.props

    return (
      <div className='television container' id='sticky-segment-ad-bottom-boundary'>
        <div className='main-container'>
          <MainContent>
            <Helmet
              title='TV-ohjelmat - TV-opas'
              meta={ [
                { name: 'description', content: 'Amppareiden TV-opas ja Telkku ovat yhdistyneet' },
                { property: 'og:title', content: 'TV-opas \u2013 Ampparit.com' },
                { property: 'og:image', content: createUrl(shareImg) },
                { property: 'og:description', content: 'Amppareiden TV-opas ja Telkku ovat yhdistyneet' },
              ] }
            />

            <TopNotification className='television tv-notification'>
              <p className='tv-notification__title'>Amppareiden TV-opas ja Telkku ovat yhdistyneet</p>
              <p className='tv-notification__text-section'>
                Ampparit ja Telkku yhdistivät voimansa, ja Amppareiden tv-opas yhdistyi Telkun kanssa.
              </p>
              <p className='tv-notification__text-section'>
                Vastedes Amppareilla ei ole omaa tv-opasta, vaan ohjelmatiedot voi jatkossa lukea Telkusta.
              </p>
              <p className='tv-notification__text-section'>
                Telkusta löydät kattavien ohjelmatietojen lisäksi tv-ohjelmiin liittyvät artikkelit.
              </p>
              <p className='tv-notification__text-section'>
                Kirjautumalla Telkkuun voit myös tallentaa omat suosikkisi muistiin ja luoda oman kanavanäkymän.&nbsp;
                { loggedIn && (
                  <span>Telkussa toimii sama Alma-tunnus kuin Amppareissakin.</span>
                ) }
              </p>

              <div className='tv-notification__image-container'>
                <img src={ TELKKU_DESK } alt='Telkku työpöytä näkymä' className='tv-notification__image-container-image' />
                <img src={ TELKKU_MOB } alt='Telkku mobiili näkymä' className='tv-notification__image-container-image' />
              </div>

              <a
                className='tv-notification__link button'
                href='https://www.iltalehti.fi/telkku/tv-ohjelmat/tanaan?utm_source=internal&utm_medium=banner&utm_campaign=ampparit'
                target='_blank'
                rel='noopener noreferrer'
              >
                Siirry Telkkuun tästä
              </a>
            </TopNotification>

          </MainContent>

          <div className='sidebar-container'>
            <GeneralSidebar category={ category } />
          </div>
        </div>
      </div>
    )
  }
}
