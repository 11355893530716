import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import Dropdown from 'react-dropdown'
import { setActivePresetProfile } from '../../user/userActions'
import './quickfilter.pcss'
import { loginAlmaTunnus } from '../../user/almalogin/almaUserActions'

export default class Quickfilter extends PureComponent {
  render() {
    const {
      activePresetProfileId,
      loggedIn,
    } = this.props

    const filterOptions = [
      {className: 'icon eye',           label: 'Näytä kaikki', value: 'all'},
      {className: 'icon news',          label: 'Uutiset',      value: 1},
      {className: 'icon sports',        label: 'Urheilu',      value: 5},
      {className: 'icon entertainment', label: 'Viihde',       value: 6},
    ]
    if (!loggedIn) {
      filterOptions.push({className: 'login', label: 'Kirjaudu tehdäksesi yhdistelmäsuodatuksia', value: 'login'})
    }

    const selectedOption = filterOptions.find(opt => opt.value === activePresetProfileId)

    return (
      <Dropdown
        className={ `${loggedIn ? 'logged-in' : ''}` }
        options={ filterOptions }
        onChange={ this.handleFilterDropdownChange }
        placeholderClassName={ selectedOption?.className  }
        placeholder='Pikasuodatus'
        value={ selectedOption }
      />
    )
  }

  handleFilterDropdownChange = ({value}) => {
    const { dispatch } = this.props
    const id = typeof value === 'number' ? value : null
    dispatch(setActivePresetProfile(id))
    if (value === 'login') {
      dispatch(loginAlmaTunnus())
    }
  }
}

Quickfilter.propTypes = exact({
  loggedIn: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  activePresetProfileId: PropTypes.number,
})
