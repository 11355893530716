import Immutable from 'immutable'

export function categoriesToCategoryItems(categories, userCategories) {
  return categories.map(category => categoryToCategoryItem(category, userCategories))
}

export function menuItemToCategoryItem(menuItem, userCategories) {
  return categoryToCategoryItem(Immutable.Map({
    id: menuItem.get('categoryId'),
    name: menuItem.get('menuTitle'),
    slug: menuItem.get('to').substring(1),
  }), userCategories)
}

export function categoryToCategoryItem(category, userCategories) {
  if (!category) return null

  return Immutable.Map({
    id: category.get('id'),
    name: category.get('name'),
    slug: category.get('slug'),
    whitelisted: userCategories.get('whitelisted').contains(category.get('id')),
    blacklisted: userCategories.get('blacklisted').contains(category.get('id')),
    type: 'category',
  })
}

