import { getTrending } from '../../api/api'
import { logException } from '../../sentry'
import { isTimestampFresh } from '../../lib/utils'

export const FETCH_TRENDING = 'FETCH_TRENDING'
export const FETCH_TRENDING_FAILURE = 'FETCH_TRENDING_FAILURE'
export const FETCH_TRENDING_SUCCESS = 'FETCH_TRENDING_SUCCESS'


export function fetchTrending(maxStaleSecs = 0) {
  return (dispatch, getState) => {
    const state = getState()
    const lastFetchTimestamp = state.trending.get('timestamp')
    const loading = state.trending.get('loading')
    if (loading || isTimestampFresh(lastFetchTimestamp, maxStaleSecs)) {
      return
    }

    dispatch({
      type: FETCH_TRENDING,
    })

    return getTrending().then(response => {
      dispatch({
        type: FETCH_TRENDING_SUCCESS,
        response,
        timestamp: Date.now(),
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: FETCH_TRENDING_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}
