import { getLogo } from '../../api/api'
import { logException } from '../../sentry'
import { isTimestampFresh } from '../../lib/utils'

export const FETCH_LOGO = 'FETCH_LOGO'
export const FETCH_LOGO_SUCCESS = 'FETCH_LOGO_SUCCESS'
export const FETCH_LOGO_FAILURE = 'FETCH_LOGO_FAILURE'

export function fetchBrandLogo(maxStaleSecs = 0) {
  return (dispatch, getState) => {
    const state = getState()
    const lastFetchTimestamp = state.brandLogo.get('timestamp')
    const loading = state.brandLogo.get('loading')
    if (loading || isTimestampFresh(lastFetchTimestamp, maxStaleSecs)) {
      return
    }

    dispatch({
      type: FETCH_LOGO,
    })

    return getLogo().then(response => {
      dispatch({
        type: FETCH_LOGO_SUCCESS,
        response,
        timestamp: Date.now(),
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: FETCH_LOGO_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}
