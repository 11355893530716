import * as actions from './sourcesActions'
import Immutable from 'immutable'

export const SourceRecord = Immutable.Record({
  id: null,
  name: null,
  link: null,
  language: null,
  hasPaywall: false,
}, 'source')

const initialState = Immutable.Map({
  loading: false,
  failedToLoad: false,
  sources: Immutable.List(),
})

function sources(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_SOURCES:
      return state
        .set('loading', true)
        .set('failedToLoad', false)

    case actions.FETCH_SOURCES_SUCCESS:
      return state
        .set('loading', false)
        .set('sources', Immutable.List(
          action.response.data.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1 }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1 }
            return 0
          }).map(source => {
            const { id, name, link, language, hasPaywall } = source
            return SourceRecord({ id, name, language, link, hasPaywall })
          })
        ))

    case actions.FETCH_SOURCES_FAILURE:
      return state
        .set('loading', false)
        .set('failedToLoad', true)
        .set('sources', Immutable.List())

    default:
      return state
  }
}

export default sources
