import React, { useState } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import classnames from 'classnames'
import SwitchToggle from './SwitchToggle'
import WhitelistToggle from './WhitelistToggle'
import BlacklistToggle from './BlacklistToggle'
import '../../styles/category-switch.pcss'
import {
  blacklistTag,
  unblacklistTag,
  unwhitelistTag,
  whitelistTag,
  blacklistCategory,
  unblacklistCategory,
  unwhitelistCategory,
  whitelistCategory,
} from '../../user/userActions'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { useClickAway } from '../../lib/utils'

const Switch = ({
  tag,
  onHandleLinkClick,
  slug,
  whitelistCategory,
  unwhitelistCategory,
  blacklistCategory,
  unblacklistCategory,
  whitelistTag,
  unwhitelistTag,
  blacklistTag,
  unblacklistTag,
}) => {
  const [showMenu, setShowMenu] = useState(false)
  const ref = React.useRef(null)

  useClickAway(ref, () => {
    if (showMenu) {
      setShowMenu(false)
    }
  })

  const closeMenu = () => {
    setShowMenu(false)
  }

  const isCategory = () => {
    return tag.get('type') === 'category'
  }

  const getName = () => {
    const name = tag.get('name')
    return isCategory() ? name : '#' + name
  }

  const getLink = () => {
    const slug = tag.get('slug')
    return isCategory() ? `/${slug}` : `/t/${slug}`
  }

  const handleToggleClick = (event) => {
    event.stopPropagation()
    setShowMenu(!showMenu)
  }

  const handleLinkClick = () => {
    if (onHandleLinkClick) {
      onHandleLinkClick(`/t/${slug}`)
    }
  }

  const handleWhitelist = () => {
    if (isCategory()) {
      whitelistCategory(tag.get('id'))
    } else {
      whitelistTag(tag.get('id'))
    }
    closeMenu()
  }

  const handleUnwhitelist = () => {
    if (isCategory()) {
      unwhitelistCategory(tag.get('id'))
    } else {
      unwhitelistTag(tag.get('id'))
    }
    closeMenu()
  }

  const handleBlacklist = () => {
    if (isCategory()) {
      blacklistCategory(tag.get('id'))
    } else {
      blacklistTag(tag.get('id'))
    }
    closeMenu()
  }

  const handleUnBlacklist = () => {
    if (isCategory()) {
      unblacklistCategory(tag.get('id'))
    } else {
      unblacklistTag(tag.get('id'))
    }
    closeMenu()
  }
  const name = getName()
  const isWhitelisted = tag.get('whitelisted')
  const isBlacklisted = tag.get('blacklisted')

  return (
    <span
      className={ classnames('category-switch', {
        'category-switch_whitelisted': isWhitelisted,
        'category-switch_blacklisted': isBlacklisted,
      }) }
      ref={ ref }
    >
      <Link className='category-switch__label gtm-tag-switch-label' to={ getLink() } onClick={ handleLinkClick }>
        { name }
      </Link>
      <SwitchToggle
        onClick={ handleToggleClick }
        isWhitelisted={ isWhitelisted }
        isBlacklisted={ isBlacklisted }
        name={ name }
      />

      { showMenu ?
        <div className='category-switch__menu-container'>
          <WhitelistToggle
            isWhitelisted={ isWhitelisted }
            onWhitelist={ handleWhitelist }
            onUnwhitelist={ handleUnwhitelist }
            name={ name }
          />
          <BlacklistToggle
            isBlacklisted={ isBlacklisted }
            onBlacklist={ handleBlacklist }
            onUnblacklist={ handleUnBlacklist }
            name={ name }
          />
        </div> : null
      }
    </span>
  )
}

// Map redux actions to props
const actions = {
  blacklistTag,
  unblacklistTag,
  unwhitelistTag,
  whitelistTag,
  blacklistCategory,
  unblacklistCategory,
  unwhitelistCategory,
  whitelistCategory,
}

export default connect(null, actions)(Switch)

Switch.propTypes = exact({
  tag: ImmutablePropTypes.mapContains({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    blacklisted: PropTypes.bool.isRequired,
    whitelisted: PropTypes.bool.isRequired,
  }),
  onHandleLinkClick: PropTypes.func,
  blacklistCategory: PropTypes.func.isRequired,
  blacklistTag: PropTypes.func.isRequired,
  unblacklistCategory: PropTypes.func.isRequired,
  unblacklistTag: PropTypes.func.isRequired,
  whitelistCategory: PropTypes.func.isRequired,
  whitelistTag: PropTypes.func.isRequired,
  unwhitelistCategory: PropTypes.func.isRequired,
  unwhitelistTag: PropTypes.func.isRequired,
  slug: PropTypes.string,
})
