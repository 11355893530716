import { canUseDOM } from 'exenv'
import config from '../config/config'
import { selectUserLoginStatusADL, selectIsUserLoggedIn, selectUserHash, selectUserWhitelistedCategories, selectUserAlmaIdHash } from './selectors/userSelector'
import { selectCategoryById, selectCategoryBySlug } from './selectors/categoriesSelector'

function getPageType(pageType, primaryCategory) {
  if (pageType === 'main' && primaryCategory === 'etusivu') {
    return 'frontpage'
  } else if (pageType === 'category' && primaryCategory === 'paikallisuutiset') {
    return 'otherpage'
  } else if (pageType === 'category' || pageType === 'otherpage') {
    return 'sectionfrontpage'
  } else if (pageType === 'main' && primaryCategory === 'personal') {
    return 'processpage'
  } else if (pageType === 'error') {
    return 'errorpage'
  } return 'otherpage'
}


/**
 * Temporary hotfix for making sure client data is up to date as quickly as possible
 */
export function updateDigitalDataClientSideAttributes() {
  if (canUseDOM && window.digitalData && window.digitalData.page) {
    window.digitalData.page.pageInfo.pageTitle = window.document.title
    window.digitalData.page.pageInfo.locationProtocol = window.document.location.protocol.slice(0, -1)
    window.digitalData.page.pageInfo.locationHost = window.document.location.host
    window.digitalData.page.pageInfo.locationPathName = window.document.location.pathname
    window.digitalData.page.attributes.userAgentHeader = window.navigator.userAgent
    window.digitalData.page.attributes.width = window.innerWidth
    if (window.innerWidth > 1024) {
      window.digitalData.page.attributes.viewport = 'desktop'
    } else if (window.innerWidth >= 980) {
      window.digitalData.page.attributes.viewport = 'tablet'
    } else {
      window.digitalData.page.attributes.viewport = 'mobile'
    }
  }
}


export function generateDigitalData(state) {
  try {
    const pageId = state.status.get('path') === '/' ? 'etusivu' : state.status.get('path').split('/').join('-').substring(1)
    const environment = config.siteUrl === 'https://www.ampparit.com' ? 'prod' : 'dev'
    const pageTitle = canUseDOM && window.document.title
    const pageType = state.items.activeList.get('listType')
    const listName = state.items.activeList.get('listName')

    const category = ['category', 'sectionfrontpage'].includes(pageType) ? selectCategoryBySlug(state, listName) : null
    const categoryParent = category != null ? selectCategoryById(state, category.get('parentId')) : null

    const primaryCategory = categoryParent != null ? categoryParent.get('name') : pageId
    const subCategory1 = categoryParent != null ? category.get('name') : ''

    const relatedTags = state.status.get('path').startsWith('/t/') ?  [listName] : []

    let locationProtocol = ''
    let locationHost = ''
    let locationPathName = ''
    let width = ''
    let userAgentHeader = ''
    let viewport

    if (canUseDOM) {
      locationProtocol = window.document.location.protocol.slice(0, -1)
      locationHost = window.document.location.host
      locationPathName = window.document.location.pathname
      userAgentHeader = window.navigator.userAgent
      width = window.innerWidth
      if (window.innerWidth > 1024) {
        viewport = 'desktop'
      } else if (window.innerWidth >= 980) {
        viewport = 'tablet'
      } else {
        viewport = 'mobile'
      }
    }

    const loginStatus = selectUserLoginStatusADL(state)
    const userHash = selectUserHash(state)
    const almaIdHash = selectUserAlmaIdHash(state)
    const accountType = selectIsUserLoggedIn(state) ? 'private' : null

    const followedTopics = selectUserWhitelistedCategories(state).map(category => category.name).toJS()

    const searchString = state.search.get('searchString')
    const searchCount = state.search.get('totalCount')

    const digitalData = {
      pageInstanceID: `${pageId}_amp_${environment}`,
      version: {
        almaVersion: '2.3',
        specificationVersion: '1.0',
      },
      page: {
        pageInfo: {
          pageID: pageId,
          sysEnv: 'responsive',
          pageTitle,
          language: 'fi-FI',
          geoRegion: 'FI',
          publisher: 'Ampparit',
          locationProtocol,
          locationHost,
          locationPathName,
        },
        category: {
          primaryCategory,
          subCategory1,
          pageType: getPageType(pageType, primaryCategory),
          tags: relatedTags,
        },
        attributes: {
          environment,
          viewport: 'desktop', // @todo set serverside default based on user-agent
          width,
          userAgentHeader,
          contentCharge: 'free',

        },
      },
      user: [
        {
          segment: {
            loginstatus: loginStatus,
          },
          profile: [
            {
              profileInfo: {
                cdime: userHash,
                followedTopics,
                almaIDHash: almaIdHash,
                accountType: accountType,
              },
            },
          ],
        },
      ],
    }

    if (searchCount) {
      digitalData.page.pageInfo.onsiteSearchTerm = searchString
      digitalData.page.pageInfo.onsiteSearchResult = searchCount
    }

    if (viewport) {
      digitalData.page.attributes.viewport = viewport
    }

    return digitalData
  } catch (error) {
    console.error('digitalData error\n', error)
    const digitalData = {
      pageInstanceID: 'amp_error',
      version: {
        almaVersion: '2.3',
        specificationVersion: '1.0',
      },
      page: {
        pageInfo: {
          pageID: 'digitaldataerror',
          language: 'fi-FI',
          geoRegion: 'FI',
          publisher: 'Ampparit',
        },
        category: {
          primaryCategory: 'digitaldataerror',
          pageType: 'errorpage',
        },
      },
    }
    return digitalData
  }
}

export function sendDataLayer() {
  const timeout = 200

  if (
    window.ALMA &&
    window.ALMA.sammioDatalayerSenderReady === true &&
    typeof window.digitalData === 'object'
  ) {
    window.document.dispatchEvent(
      new window.CustomEvent('sammio-datalayer-send', {
        detail: {
          site: 'amp',
          subsite: 'www',
          dataLayer: window.digitalData,
        },
      })
    )
    window.document.dispatchEvent(
      new window.CustomEvent('dataLayerReady', {
        detail: {
          dataLayer: window.digitalData,
        },
      })
    )
  } else {
    window.setTimeout(sendDataLayer, timeout)
  }
}
