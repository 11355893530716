import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import namedays from '../../static/namedays'
import moment from 'moment-timezone'

export default class SidebarNamedays extends PureComponent {
  render() {
    const { timestamp } = this.props

    return (
      <section id='namedays' className='sidebox'>
        <h3 className='sidebox-header'><span>Nimipäivät</span><span>{ moment(timestamp).format('D.M.') }</span></h3>
        <div className='sidebox-content'>
          { namedays.get(moment(timestamp).format('DDMM')) }
        </div>
      </section>
    )
  }
}

SidebarNamedays.propTypes = exact({
  timestamp: PropTypes.number.isRequired,
})
