import Immutable from 'immutable'

/**
 * Splits items into segments based on item ids.
 * This is used with the load more feature; freshly loaded items are added into their
 * own segment for advertisement purposes.
 *
 * @param {List} items List of items to split
 * @param {List} segments List of cut-off item ids
 * @returns {List} List of items split into segments
 */
export default function splitItemsToSegments(items, segments) {
  let segmentedItems = Immutable.List()

  segments.forEach(segment => {
    const index = items.findIndex(element => {
      if (element.props.item === undefined) return false

      return element.props.item.get('id') === segment
    }) + 1

    segmentedItems = segmentedItems.push(items.take(index))

    items = items.slice(index)
  })

  if (!items.isEmpty()) {
    segmentedItems = segmentedItems.push(items)
  }

  return segmentedItems
}
