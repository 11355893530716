import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { selectIsUserLoggedIn } from '../../selectors/userSelector'
import Carousel from '../../components/carousel/Carousel'
import GeneralSidebar from '../../components/layout/GeneralSidebar'
import MainContent from '../../components/wrappers/MainContent'
import '../user.pcss'

const userPages = [
  { to: '/asetukset/yleiset', title: 'Yleiset' },
  { to: '/asetukset/kategoriat', title: 'Kategoriat' },
  { to: '/asetukset/lahteet', title: 'Lähteet' },
  { to: '/asetukset/aihetunnisteet', title: 'Pinnalla' },
  { to: '/asetukset/suodatus', title: 'Suodatus' },
  { to: '/asetukset/omat-syotteet', title: 'Omat syötteet' },
]

@connect((state) => {
  return {
    isUserLoggedIn: selectIsUserLoggedIn(state),
  }
})

export default class SettingsView extends Component {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    children: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
  }

  render() {
    const {
      children,
      path,
      isUserLoggedIn,
    } = this.props

    const activeTabIndex = userPages.indexOf(userPages.find(page => page.to === path))

    if (!isUserLoggedIn || activeTabIndex < 0) {
      return null
    }

    return (
      <div className='main-container container' id='sticky-ad-bottom-boundary'>
        <MainContent>
          <div className='user-management'>

            <h2>Muokkaa Ampparit-profiilia</h2>
            <div className='navigation settings-nav'>
              <nav aria-label='Asetussivut'>
                <Carousel
                  activeIndex={ activeTabIndex }
                  className='settings-tabs'
                >
                  { userPages.map(page => (
                    <li className='settings-tabs__list-item' key={ page.to }>
                      <Link to={ page.to } aria-current={ page.to === path ? 'page' : 'false' }>
                        { page.title }
                      </Link>
                    </li>
                  )) }
                </Carousel>
              </nav>
            </div>

            { React.cloneElement(children) }
          </div>
        </MainContent>
        <div className='hide-on-sm'>
          <div className='sidebar-container'>
            <GeneralSidebar />
          </div>
        </div>

      </div>
    )
  }
}
