/**
 * Current values for 'deviceType' are 'ios', 'android' and 'desktop'.
 * These match and must continue to match the possible values of
 * ticker device types.
 * @returns {string} 'ios', 'android' or 'desktop'.
 */
export const selectDeviceType = state => state.status.get('deviceType')


export const selectLocationPath = state => state.status.get('path')
export const selectLocationStatus = state => state.status.get('status')
