import React, { Component } from 'react'
import { toggleOverlay } from '../../user/userActions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { SVG_ICON_LOGIN } from '../util/svgIcons'
import './menuProfile.pcss'
import { loginAlmaTunnus } from '../../user/almalogin/almaUserActions'

@connect(({ user }) => {
  return {
    isOpen: user.get('overlayIsOpen'),
  }
})

class MenuProfile extends Component {

  constructor(props) {
    super(props)

    this.state = {
      glowCount: 0,
    }
  }

  static propTypes = exact({
    isOpen: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    userName: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  })

  componentDidMount() {

    const logoElement = window.document.querySelector('.menu-profile__button-text_logged-out')

    if (logoElement) {
      // Set up an interval to run the glow animation every 10 seconds
      this.glowInterval = setInterval(() => {

        const { glowCount } = this.state

        if (glowCount < 3) {
          logoElement.classList.add('animated')
          this.setState({ glowCount: glowCount + 1 })

          // Remove the 'animated' class after 2 seconds
          setTimeout(() => {
            logoElement.classList.remove('animated')
          }, 2000)
        } else {
          clearInterval(this.glowInterval)
        }
      }, 10000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.glowInterval)
  }

  render() {
    const { isOpen, loggedIn, userName  } = this.props

    const label = loggedIn ? 'Omat asetukset' : 'Kirjautuminen'

    return (
      <button
        type='button'
        onClick={ this.handleMenuToggle }
        className={ classNames('menu-profile', { active: isOpen }) }
        aria-label={ label }
        title={ label }
      >
        { loggedIn ? (
          <span className='menu-profile__button'>
            <span className='menu-profile__button-text menu-profile__button-text_logged-in' aria-hidden='true'>{ userName }</span>
          </span>
        ) : (
          <span className='menu-profile__button-text menu-profile__button-text_logged-out'>
            <svg className='menu-profile__button-text_logged-out-logo'>
              <use href={ `#${SVG_ICON_LOGIN}` } />
            </svg>
          </span>
        ) }
      </button>

    )
  }

  handleMenuToggle = (e) => {
    const { dispatch, loggedIn, isOpen } = this.props

    if (loggedIn) {
      dispatch(toggleOverlay(!isOpen))
    } else {
      dispatch(loginAlmaTunnus())
    }
  }
}

export default MenuProfile
