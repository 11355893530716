import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import MainContent from '../../components/wrappers/MainContent'
import FeedbackForm from './FeedbackForm'
import './infoViews.pcss'

export default class FeedbackView extends Component {
  render() {
    return (
      <MainContent>
        <article className='info-page'>
          <Helmet title='Palaute' />

          <h1>Palaute</h1>
          <p>
            Alla olevalla lomakkeella saa yhteyden meihin.
          </p>
          <p>
            Palautteen voi antaa ilman sähköposti&shy;osoitetta. Kirjoita sähköpostiosoitteesi, mikäli haluat vastauksen.<br />
            <b>Emme luovuta sähköpostiosoitteita ulkopuolisille, emmekä käytä niitä markkinointiin.</b>
          </p>
          <p>
            Älä epäröi ottaa yhteyttä, vastaamme mielellämme.
          </p>
          <FeedbackForm />
        </article>
      </MainContent>
    )
  }
}
