import { searchItems } from '../../api/api'
import sanitize from './sanitize'
import { logException } from '../../sentry'

export const FETCH_SEARCH = 'FETCH_SEARCH'
export const FETCH_SEARCH_FAILURE = 'FETCH_SEARCH_FAILURE'
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS'
export const FETCH_SEARCH_ERROR_LENGTH = 'FETCH_SEARCH_ERROR_LENGTH'

export const FETCH_SEARCH_NEXT = 'FETCH_SEARCH_NEXT'
export const FETCH_SEARCH_NEXT_FAILURE = 'FETCH_SEARCH_NEXT_FAILURE'
export const FETCH_SEARCH_NEXT_SUCCESS = 'FETCH_SEARCH_NEXT_SUCCESS'

export const CHANGE_SEARCH_STRING = 'CHANGE_SEARCH_STRING'

export const OPEN_SEARCH = 'OPEN_SEARCH'
export const CLOSE_SEARCH = 'CLOSE_SEARCH'

export function search(searchString) {
  return (dispatch, getState) => {
    searchString = searchString ? searchString : ''

    if (!searchString || searchString.length < 2) {
      return new Promise((resolve, reject) => {
        dispatch({
          type: FETCH_SEARCH_ERROR_LENGTH,
          searchString,
        })

        resolve()
      })
    }

    const requestTimestamp = Date.now()

    dispatch({
      type: FETCH_SEARCH,
      searchString,
      timestamp: requestTimestamp,
    })

    return searchItems(searchString).then(response => {
      // check if this was the most recent search
      const latestTimestamp = getState().search.get('searchTimestamp')
      if (latestTimestamp === requestTimestamp) {
        dispatch({
          type: FETCH_SEARCH_SUCCESS,
          response,
        })
      }
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_SEARCH_FAILURE,
        error,
      })
    })
  }
}

export function searchNext(searchString, page) {
  return (dispatch) => {
    searchString = searchString ? sanitize(searchString) : ''
    dispatch({
      type: FETCH_SEARCH_NEXT,
    })

    return searchItems(searchString, page).then(response => {
      dispatch({
        type: FETCH_SEARCH_NEXT_SUCCESS,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_SEARCH_NEXT_FAILURE,
        error,
      })
    })
  }
}

export function changeSearchString(string) {
  return dispatch => {
    dispatch({
      type: CHANGE_SEARCH_STRING,
      string,
    })
  }
}

export function openSearch() {
  return dispatch => {
    dispatch({
      type: OPEN_SEARCH,
    })
  }
}

export function closeSearch() {
  return dispatch => {
    dispatch({
      type: CLOSE_SEARCH,
    })
  }
}
