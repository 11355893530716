import React, { PureComponent } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import SimpleItem from '../../components/item/SimpleItem'
import { LoadingFixedHeight }  from '../../components/util/Loading'
import UnableToLoad from '../../components/messages/UnableToLoad'
import { Link } from 'react-router'

export default class SidebarItems extends PureComponent {
  static propTypes = exact({
    dispatch: PropTypes.func.isRequired,
    className: PropTypes.string,
    trackingClass: PropTypes.string,
    title: PropTypes.string.isRequired,
    items: ImmutablePropTypes.orderedSet.isRequired,
    loading: PropTypes.bool.isRequired,
    failedToLoad: PropTypes.bool.isRequired,
    emptyText: PropTypes.string,
    linkTo: PropTypes.string,
    linkToTitle: PropTypes.string,
  })

  render () {
    const {
      dispatch,
      className,
      trackingClass,
      title,
      items,
      loading,
      failedToLoad,
      emptyText,
      linkTo,
      linkToTitle,
    } = this.props

    return (
      <div className={ classnames('sidebox', className ) }>
        <h3 className='sidebox-header'>
          <Link to={ linkTo } title={ linkToTitle }>
            <span>{ title }</span>
            <i className='fa-stack link-to'>
              <i className='fa fa-circle-thin fa-stack-1x' />
              <i className='fa fa-angle-right fa-stack-1x' />
            </i>
          </Link>
        </h3>

        <div className='sidebox-content'>
          <LoadingFixedHeight loading={ loading }>
            { failedToLoad && items.size === 0 &&
              <UnableToLoad errorText='Uutisia ei saatu ladattua.' />
            }

            { emptyText && !failedToLoad && items.size === 0 &&
              <UnableToLoad errorText={ emptyText } />
            }

            { items.map((item, i)=> (
              <SimpleItem
                key={ item.get('id') }
                dispatch={ dispatch }
                item={ item }
                trackingClass={ trackingClass }
                gaItemClickUiElement='Sivupalkin uutisboksi'
              />
            )) }
          </LoadingFixedHeight>
        </div>

      </div>
    )
  }
}
