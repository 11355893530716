import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { displayTemporaryProfileSaveModal } from '../modal/modalActions'
import { Link } from 'react-router'

export default class SaveTempProfileLink extends PureComponent {

  render() {
    return (
      <Link onClick={ this.handleClick } className='header-register-link'>
        { this.props.children ? this.props.children : [
          <span key='text'>Tallenna/tyhjennä valintasi </span>,
        ] }
      </Link>
    )
  }

  handleClick = () => {
    this.props.dispatch(displayTemporaryProfileSaveModal())
  }
}

SaveTempProfileLink.propTypes = exact({
  children: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
})
