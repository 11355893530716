import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import GeneralSidebar from '../components/layout/GeneralSidebar'
import Button from '../components/ui/Button'
import MainContent from '../components/wrappers/MainContent'
import UserEmailForm from './UserEmailForm'
import '../styles/form.pcss'

import { forgotPassword, forgotPasswordStatusReset } from './userActions'

@connect(({ user }) => {
  return {
    failure: user.get('userManagement').get('forgotPasswordStatus') === 'failure',
    success: user.get('userManagement').get('forgotPasswordStatus') === 'success',
    loading: user.get('userManagement').get('forgotPasswordStatus') === 'loading',
    emailNotFound: user.get('userManagement').get('forgotPasswordReason') === 'emailNotFound',
  }
})

export default class ForgotPasswordView extends Component {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    failure: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    emailNotFound: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  componentWillUnmount() {
    this.handleResetPasswordStatus()
  }

  render() {
    const {
      failure,
      success,
      loading,
      emailNotFound,
    } = this.props

    return (
      <div className='container' id='sticky-ad-bottom-boundary'>
        <div className='main-container'>

          { success ?
            <MainContent className='center-content side-padding-20'>
              <Helmet title='Vaihtolinkki lähetetty' />
              <h1>Vaihtolinkki lähetetty</h1>
              <p>Seuraa sähköpostissa saamaasi linkkiä</p>
              <br />
              <Button
                type='button'
                text='Tilaa uusi salasanan vaihtolinkki'
                loading={ loading }
                onClick={ this.handleResetPasswordStatus }
              />
            </MainContent>
            :
            <MainContent className='side-padding-20'>
              <Helmet title='Unohtunut salasana' />

              <h1 className='center-text'>Unohtunut salasana</h1>

              <p className='center-text' >Tilaa salasanan vaihtolinkki sähköpostiosoitteeseen</p>

              <div className='form-container'>
                <UserEmailForm
                  failure={ failure }
                  loading={ loading }
                  emailNotFound={ emailNotFound }
                  submitText='Lähetä vaihtolinkki'
                  onSubmit={ this.handleForgotPasswordClick }
                />
              </div>
            </MainContent>
          }

          <div className='sidebar-container'>
            <GeneralSidebar />
          </div>
        </div>
      </div>
    )
  }

  handleForgotPasswordClick = email => {
    this.props.dispatch(forgotPassword(email))
  }

  handleResetPasswordStatus = () => {
    this.props.dispatch(forgotPasswordStatusReset())
  }
}
