import React, { PureComponent } from 'react'
import { Link } from 'react-router'
import Resize from '../../ad/Resize'
import './footer.pcss'

export default class Footer extends PureComponent {

  render() {
    return (
      <footer className='footer'>

        <div className='footer__column'>
          <section>
            <h5 className='footer__title'>Yhteystiedot</h5>
            <p>
              <span className='footer__link'>Ampparit Uutispalvelut</span><br />
              <span className='footer__link'>ampparit@ampparit.com</span><br />
              <Link className='footer__link' to='/palaute'>Lähetä palautetta</Link>
            </p>
          </section>
        </div>

        <div className='footer__column'>
          <section>
            <h5 className='footer__title'>Tietoa Amppareista</h5>
            <p>
              <a className='footer__link' href='https://www.almamedia.fi/alma-media-konsernin-uutis-hyoty-ja-ajanvietesisaltojen-kaytto-ja-sopimusehdot/' target='_blank' rel='noopener noreferrer'>Käyttöehdot</a><br />
              <Link className='footer__link' to='/tietoa/ukk'>Usein kysytyt kysymykset</Link><br />
              <Link className='footer__link' to='/tietoa-palvelusta'>Tietoa palvelusta</Link><br />
              <Link className='footer__link' to='/tietoa/lahteet'>Uutislähteet</Link><br />
            </p>
          </section>
          <Resize maxBreakpoint={ 587 }>
            <section>
              <h5 className='footer__title'>Ampparit somessa</h5>
              <p>
                <a className='footer__link gtm-social-fb' href='https://www.facebook.com/ampparit' target='_blank' rel='noopener noreferrer'>Facebook</a><br />
                <a className='footer__link gtm-social-tw' href='https://twitter.com/ampparitcom' target='_blank' rel='noopener noreferrer'>Twitter</a><br />
              </p>
            </section>
          </Resize>
        </div>

        <div className='footer__column'>
          <section>
            <h5 className='footer__title'>Mainosta Amppareissa</h5>
            <p>
              <a className='footer__link' href='https://www.almamedia.fi/mainostajat/mediamyynnin-yhteystiedot/alma-mediaratkaisut' target='_blank' rel='noopener noreferrer'>Mediamyynti</a><br />
              <a className='footer__link' href='https://www.almamedia.fi/mainostajat/mediat-ja-palvelut/valtakunnalliset/ampparit/' target='_blank' rel='noopener noreferrer'>Mediatiedot</a><br />
            </p>
          </section>
          <section>
            <h5 className='footer__title'>Tietoa lähdemedioille</h5>
            <p>
              <Link className='footer__link' to='/tietoa/lahteille'>Ohjeita uutislähteille</Link><br />
              <Link className='footer__link' to='/tietoa/mukaan'>Mukaan Amppareihin</Link><br />
            </p>
          </section>
        </div>

        <div className='footer__column'>
          <section>
            <h5 className='footer__title'>Ampparit Uutispalvelut</h5>
            <p>
              <a className='footer__link' href='https://www.ampparit.com/' target='_blank' rel='noopener noreferrer'>Ampparit.com</a><br />
              <a className='footer__link' href='https://www.hakuvahti.fi/' target='_blank' rel='noopener noreferrer'>Hakuvahti.fi</a><br />
            </p>
          </section>
        </div>
      </footer>
    )
  }
}
