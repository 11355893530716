import Immutable from 'immutable'

import { FETCH_ITEMS_SUCCESS, FETCH_NEXT_ITEMS_SUCCESS } from '../components/item/itemsActions'
import { FETCH_SEARCH_NEXT_SUCCESS, FETCH_SEARCH_SUCCESS } from '../views/Search/searchActions'
import { FETCH_POPULAR_SUCCESS } from '../components/item/popularActions'
import { FETCH_TRENDING_SUCCESS } from '../components/item/trendingActions'

import { UPDATE_TIMESTAMP } from './timeActions'

const initialState = Immutable.Map({
  timestamp: Date.now(), // default to client time
})

export default function timeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ITEMS_SUCCESS:
    case FETCH_NEXT_ITEMS_SUCCESS:
    case FETCH_SEARCH_SUCCESS:
    case FETCH_SEARCH_NEXT_SUCCESS:
    case FETCH_POPULAR_SUCCESS:
    case FETCH_TRENDING_SUCCESS:
      return state.set('timestamp', action.response.timestamp)

    case UPDATE_TIMESTAMP:
      return state.set('timestamp', action.timestamp)

    default:
      return state
  }
}
