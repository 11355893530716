import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'

const MenuToggle = ({ isMobileMenuVisible, onClick }) => {

  return (
    <button
      title={ isMobileMenuVisible ? 'Sulje valikko' : 'Avaa valikko' }
      type='button'
      className={ classnames('menu-toggle', {'menu-toggle--active': isMobileMenuVisible }) }
      onClick={ onClick }
    >
      <span className='menu-toggle__bars'>
        <span className='menu-toggle__bar menu-toggle__bar--top' />
        <span className='menu-toggle__bar menu-toggle__bar--middle' />
        <span className='menu-toggle__bar menu-toggle__bar--bottom' />
      </span>
      <span className='mobile-bottom-nav-item__text '>Valikko</span>
    </button>
  )
}

MenuToggle.propTypes = exact({
  isMobileMenuVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
})

export default MenuToggle
