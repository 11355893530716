module.exports = [
  {
    'name': 'Alajärvi',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Alavieska',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Alavus',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Asikkala',
    'parent': 'Päijät-Häme',
  },
  {
    'name': 'Askola',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Aura',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Akaa',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Brändö',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Eckerö',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Enonkoski',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Enontekiö',
    'parent': 'Lappi',
  },
  {
    'name': 'Espoo',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Eura',
    'parent': 'Satakunta',
  },
  {
    'name': 'Eurajoki',
    'parent': 'Satakunta',
  },
  {
    'name': 'Evijärvi',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Finström',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Forssa',
    'parent': 'Kanta-Häme',
  },
  {
    'name': 'Föglö',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Geta',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Haapajärvi',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Haapavesi',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Hailuoto',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Halsua',
    'parent': 'Keski-Pohjanmaa',
  },
  {
    'name': 'Hamina',
    'parent': 'Kymenlaakso',
  },
  {
    'name': 'Hammarland',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Hankasalmi',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Hanko',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Harjavalta',
    'parent': 'Satakunta',
  },
  {
    'name': 'Hartola',
    'parent': 'Päijät-Häme',
  },
  {
    'name': 'Hattula',
    'parent': 'Kanta-Häme',
  },
  {
    'name': 'Hausjärvi',
    'parent': 'Kanta-Häme',
  },
  {
    'name': 'Heinävesi',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Helsinki',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Vantaa',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Hirvensalmi',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Hollola',
    'parent': 'Päijät-Häme',
  },
  {
    'name': 'Honkajoki',
    'parent': 'Satakunta',
  },
  {
    'name': 'Huittinen',
    'parent': 'Satakunta',
  },
  {
    'name': 'Humppila',
    'parent': 'Kanta-Häme',
  },
  {
    'name': 'Hyrynsalmi',
    'parent': 'Kainuu',
  },
  {
    'name': 'Hyvinkää',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Hämeenkyrö',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Hämeenlinna',
    'parent': 'Kanta-Häme',
  },
  {
    'name': 'Heinola',
    'parent': 'Päijät-Häme',
  },
  {
    'name': 'Ii',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Iisalmi',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Iitti',
    'parent': 'Kymenlaakso',
  },
  {
    'name': 'Ikaalinen',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Ilmajoki',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Ilomantsi',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Inari',
    'parent': 'Lappi',
  },
  {
    'name': 'Inkoo',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Isojoki',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Isokyrö',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Imatra',
    'parent': 'Etelä-Karjala',
  },
  {
    'name': 'Janakkala',
    'parent': 'Kanta-Häme',
  },
  {
    'name': 'Joensuu',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Jokioinen',
    'parent': 'Kanta-Häme',
  },
  {
    'name': 'Jomala',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Joroinen',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Joutsa',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Juuka',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Juupajoki',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Juva',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Jyväskylä',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Jämijärvi',
    'parent': 'Satakunta',
  },
  {
    'name': 'Jämsä',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Järvenpää',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Kaarina',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Kaavi',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Kajaani',
    'parent': 'Kainuu',
  },
  {
    'name': 'Kalajoki',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Kangasala',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Kangasniemi',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Kankaanpää',
    'parent': 'Satakunta',
  },
  {
    'name': 'Kannonkoski',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Kannus',
    'parent': 'Keski-Pohjanmaa',
  },
  {
    'name': 'Karijoki',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Karkkila',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Karstula',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Karvia',
    'parent': 'Satakunta',
  },
  {
    'name': 'Kaskinen',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Kauhajoki',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Kauhava',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Kauniainen',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Kaustinen',
    'parent': 'Keski-Pohjanmaa',
  },
  {
    'name': 'Keitele',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Kemi',
    'parent': 'Lappi',
  },
  {
    'name': 'Keminmaa',
    'parent': 'Lappi',
  },
  {
    'name': 'Kempele',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Kerava',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Keuruu',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Kihniö',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Kinnula',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Kirkkonummi',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Kitee',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Kittilä',
    'parent': 'Lappi',
  },
  {
    'name': 'Kiuruvesi',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Kivijärvi',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Kokemäki',
    'parent': 'Satakunta',
  },
  {
    'name': 'Kokkola',
    'parent': 'Keski-Pohjanmaa',
  },
  {
    'name': 'Kolari',
    'parent': 'Lappi',
  },
  {
    'name': 'Konnevesi',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Kontiolahti',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Korsnäs',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Koski Tl',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Kotka',
    'parent': 'Kymenlaakso',
  },
  {
    'name': 'Kouvola',
    'parent': 'Kymenlaakso',
  },
  {
    'name': 'Kristiinankaupunki',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Kruunupyy',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Kuhmo',
    'parent': 'Kainuu',
  },
  {
    'name': 'Kuhmoinen',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Kumlinge',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Kuopio',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Kuortane',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Kurikka',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Kustavi',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Kuusamo',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Outokumpu',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Kyyjärvi',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Kärkölä',
    'parent': 'Päijät-Häme',
  },
  {
    'name': 'Kärsämäki',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Kökar',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Kemijärvi',
    'parent': 'Lappi',
  },
  {
    'name': 'Kemiönsaari',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Lahti',
    'parent': 'Päijät-Häme',
  },
  {
    'name': 'Laihia',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Laitila',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Lapinlahti',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Lappajärvi',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Lappeenranta',
    'parent': 'Etelä-Karjala',
  },
  {
    'name': 'Lapinjärvi',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Lapua',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Laukaa',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Lemi',
    'parent': 'Etelä-Karjala',
  },
  {
    'name': 'Lemland',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Lempäälä',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Leppävirta',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Lestijärvi',
    'parent': 'Keski-Pohjanmaa',
  },
  {
    'name': 'Lieksa',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Lieto',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Liminka',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Liperi',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Loimaa',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Loppi',
    'parent': 'Kanta-Häme',
  },
  {
    'name': 'Loviisa',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Luhanka',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Lumijoki',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Lumparland',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Luoto',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Luumäki',
    'parent': 'Etelä-Karjala',
  },
  {
    'name': 'Lohja',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Parainen',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Maalahti',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Maarianhamina',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Marttila',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Masku',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Merijärvi',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Merikarvia',
    'parent': 'Satakunta',
  },
  {
    'name': 'Miehikkälä',
    'parent': 'Kymenlaakso',
  },
  {
    'name': 'Mikkeli',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Muhos',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Multia',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Muonio',
    'parent': 'Lappi',
  },
  {
    'name': 'Mustasaari',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Muurame',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Mynämäki',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Myrskylä',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Mäntsälä',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Mäntyharju',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Mänttä-Vilppula',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Naantali',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Nakkila',
    'parent': 'Satakunta',
  },
  {
    'name': 'Nivala',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Nokia',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Nousiainen',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Nurmes',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Nurmijärvi',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Närpiö',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Orimattila',
    'parent': 'Päijät-Häme',
  },
  {
    'name': 'Oripää',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Orivesi',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Oulainen',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Oulu',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Padasjoki',
    'parent': 'Päijät-Häme',
  },
  {
    'name': 'Paimio',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Paltamo',
    'parent': 'Kainuu',
  },
  {
    'name': 'Parikkala',
    'parent': 'Etelä-Karjala',
  },
  {
    'name': 'Parkano',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Pelkosenniemi',
    'parent': 'Lappi',
  },
  {
    'name': 'Perho',
    'parent': 'Keski-Pohjanmaa',
  },
  {
    'name': 'Pertunmaa',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Petäjävesi',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Pieksämäki',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Pielavesi',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Pietarsaari',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Pedersören kunta',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Pihtipudas',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Pirkkala',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Polvijärvi',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Pomarkku',
    'parent': 'Satakunta',
  },
  {
    'name': 'Pori',
    'parent': 'Satakunta',
  },
  {
    'name': 'Pornainen',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Posio',
    'parent': 'Lappi',
  },
  {
    'name': 'Pudasjärvi',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Pukkila',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Punkalaidun',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Puolanka',
    'parent': 'Kainuu',
  },
  {
    'name': 'Puumala',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Pyhtää',
    'parent': 'Kymenlaakso',
  },
  {
    'name': 'Pyhäjoki',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Pyhäjärvi',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Pyhäntä',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Pyhäranta',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Pälkäne',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Pöytyä',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Porvoo',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Raahe',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Raisio',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Rantasalmi',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Ranua',
    'parent': 'Lappi',
  },
  {
    'name': 'Rauma',
    'parent': 'Satakunta',
  },
  {
    'name': 'Rautalampi',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Rautavaara',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Rautjärvi',
    'parent': 'Etelä-Karjala',
  },
  {
    'name': 'Reisjärvi',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Riihimäki',
    'parent': 'Kanta-Häme',
  },
  {
    'name': 'Ristijärvi',
    'parent': 'Kainuu',
  },
  {
    'name': 'Rovaniemi',
    'parent': 'Lappi',
  },
  {
    'name': 'Ruokolahti',
    'parent': 'Etelä-Karjala',
  },
  {
    'name': 'Ruovesi',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Rusko',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Rääkkylä',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Raasepori',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Saarijärvi',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Salla',
    'parent': 'Lappi',
  },
  {
    'name': 'Salo',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Saltvik',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Sauvo',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Savitaipale',
    'parent': 'Etelä-Karjala',
  },
  {
    'name': 'Savonlinna',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Savukoski',
    'parent': 'Lappi',
  },
  {
    'name': 'Seinäjoki',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Sievi',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Siikainen',
    'parent': 'Satakunta',
  },
  {
    'name': 'Siikajoki',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Siilinjärvi',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Simo',
    'parent': 'Lappi',
  },
  {
    'name': 'Sipoo',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Siuntio',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Sodankylä',
    'parent': 'Lappi',
  },
  {
    'name': 'Soini',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Somero',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Sonkajärvi',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Sotkamo',
    'parent': 'Kainuu',
  },
  {
    'name': 'Sottunga',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Sulkava',
    'parent': 'Etelä-Savo',
  },
  {
    'name': 'Sund',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Suomussalmi',
    'parent': 'Kainuu',
  },
  {
    'name': 'Suonenjoki',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Sysmä',
    'parent': 'Päijät-Häme',
  },
  {
    'name': 'Säkylä',
    'parent': 'Satakunta',
  },
  {
    'name': 'Vaala',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Sastamala',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Siikalatva',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Taipalsaari',
    'parent': 'Etelä-Karjala',
  },
  {
    'name': 'Taivalkoski',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Taivassalo',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Tammela',
    'parent': 'Kanta-Häme',
  },
  {
    'name': 'Tampere',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Tervo',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Tervola',
    'parent': 'Lappi',
  },
  {
    'name': 'Teuva',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Tohmajärvi',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Toholampi',
    'parent': 'Keski-Pohjanmaa',
  },
  {
    'name': 'Toivakka',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Tornio',
    'parent': 'Lappi',
  },
  {
    'name': 'Turku',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Pello',
    'parent': 'Lappi',
  },
  {
    'name': 'Tuusniemi',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Tuusula',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Tyrnävä',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Ulvila',
    'parent': 'Satakunta',
  },
  {
    'name': 'Urjala',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Utajärvi',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Utsjoki',
    'parent': 'Lappi',
  },
  {
    'name': 'Uurainen',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Uusikaarlepyy',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Uusikaupunki',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Vaasa',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Valkeakoski',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Valtimo',
    'parent': 'Pohjois-Karjala',
  },
  {
    'name': 'Varkaus',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Vehmaa',
    'parent': 'Varsinais-Suomi',
  },
  {
    'name': 'Vesanto',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Vesilahti',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Veteli',
    'parent': 'Keski-Pohjanmaa',
  },
  {
    'name': 'Vieremä',
    'parent': 'Pohjois-Savo',
  },
  {
    'name': 'Vihti',
    'parent': 'Uusimaa',
  },
  {
    'name': 'Viitasaari',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Vimpeli',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Virolahti',
    'parent': 'Kymenlaakso',
  },
  {
    'name': 'Virrat',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Vårdö',
    'parent': 'Ahvenanmaa',
  },
  {
    'name': 'Vöyri',
    'parent': 'Pohjanmaa',
  },
  {
    'name': 'Ylitornio',
    'parent': 'Lappi',
  },
  {
    'name': 'Ylivieska',
    'parent': 'Pohjois-Pohjanmaa',
  },
  {
    'name': 'Ylöjärvi',
    'parent': 'Pirkanmaa',
  },
  {
    'name': 'Ypäjä',
    'parent': 'Kanta-Häme',
  },
  {
    'name': 'Ähtäri',
    'parent': 'Etelä-Pohjanmaa',
  },
  {
    'name': 'Äänekoski',
    'parent': 'Keski-Suomi',
  },
  {
    'name': 'Ahvenanmaa',
    'parent': null,
  },
  {
    'name': 'Etelä-Karjala',
    'parent': null,
  },
  {
    'name': 'Etelä-Pohjanmaa',
    'parent': null,
  },
  {
    'name': 'Etelä-Savo',
    'parent': null,
  },
  {
    'name': 'Kainuu',
    'parent': null,
  },
  {
    'name': 'Kanta-Häme',
    'parent': null,
  },
  {
    'name': 'Keski-Pohjanmaa',
    'parent': null,
  },
  {
    'name': 'Keski-Suomi',
    'parent': null,
  },
  {
    'name': 'Kymenlaakso',
    'parent': null,
  },
  {
    'name': 'Lappi',
    'parent': null,
  },
  {
    'name': 'Pirkanmaa',
    'parent': null,
  },
  {
    'name': 'Pohjanmaa',
    'parent': null,
  },
  {
    'name': 'Pohjois-Karjala',
    'parent': null,
  },
  {
    'name': 'Pohjois-Pohjanmaa',
    'parent': null,
  },
  {
    'name': 'Pohjois-Savo',
    'parent': null,
  },
  {
    'name': 'Päijät-Häme',
    'parent': null,
  },
  {
    'name': 'Satakunta',
    'parent': null,
  },
  {
    'name': 'Uusimaa',
    'parent': null,
  },
  {
    'name': 'Varsinais-Suomi',
    'parent': null,
  },
]
