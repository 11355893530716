import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Component } from 'react'
import classnames from 'classnames'

export default class MainContent extends Component {
  render() {
    return (
      <main className={ classnames('content', this.props.className) } id='content'>
        { this.props.children }
      </main>
    )
  }
}

MainContent.propTypes = exact({
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
})
