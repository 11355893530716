import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import '../../styles/modal.pcss'
import { ALMA_TUNNUS_VERIFICATION_MODAL } from './Modals'
import GenericModal from './GenericModal'
import { logoutAlmaTunnus } from '../../user/almalogin/almaUserActions'
import { getUserInfo } from '../../user/almalogin/AlmaLogin'

export default class AlmaTunnusVerificationModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
    }
  }

  async componentDidMount() {
    const userInfo = await getUserInfo()
    this.setState({ email: userInfo.email })
  }

  render() {
    const { onClose } = this.props
    const { email } = this.state

    return (
      <GenericModal
        modalId={ ALMA_TUNNUS_VERIFICATION_MODAL }
        onClose={ onClose }
        title='Tarkista sähköpostisi'
        okButtonTxt='Ok, selvä juttu!'
        onOkClick={ this.handleLogout }
        requireUserInput={ true }
      >
        <p>Vahvista ensin Alma-tunnuksesi sähköpostiisi <b>{ email }</b> lähetetyn linkin avulla ja kirjaudu uudestaan.</p>
      </GenericModal>
    )
  }

  handleLogout = () => {
    this.props.dispatch(logoutAlmaTunnus())
  }

}

AlmaTunnusVerificationModal.propTypes = exact({
  onClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
})
