import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import Quickfilter from './Quickfilter'
import Sticky from 'react-stickynode'
import moment from 'moment-timezone'
import './sidebarMenu.pcss'
import SidebarNameDays from '../../components/widgets/SidebarNamedays'
import { SkyscraperAd, SKYSCRAPER_1 } from '../../ad/Ad'
import { selectIsNightModeEnabled, selectIsUserLoggedIn } from '../../selectors/userSelector'
import { selectActivePresetProfileId } from '../../selectors/profilesSelector'
import NightModeButton from '../../components/ui/NightModeButton'
import SidebarMenuList from './SidebarMenuList'

/* IMPORTANT:
* When using SidebarMenu component on a page make sure to include the id='sticky-ad-bottom-boundary'
* property on the pages content div, so that on small height screens the sticky ad does not scroll over the footer
* --Yacine
*/

@connect((state) => {
  return {
    activePresetProfileId: selectActivePresetProfileId(state),
    nightMode: selectIsNightModeEnabled(state),
    timestamp: state.time.get('timestamp'),
    loggedIn: selectIsUserLoggedIn(state),
  }
})

export default class SidebarMenu extends Component {

  static propTypes = exact({
    activePresetProfileId: PropTypes.number,
    nightMode: PropTypes.bool.isRequired,
    timestamp: PropTypes.number.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  })

  render() {
    const {
      dispatch,
      timestamp,
      activePresetProfileId,
      nightMode,
      loggedIn,
    } = this.props

    return (
      <div className='menu-container'>
        <div className='menu-content'>
          { !loggedIn &&
            <NightModeButton
              dispatch={ dispatch }
              nightMode={ nightMode }
            />
          }
          <div className='quickfilter'>
            <Quickfilter
              dispatch={ dispatch }
              loggedIn={ loggedIn }
              activePresetProfileId={ activePresetProfileId }
            />
          </div>
          <nav aria-label='Kategoriat'>
            <SidebarMenuList />
          </nav>

          <SidebarNameDays timestamp={ moment(timestamp).startOf('day').valueOf() } />
        </div>

        <Sticky top={ 50 } bottomBoundary='#sticky-ad-bottom-boundary'>
          <SkyscraperAd
            adUnitId={ SKYSCRAPER_1 }
          />
        </Sticky>
      </div>
    )
  }
}
