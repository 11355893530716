import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import {
  changeActiveList,
  fetchFeatured,
  fetchFeaturedBatch,
  fetchFeaturedNext,
  unqueueItems,
  LIST_NAME_FEATURED,
  LIST_TYPE_MAIN,
} from '../../components/item/itemsActions'
import AbstractListView, { connectGenericProps } from './AbstractListView'
import { fetchPopular } from '../../components/item/popularActions'



@provideHooks({
  fetch: ({ dispatch }) => {

    dispatch(changeActiveList(LIST_TYPE_MAIN, LIST_NAME_FEATURED))

    return Promise.all([
      dispatch(fetchFeatured()),
      dispatch(fetchPopular(300)),
    ])
  },
})
export class FeaturedView extends AbstractListView {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    dispatch: PropTypes.func.isRequired,
  }

  refreshList() {
    const { dispatch } = this.props
    dispatch(fetchFeatured())
  }

  fetchNext(timestamp) {
    return (dispatch) => dispatch(fetchFeaturedNext(timestamp))
  }

  handleFetchBatchItems = (batch) => {
    const { dispatch } = this.props
    dispatch(fetchFeaturedBatch(batch))
  }

  unqueueItems() {
    const { dispatch } = this.props
    dispatch(unqueueItems(LIST_NAME_FEATURED))
  }

  setActiveList() {
    const {dispatch} = this.props

    dispatch(changeActiveList(LIST_TYPE_MAIN, LIST_NAME_FEATURED))
  }

  getTitle() {
    return 'Etusivu | Tuoreimmat uutiset | Ampparit.com'
  }

  getH1() {
    return 'Etusivu'
  }
}

export default connect(state => {
  const { trending } = state
  const featured = state.items.lists.get(LIST_NAME_FEATURED)

  return Object.assign(connectGenericProps(state, featured), {
    activePage: {
      title: 'Päälähteet',
      to: '/',
    },
    trendingFailedToLoad: trending.get('failedToLoad'),
    showLanguageOptions: true,
  })
})(FeaturedView)
