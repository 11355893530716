import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Scoreboard } from '@almamedia/consumer-sport-scoreboard'
import '@almamedia/consumer-sport-scoreboard/dist/styles.css'
import { MobileLeaderboardAd } from '../../ad/Ad'
import SidebarMenu from '../../components/layout/SidebarMenu'
import MainContent from '../../components/wrappers/MainContent'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import ListTitle from '../../components/lists/ListTitle'
import GeneralSidebar from '../../components/layout/GeneralSidebar'
import { Ticker } from '../../components/ticker/Ticker'
import { basicWildcardMatch } from '../../lib/utils'
import { selectDeviceType } from '../../selectors/statusSelector'
import './iltalehtiScoreboardView.pcss'
import { selectIsNightModeEnabled } from '../../selectors/userSelector'
import Loading from '../../components/util/Loading'

/**
 * Meta description is a legacy of the old service.
 * @todo check to match the current situation!
 */

const scoreboardComponents = {
  Brand: () => null,
  Loading: () => <Loading loading={ false } />,
  LinkToScoreboard: ({ children }) => (
    <Link
      to='/urheilu/tulospalvelu'
      mainCategory='urheilu'
      category='tulospalvelu'
    >
      { children }
    </Link>
  ),
}

scoreboardComponents.LinkToScoreboard.propTypes = exact({
  children: PropTypes.node,
})

@connect(state => {

  const { user } = state

  return {
    tickers: state.tickers.tickers,
    deviceType: selectDeviceType(state),
    timestamp: state.time.get('timestamp'),
    nightMode: selectIsNightModeEnabled(state),
    userDarkMode: user.get('theme'),
  }
})

export default class IltalehtiScoreboardView extends Component {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    dispatch: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    tickers: ImmutablePropTypes.list.isRequired,
    deviceType: PropTypes.string.isRequired,
    nightMode: PropTypes.bool.isRequired,
    userDarkMode: PropTypes.string.isRequired,
  }

  render() {
    const {
      dispatch,
      path,
      tickers,
      deviceType,
      nightMode,
      userDarkMode,
    } = this.props


    const topTicker = tickers.find(ticker =>
      !ticker.get('closed') && ticker.get('position') === 'top' && ticker.get('devices').contains(deviceType) &&
      ticker.get('pages').some(page => basicWildcardMatch(page, path))
    )

    // Check if the user (logged in or not) has the dark theme activated
    const isDarkMode = () => {
      return (
        userDarkMode.includes('matrix') ||
        userDarkMode.includes('night') ||
        nightMode
      )
    }

    return (
      <div className='il-sports container' id='sticky-ad-bottom-boundary'>
        <div className='main-container'>
          <SidebarMenu />
          <MainContent>
            <Helmet
              title='Tulospalvelu'
              meta={ [
                {name: 'description', content: 'Ampparit.com kokoaa yhteen päivän urheilut. Mukana mm. Liiga, NHL, Veikkausliiga, Mestarien liiga sekä NBA.'},
                {property: 'og:title', content: 'Tulospalvelun päivän pelit \u2013 Ampparit.com'},
                {property: 'og:description', content: 'Ampparit.com kokoaa yhteen eri lajien tulosten liveseurannan. Mukana mm. Liiga, NHL, Veikkausliiga, Mestarien liiga, sekä NBA.'},
              ] }
            />
            <MobileLeaderboardAd path={ path } />
            <div className='il-sports__header'>
              <ListTitle heading='Tulospalvelu' />
            </div>

            { topTicker != null && (
              <Ticker ticker={ topTicker } dispatch={ dispatch } />
            ) }

            <div className={ classnames('il-sports__content', { 'dark': isDarkMode() }) }>
              <div className='il-scoreboard'>
                <Scoreboard components={ scoreboardComponents } />
              </div>
            </div>
          </MainContent>
          <div className='sidebar-container'>
            <GeneralSidebar />
          </div>
        </div>
      </div>
    )
  }
}
