import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment-timezone'
import classnames from 'classnames'
import isEqual from 'lodash/isEqual'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { fetchStatsForItems } from '../../components/item/itemsActions'
import Loading from '../../components/util/Loading'
import ItemLink from './ItemLink'
import ItemDetails from './ItemDetails'
import ItemBatchToggle from './ItemBatchToggle'
import ItemActionsWidget from './ItemActionsWidget'
import { SVG_ICON_ELLIPSIS, SVG_ICON_PAYWALL } from '../util/svgIcons'
import './item.pcss'


export default class Item extends Component {
  constructor(props) {
    super(props)

    this.state = {
      actionsVisible: false,
      batchVisible: false,
    }
  }

  render() {
    const {
      item,
      timestamp,
      dispatch,
      loggedIn,
      isTagView,
    } = this.props

    const batch = item.get('batch')
    const blacklistedByTag = item.get('tags').find(tag => tag.get('blacklisted') === true)
    const blacklistedByCategory = item.getIn(['category', 'blacklisted'])
    const itemTimestamp = item.get('timestamp')
    const paywalled = item.get('paywalled', false)
    const blackListedBySource = item.get('blacklistedSource')

    return (
      <article className={ classnames('item', { blacklisted: blacklistedByCategory || (blacklistedByTag && !isTagView) || blackListedBySource }, { paywalled: paywalled }) }>
        <div className='item-row'>
          <ItemBatchToggle
            hasBatches={ batch && batch.get('size') > 1 ? true : false }
            batchVisible={ this.state.batchVisible }
            onItemBatchSwitch={ this.handleItemBatchSwitch }
          />

          <div className='item-text'>
            <h4 className='item-title'>
              { paywalled &&
                <svg className='item-title__paywalled' aria-hidden='true'>
                  <use href={ `#${SVG_ICON_PAYWALL}` } />
                </svg>
              }
              <ItemLink
                item={ item }
                trackingClass='news-item-headline'
                dispatch={ dispatch }
                gaItemClickUiElement='Uutislista'
              />
            </h4>

            <ItemDetails
              item={ item }
              isToday={ moment(itemTimestamp * 1000).isAfter(moment(timestamp).subtract(24, 'hours')) }
            />


          </div>

          <button
            type='button'
            className='item-actions-toggle'
            onClick={ this.handleToggleActions }
            tabIndex='0'
            aria-label='Avaa uutisen toimintavalikko äänestystä ja jakamista varten'
          >
            <svg className='item-actions-toggle-icon' aria-hidden='true'>
              <use href={ `#${SVG_ICON_ELLIPSIS}` } />
            </svg>
          </button>
        </div>

        { this.state.actionsVisible && (
          <ItemActionsWidget
            item={ item }
            loggedIn={ loggedIn }
            dispatch={ dispatch }
          />
        ) }

        { batch && batch.get('size') > 1 && (
          <div className='item-batch'>
            { this.state.batchVisible && (
              <Loading loading={ batch.get('loading') }>
                { batch.get('items').map(child => (
                  <Item
                    key={ child.get('id') }
                    item={ child }
                    loggedIn={ loggedIn }
                    dispatch={ dispatch }
                    timestamp={ timestamp }
                  />
                ))
                }
              </Loading>
            ) }
          </div>
        ) }
      </article>
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !nextProps.item.equals(this.props.item) ||
      !isEqual(this.state, nextState)
    )
  }

  handleToggleActions = e => {
    const { dispatch, item } = this.props

    if (!this.state.actionsVisible) {
      dispatch(fetchStatsForItems([item.get('id')]))
    }

    this.setState({
      actionsVisible: !this.state.actionsVisible,
    })
  }

  handleItemBatchSwitch = e => {
    const { onFetchBatchItems, item } = this.props

    if (!onFetchBatchItems) return

    if (this.state.batchVisible === false) {
      this.setState({
        batchVisible: true,
      })
      onFetchBatchItems(item.get('batch').get('id'))
    }
    else {
      this.setState({
        batchVisible: false,
      })
    }
  }
}

Item.propTypes = exact({
  item: ImmutablePropTypes.mapContains({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    tags: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      whitelisted: PropTypes.bool.isRequired,
      blacklisted: PropTypes.bool.isRequired,
    })).isRequired,
    plus_votes: PropTypes.number.isRequired,
    minus_votes: PropTypes.number.isRequired,
    clicks: PropTypes.number.isRequired,
  }),
  timestamp: PropTypes.number.isRequired,
  onFetchBatchItems: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  isTagView: PropTypes.bool,
})
