import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { fetchAllTags } from '../../components/item/tagsActions'
import MainContent from '../../components/wrappers/MainContent'
import TopNotification from '../../user/TopNotification'
import Switch from '../../components/ui/Switch'
import SidebarMenu from '../../components/layout/SidebarMenu'
import GeneralSidebar from '../../components/layout/GeneralSidebar'
import HakuvahtiAd from '../../assets/ampparit_hakuvahti_600x480.png'
import './allTagsView.pcss'
import ListTitle from '../../components/lists/ListTitle'
import ArrowToggle from '../../components/util/ArrowToggle'
import { selectAllTagsItems, selectTrendingTagItems, selectTagsLoading, selectTagsFailedToLoad, selectAllTagsLoading, selectAllTagsFailedToLoad } from '../../selectors/tagsSelector'
import Loading from '../../components/util/Loading'
import UnableToLoad from '../../components/messages/UnableToLoad'

const AllTagsView = () => {
  const dispatch = useDispatch()
  const allTags = useSelector(selectAllTagsItems)
  const trending = useSelector(selectTrendingTagItems).map(tag => tag.get('id'))
  const loading = useSelector(selectTagsLoading)
  const failedToLoad = useSelector(selectTagsFailedToLoad)
  const allTagsLoading = useSelector(selectAllTagsLoading)
  const allTagsFailedToLoad = useSelector(selectAllTagsFailedToLoad)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState(allTags)
  const [sortColumn, setSortColumn] = useState('itemsCount')
  const [sortDirection, setSortDirection] = useState('desc')

  useEffect(() => {
    dispatch(fetchAllTags())
  }, [dispatch])

  useEffect(() => {
    const results = allTags.filter(tag =>
      tag.get('name').toLowerCase().includes(searchTerm.toLowerCase())
    )
    setSearchResults(results)
  }, [allTags, searchTerm])

  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value)
  }, [])

  const handleSort = useCallback((column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortDirection(column === 'name' ? 'asc' : 'desc')
    }
  }, [sortColumn, sortDirection])

  const handleSortName = useCallback(() => {
    handleSort('name')
  }, [handleSort])

  const handleSortWhiteListed = useCallback(() => {
    handleSort('whiteListedCount')
  }, [handleSort])

  const handleSortBlackListed = useCallback(() => {
    handleSort('blackListedCount')
  }, [handleSort])

  const handleSortItems = useCallback(() => {
    handleSort('itemsCount')
  }, [handleSort])

  const sortedTags = useMemo(() => {
    return searchResults.sort((a, b) => {
      const aValue = a.get(sortColumn)
      const bValue = b.get(sortColumn)
      if (sortColumn === 'name') {
        return sortDirection === 'asc' ? aValue.localeCompare(bValue, 'fi') : bValue.localeCompare(aValue, 'fi')
      }
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue
    })
  }, [searchResults, sortColumn, sortDirection])

  return (
    <div className='all-tags container' id='sticky-ad-bottom-boundary'>
      <div className='main-container'>
        <SidebarMenu />
        <MainContent>

          <ListTitle heading='Aihetunnisteet' />

          <div className='all-tags__info'>
            <TopNotification className='info-box'>
              <p>Seuraa helposti sinua kiinnostavia aiheita. Valitse tästä omaan uutislistaasi aiheet, joita haluat seurata. Voit myös halutessasi piilottaa aiheen, jota et halua nähdä. Klikkaamalla aihetunnistetta löydät nipun uutisotsikoita sinua kiinnostavasta aiheesta.</p>
            </TopNotification>
          </div>

          <article className='all-tags__page'>
            <Helmet
              title='Aihetunnisteet'
              meta={ [
                { name: 'description', content: 'Tuoreimmat uutiset ja kovimmat puheenaiheet yhdestä paikasta. Ampparit käyttää aihetunnisteita, jotta kiinnostavien uutisten löytäminen olisi helpompaa.' },
                { property: 'og:title', content: 'Aihetunnisteet \u2013 Ampparit.com' },
                { property: 'og:description', content: 'Tuoreimmat uutiset ja kovimmat puheenaiheet yhdestä paikasta. Ampparit käyttää aihetunnisteita, jotta kiinnostavien uutisten löytäminen olisi helpompaa.' },
              ] }
            />

            <div className='all-tags__pinned'>
              <h3 className='all-tags__pinned-title'>Pinnalla olevat</h3>
              <div className='all-tags__pinned-container'>
                <table className='all-tags__pinned-table'>

                  <thead className='all-tags__pinned-table-head'>
                    <tr>
                      <th scope='col'>Aihetunniste</th>
                      <th scope='col'>Uutisia</th>
                    </tr>
                  </thead>
                  <tbody className='all-tags__pinned-table-body'>
                    { loading ?
                      <tr>
                        <td colSpan='4'>
                          <Loading loading={ loading } />
                        </td>
                      </tr>
                      :
                      allTags.entrySeq().map(([key, tag]) => (
                        trending.contains(tag.get('id')) &&
                      renderPinnedRow(key, tag)
                      )) }
                    { failedToLoad &&
                      <tr>
                        <td colSpan='4'>
                          <UnableToLoad errorText='Pinnalla olevia aihetunnisteita ei saatu ladattua.' />
                        </td>
                      </tr>
                    }

                  </tbody>

                </table>
                <div className='all-tags__ad-hakuvahti'>
                  <a href='https://www.hakuvahti.fi/' target='_blank' rel='noopener noreferrer'>
                    <img className='all-tags__ad-hakuvahti_ad-image' src={ HakuvahtiAd } alt='hakuvahti_ad' />
                  </a>
                </div>
              </div>

              { failedToLoad &&
              <UnableToLoad errorText='Pinnalla olevia aihetunnisteita ei saatu ladattua.' />
              }
            </div>

            <div className='all-tags__search-input-container'>
              <div className='all-tags__search-input-header'>
                <h3>Kaikki aihetunnisteet</h3>
                <span className='all-tags__search-input-header__tag-count'>{ `(${ allTags.size } kpl)` }</span>
              </div>
              <input
                className='all-tags__search-input'
                type='text'
                placeholder='Etsi aihetunnisteita'
                aria-label='Etsi aihetunnisteita'
                value={ searchTerm }
                onChange={ handleSearchChange }
              />

              <div className='all-tags__search-results'>

                <table className='all-tags__search-results-table'>
                  <thead className='all-tags__search-results-table-head'>
                    <tr>
                      <th scope='col' onClick={ handleSortName }>
                        <span>Aihetunniste</span>
                        <ArrowToggle
                          toggleUp={ sortColumn === 'name' && sortDirection === 'asc' }
                          onToggleAction={ handleSortName }
                          screenReaderMessage={ sortDirection === 'asc' ? 'Järjestä tunnisteet nousevasti' : 'Järjestä tunnisteet laskevasti' }
                        />
                      </th>
                      <th scope='col' onClick={ handleSortItems }>
                        <span>Uutisia</span>
                        <ArrowToggle
                          toggleUp={ sortColumn === 'itemsCount' && sortDirection === 'asc' }
                          onToggleAction={ handleSortItems }
                          screenReaderMessage={ sortDirection === 'asc' ? 'Järjestä uutisten määrällä nousevasti' : 'Järjestä uutisten määrällä laskevasti' }
                        />
                      </th>
                      <th scope='col' onClick={ handleSortWhiteListed }>
                        <span>Seuraajia</span>
                        <ArrowToggle
                          toggleUp={ sortColumn === 'whiteListedCount' && sortDirection === 'asc' }
                          onToggleAction={ handleSortWhiteListed }
                          screenReaderMessage={ sortDirection === 'asc' ? 'Järjestä seuraajien määrällä nousevasti' : 'Järjestä seuraajien määrällä laskevasti' }
                        />
                      </th>
                      <th scope='col' onClick={ handleSortBlackListed }>
                        <span>Piilottajia</span>
                        <ArrowToggle
                          toggleUp={ sortColumn === 'blackListedCount' && sortDirection === 'asc' }
                          onToggleAction={ handleSortBlackListed }
                          screenReaderMessage={ sortDirection === 'asc' ? 'Järjestä piilottajien määrällä nousevasti' : 'Järjestä piilottajien määrällä laskevasti' }
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody className='all-tags__search-results-table-body'>
                    { loading ? (
                      <tr>
                        <td colSpan='4'>
                          <Loading loading={ allTagsLoading } />
                        </td>
                      </tr>
                    ) : (
                      searchResults && searchResults.size > 0 ? sortedTags.map((tag, index) => (
                        renderSearchRow(index, tag)
                      )) : renderNoResult()
                    ) }
                  </tbody>
                </table>
                { allTagsFailedToLoad &&
                  <tr>
                    <td colSpan='4'>
                      <UnableToLoad errorText='Aihetunnisteita ei saatu ladattua.' />
                    </td>
                  </tr>
                }

              </div>
            </div>
          </article>
        </MainContent>
        <div className='sidebar-container'>
          <GeneralSidebar />
        </div>
      </div>
    </div>
  )

  function renderPinnedRow(key, tag) {
    return (
      <tr key={ key }>
        <td>
          <Switch tag={ tag } />
        </td>
        <td>{ tag.get('itemsCount') }</td>
      </tr>
    )
  }

  function renderSearchRow(key, tag) {
    return (
      <tr key={ key }>
        <td>
          <Switch tag={ tag } />
        </td>
        <td>{ tag.get('itemsCount') }</td>
        <td>{ tag.get('whiteListedCount') }</td>
        <td>{ tag.get('blackListedCount') }</td>
      </tr>
    )
  }

  function renderNoResult() {
    return (
      <tr>
        <td>
          <span className='all-tags__search-results-table__no-results'>Ei tuloksia</span>
        </td>
      </tr>
    )
  }
}

export default AllTagsView
