import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import { push } from 'react-router-redux'
import * as keyCodes from '../../lib/keyCodes'
import SearchStatusIndicator from './SearchStatusIndicator'
import { changeSearchString, search, closeSearch } from './searchActions'
import { addSavedSearch, removeSavedSearch } from '../../user/userActions'
import './searchInput.pcss'


/**
 * Displays a search input field with an indicator for how many results were found.
 */
export default class SearchInput extends Component {
  static propTypes = exact({
    resultCount: PropTypes.number.isRequired,
    searchString: PropTypes.string,
    inProgress: PropTypes.bool.isRequired,
    hasErrors: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    isSearchPage: PropTypes.bool.isRequired,
    onCloseClick: PropTypes.func,
    savedSearches: ImmutablePropTypes.orderedSetOf(PropTypes.string).isRequired,
  })

  constructor(props) {
    super(props)
    this.refSearchInput = React.createRef()
  }

  componentDidMount() {
    if (!this.props.searchString || !window.navigator.maxTouchPoints > 1) {
      this.refSearchInput.current.focus()
    }
  }

  render() {
    const {
      resultCount,
      searchString,
      inProgress,
      hasErrors,
      loggedIn,
      isSearchPage,
      onCloseClick,
      savedSearches,
    } = this.props

    const showSaveSearch = loggedIn && searchString.length > 0 && !hasErrors
    const isSavedSearch = savedSearches.includes(searchString)

    return (
      <div className='search-input'>
        <span className='search-icon fa fa-search' aria-hidden='true' />

        <span className='search-input-field-container'>
          <span className='search-input-field'>
            <input
              type='text'
              maxLength='200'
              placeholder='Hae uutisia'
              ref={ this.refSearchInput }
              value={ searchString }
              onChange={ this.handleSearchStringChange }
              onKeyUp={ this.handleKeyUp }
              aria-label='Hakusanat'
            />
          </span>
        </span>

        { showSaveSearch ?
          <span className='search-input__save-search'>
            { isSavedSearch ?
              <button type='button' className='fa fa-star' onClick={ this.handleSaveSearch } title={ `Poista ${searchString} tallennetuista hauista` }>
                <span className='sr-only'>Poista { searchString } tallennetuista hauista</span>
              </button> :
              <button type='button' className='fa fa-star-o' onClick={ this.handleSaveSearch } title={ `Tallenna haku ${searchString}` }>
                <span className='sr-only'>Tallenna haku { searchString }</span>
              </button>
            }
          </span> : null }

        <SearchStatusIndicator
          inProgress={ inProgress }
          errorText={ hasErrors ? 'tarkenna hakua' : '' }
          resultCount={ resultCount }
          searchIsActive={ !!searchString }
          onResultClick={ this.handleSearchClick }
        />

        { !isSearchPage &&
        <span className='search-bar-close'>
          <button type='button' className='fa fa-times' onClick={ onCloseClick }>
            <span className='sr-only'>Sulje haku</span>
          </button>
        </span>
        }
      </div>
    )
  }

  handleSaveSearch = () => {
    const { dispatch, searchString, savedSearches } = this.props
    if (savedSearches.includes(searchString)) {
      dispatch(removeSavedSearch(searchString))
    } else {
      dispatch(addSavedSearch(searchString))
    }
  }

  handleSearchStringChange = event => {
    this.props.dispatch(changeSearchString(event.target.value))
  }

  /**
   * Perform search on user input, or if enter is clicked, reroute to the search page.
   * @param event
   */
  handleKeyUp = event => {
    switch (event.keyCode) {
      case keyCodes.enter:
        event.preventDefault()
        if (window.navigator.maxTouchPoints > 1) {
          this.refSearchInput.current.blur()
        }
        this.goToSearchPage()
        break

      case keyCodes.esc:
        this.props.dispatch(closeSearch())
        break

      case keyCodes.upArrow:
      case keyCodes.downArrow:
      case keyCodes.leftArrow:
      case keyCodes.rightArrow:
      case keyCodes.shift:
      case keyCodes.ctrl:
      case keyCodes.alt:
      case keyCodes.leftCmd:
      case keyCodes.rightCmd:
      case keyCodes.capsLock:
      case keyCodes.tab:
      case keyCodes.home:
      case keyCodes.end:
        break // Do nothing

      default:
        this.debouncedSearch()
    }
  }

  handleSearchClick = () => {
    this.goToSearchPage()
  }

  /**
   * Perform "preview search" without updating route
   */
  debouncedSearch = debounce(() => {
    const { dispatch, searchString } = this.props
    dispatch(search(searchString))
  }, 500)

  /**
   * Perform "commited search", where route is updated and history entry added
   */
  goToSearchPage() {
    const { dispatch, searchString } = this.props
    const target = '/haku?q=' + encodeURIComponent(searchString)
    this.debouncedSearch.cancel()
    dispatch(push(target))
    window.scrollTo(0, 0)
  }
}
