import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { itemShareIntent } from '../item/itemsActions'

export default class ShareButtonTwitter extends PureComponent {
  render() {
    const { link, itemTitle } = this.props
    const href = 'https://twitter.com/intent/tweet?text=' +  encodeURIComponent(itemTitle) + '&url=' + link
    const title = 'Jaa uutinen Twitterissä'

    return (
      <a
        className='fa-stack share-twitter gtm-share'
        target='_blank'
        rel='noopener noreferrer'
        href={ href }
        title={ title }
        onClick={ this.handleClick }
      >
        <span className='sr-only'>{ title }</span>
        <span className='fa fa-circle fa-stack-2x gtm-share' aria-hidden='true' />
        <span className='fa fa-twitter fa-stack-1x gtm-share' aria-hidden='true' />
      </a>
    )
  }

  handleClick = () => {
    const { itemId, dispatch } = this.props
    dispatch(itemShareIntent(itemId, 'twitter'))
  }
}

ShareButtonTwitter.propTypes = exact({
  itemTitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
})
