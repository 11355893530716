import Immutable from 'immutable'
import { canUseDOM } from 'exenv'
import { push } from 'react-router-redux'
import {
  addBlacklistCategory,
  addBlacklistSource,
  addSubscribeSource,
  addUserSubscribedLanguage,
  addBlacklistTag,
  addUserFeed,
  addUserWordFilter,
  addUserSavedSearch,
  addWeatherLocation,
  addWhitelistCategory,
  addWhitelistTag,
  getUser,
  forgotUserPassword,
  getReadyMadeProfiles,
  getRecommendedCategories,
  logoutAmpparitUser,
  newUserPassword,
  removeBlacklistCategory,
  removeBlacklistSource,
  removeSubscribeSource,
  removeUserSubscribedLanguage,
  removeBlacklistTag,
  removeUserFeed,
  removeUserWordFilter,
  removeUserSavedSearch,
  removeWhitelistCategory,
  removeWhitelistTag,
  setDefaultWeatherLocation,
  setKeyValue,
  setUserVoteFilter,
  setWeatherLocations,
  incRecommendedCategoriesDisplay,
  setRecommendedCategoriesAccept,
  setRecommendedCategoriesReject,
  updateUserFeed,
} from '../api/api'
import { Cookies } from 'react-cookie'
import config from '../../config/config'
import { displayTemporaryProfileInfoModal } from '../components/modal/modalActions'
import {
  selectUserJWT,
  selectIsUserLoggedIn,
  selectUserLayoutWidth,
  selectUserActiveTheme,
} from '../selectors/userSelector'
import { isTempProfileModified } from './user'
import { setThemeBodyClass } from '../lib/themes'
import { setLayoutWidthBodyClass } from '../lib/layout'
import { selectDescendantCategories } from '../selectors/categoriesSelector'
import { selectDeviceType, selectLocationPath, selectLocationStatus } from '../selectors/statusSelector'
import { logException, logWarning } from '../sentry'
import { isTimestampFresh } from '../lib/utils'

export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER'

export const CLEAR_USER_JWT = 'CLEAR_USER_JWT'

export const QUEUE_PERSONAL_ITEM = 'QUEUE_PERSONAL_ITEM'
export const UNQUEUE_PERSONAL_ITEMS = 'UNQUEUE_PERSONAL_ITEMS'

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE'
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE'

export const WHITELIST_CATEGORY = 'WHITELIST_CATEGORY'
export const WHITELIST_CATEGORY_SUCCESS = 'WHITELIST_CATEGORY_SUCCESS'
export const WHITELIST_CATEGORY_FAILURE = 'WHITELIST_CATEGORY_FAILURE'

export const UNWHITELIST_CATEGORY = 'UNWHITELIST_CATEGORY'
export const UNWHITELIST_CATEGORY_SUCCESS = 'UNWHITELIST_CATEGORY_SUCCESS'
export const UNWHITELIST_CATEGORY_FAILURE = 'UNWHITELIST_CATEGORY_FAILURE'

export const BLACKLIST_CATEGORY = 'BLACKLIST_CATEGORY'
export const BLACKLIST_CATEGORY_SUCCESS = 'BLACKLIST_CATEGORY_SUCCESS'
export const BLACKLIST_CATEGORY_FAILURE = 'BLACKLIST_CATEGORY_FAILURE'

export const UNBLACKLIST_CATEGORY = 'UNBLACKLIST_CATEGORY'
export const UNBLACKLIST_CATEGORY_SUCCESS = 'UNBLACKLIST_CATEGORY_SUCCESS'
export const UNBLACKLIST_CATEGORY_FAILURE = 'UNBLACKLIST_CATEGORY_FAILURE'

export const FETCH_RECOMMENDED_CATEGORIES = 'FETCH_RECOMMENDED_CATEGORIES'
export const FETCH_RECOMMENDED_CATEGORIES_SUCCESS = 'FETCH_RECOMMENDED_CATEGORIES_SUCCESS'
export const FETCH_RECOMMENDED_CATEGORIES_FAILURE = 'FETCH_RECOMMENDED_CATEGORIES_FAILURE'
export const DISPLAY_RECOMMENDED_CATEGORIES = 'DISPLAY_RECOMMENDED_CATEGORIES'
export const DISPLAY_RECOMMENDED_CATEGORIES_SUCCESS = 'DISPLAY_RECOMMENDED_CATEGORIES_SUCCESS'
export const DISPLAY_RECOMMENDED_CATEGORIES_FAILURE = 'DISPLAY_RECOMMENDED_CATEGORIES_FAILURE'
export const ACCEPT_RECOMMENDED_CATEGORIES = 'ACCEPT_RECOMMENDED_CATEGORIES'
export const ACCEPT_RECOMMENDED_CATEGORIES_SUCCESS = 'ACCEPT_RECOMMENDED_CATEGORIES_SUCCESS'
export const ACCEPT_RECOMMENDED_CATEGORIES_FAILURE = 'ACCEPT_RECOMMENDED_CATEGORIES_FAILURE'
export const REJECT_RECOMMENDED_CATEGORIES = 'REJECT_RECOMMENDED_CATEGORIES'
export const REJECT_RECOMMENDED_CATEGORIES_SUCCESS = 'REJECT_RECOMMENDED_CATEGORIES_SUCCESS'
export const REJECT_RECOMMENDED_CATEGORIES_FAILURE = 'REJECT_RECOMMENDED_CATEGORIES_FAILURE'

export const TOGGLE_OVERLAY_OPEN = 'TOGGLE_OVERLAY_OPEN'

export const ADD_USER_WORD_FILTER = 'ADD_USER_WORD_FILTER'
export const ADD_USER_WORD_FILTER_SUCCESS = 'ADD_USER_WORD_FILTER_SUCCESS'
export const ADD_USER_WORD_FILTER_FAILURE = 'ADD_USER_WORD_FILTER_FAILURE'
export const ADD_USER_WORD_FILTER_MAX = 'ADD_USER_WORD_FILTER_MAX'

export const REMOVE_USER_WORD_FILTER = 'REMOVE_USER_WORD_FILTER'
export const REMOVE_USER_WORD_FILTER_SUCCESS = 'REMOVE_USER_WORD_FILTER_SUCCESS'
export const REMOVE_USER_WORD_FILTER_FAILURE = 'REMOVE_USER_WORD_FILTER_FAILURE'

export const ADD_USER_SAVED_SEARCH = 'ADD_USER_SAVED_SEARCH'
export const ADD_USER_SAVED_SEARCH_SUCCESS = 'ADD_USER_SAVED_SEARCH_SUCCESS'
export const ADD_USER_SAVED_SEARCH_FAILURE = 'ADD_USER_SAVED_SEARCH_FAILURE'
export const ADD_USER_SAVED_SEARCH_MAX = 'ADD_USER_SAVED_SEARCH_MAX'

export const REMOVE_USER_SAVED_SEARCH = 'REMOVE_USER_SAVED_SEARCH'
export const REMOVE_USER_SAVED_SEARCH_SUCCESS = 'REMOVE_USER_SAVED_SEARCH_SUCCESS'
export const REMOVE_USER_SAVED_SEARCH_FAILURE = 'REMOVE_USER_SAVED_SEARCH_FAILURE'

export const REMOVE_CUSTOM_FEED = 'REMOVE_CUSTOM_FEED'
export const REMOVE_CUSTOM_FEED_SUCCESS = 'REMOVE_CUSTOM_FEED_SUCCESS'
export const REMOVE_CUSTOM_FEED_FAILURE = 'REMOVE_CUSTOM_FEED_FAILURE'

export const ADD_CUSTOM_FEED = 'ADD_CUSTOM_FEED'
export const ADD_CUSTOM_FEED_SUCCESS = 'ADD_CUSTOM_FEED_SUCCESS'
export const ADD_CUSTOM_FEED_FAILURE = 'ADD_CUSTOM_FEED_FAILURE'

export const UPDATE_CUSTOM_FEED = 'UPDATE_CUSTOM_FEED'
export const UPDATE_CUSTOM_FEED_SUCCESS = 'UPDATE_CUSTOM_FEED_SUCCESS'
export const UPDATE_CUSTOM_FEED_FAILURE = 'UPDATE_CUSTOM_FEED_FAILURE'

export const SET_ACTIVE_USER_PROFILE = 'SET_ACTIVE_USER_PROFILE'
export const SET_ACTIVE_PRESET_PROFILE = 'SET_ACTIVE_PRESET_PROFILE'

export const SET_NIGHT_MODE = 'SET_NIGHT_MODE'
export const SET_NIGHT_MODE_SUCCESS = 'SET_NIGHT_MODE_SUCCESS'
export const SET_NIGHT_MODE_FAILURE = 'SET_NIGHT_MODE_FAILURE'

export const WHITELIST_TAG = 'WHITELIST_TAG'
export const WHITELIST_TAG_SUCCESS = 'WHITELIST_TAG_SUCCESS'
export const WHITELIST_TAG_FAILURE = 'WHITELIST_TAG_FAILURE'

export const UNWHITELIST_TAG = 'UNWHITELIST_TAG'
export const UNWHITELIST_TAG_SUCCESS = 'UNWHITELIST_TAG_SUCCESS'
export const UNWHITELIST_TAG_FAILURE = 'UNWHITELIST_TAG_FAILURE'

export const BLACKLIST_TAG = 'BLACKLIST_TAG'
export const BLACKLIST_TAG_SUCCESS = 'BLACKLIST_TAG_SUCCESS'
export const BLACKLIST_TAG_FAILURE = 'BLACKLIST_TAG_FAILURE'

export const UNBLACKLIST_TAG = 'UNBLACKLIST_TAG'
export const UNBLACKLIST_TAG_SUCCESS = 'UNBLACKLIST_TAG_SUCCESS'
export const UNBLACKLIST_TAG_FAILURE = 'UNBLACKLIST_TAG_FAILURE'

export const BLACKLIST_SOURCE = 'BLACKLIST_SOURCE'
export const BLACKLIST_SOURCE_SUCCESS = 'BLACKLIST_SOURCE_SUCCESS'
export const BLACKLIST_SOURCE_FAILURE = 'BLACKLIST_SOURCE_FAILURE'
export const SOURCES_MIN_LIMIT = 'SOURCES_MIN_LIMIT'

export const UNBLACKLIST_SOURCE = 'UNBLACKLIST_SOURCE'
export const UNBLACKLIST_SOURCE_SUCCESS = 'UNBLACKLIST_SOURCE_SUCCESS'
export const UNBLACKLIST_SOURCE_FAILURE = 'UNBLACKLIST_SOURCE_FAILURE'

export const SUBSCRIBE_SOURCE = 'SUBSCRIBE_SOURCE'
export const SUBSCRIBE_SOURCE_SUCCESS = 'SUBSCRIBE_SOURCE_SUCCESS'
export const SUBSCRIBE_SOURCE_FAILURE = 'SUBSCRIBE_SOURCE_FAILURE'

export const UNSUBSCRIBE_SOURCE = 'UNSUBSCRIBE_SOURCE'
export const UNSUBSCRIBE_SOURCE_SUCCESS = 'UNSUBSCRIBE_SOURCE_SUCCESS'
export const UNSUBSCRIBE_SOURCE_FAILURE = 'UNSUBSCRIBE_SOURCE_FAILURE'

export const SUBSCRIBE_LANGUAGE = 'SUBSCRIBE_LANGUAGE'
export const SUBSCRIBE_LANGUAGE_SUCCESS = 'SUBSCRIBE_LANGUAGE_SUCCESS'
export const SUBSCRIBE_LANGUAGE_FAILURE = 'SUBSCRIBE_LANGUAGE_FAILURE'

export const UNSUBSCRIBE_LANGUAGE = 'UNSUBSCRIBE_LANGUAGE'
export const UNSUBSCRIBE_LANGUAGE_SUCCESS = 'UNSUBSCRIBE_LANGUAGE_SUCCESS'
export const UNSUBSCRIBE_LANGUAGE_FAILURE = 'UNSUBSCRIBE_LANGUAGE_FAILURE'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
export const FORGOT_PASSWORD_ERROR_EMAIL_NOT_FOUND = 'FORGOT_PASSWORD_ERROR_EMAIL_NOT_FOUND'
export const FORGOT_PASSWORD_STATUS_RESET = 'FORGOT_PASSWORD_STATUS_RESET'

export const NEW_PASSWORD = 'NEW_PASSWORD'
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS'
export const NEW_PASSWORD_FAILURE = 'NEW_PASSWORD_FAILURE'

export const FETCH_READYMADE_PROFILES = 'FETCH_READYMADE_PROFILES'
export const FETCH_READYMADE_PROFILES_SUCCESS = 'FETCH_READYMADE_PROFILES_SUCCESS'
export const FETCH_READYMADE_PROFILES_FAILURE = 'FETCH_READYMADE_PROFILES_FAILURE'

export const SET_VOTE_FILTER = 'SET_VOTE_FILTER'
export const SET_VOTE_FILTER_SUCCESS = 'SET_VOTE_FILTER_SUCCESS'
export const SET_VOTE_FILTER_FAILURE = 'SET_VOTE_FILTER_FAILURE'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE'

export const CHANGE_FRONT_PAGE = 'CHANGE_FRONT_PAGE'
export const CHANGE_FRONT_PAGE_SUCCESS = 'CHANGE_FRONT_PAGE_SUCCESS'
export const CHANGE_FRONT_PAGE_FAILURE = 'CHANGE_FRONT_PAGE_FAILURE'

export const CHANGE_THEME = 'CHANGE_THEME'
export const CHANGE_THEME_SUCCESS = 'CHANGE_THEME_SUCCESS'
export const CHANGE_THEME_FAILURE = 'CHANGE_THEME_FAILURE'

export const CHANGE_LAYOUT_WIDTH = 'CHANGE_LAYOUT_WIDTH'
export const CHANGE_LAYOUT_WIDTH_FAILURE = 'CHANGE_LAYOUT_WIDTH_FAILURE'
export const CHANGE_LAYOUT_WIDTH_SUCCESS = 'CHANGE_LAYOUT_WIDTH_SUCCESS'

export const LAYOUT_WIDTH_NORMAL = 'normal'
export const LAYOUT_WIDTH_WIDE = 'wide'
export const LAYOUT_WIDTH_FULL = 'full'

export const CHANGE_SHOW_CUSTOM_FEEDS_IN_SIDEBAR = 'CHANGE_SHOW_CUSTOM_FEEDS_IN_SIDEBAR'
export const CHANGE_SHOW_CUSTOM_FEEDS_IN_SIDEBAR_FAILURE = 'CHANGE_SHOW_CUSTOM_FEEDS_IN_SIDEBAR_FAILURE'
export const CHANGE_SHOW_CUSTOM_FEEDS_IN_SIDEBAR_SUCCESS = 'CHANGE_SHOW_CUSTOM_FEEDS_IN_SIDEBAR_SUCCESS'

export const SET_WEATHER_LOCATIONS = 'SET_WEATHER_LOCATIONS'
export const SET_WEATHER_LOCATIONS_SUCCESS = 'SET_WEATHER_LOCATIONS_SUCCESS'
export const SET_WEATHER_LOCATIONS_FAILURE = 'SET_WEATHER_LOCATIONS_FAILURE'

export const ADD_WEATHER_LOCATION = 'ADD_WEATHER_LOCATION'
export const ADD_WEATHER_LOCATION_SUCCESS = 'ADD_WEATHER_LOCATION_SUCCESS'
export const ADD_WEATHER_LOCATION_FAILURE = 'ADD_WEATHER_LOCATION_FAILURE'

export const SET_DEFAULT_WEATHER_LOCATION = 'SET_DEFAULT_WEATHER_LOCATION'
export const SET_DEFAULT_WEATHER_LOCATION_SUCCESS = 'SET_DEFAULT_WEATHER_LOCATION_SUCCESS'
export const SET_DEFAULT_WEATHER_LOCATION_FAILURE = 'SET_DEFAULT_WEATHER_LOCATION_FAILURE'

export const SET_TEMPORARY_PROFILE = 'SET_TEMPORARY_PROFILE'
export const RESET_TEMPORARY_PROFILE = 'RESET_TEMPORARY_PROFILE'

// Cookie names as constants to avoid typos
export const TEMP_PROFILE_COOKIE_NAME = 'tempProfile'
export const AMPPARIT_USER_COOKIE_NAME = 'ampUser'
export const PRESET_PROFILE_COOKIE_NAME = 'activePresetProfile'

// Temporary profile operations and list names
const TEMP_OP_ADD = 'add'
const TEMP_OP_REMOVE = 'remove'
const TEMP_WHITELISTED_TAGS = 'whitelistedTags'
const TEMP_BLACKLISTED_TAGS = 'blacklistedTags'
const TEMP_WHITELISTED_CATEGORIES = 'whitelistedCats'
const TEMP_BLACKLISTED_CATEGORIES = 'blacklistedCats'

export const defaultTempProfile = Immutable.fromJS({
  whitelistedTags: [],
  blacklistedTags: [],
  whitelistedCats: [],
  blacklistedCats: [],
  nightMode: false,
})



/**
 * Sync redux state from user tempProfile cookie
 * @returns {Function}
 */
function refreshTempProfile() {
  return dispatch => {
    const cookies = new Cookies()
    const tempProfile = getTempProfile(cookies)
    dispatch({
      type: SET_TEMPORARY_PROFILE,
      profileData: tempProfile,
    })
  }
}

function getTempProfile(cookies) {
  const cookieProfile = cookies.get(TEMP_PROFILE_COOKIE_NAME)
  const tempProfile = Object.assign({}, defaultTempProfile.toJS(), cookieProfile)
  return tempProfile
}


function setTempProfile(cookies, tempProfile) {
  return dispatch => {
    if (!tempProfile) {
      logWarning('profileData not provided, using default data. This should not happen.')
      tempProfile = defaultTempProfile.toJS()
    }

    dispatch({
      type: SET_TEMPORARY_PROFILE,
      profileData: tempProfile,
    })

    // Prune any obsolete data from existing tempProfile cookie
    for (const key in tempProfile) {
      if (!defaultTempProfile.has(key)) {
        delete tempProfile[key]
      }
    }
    cookies.set(TEMP_PROFILE_COOKIE_NAME, tempProfile, config.cookieOptions)
  }
}

export function resetTemporaryProfile(cookies, notify = false) {
  return dispatch => {
    dispatch({
      type: RESET_TEMPORARY_PROFILE,
      notify,
    })

    return dispatch(setTempProfile(cookies, defaultTempProfile.toJS()))
  }
}


function modifyTempProfileList(listname, operation, values) {
  const cookies = new Cookies()
  const tempProfile = getTempProfile(cookies)
  if (!Array.isArray(values)) {
    throw new TypeError('modifyTempProfileList `values` parameter must be an array')
  }
  if (operation === TEMP_OP_ADD) {
    values.forEach(value => {
      if (!tempProfile[listname].includes(value)) {
        tempProfile[listname].push(value)
      }
    })
  } else if (operation === TEMP_OP_REMOVE) {
    tempProfile[listname] = tempProfile[listname].filter(id => !values.includes(id))
  }
  cookies.set(TEMP_PROFILE_COOKIE_NAME, tempProfile, config.cookieOptions)
}



/**
 * Sync redux state from logged in user's profile data from server. Call this to
 * make sure profile data in redux store is up to date. Note that all actions
 * that modify user profile automatically refresh the profile data.
 * @param {int} maxStaleSecs
 * @returns
 */
export function fetchUserProfile(maxStaleSecs = 0) {
  return (dispatch, getState) => {
    const state = getState()
    const lastFetchTimestamp = state.user.get('timestamp')
    if (isTimestampFresh(lastFetchTimestamp, maxStaleSecs) || !selectIsUserLoggedIn(state)) {
      return
    }

    dispatch({
      type: FETCH_USER_PROFILE,
    })

    const jwt = selectUserJWT(state)
    return getUser(jwt)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: FETCH_USER_PROFILE_FAILURE,
          timestamp: Date.now(),
          error,
        })
      })
  }
}



export function changeFrontPage(frontPage) {
  return (dispatch, getState) => {
    dispatch({
      type: CHANGE_FRONT_PAGE,
    })

    return setKeyValue('frontPage', frontPage)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: CHANGE_FRONT_PAGE_FAILURE,
          error,
        })
      })
  }
}

export function changeTheme(theme) {
  return (dispatch, getState) => {
    dispatch({
      type: CHANGE_THEME,
    })

    return setKeyValue('theme', theme)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: CHANGE_THEME_FAILURE,
          error,
        })
      })
  }
}

export function setNightMode(active) {
  return (dispatch, getState) => {

    active = !!active
    dispatch({
      type: SET_NIGHT_MODE,
      active,
    })

    const state = getState()
    if (!selectIsUserLoggedIn(state)) {
      const cookies = new Cookies()
      const tempProfile = getTempProfile(cookies)
      tempProfile.nightMode = active
      cookies.set(TEMP_PROFILE_COOKIE_NAME, tempProfile, config.cookieOptions)

      dispatch({
        type: SET_NIGHT_MODE_SUCCESS,
        active,
      })

      setThemeBodyClass(selectUserActiveTheme(getState()))

      return Promise.resolve()
    } else {
      return dispatch({
        type: SET_NIGHT_MODE_FAILURE,
      })
    }
  }
}

export function changeLayoutWidth(layoutWidth) {
  return dispatch => {
    dispatch({
      type: CHANGE_LAYOUT_WIDTH,
    })

    return setKeyValue('layoutWidth', layoutWidth)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: CHANGE_LAYOUT_WIDTH_FAILURE,
          error,
        })
      })
  }
}

export function setShowUserFeedsBoxInSidebar(show) {
  return dispatch => {
    dispatch({
      type: CHANGE_SHOW_CUSTOM_FEEDS_IN_SIDEBAR,
    })
    return setKeyValue('showUserFeedsBoxInSidebar', show)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: CHANGE_SHOW_CUSTOM_FEEDS_IN_SIDEBAR_FAILURE,
          error,
        })
      })
  }
}

export function setActivePresetProfile(profileId, cookies = null) {
  profileId = parseInt(profileId, 10)
  if (profileId !== 1 && profileId !== 5 && profileId !== 6) { // 1,5,6 are the currently valid IDs
    profileId = null
  }

  cookies = cookies ?? new Cookies()

  if (profileId) {
    cookies.set(PRESET_PROFILE_COOKIE_NAME, profileId, config.cookieOptions)
  } else {
    cookies.remove(PRESET_PROFILE_COOKIE_NAME, config.cookieOptions)
  }

  return dispatch => {
    dispatch({
      type: SET_ACTIVE_PRESET_PROFILE,
      profileId,
    })
  }
}


export function blacklistSource(sourceId) {
  return (dispatch) => {
    dispatch({
      type: BLACKLIST_SOURCE,
      source: sourceId,
    })

    return addBlacklistSource(sourceId)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        if (error.response && error.response.status === 429) {
          return dispatch({
            type: SOURCES_MIN_LIMIT,
          })
        } else {
          logException(error)
          dispatch({
            type: BLACKLIST_SOURCE_FAILURE,
            source: sourceId,
            error,
          })
        }
      })
  }
}

export function unblacklistSource(sourceId) {
  return (dispatch) => {
    dispatch({
      type: UNBLACKLIST_SOURCE,
      source: sourceId,
    })

    return removeBlacklistSource(sourceId)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: UNBLACKLIST_SOURCE_FAILURE,
          source: sourceId,
          error,
        })
      })
  }
}


export function subscribeSource(sourceId) {
  return (dispatch) => {
    dispatch({
      type: SUBSCRIBE_SOURCE,
      source: sourceId,
    })

    return addSubscribeSource(sourceId)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: SUBSCRIBE_SOURCE_FAILURE,
          source: sourceId,
          error,
        })
      })
  }
}

export function unSubscribeSource(sourceId) {
  return (dispatch) => {
    dispatch({
      type: UNSUBSCRIBE_SOURCE,
      source: sourceId,
    })

    return removeSubscribeSource(sourceId)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: UNSUBSCRIBE_SOURCE_FAILURE,
          source: sourceId,
          error,
        })
      })
  }
}


export function subscribeUserLanguage(languageSlug) {
  return (dispatch) => {
    dispatch({
      type: SUBSCRIBE_LANGUAGE,
      language: languageSlug,
    })

    return addUserSubscribedLanguage(languageSlug)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: SUBSCRIBE_LANGUAGE_FAILURE,
          language: languageSlug,
          error,
        })
      })
  }
}

export function unSubscribeUserLanguage(languageSlug) {
  return (dispatch) => {
    dispatch({
      type: UNSUBSCRIBE_LANGUAGE,
      language: languageSlug,
    })

    return removeUserSubscribedLanguage(languageSlug)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: UNSUBSCRIBE_LANGUAGE_FAILURE,
          language: languageSlug,
          error,
        })
      })
  }
}


export function toggleOverlay(toggleState) {
  return (dispatch, getState) => {
    dispatch({
      type: TOGGLE_OVERLAY_OPEN,
      toggleState,
    })

    /**
     * iOS11 has a bug, where inputs placed inside of a fixed container have their
     * caret positioned wrong
     *
     * https://bugs.webkit.org/show_bug.cgi?id=176896
     *
     * As a temp fix, we check if the device is iOS and apply a hacky fix to body element
     *
     * -Yacine
     */
    if (selectDeviceType(getState()) === 'ios') {
      window.document.body.style.position = toggleState ? 'fixed' : 'static'
      window.document.body.style.width = toggleState ? '100%' : 'auto'
    }
  }
}

export function queuePersonalItem(item) {
  return {
    type: QUEUE_PERSONAL_ITEM,
    item,
  }
}

export function unqueuePersonalItems() {
  return {
    type: UNQUEUE_PERSONAL_ITEMS,
  }
}

export function fetchReadyMadeProfiles() {
  return dispatch => {
    dispatch({
      type: FETCH_READYMADE_PROFILES,
    })

    return getReadyMadeProfiles().then(response => {
      dispatch({
        type: FETCH_READYMADE_PROFILES_SUCCESS,
        response,
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_READYMADE_PROFILES_FAILURE,
        error,
      })
    })
  }
}



export function whitelistCategory(id) {
  return (dispatch, getState) => {
    if (!id || isNaN(id)) throw new TypeError('Invalid id:', id)

    dispatch({
      type: WHITELIST_CATEGORY,
      id,
    })

    const state = getState()
    if (!selectIsUserLoggedIn(state)) {
      dispatch(checkTempProfileCreate())
      const categoryIds = selectDescendantCategories(state, id).map(category => category.id).add(id).toArray()
      modifyTempProfileList(TEMP_BLACKLISTED_CATEGORIES, TEMP_OP_REMOVE, categoryIds)
      modifyTempProfileList(TEMP_WHITELISTED_CATEGORIES, TEMP_OP_ADD, categoryIds)
      return dispatch(refreshTempProfile())
    }

    return addWhitelistCategory(id)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: WHITELIST_CATEGORY_FAILURE,
          id,
          error,
        })
      })
  }
}

export function unwhitelistCategory(id) {
  return (dispatch, getState) => {
    if (!id || isNaN(id)) throw new TypeError('Invalid id:', id)

    dispatch({
      type: UNWHITELIST_CATEGORY,
      id,
    })

    const state = getState()
    if (!selectIsUserLoggedIn(state)) {
      dispatch(checkTempProfileCreate())
      const categoryIds = selectDescendantCategories(state, id).map(category => category.id).add(id).toArray()
      modifyTempProfileList(TEMP_WHITELISTED_CATEGORIES, TEMP_OP_REMOVE, categoryIds)
      return dispatch(refreshTempProfile())
    }

    return removeWhitelistCategory(id)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: UNWHITELIST_CATEGORY_FAILURE,
          id,
          error,
        })
      })
  }
}

export function blacklistCategory(id) {
  return (dispatch, getState) => {
    if (!id || isNaN(id)) throw new TypeError('Invalid id:', id)

    dispatch({
      type: BLACKLIST_CATEGORY,
      id,
    })

    const state = getState()
    if (!selectIsUserLoggedIn(state)) {
      dispatch(checkTempProfileCreate())
      const categoryIds = selectDescendantCategories(state, id).map(category => category.id).add(id).toArray()
      modifyTempProfileList(TEMP_WHITELISTED_CATEGORIES, TEMP_OP_REMOVE, categoryIds)
      modifyTempProfileList(TEMP_BLACKLISTED_CATEGORIES, TEMP_OP_ADD, categoryIds)
      return dispatch(refreshTempProfile())
    }

    return addBlacklistCategory(id)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: BLACKLIST_CATEGORY_FAILURE,
          id,
          error,
        })
      })
  }
}

export function unblacklistCategory(id) {
  return (dispatch, getState) => {
    if (!id || isNaN(id)) throw new TypeError('Invalid id:', id)

    dispatch({
      type: UNBLACKLIST_CATEGORY,
      id,
    })

    const state = getState()
    if (!selectIsUserLoggedIn(state)) {
      dispatch(checkTempProfileCreate())
      const categoryIds = selectDescendantCategories(state, id).map(category => category.id).add(id).toArray()
      modifyTempProfileList(TEMP_BLACKLISTED_CATEGORIES, TEMP_OP_REMOVE, categoryIds)
      return dispatch(refreshTempProfile())
    }

    return removeBlacklistCategory(id)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: UNBLACKLIST_CATEGORY_FAILURE,
          id,
          error,
        })
      })
  }
}

export function fetchRecommendedCategories(maxStaleSecs = 0) {
  return (dispatch, getState) => {
    const state = getState()
    const lastFetchTimestamp = state.user.get('recommendedCategories').get('timestamp')
    if (isTimestampFresh(lastFetchTimestamp, maxStaleSecs) || !selectIsUserLoggedIn(state)) {
      return
    }

    dispatch({
      type: FETCH_RECOMMENDED_CATEGORIES,
    })

    const jwt = selectUserJWT(state)
    return getRecommendedCategories(jwt)
      .then(response => {
        dispatch({
          type: FETCH_RECOMMENDED_CATEGORIES_SUCCESS,
          data: response.data.data,
          timestamp: Date.now(),
        })
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: FETCH_RECOMMENDED_CATEGORIES_FAILURE,
          timestamp: Date.now(),
          error,
        })
      })
  }
}

export function displayRecommendedCategories(cateogryId) {
  return (dispatch) => {
    dispatch({
      type: DISPLAY_RECOMMENDED_CATEGORIES,
      id: cateogryId,
    })

    return incRecommendedCategoriesDisplay(cateogryId)
      .then(response => {
        dispatch({
          type: DISPLAY_RECOMMENDED_CATEGORIES_SUCCESS,
          id: cateogryId,
          response,
        })
      })
      .catch(error => {
        if (error.response) {
          logException(error)
          dispatch({
            type: DISPLAY_RECOMMENDED_CATEGORIES_FAILURE,
            id: cateogryId,
            error,
          })
        }
      })
  }
}

export function acceptRecommendedCategories(cateogryId) {
  return (dispatch) => {
    dispatch({
      type: ACCEPT_RECOMMENDED_CATEGORIES,
      id: cateogryId,
    })

    return setRecommendedCategoriesAccept(cateogryId)
      .then(response => {
        dispatch({
          type: ACCEPT_RECOMMENDED_CATEGORIES_SUCCESS,
          id: cateogryId,
          response,
        })
      })
      .catch(error => {
        if (error.response) {
          logException(error)
          dispatch({
            type: ACCEPT_RECOMMENDED_CATEGORIES_FAILURE,
            id: cateogryId,
            error,
          })
        }
      })
  }
}

export function rejectRecommendedCategories(cateogryId) {
  return (dispatch) => {

    dispatch({
      type: REJECT_RECOMMENDED_CATEGORIES,
      id: cateogryId,
    })

    return setRecommendedCategoriesReject(cateogryId)
      .then(response => {
        dispatch({
          type: REJECT_RECOMMENDED_CATEGORIES_SUCCESS,
          id: cateogryId,
          response,
        })
      })
      .catch(error => {
        if (error.response) {
          logException(error)
          dispatch({
            type: REJECT_RECOMMENDED_CATEGORIES_FAILURE,
            id: cateogryId,
            error,
          })
        }
      })
  }
}



export function changeVoteFilter(threshold) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_VOTE_FILTER,
      threshold,
    })

    return setUserVoteFilter(threshold)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
        dispatch({type: SET_VOTE_FILTER_SUCCESS, threshold})
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: SET_VOTE_FILTER_FAILURE,
          error,
        })
      })
  }
}


export function addFeed(url) {
  return (dispatch) => {

    dispatch({
      type: ADD_CUSTOM_FEED,
      url,
    })

    return addUserFeed(url)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
        dispatch({type: ADD_CUSTOM_FEED_SUCCESS, url, response})
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: ADD_CUSTOM_FEED_FAILURE,
          url,
          error,
        })
      })
  }
}

export function updateFeed(feedId, categoryId) {
  return (dispatch) => {

    dispatch({
      type: UPDATE_CUSTOM_FEED,
      feedId,
      categoryId,
    })

    return updateUserFeed(feedId, categoryId)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: UPDATE_CUSTOM_FEED_FAILURE,
          feedId,
          categoryId,
          error,
        })
      })
  }
}

export function removeFeed(feedId) {
  return (dispatch) => {

    dispatch({
      type: REMOVE_CUSTOM_FEED,
      feedId,
    })

    return removeUserFeed(feedId)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: REMOVE_CUSTOM_FEED_FAILURE,
          feedId,
          error,
        })
      })
  }
}


export function addWordFilter(filter) {
  return (dispatch) => {
    dispatch({
      type: ADD_USER_WORD_FILTER,
      filter,
    })

    return addUserWordFilter(filter)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        if (error.response && error.response.status === 429) {
          dispatch({
            type: ADD_USER_WORD_FILTER_MAX,
            filter,
            error,
          })
        } else {
          logException(error)
          dispatch({
            type: ADD_USER_WORD_FILTER_FAILURE,
            filter,
            error,
          })
        }
      })
  }
}

export function removeWordFilter(filter) {
  return (dispatch) => {

    dispatch({
      type: REMOVE_USER_WORD_FILTER,
      filter,
    })

    return removeUserWordFilter(filter)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: REMOVE_USER_WORD_FILTER_FAILURE,
          filter,
          error,
        })
      })
  }
}

export function addSavedSearch(searchTerm) {
  return (dispatch) => {
    dispatch({
      type: ADD_USER_SAVED_SEARCH,
      searchTerm,
    })

    return addUserSavedSearch(searchTerm)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
        dispatch({type: ADD_USER_SAVED_SEARCH_SUCCESS, searchTerm})
      })
      .catch(error => {
        if (error.response && error.response.status === 429) {
          dispatch({
            type: ADD_USER_SAVED_SEARCH_MAX,
            searchTerm,
            error,
          })
        } else {
          logException(error)
          dispatch({
            type: ADD_USER_SAVED_SEARCH_FAILURE,
            searchTerm,
            error,
          })
        }
      })
  }
}

export function removeSavedSearch(searchTerm) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_USER_SAVED_SEARCH,
      searchTerm,
    })

    return removeUserSavedSearch(searchTerm)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
        dispatch({type: REMOVE_USER_SAVED_SEARCH_SUCCESS, searchTerm})
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: REMOVE_USER_SAVED_SEARCH_FAILURE,
          searchTerm,
          error,
        })
      })
  }
}


export function whitelistTag(tagId) {
  return (dispatch, getState) => {
    if (!tagId) throw new Error('Invalid tag:', tagId)

    dispatch({
      type: WHITELIST_TAG,
      tag: tagId,
    })

    const state = getState()
    if (!selectIsUserLoggedIn(state)) {
      dispatch(checkTempProfileCreate())
      modifyTempProfileList(TEMP_BLACKLISTED_TAGS, TEMP_OP_REMOVE, [tagId])
      modifyTempProfileList(TEMP_WHITELISTED_TAGS, TEMP_OP_ADD, [tagId])
      return dispatch(refreshTempProfile())
    }

    return addWhitelistTag(tagId)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: WHITELIST_TAG_FAILURE,
          tag: tagId,
          error,
        })
      })
  }
}

export function unwhitelistTag(tagId) {
  return (dispatch, getState) => {
    if (!tagId) throw new Error('Invalid tag:', tagId)

    dispatch({
      type: UNWHITELIST_TAG,
      tag: tagId,
    })

    const state = getState()
    if (!selectIsUserLoggedIn(state)) {
      dispatch(checkTempProfileCreate())
      modifyTempProfileList(TEMP_WHITELISTED_TAGS, TEMP_OP_REMOVE, [tagId])
      return dispatch(refreshTempProfile())
    }

    return removeWhitelistTag(tagId)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: UNWHITELIST_TAG_FAILURE,
          tag: tagId,
          error,
        })
      })
  }
}

export function blacklistTag(tagId) {
  return (dispatch, getState) => {
    if (!tagId) throw new Error('Invalid tag:', tagId)

    dispatch({
      type: BLACKLIST_TAG,
      tag: tagId,
    })

    const state = getState()
    if (!selectIsUserLoggedIn(state)) {
      dispatch(checkTempProfileCreate())
      modifyTempProfileList(TEMP_WHITELISTED_TAGS, TEMP_OP_REMOVE, [tagId])
      modifyTempProfileList(TEMP_BLACKLISTED_TAGS, TEMP_OP_ADD, [tagId])
      return dispatch(refreshTempProfile())
    }

    return addBlacklistTag(tagId)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: BLACKLIST_TAG_FAILURE,
          tag: tagId,
          error,
        })
      })
  }
}

export function unblacklistTag(tagId) {
  return (dispatch, getState) => {
    if (!tagId) throw new Error('Invalid tag:', tagId)

    dispatch({
      type: UNBLACKLIST_TAG,
      tag: tagId,
    })

    const state = getState()
    if (!selectIsUserLoggedIn(state)) {
      dispatch(checkTempProfileCreate())
      modifyTempProfileList(TEMP_BLACKLISTED_TAGS, TEMP_OP_REMOVE, [tagId])
      return dispatch(refreshTempProfile())
    }

    return removeBlacklistTag(tagId)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: UNBLACKLIST_TAG_FAILURE,
          tag: tagId,
          error,
        })
      })
  }
}



export function forgotPassword(email){
  return dispatch => {
    dispatch({
      type: FORGOT_PASSWORD,
    })

    return forgotUserPassword(email)
      .then(response => {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
        })
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          dispatch({
            type: FORGOT_PASSWORD_ERROR_EMAIL_NOT_FOUND,
            error,
          })
        } else {
          logException(error)
          dispatch({
            type: FORGOT_PASSWORD_FAILURE,
            error,
          })
        }
      })
  }
}

export function forgotPasswordStatusReset() {
  return dispatch => {
    dispatch({
      type: FORGOT_PASSWORD_STATUS_RESET,
    })
  }
}

export function newPassword(token, password){
  return dispatch => {
    dispatch({
      type: NEW_PASSWORD,
      token,
    })

    return newUserPassword(token, password)
      .then(response => {
        dispatch({
          type: NEW_PASSWORD_SUCCESS,
        })
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: NEW_PASSWORD_FAILURE,
          error,
        })
      })
  }
}

/**
 * Refresh react router by pushing the current location - or if the user is on root page, to their
 * profile's preferred front page. This is important to update @provideHook's fetch so all user
 * relevant data/logic gets updated.
 */
export function afterLoginRefresh() {
  return (dispatch, getState) => {
    const state = getState()
    const currentLocation = selectLocationPath(state)
    const currentStatus = selectLocationStatus(state)
    const profileFrontPage = state.user.get('frontPage')

    if (currentLocation === '/' || currentStatus !== 200) {
      dispatch(push(profileFrontPage || '/'))
    } else {
      dispatch(push(currentLocation))
    }
  }
}

export function setUserProfile(profile) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_USER_PROFILE,
      profile,
      timestamp: Date.now(),
    })

    if (canUseDOM) {
      setThemeBodyClass(selectUserActiveTheme(getState()))
      setLayoutWidthBodyClass(selectUserLayoutWidth(getState()))
    }
  }
}




export function setLoggedInUser(id, name, email, profile, userHash, almaIdHash, jwt = '') {
  return dispatch => {
    dispatch({
      type: SET_LOGGED_IN_USER,
      username: name,
      userId: id,
      userHash,
      almaIdHash,
      email,
      jwt,
    })
  }
}


/**
 * Initialize user and profile data from cookies. For logged in users this means
 * fetching data using the JWT cookie. For logged out users this means reading
 * the tempProfile cookie.
 * @returns Promise<string> Resolves to JWT (logged in) or null (logged out)
 */
export function initUser(cookies) {
  let jwt = null

  const userCookie = cookies.get(AMPPARIT_USER_COOKIE_NAME)
  if (typeof userCookie === 'string' && userCookie.match(/^eyJ/)) {
    jwt = userCookie
  } else if (userCookie?.jwt?.match(/^eyJ/)) { // Legacy cookie format. Can remove support after 2025-07-01
    jwt = userCookie.jwt
  }

  const presetProfileId = cookies.get(PRESET_PROFILE_COOKIE_NAME)

  return dispatch => {
    // Set active preset profile if that cookie was set
    if (presetProfileId) {
      dispatch(setActivePresetProfile(presetProfileId, cookies))
    }

    // Init profile state from tempProfile if user is not logged in
    if (!jwt) {
      const tempProfile = getTempProfile(cookies)
      dispatch(setTempProfile(cookies, tempProfile))
      return Promise.resolve(null)
    }

    // User cookie is httpOnly, default config.cookieOptions is not. Since initUser()
    // only runs in server side rendering we are able to set or remove it here.
    const userCookieOptions = Object.assign({}, config.cookieOptions, { httpOnly: true })

    // User is logged in. Fetch user info and profile settings, refresh cookie
    return getUser(jwt)
      .then(response => {
        const { id, name, email, profile, userHash, almaIdHash } = response.data
        dispatch(setLoggedInUser(id, name, email, profile, userHash, almaIdHash, jwt))
        dispatch(setUserProfile(profile))
        cookies.set(AMPPARIT_USER_COOKIE_NAME, jwt, userCookieOptions)
        return jwt
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          cookies.remove(AMPPARIT_USER_COOKIE_NAME, userCookieOptions)
        } else {
          logException(error)
        }
        return null
      })
  }
}


export function logoutAmpparit() {
  return (dispatch, getState) => {
    dispatch({
      type: LOGOUT_USER,
    })

    return logoutAmpparitUser()
      .then(() => {
        dispatch({
          type: LOGOUT_USER_SUCCESS,
        })
        /*
        This code is not currently needed because AlmaTunnus always does a hard redirect after logout,
        thus giving us a blank state with no remains of profile settings. But if we were ever to stop using
        AlmaTunnus due to Ampparit being sold etc, we will need to restore state-resetting
        setThemeBodyClass(selectUserActiveTheme(getState()))
        setLayoutWidthBodyClass(selectUserLayoutWidth(getState()))
        return dispatch(push('/')) // Triggers provideHooks to update state data
        */
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: LOGOUT_USER_FAILURE,
          error,
        })
      })
  }
}

export function addUserWeatherLocation(locationId) {
  return (dispatch, getState) => {

    dispatch({
      type: ADD_WEATHER_LOCATION,
    })

    return addWeatherLocation(locationId)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: ADD_WEATHER_LOCATION_FAILURE,
          error,
        })
      })
  }
}

export function setUserWeatherLocations(locationIds) {
  return (dispatch, getState) => {

    dispatch({
      type: SET_WEATHER_LOCATIONS,
    })

    return setWeatherLocations(locationIds)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: SET_WEATHER_LOCATIONS_FAILURE,
          error,
        })
      })
  }
}


export function setUserDefaultWeatherLocation(locationId) {
  return (dispatch, getState) => {

    dispatch({
      type: SET_DEFAULT_WEATHER_LOCATION,
      locationId,
    })

    return setDefaultWeatherLocation(locationId)
      .then(response => {
        dispatch(setUserProfile(response.data.profile))
      })
      .catch(error => {
        logException(error)
        dispatch({
          type: SET_DEFAULT_WEATHER_LOCATION_FAILURE,
          error,
        })
      })
  }
}

export function checkTempProfileCreate() {
  return (dispatch, getState) => {
    const loggedIn = selectIsUserLoggedIn(getState())
    if (!loggedIn && !isTempProfileModified(new Cookies())) {
      dispatch(displayTemporaryProfileInfoModal())
    }
    return true
  }
}


/**
 * DO NOT USE UNTIL getItems() IN api.js HAS BEEN CHANGED TO NOT
 * DEPEND ON JWT FOR CHOOSING BETWEEN CACHEABLE AND NON-CACHEABLE
 * ENDPOINTS.
 *
 * Clears user JWT from redux store after server side rendering but
 * before state serialization. This way the JWT will only exists on
 * the client as HttpOnly cookie, meaning it cannot possibly be stolen
 * even if someone manages to inject malicious code.
 */
export function clearUserJWT() {
  return { type: CLEAR_USER_JWT }
}
