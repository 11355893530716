import { buildPathWithQuery } from './utils'
import { redirectToItem } from '../components/item/itemsActions'
import { displayRedirectModal } from '../components/modal/modalActions'
import { COOKIE_REDIRECT_MODAL_DISPLAYED } from '../components/modal/RedirectModal'
import shallowEqual from 'shallowequal'
import { logException } from '../sentry'


export function handleAppQueryParams(props) {
  const {
    dispatch,
    cookies,
    location,
  } = props

  const query = Object.assign({}, location.query)

  // Check if redirect modal should be displayed or redirection made
  if (location && query && query.share) {
    const itemId = query.share
    const itemPromise = []

    Promise.all(itemPromise).then(() => {

      if (!cookies.get(COOKIE_REDIRECT_MODAL_DISPLAYED)) {
        // Set cookie to prevent modal being displayed more than once a day
        const days = 1
        const date = new Date()
        date.setDate(date.getDate() + days)
        cookies.set(COOKIE_REDIRECT_MODAL_DISPLAYED, true, { 'expires': date })

        dispatch(displayRedirectModal(itemId, !!location.query.share))
      } else {
        dispatch(redirectToItem(itemId))
      }
    }).catch(error => {
      logException(error)
    })
  }

  // update the address in case query params were altered
  if (!shallowEqual(location.query, query)) {
    window.history.replaceState({}, '', buildPathWithQuery('/', query))
  }
}
