import React, { useState, useCallback, useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import moment from 'moment-timezone'
import { getNextForecast } from './forecastParser'
import WeatherHourly from './WeatherHourly'
import Loading from '../../components/util/Loading'
import WeatherDay from './WeatherDay'

const WeeklyWeather = ({loading, locationData}) => {

  const [selectedDay, setSelectedDay] = useState(null)

  const selectedMoment = useMemo(() => selectedDay ? moment(selectedDay) : null, [selectedDay])

  // Find the index of the selected day in the locationData list
  const index = useMemo(() => locationData.findIndex((data) => moment(data.get('date')).isSame(selectedMoment)), [locationData, selectedMoment])

  const handleDayClick = useCallback((date) => {
    setSelectedDay(prevDate => prevDate === date ? null : date)
  }, [])

  return (
    <div className='weekly-weather'>
      <div className='weekly-weather-day-header'>
        <h3>10 vrk sää</h3>
      </div>
      <Loading loading={ loading }>
        <ul className='weekly-weather-days'>
          { locationData.toArray().map((dailyData, i) => {

            const date = dailyData.get('date')
            let day = moment(date).format('dd D.M.')

            let temperature = dailyData.get('maxTemperature')
            if (i === 0) {
              day = 'Tänään'
              temperature = getNextForecast(locationData)?.get('temperature') ?? 0
            }

            temperature = (temperature > 0 ? '+' : '') + Math.floor(temperature)

            const minTemperature = (dailyData.get('minTemperature') > 0 ? '+' : '') + Math.floor(dailyData.get('minTemperature'))

            return (
              <Fragment key={ dailyData.get('date') }>
                <li className='weekly-weather-list-wrapper' key={ date }>
                  <WeatherDay
                    handleDayClick={ handleDayClick }
                    dailyData={ dailyData }
                    selectedDay={ selectedDay }
                    day={ day }
                    temperature={ temperature }
                    minTemperature={ minTemperature }
                  />
                </li>

                { selectedDay === date && index !== -1 && (
                  <WeatherHourly
                    onComponentClick={ () => setSelectedDay(null) }
                    locationData={ locationData }
                    index={ index }
                  />
                ) }
              </Fragment>
            )
          }) }
        </ul>
      </Loading>
    </div>
  )
}

WeeklyWeather.propTypes = exact({
  loading: PropTypes.bool.isRequired,
  locationData: ImmutablePropTypes.list.isRequired,
})

export default WeeklyWeather
