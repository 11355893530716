import { createSelector } from 'reselect'
import Immutable from 'immutable'
import moment from 'moment-timezone'
import { itemIdsToItems } from '../components/item/itemsTransformers'

const selectMostRead = state => state.items.mostRead
const selectTimestamp = state => state.time.get('timestamp')

/**
 * @return {{items: Immutable.List<ItemRecord>, loading: boolean, timestamp: number}}
 */
export const selectNotFoundViewData = createSelector(
  [selectMostRead, selectTimestamp, state => state],
  (mostRead, timestamp, state) => {
    let items = Immutable.List()

    const group = mostRead.get('groups')
      .find(groupCandidate => {
        return moment
          .duration(moment.unix(groupCandidate.maxTimestamp).diff(moment.unix(groupCandidate.minTimestamp)))
          .asHours() === 1
      })

    if (group) {
      items = itemIdsToItems(state, group.itemIds.toList())
    }

    return {
      items,
      loading: mostRead.get('loading') || items.isEmpty(),
      timestamp,
    }
  }
)
