export const ANNOUNCE_SR_MESSAGE = 'ANNOUNCE_SR_MESSAGE'

export function announceToScreenreader(message) {
  return (dispatch) => {
    dispatch({
      type: ANNOUNCE_SR_MESSAGE,
      message,
    })
  }
}
