import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { itemShareIntent } from '../item/itemsActions'

export default class ShareButtonWhatsapp extends PureComponent {
  render() {
    const { link, itemTitle } = this.props
    const href = 'https://wa.me/send?text=' + encodeURIComponent(itemTitle) + '%0a%0aLue artikkeli: ' + link
    const title = 'Jaa uutinen WhatsApp-sovelluksessa'

    return (
      <a
        className='fa-stack share-whatsapp gtm-share'
        data-action='share/whatsapp/share'
        href={ href }
        target='_blank'
        rel='noopener noreferrer'
        title={ title }
        onClick={ this.handleClick }
      >
        <span className='sr-only'>{ title }</span>
        <span className='fa fa-circle fa-stack-2x gtm-share' aria-hidden='true' />
        <span className='fa fa-whatsapp fa-stack-1x gtm-share' aria-hidden='true' />
      </a>
    )
  }

  handleClick = () => {
    const { itemId, dispatch } = this.props
    dispatch(itemShareIntent(itemId, 'whatsapp'))
  }
}

ShareButtonWhatsapp.propTypes = exact({
  itemTitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
})
