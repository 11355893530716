import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import '../../styles/settings.pcss'
import { Helmet } from 'react-helmet'
import TagsInput from 'react-tagsinput'
import Button from '../../components/ui/Button'
import {
  addWordFilter,
  fetchUserProfile,
  removeWordFilter,
} from '../../user/userActions'
import VoteFilter from './VoteFilter'
import {
  selectUserVoteFilterThreshold,
  selectUserWordFilters,
} from '../../selectors/userSelector'

const CHAR_FILTERS = [
  { char: '?', description: '(kysymysmerkki)' },
  { char: '!', description: '(huutomerkki)' },
  { char: '"', description: '(lainausmerkki)' },
  { char: '…', description: '(kolme pistettä)' },
]

const WordFiltersView = () => {
  const dispatch = useDispatch()
  const wordFilters = useSelector(selectUserWordFilters)
  const voteFilterThreshold = useSelector(selectUserVoteFilterThreshold)

  const [inputText, setInputText] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const tagsRef = useRef(null)

  useEffect(() => {
    dispatch(fetchUserProfile(5))
  }, [dispatch])

  const handleSubmit = () => {
    tagsRef.current.accept()
  }

  const handleChange = (tags) => {
    // handle deleted tags (excluding single char ones)
    wordFilters.filter((expression) => expression.length > 1).forEach((expression) => {
      if (!tags.includes(expression)) {
        dispatch(removeWordFilter(expression))
      }
    })

    const setError = (inputText, errorMessage) => {
      setInputText(inputText)
      setErrorMessage(errorMessage)
    }

    const handleAddition = (tag) => {
      const str = tag.trim().replace(/\s+/g, ' ')

      if (str.length < 2 || str.match(/^.\*$/)) {
        return setError(tag, 'Sanan tulee sisältää vähintään kaksi merkkiä.')
      }
      if (str.length > 84) {
        return setError(tag, 'Sanan tai lauseen tulee olla maksimissaan 84 merkkiä.')
      }
      if (str.match(/\*./)) {
        return setError(tag, 'Tähtimerkin "*" tulee sijaita sanan lopussa.')
      }
      if (str.includes('*') && str.includes(' ')) {
        return setError(tag, 'Tähtimerkin "*" käyttö on rajattu yksittäisiin sanoihin.')
      }
      if (str.split(' ').some((word) => word.length < 2)) {
        return setError(tag, 'Kaikkien sanojen tulee sisältää vähintään kaksi merkkiä.')
      }
      const invalidCharacters = str.replace(/[ a-zA-Z0-9À-ÖØ-öø-ÿåÅČĆŽŠĐčćžšđ*-]/g, '')
      if (invalidCharacters.length > 0) {
        return setError(tag, 'Sana tai lause voi sisältää vain kirjaimia ja numeroita. Poista merkit:' + invalidCharacters)
      }

      setInputText('')
      setErrorMessage('')

      dispatch(addWordFilter(str))
    }

    // handle added tags
    tags.forEach((tag) => {
      if (!wordFilters.includes(tag)) {
        handleAddition(tag)
      }
    })
  }

  const handleCharFilterChange = (e) => {
    const char = e.target.value
    if (e.target.checked) {
      dispatch(addWordFilter(char))
    } else {
      dispatch(removeWordFilter(char))
    }
  }

  const handleTagRemove = (removeFnc, key) => {
    return function (e) {
      return removeFnc(key)
    }
  }

  const renderLayout = (tagComponents, inputComponent) => {
    return (
      <div>
        { inputComponent }
        <span className='description'>
          Sanan loppuosan voi korvata *-merkillä. Sanan minimimerkkimäärä on 2 ja sanan tai lauseen maksimimerkkimäärä on 84.
        </span>
        <div className='tags'>{ tagComponents }</div>
      </div>
    )
  }

  const renderInput = ({ addTag, ...props }) => {
    const { onChange, onSubmit, value, ...other } = props
    return (
      <div className='input-wrapper'>
        <input type='text' onChange={ onChange } value={ value } { ...other } />
        <Button type='button' text='Lisää' onClick={ onSubmit } />
      </div>
    )
  }

  const renderTag = (props) => {
    const {
      handleTagRemove,
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props

    return (
      <span key={ key } { ...other }>
        { getTagDisplayValue(tag) }
        { !disabled && (
          <button
            type='button'
            className={ classnames('fa fa-times', classNameRemove) }
            onClick={ handleTagRemove(onRemove, key) }
            title='Poista suodatin'
            aria-label='Poista suodatin'
          />
        ) }
      </span>
    )
  }

  // Filter out the single character filters
  const tags = wordFilters.filter((expression) => expression.length > 1).toArray()

  return (
    <div>
      <Helmet title='Suodatus' />
      <section className='content'>
        <div className='personal word-filtering'>
          <div className='word-filters'>
            <h3>Uutisten sanasuodatus</h3>
            <p>
              Voit suodattaa uutisistasi tietyn sanan tai lauseen sisältävät otsikot pois. Lisää yksi sana tai lause kerrallaan.
              <br />
              Suodattimia voi olla enintään 40 kappaletta.
            </p>
            { !!errorMessage && <span className='settings-input-error'>{ errorMessage }</span> }
            <TagsInput
              ref={ tagsRef }
              value={ tags }
              currentValue={ inputText }
              onChange={ handleChange }
              renderLayout={ renderLayout }
              renderInput={ renderInput }
              renderTag={ renderTag }
              onRemove={ handleTagRemove }
              inputProps={ {
                placeholder: 'Lisää sana tai lause',
                'aria-label': 'Suodatettava sana tai lause',
                onSubmit: handleSubmit,
              } }
              tagProps={ {
                className: 'react-tagsinput-tag',
                classNameRemove: 'react-tagsinput-remove',
                handleTagRemove: handleTagRemove,
              } }
              removeKeys={ [] }
            />
          </div>
          <hr />
          <div className='char-filters'>
            <h3>Uutisten merkkisuodatus</h3>
            <p>Voit suodattaa uutisistasi tietyn merkin sisältävät otsikot.</p>
            <ul>
              { CHAR_FILTERS.map(({ char, description }) => {
                return (
                  <li key={ char }>
                    <label>
                      <input
                        type='checkbox'
                        value={ char }
                        checked={ wordFilters.contains(char) }
                        onChange={ handleCharFilterChange }
                      />
                      <span className='char-filter-char'>{ char }</span> { description }
                    </label>
                  </li>
                )
              }) }
            </ul>
          </div>
          <hr />
          <div className='votes-filter'>
            <h3>Negatiivisia ääniä saaneiden uutisten suodatus</h3>
            <p>Suodata pois mielestäsi liian monta miinusääntä saaneet uutisotsikot.</p>
            <VoteFilter dispatch={ dispatch } voteFilterThreshold={ voteFilterThreshold } />
          </div>
        </div>
      </section>
    </div>
  )
}

WordFiltersView.propTypes = exact({
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  value: PropTypes.string,
  onRemove: PropTypes.func,
  handleTagRemove: PropTypes.func,
  classNameRemove: PropTypes.string,
  tag: PropTypes.string,
  key: PropTypes.number,
  disabled: PropTypes.bool,
  getTagDisplayValue: PropTypes.func,
  handleRemove: PropTypes.func,
  handleCharFilterChange: PropTypes.func,
})

export default WordFiltersView
