import { combineReducers } from 'redux'
import Immutable from 'immutable'

import * as actions from './categoryActions'

export const CategoryRecord = Immutable.Record({
  id: 0,
  slug: '',
  name: '',
  parentId: 0,
  treeLevel: 0,
  desc: '',
  title: '',
  h1: '',
  relatedCategoryIds: Immutable.OrderedSet(),
  ogTitle: '',
  ogDesc: '',
  ogImage: '',
}, 'category')

function loading(state = false, action) {
  switch (action.type) {
    case actions.FETCH_CATEGORIES:
      return true

    case actions.FETCH_CATEGORIES_SUCCESS:
    case actions.FETCH_CATEGORIES_FAILURE:
      return false

    default:
      return state
  }
}

function categories(state = Immutable.Map(), action) {
  switch (action.type) {
    case actions.FETCH_CATEGORIES_SUCCESS: {
      const recordsBySlug = {}
      action.response.data.forEach(category => {
        recordsBySlug[category.name] = CategoryRecord({
          id: category.id,
          slug: category.name,
          name: category.properName,
          parentId: category.parentId,
          treeLevel: category.treeLevel,
          desc: category.metaDescription,
          title: category.title,
          h1: category.h1,
          relatedCategoryIds: Immutable.OrderedSet(category.relatedCategoryIds),
          ogTitle: category.ogTitle || category.title,
          ogDesc: category.ogDescription || category.metaDescription,
          ogImage: category.ogImage,
        })
      })
      return Immutable.Map(recordsBySlug)
    }
    default:
      return state
  }
}

function timestamp(state = null, action) {
  switch (action.type) {
    case actions.FETCH_CATEGORIES_SUCCESS:
      return action.timestamp

    default:
      return state
  }
}

export default combineReducers({
  loading,
  categories,
  timestamp,
})
