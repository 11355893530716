import React, { Component } from 'react'
import Sticky from 'react-stickynode'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import './queue.pcss'

export default class Queue extends Component {
  constructor() {
    super()

    this.state = {
      sticky: null,
    }

    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    if (this.state.sticky === null) {
      this.setState({ sticky: this.shouldStick() })
    }
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const { count, reload, isTagView } = this.props

    let text = null
    if (reload === true) {
      text = 'Uusimmat'
    } else if (count > 1) {
      text = `${count} uutta juttua`
    } else if (count === 1) {
      text = '1 uusi juttu'
    }

    return (
      !isTagView ? (
        <Sticky
          className='items-queue'
          top={ 58 }
          bottomBoundary='#sticky-ad-bottom-boundary'
          innerZ={ 1 }
          enabled={ this.state.sticky }
        >
          { text ? (
            <div className='items-queue__content items-queue__content_new-items'>
              <button type='button' className='items-queue__button' onClick={ this.handleClick }>
                <span className='fa fa-chevron-up items-queue__content-arrow' />
                { text }
              </button>
            </div>
          ) : (
            <div className='items-queue__content items-queue__content_empty' />
          ) }
        </Sticky> )
        : (
          <div className='items-refresh'>
            <div className='items-queue__content items-queue__content_new-items'>
              <button type='button' className='items-queue__button' onClick={ this.handleClick }>
                <span className='fa fa-refresh items-queue__content-arrow' />
                Päivitä lista
              </button>
            </div>
          </div>
        )
    )
  }

  handleClick = () => {
    this.props.onUnqueueItems()
    if (this.state.sticky) {
      window.scroll({top: 0, behavior: 'smooth'})
    }
  }

  handleResize(e) {
    this.setState({
      sticky: this.shouldStick(),
    })
  }

  shouldStick() {
    return window.innerWidth <= 644
  }
}

Queue.propTypes = exact({
  count: PropTypes.number,
  reload: PropTypes.bool.isRequired,
  onUnqueueItems: PropTypes.func.isRequired,
  isTagView: PropTypes.bool.isRequired,
})
