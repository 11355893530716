import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { provideHooks } from 'redial'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import Immutable from 'immutable'
import { openSearch, search, searchNext } from './searchActions'
import SearchResultsEmpty from './SearchResultsEmpty'
import SearchList from './SearchList'
import { itemIdsToItems } from '../../components/item/itemsTransformers'
import './search.pcss'
import Loading from '../../components/util/Loading'
import SidebarMenu from '../../components/layout/SidebarMenu'
import GeneralSidebar from '../../components/layout/GeneralSidebar'
import ItemsListHeader from '../../components/lists/ItemsListHeader'
import MainContent from '../../components/wrappers/MainContent'
import classnames from 'classnames'

@provideHooks({
  fetch: ({ dispatch, query }) => {
    return dispatch(search(query.q))
  },
})

export class SearchView extends Component {
  constructor() {
    super()
    this.state = {
      page: 1,
    }
  }

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    items: ImmutablePropTypes.orderedSet.isRequired,
    totalCount: PropTypes.number.isRequired,
    timestamp: PropTypes.number.isRequired,
    loadingNext: PropTypes.bool.isRequired,
    errorLength: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    searchString: PropTypes.string.isRequired,
  }

  componentDidMount() {
    this.props.dispatch(openSearch())
    window.scrollTo(0, 0)
  }

  render() {
    const {
      items,
      totalCount,
      timestamp,
      loadingNext,
      errorLength,
      inProgress,
      dispatch,
      loggedIn,
      searchString,
    } = this.props

    let itemsList

    if (items.size === 0) {
      itemsList = (
        <SearchResultsEmpty errorLength={ errorLength } />
      )
    } else {
      itemsList = (
        <SearchList
          items={ items }
          totalCount={ totalCount }
          timestamp={ timestamp }
          loadingNext={ loadingNext }
          onMoreItemsClick={ this.handleMoreItemsClick }
          dispatch={ dispatch }
          loggedIn={ loggedIn }
        />
      )
    }

    return (
      <div id='search' className='main-container container'>
        <SidebarMenu />
        <MainContent className={ classnames({'content_no-bg': !inProgress}) }>
          <div id='sticky-ad-bottom-boundary'>
            <ItemsListHeader heading={ `Tulokset haulle: ${searchString}` } dispatch={ dispatch } />
            <Loading loading={ inProgress }>
              <Helmet
                title='Haku'
                meta={ [
                  {name: 'description', content: 'Ampparit.com kokoaa yhteen koko Suomen uutistarjonnan. Löydä kätevästi kaikki tuoreimmat uutiset ilmaiseksi Amppareiden uutispalvelusta! Se kattavin uutistarjonta \u2013 Ampparit.com'},
                  {property: 'og:title', content: 'Haku \u2013 Ampparit.com'},
                  {property: 'og:description', content: 'Ampparit.com kokoaa yhteen koko Suomen uutistarjonnan. Löydä kätevästi kaikki tuoreimmat uutiset ilmaiseksi Amppareiden uutispalvelusta! Se kattavin uutistarjonta \u2013 Ampparit.com'},
                ] }
              />
              { !inProgress && itemsList }
            </Loading>
          </div>
        </MainContent>
        <div className='sidebar-container'>
          <GeneralSidebar />
        </div>
      </div>
    )
  }

  handleMoreItemsClick = () => {
    const page = this.state.page + 1
    this.props.dispatch(searchNext(this.props.searchString, page))
    this.setState({
      page,
    })
  }
}

export default connect(state => {
  const items = itemIdsToItems(state, state.search.get('itemIds'))

  const relatedTags = items
    .reduce((acc, item) => {
      return item.get('tags').reduce((a, tag) => {
        return a.set(tag.slug, tag)
      }, acc)
    }, Immutable.Map())
    .toList()

  return {
    items,
    timestamp: state.time.get('timestamp'),
    inProgress: state.search.get('inProgress'),
    loadingNext: state.search.get('loadingNext'),
    searchString: state.search.get('searchString'),
    error: state.search.get('error'),
    errorLength: state.search.get('errorLength'),
    totalCount: state.search.get('totalCount'),
    query: state.routing.locationBeforeTransitions.query,
    relatedTags,
  }
})(SearchView)
