import { selectBlacklistedCategoryIds } from '../../selectors/categoriesSelector'
import {
  getFeatured,
  getItem,
  getItemsForCategory,
  getItemsForTag,
  getMostRead,
  getMostVoted,
  getNewest,
  getPaywalled,
  getStatsForItems,
  getUserFeedItems,
  getUserItems,
  postClick,
  postVote,
  postReport,
} from '../../api/api'
import { logException } from '../../sentry'
import { setStatus } from '../../status/statusActions'
import {
  selectIsUserLoggedIn,
  selectUserJWT,
  selectUserBlacklistedTagIds,
  selectUserWhitelistedTagIds,
  selectUserWhitelistedCategoryIds,
} from '../../selectors/userSelector'
import { isTimestampFresh } from '../../lib/utils'
import { itemIsBlacklisted, sendItemToActiveList } from '../../lib/items'
import { selectPresetBlacklistedCategoryIds } from '../../selectors/profilesSelector'

export const REFRESH_SEGMENT_LIST = 'REFRESH_SEGMENT_LIST'
export const ACTIVE_LIST = 'ACTIVE_LIST'

export const LIST_TYPE_NONE = 'none'
export const LIST_TYPE_MAIN = 'main'
export const LIST_TYPE_TAG = 'tag'
export const LIST_TYPE_CATEGORY = 'category'

// for tag and category type lists, the name is dynamic and based on category or tag slug
export const LIST_NAME_NONE = 'none'
export const LIST_NAME_PERSONAL = 'personal'
export const LIST_NAME_NEWEST = 'newest'
export const LIST_NAME_PAYWALLED = 'paywalled'
export const LIST_NAME_FEATURED = 'featured'

export const FETCH_STATS_FOR_ITEMS = 'FETCH_STATS_FOR_ITEMS'
export const FETCH_STATS_FOR_ITEMS_SUCCESS = 'FETCH_STATS_FOR_ITEMS_SUCCESS'
export const FETCH_STATS_FOR_ITEMS_FAILURE = 'FETCH_STATS_FOR_ITEMS_FAILURE'

export const FETCH_SHARED_ITEM = 'FETCH_SHARED_ITEM'
export const FETCH_SHARED_ITEM_SUCCESS = 'FETCH_SHARED_ITEM_SUCCESS'
export const FETCH_SHARED_ITEM_FAILURE = 'FETCH_SHARED_ITEM_FAILURE'

export const FETCH_ITEMS = 'FETCH_ITEMS'
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS'
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE'
export const FETCH_NEXT_ITEMS = 'FETCH_NEXT_ITEMS'
export const FETCH_NEXT_ITEMS_FAILURE = 'FETCH_NEXT_ITEMS_FAILURE'
export const FETCH_NEXT_ITEMS_SUCCESS = 'FETCH_NEXT_ITEMS_SUCCESS'
export const FETCH_BATCH_ITEMS = 'FETCH_BATCH_ITEMS'
export const FETCH_BATCH_ITEMS_FAILURE = 'FETCH_BATCH_ITEMS_FAILURE'
export const FETCH_BATCH_ITEMS_SUCCESS = 'FETCH_BATCH_ITEMS_SUCCESS'
export const QUEUE_ITEM = 'QUEUE_ITEM'
export const UNQUEUE_ITEMS = 'UNQUEUE_ITEMS'

export const FETCH_CATEGORY_ITEMS = 'FETCH_CATEGORY_ITEMS'
export const FETCH_CATEGORY_ITEMS_SUCCESS = 'FETCH_CATEGORY_ITEMS_SUCCESS'
export const FETCH_CATEGORY_ITEMS_FAILURE = 'FETCH_CATEGORY_ITEMS_FAILURE'
export const FETCH_NEXT_CATEGORY_ITEMS = 'FETCH_NEXT_CATEGORY_ITEMS'
export const FETCH_NEXT_CATEGORY_ITEMS_FAILURE = 'FETCH_NEXT_CATEGORY_ITEMS_FAILURE'
export const FETCH_NEXT_CATEGORY_ITEMS_SUCCESS = 'FETCH_NEXT_CATEGORY_ITEMS_SUCCESS'
export const FETCH_BATCH_CATEGORY_ITEMS = 'FETCH_BATCH_CATEGORY_ITEMS'
export const FETCH_BATCH_CATEGORY_ITEMS_FAILURE = 'FETCH_BATCH_CATEGORY_ITEMS_FAILURE'
export const FETCH_BATCH_CATEGORY_ITEMS_SUCCESS = 'FETCH_BATCH_CATEGORY_ITEMS_SUCCESS'
export const QUEUE_CATEGORY_ITEM = 'QUEUE_CATEGORY_ITEM'
export const UNQUEUE_CATEGORY_ITEMS = 'UNQUEUE_CATEGORY_ITEMS'

export const FETCH_TAG_ITEMS = 'FETCH_TAG_ITEMS'
export const FETCH_TAG_ITEMS_SUCCESS = 'FETCH_TAG_ITEMS_SUCCESS'
export const FETCH_TAG_ITEMS_FAILURE = 'FETCH_TAG_ITEMS_FAILURE'
export const FETCH_NEXT_TAG_ITEMS = 'FETCH_NEXT_TAG_ITEMS'
export const FETCH_NEXT_TAG_ITEMS_FAILURE = 'FETCH_NEXT_TAG_ITEMS_FAILURE'
export const FETCH_NEXT_TAG_ITEMS_SUCCESS = 'FETCH_NEXT_TAG_ITEMS_SUCCESS'
export const FETCH_BATCH_TAG_ITEMS = 'FETCH_BATCH_TAG_ITEMS'
export const FETCH_BATCH_TAG_ITEMS_FAILURE = 'FETCH_BATCH_TAG_ITEMS_FAILURE'
export const FETCH_BATCH_TAG_ITEMS_SUCCESS = 'FETCH_BATCH_TAG_ITEMS_SUCCESS'
export const QUEUE_TAG_ITEM = 'QUEUE_TAG_ITEM'
export const UNQUEUE_TAG_ITEMS = 'UNQUEUE_TAG_ITEMS'

export const FETCH_USER_ITEMS = 'FETCH_USER_ITEMS'
export const FETCH_USER_ITEMS_SUCCESS = 'FETCH_USER_ITEMS_SUCCESS'
export const FETCH_USER_ITEMS_FAILURE = 'FETCH_USER_ITEMS_FAILURE'
export const FETCH_NEXT_USER_ITEMS = 'FETCH_NEXT_USER_ITEMS'
export const FETCH_NEXT_USER_ITEMS_SUCCESS = 'FETCH_NEXT_USER_ITEMS_SUCCESS'
export const FETCH_NEXT_USER_ITEMS_FAILURE = 'FETCH_NEXT_USER_ITEMS_FAILURE'
export const FETCH_BATCH_USER_ITEMS = 'FETCH_BATCH_USER_ITEMS'
export const FETCH_BATCH_USER_ITEMS_SUCCESS = 'FETCH_BATCH_USER_ITEMS_SUCCESS'
export const FETCH_BATCH_USER_ITEMS_FAILURE = 'FETCH_BATCH_USER_ITEMS_FAILURE'

export const ITEM_VOTE = 'ITEM_VOTE'
export const ITEM_VOTE_SUCCESS = 'ITEM_VOTE_SUCCESS'
export const ITEM_VOTE_FAILURE = 'ITEM_VOTE_FAILURE'

export const ITEM_REPORT = 'ITEM_REPORT'
export const ITEM_REPORT_SUCCESS = 'ITEM_REPORT_SUCCESS'
export const ITEM_REPORT_FAILURE = 'ITEM_REPORT_FAILURE'

// These are the vote type strings recognized by API
export const ITEM_VOTE_TYPE_UP = 'plus'
export const ITEM_VOTE_TYPE_NEUTRAL = 'cancel'
export const ITEM_VOTE_TYPE_DOWN = 'minus'

// These are the report type strings recognized by API
export const ITEM_REPORT_TYPE_SPAM = 'spam'
export const ITEM_REPORT_TYPE_NOT_WORKING = 'not_working'
export const ITEM_REPORT_TYPE_PAYWALL = 'paywall'
export const ITEM_REPORT_TYPE_NEUTRAL = 'cancel'

export const ITEM_SHARE_INTENT = 'ITEM_SHARE_INTENT'

export const ITEM_CLICK = 'ITEM_CLICK'
export const ITEM_CLICK_SUCCESS = 'ITEM_CLICK_SUCCESS'
export const ITEM_CLICK_FAILURE = 'ITEM_CLICK_FAILURE'

export const FETCH_MOST_READ_ITEMS = 'FETCH_MOST_READ_ITEMS'
export const FETCH_MOST_READ_ITEMS_SUCCESS = 'FETCH_MOST_READ_ITEMS_SUCCESS'
export const FETCH_MOST_READ_ITEMS_FAILURE = 'FETCH_MOST_READ_ITEMS_FAILURE'

export const FETCH_MOST_VOTED_ITEMS = 'FETCH_MOST_VOTED_ITEMS'
export const FETCH_MOST_VOTED_ITEMS_SUCCESS = 'FETCH_MOST_VOTED_ITEMS_SUCCESS'
export const FETCH_MOST_VOTED_ITEMS_FAILURE = 'FETCH_MOST_VOTED_ITEMS_FAILURE'

export const FETCH_CUSTOM_FEED_ITEMS = 'FETCH_CUSTOM_FEED_ITEMS'
export const FETCH_CUSTOM_FEED_ITEMS_SUCCESS = 'FETCH_CUSTOM_FEED_ITEMS_SUCCESS'
export const FETCH_CUSTOM_FEED_ITEMS_FAILURE = 'FETCH_CUSTOM_FEED_ITEMS_FAILURE'

export const SET_ITEMS_LANGUAGE = 'SET_ITEMS_LANGUAGE'

export function InitializeSegmentList(refresh) {
  return (dispatch) => {
    dispatch({
      type: REFRESH_SEGMENT_LIST,
      refresh,
    })
  }
}

export function changeActiveList(listType, listName = 'main') {
  return (dispatch) => {
    dispatch({
      type: ACTIVE_LIST,
      listType,
      listName,
    })
  }
}

/**
 * @param {Immutable.Map} item
 */
export function reportClick(item, gaItemClickUiElement) {
  return (dispatch, getState) => {
    const itemId = item.get('id')
    dispatch({
      type: ITEM_CLICK,
      id: itemId,
      gaItemClickUiElement,
    })

    if (item.get('custom')) {
      return Promise.resolve()
    }

    return postClick(itemId).then(response => {
      dispatch({
        type: ITEM_CLICK_SUCCESS,
        id: itemId,
      })
    }).catch((error) => {
      dispatch({
        type: ITEM_CLICK_FAILURE,
        id: itemId,
      })

      // Ignore duplicate click on item
      // Ignore click on item that was available but has since been deleted
      const status = error.response?.status
      if (status !== 429 && status !== 404) {
        logException(error)
      }
    })
  }
}


export function voteItem(id, voteType) {
  return (dispatch) => {
    dispatch({
      type: ITEM_VOTE,
      voteType,
      id,
    })

    return postVote(id, voteType).then(response => {
      dispatch({
        type: ITEM_VOTE_SUCCESS,
        voteType,
        id,
        response,
      })
      return response
    }).catch((error) => {
      logException(error)
      dispatch({
        type: ITEM_VOTE_FAILURE,
        id,
        error,
      })
    })
  }
}

export function reportItem(id, reportType) {
  return (dispatch) => {
    dispatch({
      type: ITEM_REPORT,
      reportType,
      id,
    })

    return postReport(id, reportType).then(response => {
      dispatch({
        type: ITEM_REPORT_SUCCESS,
        reportType,
        id,
        response,
      })
      return response
    }).catch((error) => {
      logException(error)
      dispatch({
        type: ITEM_REPORT_FAILURE,
        id,
        error,
      })
    })
  }
}

export function itemShareIntent(itemId, channel) {
  return (dispatch) => {
    dispatch({
      type: ITEM_SHARE_INTENT,
      id: itemId,
      channel,
    })
  }
}

export function fetchSharedItem(itemId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SHARED_ITEM,
    })

    return getItem(itemId).then(response => {
      dispatch({
        type: FETCH_SHARED_ITEM_SUCCESS,
        itemId,
        response,
      })
    }).catch((error) => {
      // 404s happen, no need for special error handling
      if (!error.response || error.response.status !== 404) {
        logException(error)
      }
      dispatch(setStatus(404))
      dispatch({
        type: FETCH_SHARED_ITEM_FAILURE,
        error,
      })
    })
  }
}

function fetchItems(apiCall, list, params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_ITEMS,
      list,
    })

    addBlacklistParamsToRequest(getState(), params)

    return apiCall(params).then(response => {
      dispatch({
        type: FETCH_ITEMS_SUCCESS,
        list,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_ITEMS_FAILURE,
        list,
        error,
      })
    })
  }
}

export function fetchStatsForItems(itemIds) {
  return (dispatch) => {
    dispatch({
      type: FETCH_STATS_FOR_ITEMS,
    })

    itemIds = itemIds.slice(0, 100) // update max 100 first items

    return getStatsForItems(itemIds).then(response => {
      dispatch({
        type: FETCH_STATS_FOR_ITEMS_SUCCESS,
        itemIds,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_STATS_FOR_ITEMS_FAILURE,
        error,
      })
    })
  }
}

function fetchNextItems(apiCall, list, params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_NEXT_ITEMS,
      list,
    })

    addBlacklistParamsToRequest(getState(), params)

    return apiCall(params).then(response => {
      dispatch({
        type: FETCH_NEXT_ITEMS_SUCCESS,
        list,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_NEXT_ITEMS_FAILURE,
        list,
        error,
      })
    })
  }
}

function fetchBatchItems(apiCall, list, batch, params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_BATCH_ITEMS,
      list,
      batch,
    })

    addBlacklistParamsToRequest(getState(), params)

    return apiCall(params).then(response => {
      dispatch({
        type: FETCH_BATCH_ITEMS_SUCCESS,
        list,
        response,
        batch,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_BATCH_ITEMS_FAILURE,
        list,
        batch,
        error,
      })
    })
  }
}

export function fetchUserItems(params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_USER_ITEMS,
    })

    addBlacklistParamsToRequest(getState(), params)
    addWhitelistParamsToRequest(getState(), params)

    return getUserItems(selectUserJWT(getState()), params).then(response => {
      dispatch({
        type: FETCH_USER_ITEMS_SUCCESS,
        response,
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: FETCH_USER_ITEMS_FAILURE,
        error,
      })
    })
  }
}

export function fetchUserItemsNext(timestamp, params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_NEXT_USER_ITEMS,
    })

    addBlacklistParamsToRequest(getState(), params)
    addWhitelistParamsToRequest(getState(), params)

    return getUserItems(selectUserJWT(getState()), { ...params, timestamp }).then(response => {
      dispatch({
        type: FETCH_NEXT_USER_ITEMS_SUCCESS,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_NEXT_USER_ITEMS_FAILURE,
        error,
      })
    })
  }
}

export function fetchUserItemsBatch(batch, params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_BATCH_USER_ITEMS,
      batch,
    })

    addBlacklistParamsToRequest(getState(), params)
    addWhitelistParamsToRequest(getState(), params)

    return getUserItems(selectUserJWT(getState()), { ...params, batch }).then(response => {
      dispatch({
        type: FETCH_BATCH_USER_ITEMS_SUCCESS,
        response,
        batch,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_BATCH_USER_ITEMS_FAILURE,
        error,
        batch,
      })
    })
  }
}

export function fetchFeatured(params = {}) {
  return (dispatch, getState) => {

    const list = LIST_NAME_FEATURED

    dispatch({
      type: FETCH_ITEMS,
      list,
    })

    addBlacklistParamsToRequest(getState(), params)
    addWhitelistParamsToRequest(getState(), params)
    addLanguageToRequest(getState(), params)

    return getFeatured(selectUserJWT(getState()), params).then(response => {
      dispatch({
        type: FETCH_ITEMS_SUCCESS,
        list,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_ITEMS_FAILURE,
        list,
        error,
      })
    })
  }
}

export function fetchFeaturedNext(timestamp, params = {}) {
  return (dispatch, getState) => {

    const list = LIST_NAME_FEATURED

    dispatch({
      type: FETCH_NEXT_ITEMS,
      list,
    })

    addBlacklistParamsToRequest(getState(), params)
    addWhitelistParamsToRequest(getState(), params)
    addLanguageToRequest(getState(), params)

    return getFeatured(selectUserJWT(getState()), { ...params, timestamp }).then(response => {
      dispatch({
        type: FETCH_NEXT_ITEMS_SUCCESS,
        list,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_NEXT_ITEMS_FAILURE,
        list,
        error,
      })
    })
  }
}

export function fetchFeaturedBatch(batch, params = {}) {
  return (dispatch, getState) => {

    const list = LIST_NAME_FEATURED

    dispatch({
      type: FETCH_BATCH_ITEMS,
      list,
      batch,
    })

    addBlacklistParamsToRequest(getState(), params)
    addWhitelistParamsToRequest(getState(), params)
    addLanguageToRequest(getState(), params)

    return getFeatured(selectUserJWT(getState()), { ...params, batch }).then(response => {
      dispatch({
        type: FETCH_BATCH_ITEMS_SUCCESS,
        list,
        response,
        batch,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_BATCH_ITEMS_FAILURE,
        list,
        batch,
        error,
      })
    })
  }
}

export function fetchNewest() {
  return (dispatch, getState) => {
    const jwt = selectUserJWT(getState())
    return dispatch(fetchItems((params) => getNewest(jwt, params), LIST_NAME_NEWEST))
  }
}

export function fetchNewestBatch(batch) {
  return (dispatch, getState) => {
    const jwt = selectUserJWT(getState())
    return dispatch(fetchBatchItems((params) => getNewest(jwt, { ...params, batch }), LIST_NAME_NEWEST, batch))
  }
}

export function fetchNewestNext(timestamp) {
  return (dispatch, getState) => {
    const jwt = selectUserJWT(getState())
    return dispatch(fetchNextItems((params) => getNewest(jwt, { ...params, timestamp }), LIST_NAME_NEWEST))
  }
}

export function fetchPaywalled() {
  return (dispatch, getState) => {
    const jwt = selectUserJWT(getState())
    return dispatch(fetchItems((params) => getPaywalled(jwt, params), LIST_NAME_PAYWALLED))
  }
}

export function fetchPaywalledBatch(batch) {
  return (dispatch, getState) => {
    const jwt = selectUserJWT(getState())
    return dispatch(fetchBatchItems((params) => getPaywalled(jwt, { ...params, batch }), LIST_NAME_PAYWALLED, batch))
  }
}

export function fetchPaywalledNext(timestamp) {
  return (dispatch, getState) => {
    const jwt = selectUserJWT(getState())
    return dispatch(fetchNextItems((params) => getPaywalled(jwt, { ...params, timestamp }), LIST_NAME_PAYWALLED))
  }
}

export function fetchCategoryItems(category, params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_CATEGORY_ITEMS,
      category,
    })

    addBlacklistParamsToRequest(getState(), params)
    addLanguageToRequest(getState(), params)

    return getItemsForCategory(selectUserJWT(getState()), category, params).then(response => {
      dispatch({
        type: FETCH_CATEGORY_ITEMS_SUCCESS,
        category,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_CATEGORY_ITEMS_FAILURE,
        category,
        error,
      })
    })
  }
}

export function fetchCategoryItemsNext(category, timestamp, params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_NEXT_CATEGORY_ITEMS,
      category,
    })

    addBlacklistParamsToRequest(getState(), params)
    addLanguageToRequest(getState(), params)

    return getItemsForCategory(selectUserJWT(getState()), category, { ...params, timestamp }).then(response => {
      dispatch({
        type: FETCH_NEXT_CATEGORY_ITEMS_SUCCESS,
        category,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_NEXT_CATEGORY_ITEMS_FAILURE,
        category,
        error,
      })
    })
  }
}

export function fetchCategoryItemsBatch(category, batch, params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_BATCH_CATEGORY_ITEMS,
      category,
      batch,
    })

    addBlacklistParamsToRequest(getState(), params)
    addLanguageToRequest(getState(), params)

    return getItemsForCategory(selectUserJWT(getState()), category, { ...params, batch }).then(response => {
      dispatch({
        type: FETCH_BATCH_CATEGORY_ITEMS_SUCCESS,
        category,
        response,
        batch,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_BATCH_CATEGORY_ITEMS_FAILURE,
        category,
        error,
        batch,
      })
    })
  }
}

export function fetchTagItems(tag, params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_TAG_ITEMS,
      tag,
    })

    addBlacklistParamsToRequest(getState(), params)

    return getItemsForTag(selectUserJWT(getState()), tag, params).then(response => {
      dispatch({
        type: FETCH_TAG_ITEMS_SUCCESS,
        tag,
        response,
      })
    }).catch((error) => {
      // 404s happen, no need for special error handling
      if (!error.response || error.response.status !== 404) {
        logException(error)
      }
      dispatch({
        type: FETCH_TAG_ITEMS_FAILURE,
        tag,
        error,
      })
    })
  }
}

export function fetchTagItemsNext(tag, timestamp, params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_NEXT_TAG_ITEMS,
      tag,
    })

    addBlacklistParamsToRequest(getState(), params)

    return getItemsForTag(selectUserJWT(getState()), tag, { ...params, timestamp }).then(response => {
      dispatch({
        type: FETCH_NEXT_TAG_ITEMS_SUCCESS,
        tag,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_NEXT_TAG_ITEMS_FAILURE,
        tag,
        error,
      })
    })
  }
}

export function fetchTagItemsBatch(tag, batch, params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_BATCH_TAG_ITEMS,
      tag,
      batch,
    })

    addBlacklistParamsToRequest(getState(), params)

    return getItemsForTag(selectUserJWT(getState()), tag, { ...params, batch }).then(response => {
      dispatch({
        type: FETCH_BATCH_TAG_ITEMS_SUCCESS,
        tag,
        response,
        batch,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_BATCH_TAG_ITEMS_FAILURE,
        tag,
        error,
        batch,
      })
    })
  }
}

export function fetchMostRead(params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_MOST_READ_ITEMS,
    })

    addBlacklistParamsToRequest(getState(), params)

    return getMostRead(selectUserJWT(getState()), params).then(response => {
      dispatch({
        type: FETCH_MOST_READ_ITEMS_SUCCESS,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_MOST_READ_ITEMS_FAILURE,
        error,
      })
    })
  }
}

export function fetchMostVoted(params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_MOST_VOTED_ITEMS,
    })

    addBlacklistParamsToRequest(getState(), params)

    return getMostVoted(selectUserJWT(getState()), params).then(response => {
      dispatch({
        type: FETCH_MOST_VOTED_ITEMS_SUCCESS,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_MOST_VOTED_ITEMS_FAILURE,
        error,
      })
    })
  }
}

export function queueItem(item, list) {
  return {
    type: QUEUE_ITEM,
    item,
    list,
  }
}

export function unqueueItems(list) {
  return {
    type: UNQUEUE_ITEMS,
    list,
  }
}

export function queueCategoryItem(item, category) {
  return {
    type: QUEUE_CATEGORY_ITEM,
    item,
    category,
  }
}

export function unqueueCategoryItems(category) {
  return {
    type: UNQUEUE_CATEGORY_ITEMS,
    category,
  }
}

export function queueTagItem(item, tag) {
  return {
    type: QUEUE_TAG_ITEM,
    item,
    tag,
  }
}

export function unqueueTagItems(tag) {
  return {
    type: UNQUEUE_TAG_ITEMS,
    tag,
  }
}

export function ingestWebsocketItem(item) {
  return (dispatch, getState) => {
    const state = getState()
    if (!itemIsBlacklisted(item, state)) {
      sendItemToActiveList(item, state, dispatch)
    }
  }
}

export function redirectToItem(itemId) {
  return (dispatch, getState) => {
    const state = getState()
    const item = state.items.items.get(itemId)
    if (item) {
      window.location.href = item.get('link')
    }
  }
}

export function fetchUserFeedItems(maxStaleSecs = 0) {
  return (dispatch, getState) => {
    const state = getState()
    const lastFetchTimestamp = state.items.userFeeds.get('timestamp')
    const loading = state.items.userFeeds.get('loading')
    if (loading || isTimestampFresh(lastFetchTimestamp, maxStaleSecs) || !selectIsUserLoggedIn(state)) {
      return
    }

    const params = {
      limit: 5,
    }

    dispatch({
      type: FETCH_CUSTOM_FEED_ITEMS,
      params,
    })

    const jwt = selectUserJWT(state)
    return getUserFeedItems(jwt, params).then(response => {
      dispatch({
        type: FETCH_CUSTOM_FEED_ITEMS_SUCCESS,
        response,
        timestamp: Date.now(),
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: FETCH_CUSTOM_FEED_ITEMS_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}

/**
 * Populates request params with blacklisted tags and categories. For logged-in
 * users this only adds the blacklist from active preset profile, rest are read
 * by API directly from DB.
 * @returns {void}
 */
export function addBlacklistParamsToRequest(state, params) {
  if (selectIsUserLoggedIn(state)) {
    params.blacklisted_categories = selectPresetBlacklistedCategoryIds(state).join(',')
  } else {
    params.blacklisted_categories = selectBlacklistedCategoryIds(state).join(',')
    params.blacklisted_tags = selectUserBlacklistedTagIds(state).join(',')
  }
}

/**
 * Populates request params with tempProfile whitelisted tags and categories. For logged-in
 * users this is a no-op because whitelists are read by API directly from DB.
 * @returns {void}
 */
function addWhitelistParamsToRequest(state, params) {
  if (!selectIsUserLoggedIn(state)) {
    params.whitelisted_tags = selectUserWhitelistedTagIds(state).join(',')
    params.whitelisted_categories = selectUserWhitelistedCategoryIds(state).join(',')
  }
}

export function setSelectedLanguage(value) {
  return {
    type: SET_ITEMS_LANGUAGE,
    value,
  }
}

export function addLanguageToRequest(state, params) {
  if (!selectIsUserLoggedIn(state)) {
    const language = state.items.itemsLanguage.get('language')
    params.language = language
  }
}
