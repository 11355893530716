import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { displayUrlCopyModal } from '../../components/modal/modalActions.js'
import { itemShareIntent } from '../item/itemsActions'

export default class ShareButtonUrl extends PureComponent {
  render() {
    const title = 'Jaa uutinen jakolinkillä'

    return (
      <button type='button' className='fa-stack share-url gtm-share' onClick={ this.handleUrlShare } title={ title }>
        <span className='sr-only'>{ title }</span>
        <span className='fa fa-circle fa-stack-2x share-url gtm-share' aria-hidden='true' />
        <span className='fa fa-link fa-stack-1x gtm-share' aria-hidden='true' />
      </button>
    )
  }

  handleUrlShare = () => {
    const { link, itemTitle, itemId, dispatch } = this.props
    dispatch(itemShareIntent(itemId, 'link'))
    dispatch(displayUrlCopyModal(link, itemTitle))
  }
}

ShareButtonUrl.propTypes = exact({
  itemTitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
})
