import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

export default class CopyInput extends Component {

  render() {
    const { name, value } = this.props
    return (
      <input
        name={ name }
        type='text'
        readOnly={ true }
        value={ value }
        onFocus={ this.handleFocus }
      />
    )
  }

  handleFocus = (e) => {
    // We need to delay the selection (1 frame) for some browsers.
    const target = e.target
    setTimeout(() => { target.setSelectionRange(0, 9999) }, 0)
  }
}

CopyInput.propTypes = exact({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})
