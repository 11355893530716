export const SET_SHOW_BLACKLISTED_PAGES = 'SET_SHOW_BLACKLISTED_PAGES'

export function setShowBlacklistedPages(value, path) {
  return dispatch => {
    dispatch({
      type: SET_SHOW_BLACKLISTED_PAGES,
      show: value,
      path: path,
    })
  }
}
