import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

export default class GenericMessage extends Component {

  render() {
    const { type, message } = this.props

    let className

    switch (type) {
      case 'empty':
        className = 'empty-message'
        break
      default:
        className = 'generic-message'
    }

    return (
      <div className={ className }>
        { message }
      </div>
    )
  }
}

GenericMessage.propTypes = exact({
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
})
