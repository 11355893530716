import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import MainContent from '../../components/wrappers/MainContent'
import './infoViews.pcss'
import appStoreLogo from '../../assets/appstore.png'
import googlePlayLogo from '../../assets/playstore.png'

export default class AppLander extends Component {
  render() {
    return (
      <MainContent>
        <article className='info-page'>
          <Helmet title='Ampparit sovellukset' />
          <h1>Amppareiden sovellus uudistuu</h1>

          <p>
            Amppareiden Android- ja iOS-sovellukset ovat uudistuneet. Uudistuneen sovelluksen tavoitteena on tarjota käyttäjälle entistä parempi uutiskokemus ja katsaus siihen, mitä Suomessa ja maailmalla tapahtuu juuri nyt.
            Käyttäjät voivat entistä helpommin räätälöidä sovelluksen sisältöä juuri itselleen sopivaksi.
          </p>

          <p>
            Uusi sovellus toimii Alma-tunnuksella ja on täysin maksuton. Kirjautumalla pääset luomaan oman uutislistan, johon voit koota juuri sinua kiinnostavia uutisia.
          </p>

          <p>
            Sovellus kokoaa Suomen medioiden uutiset yhteen paikkaan. Kun jotain tapahtuu, löydät Amppareista helposti kaiken, mitä aiheesta on kirjoitettu.
          </p>

          <p>
            <b>Lataa sovellus puhelimeesi ja lue uutiset entistä helpommin ja nopeammin.</b>
          </p>

          <div className='app-lander__app-links'>
            <a href='https://apps.apple.com/us/app/uutiset-ampparit-com/id673931936?itscg=30200&itsct=apps_box_badge&mttnsubad=673931936'>
              <img src={ appStoreLogo } alt='App Store' />
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.ampparit.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img src={ googlePlayLogo } alt='Google Play' />
            </a>
          </div>


          <Link to='/palaute'>Ota yhteyttä</Link>
        </article>
      </MainContent>
    )
  }
}
