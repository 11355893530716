import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import ItemLink from '../../components/item/ItemLink'
import moment from 'moment-timezone'
import ItemDetails from './ItemDetails'
import './simpleItem.pcss'

const SimpleItem = ({ item, trackingClass, dispatch, gaItemClickUiElement }) => {
  const itemTimestamp = item.get('timestamp')
  const timestamp = Math.floor(Date.now() / 1000)

  return (
    <div className='simple-item'>
      <h4 className='simple-item__title'>
        <ItemLink
          item={ item }
          trackingClass={ trackingClass }
          dispatch={ dispatch }
          gaItemClickUiElement={ gaItemClickUiElement }
        />
      </h4>

      <div className='simple-item__meta'>
        <ItemDetails
          item={ item }
          isToday={ moment(itemTimestamp * 1000).isAfter(moment(timestamp).subtract(24, 'hours')) }
        />
      </div>
    </div>
  )
}

SimpleItem.propTypes = exact({
  item: ImmutablePropTypes.map.isRequired,
  trackingClass: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  gaItemClickUiElement: PropTypes.string.isRequired,
})

export default SimpleItem
