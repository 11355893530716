import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import '../../styles/modal.pcss'
import { URL_COPY_MODAL } from './Modals'
import GenericModal from './GenericModal'
import CopyInput from '../../components/ui/CopyInput'

export default class UrlCopyModal extends Component {

  render() {
    const { onClose, url, title } = this.props
    return (
      <GenericModal
        modalId={ URL_COPY_MODAL }
        onClose={ onClose }
        requireUserInput={ false }
      >
        <div className='urlmodal'>
          <h2>
            URL-linkki kopioitavaksi:
          </h2>
          <h4>{ title }</h4>
          <CopyInput value={ url } name={ title } />
        </div>
      </GenericModal>
    )
  }

  handleOkClick = () => {}
}

UrlCopyModal.propTypes = exact({
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
})
