import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { Radio, RadioGroup } from 'react-radio-group'
import { sendSourceApplication } from './sourceApplicationActions'
import Button from '../../components/ui/Button'
import { validateURL, validateEmail } from '../../user/validate'
import './infoPageForms.pcss'


@connect((state) => {
  const { sourceApplication } = state
  return {
    loading: sourceApplication.get('loading'),
    sent: sourceApplication.get('sent'),
    error: state.sourceApplication.get('error'),
  }
})

export default class SourceApplicationForm extends Component {
  constructor(props) {
    super(props)

    this.fieldNames = [
      'medianame', // becomes "medianameValue: null, medianameValid: null" in initial state
      'contact',
      'email',
      'founder',
      'foundationYear',
      'journalists',
      'newsPerDay',
      'contentInfo',
      'rssfeed',
      'category',
      'paywall',
      'paywallKeyword',
      'native',
      'nativeKeyword',
      'terms',
    ]

    this.state = {
      ...Object.fromEntries( this.fieldNames.map( name => [name + 'Value', ''] )),
      ...Object.fromEntries( this.fieldNames.map( name => [name + 'Valid', null] )),
    }

    this.refForm = React.createRef()
  }

  static propTypes = exact({
    loading: PropTypes.bool.isRequired,
    sent: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  })

  render() {
    const { loading, sent, error } = this.props

    if (sent) {
      return (
        <section className='info-page-form-thanks'>
          <h2>Kiitos hakemuksestasi!</h2>
        </section>
      )
    }

    if (error) {
      return (
        <section className='info-page-form-error'>
          <h2>Virhe!</h2>
          <p>
            Lomakkeen lähetys epäonnistui. Yritä uudelleen myöhemmin.
          </p>
        </section>
      )
    }

    return (
      <form className='info-page-form' noValidate ref={ this.refForm } onSubmit={ this.handleSubmit }>
        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='medianame'>Median nimi</label>
          </div>
          <div className='form-group-inputs'>
            <input
              type='text'
              id='medianame'
              name='Median nimi'
              value={ this.state.medianameValue }
              onChange={ this.handleMedianameInput }
              onInput={ this.handleMedianameInput }
              onBlur={ this.handleMedianameInput }
            />
            { this.state.medianameValid === false && (
              <div className='form-validation-message'>Anna median nimi</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='contact'>Yhteyshenkilön nimi</label>
          </div>
          <div className='form-group-inputs'>
            <input
              type='text'
              id='contact'
              name='Yhteyshenkilön nimi'
              value={ this.state.contactValue }
              onChange={ this.handleContactInput }
              onInput={ this.handleContactInput }
              onBlur={ this.handleContactInput }
            />
            { this.state.contactValid === false && (
              <div className='form-validation-message'>Anna yhteyshenkilön nimi</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='email'>Yhteyshenkilön sähköposti</label>
          </div>
          <div className='form-group-inputs'>
            <input
              type='email'
              id='email'
              name='Yhteyshenkilön sähköposti'
              value={ this.state.emailValue }
              onChange={ this.handleEmailInput }
              onInput={ this.handleEmailInput }
              onBlur={ this.handleEmailInput }
              placeholder='matti.meikalainen@esimerkkimedia.fi'
            />
            { this.state.emailValid === false && (
              <div className='form-validation-message'>Anna yhteyshenkilön sähköpostiosoite</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='founder'>Perustaja</label>
          </div>
          <div className='form-group-inputs'>
            <input
              type='text'
              id='founder'
              name='Perustaja'
              value={ this.state.founderValue }
              onChange={ this.handleFounderInput }
              onInput={ this.handleFounderInput }
              onBlur={ this.handleFounderInput }
            />
            { this.state.founderValid === false && (
              <div className='form-validation-message'>Anna perustajan nimi</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='foundationYear'>Perustamisvuosi</label>
          </div>
          <div className='form-group-inputs'>
            <input
              type='number'
              min='1900'
              id='foundationYear'
              name='Perustamisvuosi'
              value={ this.state.foundationYearValue }
              onChange={ this.handleFoundationYearInput }
              onInput={ this.handleFoundationYearInput }
              onBlur={ this.handleFoundationYearInput }
            />
            { this.state.foundationYearValid === false && (
              <div className='form-validation-message'>Anna perustamisvuosi</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='journalists'>Toimittajien määrä</label>
          </div>
          <div className='form-group-inputs'>
            <input
              type='number'
              min='0'
              id='journalists'
              name='Toimittajien määrä'
              value={ this.state.journalistsValue }
              onChange={ this.handleJournalistsInput }
              onInput={ this.handleJournalistsInput }
              onBlur={ this.handleJournalistsInput }
            />
            { this.state.journalistsValid === false && (
              <div className='form-validation-message'>Anna toimittajien määrä</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='newsPerDay'>Montako uutista / päivä (arvio)</label>
          </div>
          <div className='form-group-inputs'>
            <input
              type='number'
              min='0'
              id='newsPerDay'
              name='Montako uutista / päivä (arvio)'
              value={ this.state.newsPerDayValue }
              onChange={ this.handleNewsPerDayInput }
              onInput={ this.handleNewsPerDayInput }
              onBlur={ this.handleNewsPerDayInput }
            />
            { this.state.newsPerDayValid === false && (
              <div className='form-validation-message'>Anna arvio uutisten määrästä</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='contentInfo'>Kuvailkaa uutissisältöänne</label>
          </div>
          <div className='form-group-inputs'>
            <input
              type='text'
              id='contentInfo'
              name='Kuvailkaa uutissisältöänne'
              value={ this.state.contentInfoValue }
              onChange={ this.handleContentInfoInput }
              onInput={ this.handleContentInfoInput }
              onBlur={ this.handleContentInfoInput }
              placeholder='Uutisia, käännösjuttuja, näkökulmia...'
            />
            { this.state.contentInfoValid === false && (
              <div className='form-validation-message'>Anna infoteksti</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='rssfeed'>RSS-syötteen osoite</label>
          </div>
          <div className='form-group-inputs'>
            <input
              type='url'
              id='rssfeed'
              name='RSS-syötteen osoite'
              value={ this.state.rssfeedValue }
              onChange={ this.handleRssfeedInput }
              onInput={ this.handleRssfeedInput }
              onBlur={ this.handleRssfeedInput }
              placeholder='https://www.esimerkkimedia.fi/feed/'
            />

            { this.state.rssfeedValid === false && (
              <div className='form-validation-message'>Anna RSS-syötteen osoite</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='category'>Kategoriaehdotukset</label>
          </div>
          <div className='form-group-inputs'>
            <input
              type='text'
              id='category'
              name='Kategoriaehdotukset'
              value={ this.state.categoryValue }
              onChange={ this.handleCategoryInput }
              onInput={ this.handleCategoryInput }
              onBlur={ this.handleCategoryInput }
              placeholder='kotimaa, helsinki, urheilu'
            />
            { this.state.categoryValid === false && (
              <div className='form-validation-message'>Anna ehdotus lähteen kategorioiksi</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            Maksullinen sisältö
          </div>
          <div className='form-group-inputs'>
            <RadioGroup name='Maksullista sisältöä' selectedValue={ this.state.paywallValue } onChange={ this.handlePaywallInput }>
              <label><Radio value='ei' /> Syötteessä ei ole maksullista sisältöä</label>
              <label><Radio value='kyllä' /> Maksullinen sisältö on merkitty avainsanalla</label>
            </RadioGroup>
            { this.state.paywallValid === false && (
              <div className='form-validation-message'>Valitse jompi kumpi vaihtoehto</div>
            ) }
            { ( this.state.paywallValue === 'kyllä' &&
              <input
                type='text'
                name='Maksullisten juttujen avainsana'
                placeholder='Maksullisten avainsana'
                value={ this.state.paywallKeywordValue }
                onChange={ this.handlePaywallKeywordInput }
              />
            ) }
            { this.state.paywallValid && this.state.paywallKeywordValid === false && (
              <div className='form-validation-message'>Anna maksullisen sisällön avainsana</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            Kaupallinen sisältö
          </div>
          <div className='form-group-inputs'>
            <RadioGroup name='Kaupallista sisältöä' selectedValue={ this.state.nativeValue } onChange={ this.handleNativeInput }>
              <label><Radio value='ei' /> Syötteessä ei ole kaupallista sisältöä</label>
              <label><Radio value='kyllä' /> Kaupallinen sisältö on merkitty avainsanalla</label>
            </RadioGroup>
            { this.state.nativeValid === false && (
              <div className='form-validation-message'>Valitse jompi kumpi vaihtoehto</div>
            ) }
            { ( this.state.nativeValue === 'kyllä' &&
              <input
                type='text'
                name='Kaupallisten juttujen avainsana'
                placeholder='Kaupallisten avainsana'
                value={ this.state.nativeKeywordValue }
                onChange={ this.handleNativeKeywordInput }
              />
            ) }
            { this.state.nativeValid && this.state.nativeKeywordValid === false && (
              <div className='form-validation-message'>Anna kaupalisen sisällön avainsana</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
          </div>
          <div className='form-group-inputs'>
            <label>
              <input
                type='checkbox'
                name='Hyväksyn ehdot'
                value='kyllä'
                checked={ this.state.termsValue === 'kyllä' }
                onChange={ this.handleTermsInput }
              />
              Olen lukenut <Link to='/tietoa/lahteille' target='_blank'>lähteiden ohjesivun </Link> ja hyväksyn ehdot
            </label>
            { this.state.termsValid === false && (
              <div className='form-validation-message'>Hyväksy ehdot voidaksesi lähettää hakemuksen</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
          </div>
          <div className='form-group-inputs'>
            <Button
              type='submit'
              text='Lähetä hakemus'
              loading={ loading }
            />
          </div>
        </div>
      </form>
    )
  }

  handleMedianameInput = e => {
    const value = e.target.value
    const valid = value.trim().length >= 2 || (e.type === 'blur' ? false : null)
    this.setState({
      medianameValue: value,
      medianameValid: valid,
    })
  }

  handleContactInput = e => {
    const value = e.target.value
    const valid = value.trim().length >= 2 || (e.type === 'blur' ? false : null)
    this.setState({
      contactValue: value,
      contactValid: valid,
    })
  }

  handleEmailInput = e => {
    const value = e.target.value
    const valid = validateEmail(value.trim()) || (e.type === 'blur' ? false : null)
    this.setState({
      emailValue: value,
      emailValid: valid,
    })
  }

  handleFounderInput = e => {
    const value = e.target.value
    const valid = value.trim().length >= 2 || (e.type === 'blur' ? false : null)
    this.setState({
      founderValue: value,
      founderValid: valid,
    })
  }

  handleFoundationYearInput = e => {
    const value = e.target.value
    const valid = /^\d{4}$/.test(value.trim()) || (e.type === 'blur' ? false : null)
    this.setState({
      foundationYearValue: value,
      foundationYearValid: valid,
    })
  }

  handleJournalistsInput = e => {
    const value = e.target.value
    const valid = /^([1-9][0-9]{0,2})$/.test(value.trim()) || (e.type === 'blur' ? false : null)
    this.setState({
      journalistsValue: value,
      journalistsValid: valid,
    })
  }

  handleNewsPerDayInput = e => {
    const value = e.target.value
    const valid = /^([1-9][0-9]{0,2})$/.test(value.trim()) || (e.type === 'blur' ? false : null)
    this.setState({
      newsPerDayValue: value,
      newsPerDayValid: valid,
    })
  }

  handleContentInfoInput = e => {
    const value = e.target.value
    const valid = value.trim().length >= 2 || (e.type === 'blur' ? false : null)
    this.setState({
      contentInfoValue: value,
      contentInfoValid: valid,
    })
  }

  handleRssfeedInput = e => {
    const value = e.target.value
    const valid = validateURL(value.trim()) || (e.type === 'blur' ? false : null)
    this.setState({
      rssfeedValue: value,
      rssfeedValid: valid,
    })
  }

  handleCategoryInput = e => {
    const value = e.target.value
    const valid = value.trim().length >= 2 || (e.type === 'blur' ? false : null)  // Our shortest category name is "IT"
    this.setState({
      categoryValue: value,
      categoryValid: valid,
    })
  }

  handlePaywallInput = value => {
    this.setState({
      paywallValue: value,
      paywallValid: true,
      paywallKeywordValid: value === 'ei' || this.state.paywallKeywordValue.trim().length >= 2 || null,
    })
  }

  handleNativeInput = value => {
    this.setState({
      nativeValue: value,
      nativeValid: true,
      nativeKeywordValid: value === 'ei' || this.state.nativeKeywordValue.trim().length >= 2 || null,
    })
  }

  handlePaywallKeywordInput = e => {
    const value = e.target.value
    const valid = value.trim().length >= 2 || (e.type === 'blur' ? false : null)
    this.setState({
      paywallKeywordValue: value,
      paywallKeywordValid: valid,
    })
  }

  handleNativeKeywordInput = e => {
    const value = e.target.value
    const valid = value.trim().length >= 2 || (e.type === 'blur' ? false : null)
    this.setState({
      nativeKeywordValue: value,
      nativeKeywordValid: valid,
    })
  }

  handleTermsInput = (e) => {
    this.setState({
      termsValue: e.target.checked ? e.target.value : null,
      termsValid: !!e.target.checked,
    })
  }


  formIsValid() {
    const stateUpdate = {}
    this.fieldNames.forEach(name => {
      name = name + 'Valid'
      const valid = !!this.state[name]
      stateUpdate[name] = valid
    })
    this.setState(stateUpdate)
    return Object.values(stateUpdate).every(v => v === true)
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.formIsValid() || this.props.loading) {
      return
    } else {
      this.submit()
    }
  }

  /**
   * Serializes form to Object { 'Input Name 1': inputValue, 'Input Name 2': inputValue, ... }
   */
  serializeForm() {
    const data = {}
    const form = this.refForm.current
    for (const input of form) {
      if (input.type === 'radio') {
        data[input.name] = input.checked ? input.value : (data[input.name] ?? '')
      } else if (input.type === 'checkbox') {
        data[input.name] = input.checked ? input.value : '-'
      } else if (input.type !== 'submit') {
        data[input.name] = input.value.trim()
      }
    }
    return data
  }

  /**
   * Dispatch data to be sent to API
   */
  submit() {
    const { emailValue } = this.state
    this.props.dispatch(
      sendSourceApplication(emailValue, this.serializeForm())
    )
  }
}
