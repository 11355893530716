import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

export default class UnableToLoad extends PureComponent {

  render() {
    const { errorText } = this.props
    return (
      <div className='unable-to-load'>
        { errorText }
      </div>
    )
  }
}

UnableToLoad.propTypes = exact({
  errorText: PropTypes.string.isRequired,
})
