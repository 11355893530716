import { TEMP_PROFILE_COOKIE_NAME } from './userActions'


export function isTempProfileModified(cookies) {
  const tempProfile = cookies.get(TEMP_PROFILE_COOKIE_NAME)
  if (!tempProfile) {
    return false
  }

  // we are only interested in fields that will be part of user register import. User
  // can choose to import data from these fields to the actual profile during registration
  return tempProfile.whitelistedTags.length > 0 ||
         tempProfile.blacklistedTags.length > 0 ||
         tempProfile.whitelistedCats.length > 0 ||
         tempProfile.blacklistedCats.length > 0
}
