import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classnames from 'classnames'
import { Link } from 'react-router'
import ArrowToggle from '../util/ArrowToggle'
import './mobileMenu.pcss'

const MobileMenuItems = ({ page, subKey, onLinkClick, path, blacklistedCategoryIds }) => {
  const [showSubPages, setShowSubPages] = useState(false)
  const [linkClicked, setLinkClicked] = useState(false)

  const handleSubmenuClick = useCallback(() => {
    setShowSubPages(!showSubPages)
    setLinkClicked(true)
  }, [showSubPages])

  const isActive = (page) => {
    if (page.get('to') === path) {
      return true
    }
    const subPages = page.get('pages')
    if (subPages && subPages.size > 0) {
      return subPages.some(subPage => subPage.get('to') === path)
    }

    return false
  }

  const shouldShowSubPages = useCallback((page) => {
    const subPages = page.get('pages')
    if (subPages && subPages.size > 0) {
      return subPages.some(subPage => subPage.get('to') === path)
    }

    return false
  }, [path])

  const isBlacklisted = (page) => {
    if (blacklistedCategoryIds.contains(page.categoryId)) {
      return true
    }
  }

  const hasNonBlacklistedChildren = (page) => {
    const pages = page.get('pages')
    if (!pages || pages.isEmpty()) {
      return false
    }
    for (const subcategory of pages) {
      if (!blacklistedCategoryIds.contains(subcategory.categoryId)) {
        return true
      }
    }
    return false
  }

  const handleLinkClick = useCallback(() => {
    onLinkClick()
    setLinkClicked(true)
  }, [onLinkClick])

  useEffect(() => {
    if (!linkClicked) {
      setShowSubPages(shouldShowSubPages(page))
    }
  }, [linkClicked, shouldShowSubPages, page])

  const pages = page.get('pages')
  const linkClasses = {
    'mobile-menu-item-row__link': true,
    'mobile-menu-item-row__link--active': isActive(page),
    'mobile-menu-item-row__link--blacklisted': isBlacklisted(page) && !hasNonBlacklistedChildren(page),
    'mobile-menu-item-row__link--has-subpages': pages,
  }

  return (
    <li className='mobile-menu-item' key={ subKey }>
      <div className='mobile-menu-item-row'>
        <Link
          className={ classnames(linkClasses) }
          to={ page.to }
          onClick={ handleLinkClick }
          aria-label={ page.menuTitle }
        >
          <span className='mobile-menu-item-row__link-text'>{ page.menuTitle }</span>
        </Link>

        { pages && pages.size > 0 &&
        <ArrowToggle
          toggleUp={ showSubPages }
          onToggleAction={ handleSubmenuClick }
          screenReaderMessage={ showSubPages ? 'Piilota alakategoriat' : 'Listaa alakategoriat' }
        />
        }
      </div>
      <ul className='subpage-vertical'>
        { showSubPages && pages && pages.map((subPage, k) => {
          const subLinkClasses = {
            'mobile-menu-item-row__link': true,
            'mobile-menu-item-row__link--active': isActive(subPage),
            'mobile-menu-item-row__link--blacklisted': isBlacklisted(subPage),
          }
          return (
            <li className='mobile-menu-item' key={ k }>
              <div className='mobile-menu-item-row'>
                <Link
                  className={ classnames(subLinkClasses) }
                  to={ subPage.get('to') }
                  onClick={ handleLinkClick }
                  aria-label={ subPage.menuTitle }
                >
                  <span className='mobile-menu-item-row__link-text'>{ subPage.menuTitle }</span>
                </Link>
              </div>
            </li>
          )
        }) }
      </ul>
    </li>
  )
}

MobileMenuItems.propTypes = exact({
  page: ImmutablePropTypes.record.isRequired,
  subKey: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  blacklistedCategoryIds: ImmutablePropTypes.set,
  onLinkClick: PropTypes.func.isRequired,
})

export default MobileMenuItems
