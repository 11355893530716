import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

export default class MoreItems extends Component {
  render() {
    const { loading } = this.props

    return (
      <div className='more-items'>
        <button type='button' onClick={ this.handleClick } className='more-items-button gtm-more-items'>
          { loading && (
            <span className='spin'><i className='fa fa-spinner fa-spin' aria-hidden='true'></i></span>
          ) }

          { !loading && (
            <span className='button gtm-more-items'>Lisää uutisia</span>
          ) }
        </button>
      </div>
    )
  }

  handleClick = () => {
    if (!this.props.loading) {
      this.props.onMoreItemsClick()
    }
  }
}

MoreItems.propTypes = exact({
  loading: PropTypes.bool.isRequired,
  onMoreItemsClick: PropTypes.func.isRequired,
})
