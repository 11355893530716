import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { setNightMode } from '../../user/userActions'
import '../../styles/night-mode-button.pcss'

export default class NightModeButton extends PureComponent {

  render() {
    const { nightMode } = this.props

    return (
      <button type='button' className='night-mode-button gtm-night-mode' onClick={ this.handleClick }>
        <span className='night-mode-button__icon fa fa-lightbulb-o' />
        <span className='night-mode-button__text'>{ !nightMode ? 'Tummat värit' : 'Vaaleat värit' }</span>
      </button>
    )
  }

  handleClick = () => {
    this.props.dispatch(setNightMode(!this.props.nightMode))
  }
}

NightModeButton.propTypes = exact({
  nightMode: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
})
