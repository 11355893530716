export function selectSourcesOrderedByName(state) {
  return state.sources.get('sources')
}

export function selectSourceLoading(state) {
  return state.sources.get('loading')
}

export function selectSourceFailedToLoad(state) {
  return state.sources.get('failedToLoad')
}
