import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

/**
 * Placeholder to use in place of AlmaAd when ads are disabled
 */
export default class PlaceholderAd extends Component {
  constructor(props) {
    super(props)
    this.refSpan = React.createRef()
    this.handleResize = this.handleResize.bind(this)
  }

  handleResize() {
    const { id } = this.props
    const w = this.refSpan.current.parentElement.offsetWidth
    const h = this.refSpan.current.parentElement.clientHeight // no border
    this.refSpan.current.innerHTML = `${id}<br>${w}x${h}`
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    return (
      <div className={ `ad-slot ad-slot--placeholder ${this.props.className}` }>
        <span ref={ this.refSpan }></span>
      </div>
    )
  }
}

PlaceholderAd.propTypes = exact({
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
})
