import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { Link } from 'react-router'


const TrendingTagNewsListBox = ({ trending, failedToLoad, wrappingClass }) => {
  return (!failedToLoad || trending.size !== 0 ?
    <div className={ `${wrappingClass} trending` }>
      <div className='trending-header'>Pinnalla:</div>
      { trending.map(tag => (
        <Link className='trending-tag' to={ `/t/${tag.get('slug')}` } title={ tag.get('name') } key={ tag.get('id') }>
          { `#${tag.get('name')}` }
        </Link>
      )) }
      <Link className='trending-all-tags' to='/aihetunnisteet'>| Katso kaikki <i className='fa fa-angle-double-right' aria-hidden='true' /></Link>
    </div> : null
  )
}

TrendingTagNewsListBox.propTypes = exact({
  trending: ImmutablePropTypes.list.isRequired,
  failedToLoad: PropTypes.bool.isRequired,
  wrappingClass: PropTypes.string.isRequired,
})

export default TrendingTagNewsListBox
