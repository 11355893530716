import { classNamesSetClass } from '../lib/utils'


export function classNamesSetPage(classNames, path) {
  let name = path.substr(1).replace(/\//g, '_')
  name = name.length > 0 ? name : 'root'
  return classNamesSetClass(classNames, 'page-', name)
}

export function setPageBodyClass(path) {
  window.document.body.className = classNamesSetPage(window.document.body.className, path)
}
