import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

export default class BlacklistToggle extends Component {
  render() {
    const { isBlacklisted, onBlacklist, onUnblacklist, name } = this.props

    if (isBlacklisted) {
      return (
        <button
          className='blacklist-toggle blacklist-toggle_active'
          onClick={ onUnblacklist }
          type='button'
          aria-label={ `Poista ${name} estolistalta` }
          title={ `Poista ${name} estolistalta` }
        >
          <i className='fa fa-fw fa-circle-o' aria-hidden='true' />&#8201;Älä piilota
        </button>
      )
    } else {
      return (
        <button
          className='blacklist-toggle'
          onClick={ onBlacklist }
          type='button'
          aria-label={ `Lisää ${name} estolistalle` }
          title={ `Lisää ${name} estolistalle` }
        >
          <i className='fa fa-fw fa-ban' aria-hidden='true' />&#8201;Piilota
        </button>
      )
    }
  }
}

BlacklistToggle.propTypes = exact({
  isBlacklisted: PropTypes.bool.isRequired,
  onBlacklist: PropTypes.func.isRequired,
  onUnblacklist: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
})
