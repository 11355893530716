import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import Button from '../../components/ui/Button'
import { changeVoteFilter } from '../userActions'

export default class VoteFilter extends Component {
  constructor(props) {
    super(props)
    this.refNumberInput = React.createRef()
  }

  render() {
    return (
      <section>
        <form className='form' method='post' noValidate onSubmit={ this.handleSubmit }>
          <div className='container'>
            <div className='vote-filter'>
              <input
                ref={ this.refNumberInput }
                type='number'
                max='0'
                min='-100'
                id='voteFilter'
                name='voteFilter'
                placeholder='Syötä numero'
                className='vote-filter-input'
                defaultValue={ this.props.voteFilterThreshold }
                aria-label='Miinusäänten raja'
              />
              <div className='button-group'>
                <Button type='submit' className='user-edit-change form-action' text='TALLENNA' />
              </div>
            </div>
            <div className='description'>
              <span>
                Esimerkiksi arvo -5 poistaa näkyvistä otsikot joiden äänten summa on -5 tai alle.
                Suodatus on poissa käytöstä arvolla 0.
              </span>
            </div>
          </div>
        </form>
      </section>
    )
  }

  handleSubmit = event => {
    event.preventDefault()

    const voteFilterThreshold = -Math.abs(this.refNumberInput.current.value ?? 0)
    this.props.dispatch(changeVoteFilter(voteFilterThreshold))
  }

}

VoteFilter.propTypes = exact({
  voteFilterThreshold: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
})
