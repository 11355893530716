import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import React, { Component } from 'react'
import { Link } from 'react-router'
import '../../styles/more-content.pcss'

export default class MoreContent extends Component {
  render() {
    const { title } = this.props

    return (
      <section className='more-content'>
        <h3 className='more-content__title'>{ title }</h3>

        <div className='more-content__links'>
          <Link to='/'>Etusivu</Link>
          <Link to='/luetuimmat'>Luetuimmat</Link>
        </div>
      </section>
    )
  }
}

MoreContent.propTypes = exact({
  title: PropTypes.string.isRequired,
})
