import React, { Component } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import './brandLogo.pcss'
import defaultLogo from '../../assets/ampparit-logo.svg'
import { refreshAllAds } from '../../ad/AlmaAd'

export default class BrandLogo extends Component {
  render() {
    const { image, frontPage } = this.props

    const height = 25
    const width = defaultLogo ? 150 : 'auto'

    return (
      <div className='logo-wrapper'>
        <header className='brand-logo'>
          <Link to={ frontPage } onClick={ this.handleRefreshAdsIfOnSamePage } >
            <img src={ image ? image : defaultLogo } alt='Ampparit.com - Etusivu' height={ height } width={ width } />
          </Link>
        </header>
      </div>
    )
  }

  shouldComponentUpdate(nextProps) {
    return this.props.image !== nextProps.image || this.props.frontPage !== nextProps.frontPage
  }

  handleRefreshAdsIfOnSamePage = () => {
    if (this.props.path === this.props.frontPage) {
      refreshAllAds()
    }
  }
}

BrandLogo.propTypes = exact({
  image: PropTypes.string,
  frontPage: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
})
