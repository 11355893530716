import React, { useCallback, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'
import { Link } from 'react-router'
import MenuToggle from './MenuToggle'
import MobileMenu from './MobileMenu'
import './bottomNavigation.pcss'

const navItems = [
  { title: 'Etusivu', to: '/', icon: 'fa fa-home' },
  { title: 'Uusimmat', to: '/uusimmat', icon: 'fa fa-clock-o' },
  { title: 'Luetuimmat', to: '/luetuimmat', icon: 'fa fa-star-o' },
  { title: 'Äänestetyimmät', to: '/aanestetyimmat', icon: 'fa fa-thumbs-o-up' },
]

const BottomNavigation = ({ path, isLoginOverlayOpen, onMobileMenuOpen }) => {

  const [showMenu, setShowMenu] = useState(false)
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [showNav, setShowNav] = useState(true)

  const previousPath = useRef(path)

  // Hide nav on scroll down, show on scroll up. Except on DuckDuckGo, which has its own conflicting bottom navi hiding logic
  const handleScroll = useCallback(() => {
    if (!window.navigator.userAgent.includes('DuckDuckGo')) {
      const currentScrollPos = window.scrollY
      setShowNav(prevScrollPos > currentScrollPos || currentScrollPos < 10)
      setPrevScrollPos(currentScrollPos)
    }
  }, [prevScrollPos])

  // Toggle menu visibility on click of menu button (hamburger)
  const handleMenuClick = useCallback(() => {
    setShowMenu(!showMenu)
    onMobileMenuOpen()
  }, [showMenu, onMobileMenuOpen])

  // Hide menu when clicking a link
  const handleLinkClick = useCallback(() => {
    setShowMenu(false)
  }, [])

  // Hide menu when resizing window e.g. mobile device rotate to landscape
  const handleResize = useCallback(() => {
    if (window.innerWidth > 645) {
      setShowMenu(false)
    }
  }, [])

  // Hide menu when clicking outside of menu
  useEffect(() => {
    if (path !== previousPath.current) {
      previousPath.current = path
      setShowMenu(false)
    }

    if (isLoginOverlayOpen) {
      setShowMenu(false)
    }
  }, [path, previousPath, isLoginOverlayOpen])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [handleScroll, handleResize])

  return (
    <React.Fragment>
      <nav className={ `mobile-bottom-nav-container mobile-bottom-nav-container--${showNav ? 'visible' : 'hidden'}` } aria-hidden={ !showNav } aria-label='Uutisivut'>
        <ul className='mobile-bottom-nav'>
          { navItems.map((page, i) => {
            const activePage = page.to === path
            return (
              <li key={ i } >
                <Link
                  to={ page.to }
                  className={ classnames('mobile-bottom-nav-item', {'mobile-bottom-nav-item--active': activePage && !showMenu }) }
                  onClick={ handleLinkClick }
                  aria-current={ activePage ? 'page' : undefined }
                >
                  <span className={ `mobile-bottom-nav-item__icon ${ page.icon }` }></span>
                  <span className='mobile-bottom-nav-item__text'>{ page.title }</span>
                  <span className='sr-only'>{ activePage ? 'Nykyinen sivu' : '' }</span>
                </Link>
              </li>
            )
          }) }
          <li className='mobile-bottom-nav-item menu-toggle-container'>
            <MenuToggle
              isMobileMenuVisible={ showMenu }
              onClick={ handleMenuClick }
            />
          </li>
        </ul>
      </nav>
      { showMenu &&
      <MobileMenu
        path={ path }
        onLinkClick={ handleLinkClick }
        closeMenu={ handleLinkClick }
      />
      }
    </React.Fragment>
  )
}

BottomNavigation.propTypes = exact({
  path: PropTypes.string.isRequired,
  isLoginOverlayOpen: PropTypes.bool,
  onMobileMenuOpen: PropTypes.func.isRequired,
})

export default BottomNavigation
