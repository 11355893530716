import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'
import {
  voteItem,
  ITEM_VOTE_TYPE_UP,
  ITEM_VOTE_TYPE_NEUTRAL,
  ITEM_VOTE_TYPE_DOWN,
} from './itemsActions'
import './itemVotingWidget.pcss'
import NotificationBubble from '../util/NotificationBubble'

const ItemVotingWidget = ({
  currentVote,
  plusVotes,
  minusVotes,
  dispatch,
  loggedIn,
  itemId,
}) => {

  const [voteClicked, setVoteClicked] = useState(false)

  const isUpvoted = currentVote === ITEM_VOTE_TYPE_UP
  const isDownvoted = currentVote === ITEM_VOTE_TYPE_DOWN


  /** Vote clicks */
  const handleVoteClick = (voteType) => {
    if (loggedIn) {
      dispatch(voteItem(itemId, voteType))
      return
    }

    setVoteClicked(true)
  }


  const handleVoteUpClick = () => {
    handleVoteClick(ITEM_VOTE_TYPE_UP)
  }

  const handleVoteNeutralClick = () => {
    handleVoteClick(ITEM_VOTE_TYPE_NEUTRAL)
  }

  const handleVoteDownClick = () => {
    handleVoteClick(ITEM_VOTE_TYPE_DOWN)
  }

  const handleNotificationClick = () => {
    setVoteClicked(false)
  }

  return (
    <div className='item-actions-vote-container'>

      { /* Negative votes */ }
      <p className='sr-only'>Äänestä uutista</p>

      <button
        type='button'
        className='item-vote-down-container gtm-item-vote'
        onClick={ isDownvoted ? handleVoteNeutralClick : handleVoteDownClick }
        role='switch'
        aria-checked={ isDownvoted }
      >
        <span
          className={ classnames('item-vote-down fa fa-thumbs-o-down', {
            'item-voted-down': isDownvoted,
          }) }
          aria-hidden='true'
        />
        <span className='sr-only'>Negatiivinen ääni</span>
        <span className='item-votes'>{ minusVotes }</span>
      </button>

      { /* Positive votes */ }
      <button
        type='button'
        className='item-vote-up-container gtm-item-vote'
        onClick={ isUpvoted ? handleVoteNeutralClick : handleVoteUpClick }
        role='switch'
        aria-checked={ isUpvoted }
      >
        <span
          className={ classnames('item-vote-up fa fa-thumbs-o-up', {
            'item-voted-up': isUpvoted,
          }) }
          aria-hidden='true'
        />
        <span className='sr-only'>Positiivinen ääni</span>
        <span className='item-votes'>{ plusVotes }</span>
      </button>

      { voteClicked && (
        <NotificationBubble
          message='Ole hyvä ja kirjaudu äänestääksesi'
          showNotification={ voteClicked }
          dispatch={ dispatch }
          onNotificationClick={ handleNotificationClick }
        />
      ) }
    </div>
  )
}

ItemVotingWidget.propTypes = exact({
  minusVotes: PropTypes.number.isRequired,
  plusVotes: PropTypes.number.isRequired,
  itemId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  currentVote: PropTypes.oneOf([
    ITEM_VOTE_TYPE_UP,
    ITEM_VOTE_TYPE_NEUTRAL,
    ITEM_VOTE_TYPE_DOWN,
  ]).isRequired,
})

export default ItemVotingWidget
