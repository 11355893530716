import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import throttle from 'lodash/throttle'
import classnames from 'classnames'
import '../../styles/scroll-button.pcss'


export default class ScrollToTopButton extends Component {
  constructor() {
    super()
    this.state = {
      show: true,
      lastScrollPos: 0,
    }
    this.handleScroll = throttle(this.handleScroll.bind(this), 100)
  }

  componentDidMount() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    if (window.pageYOffset > (this.props.showUnder || 160) && window.pageYOffset < this.state.lastScrollPos) {
      this.setState({
        show: true,
        lastScrollPos: window.pageYOffset,
      })
    } else {
      this.setState({
        show: false,
        lastScrollPos: window.pageYOffset,
      })
    }
  }

  handleClick = () => {
    window.scroll({top: 0, behavior: 'smooth'})
  }

  render() {

    return (
      <div className='scrollup-button-wrapper'>
        <button
          type='button'
          className={ classnames('scrollup-button', { 'sr-only sr-only-focusable': !this.state.show }) }
          onClick={ this.handleClick }
        >
          <span className='fa-stack'>
            <span className='scrollup-button__bg fa fa-circle fa-stack-1x' />
            <span className='scrollup-button__arrow fa fa-arrow-circle-up fa-stack-1x' />
          </span>
          <span className='sr-only'>Takaisin sivun alkuun</span>
        </button>
      </div>
    )
  }
}

ScrollToTopButton.propTypes = exact({
  showUnder: PropTypes.number,
})
