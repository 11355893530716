import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { canUseDOM } from 'exenv'
import { displayAds } from '../../config/config'
import AlmaAd from './AlmaAd'
import PlaceholderAd from './PlaceholderAd'
import Resize from './Resize'
import './ad.pcss'


/**
 * Select Ad slot implementation based on site config or "debugslot" client side query param
 */
const AdSlotImpl = (function() {
  if (!displayAds) {
    return PlaceholderAd
  }
  if (canUseDOM && window.location.search.includes('debugslots')) {
    return PlaceholderAd
  }
  return AlmaAd
})()


/**
 * Top ad on the page. (desktop)
 */
export class LeaderboardAd extends PureComponent {
  render() {
    return (
      <div className='resize-container desktop-leaderboard-container'>
        <Resize minBreakpoint={ 980 }>
          <AdSlotImpl id='almad-leaderboard-1' className='ad-slot--leaderboard' />
        </Resize>
      </div>
    )
  }
}


/**
 * After news list. Now only on the frontpage (desktop).
 */
export class PostContentAd extends PureComponent {
  render() {
    return (
      <div className='resize-container'>
        <Resize minBreakpoint={ 728 }>
          <AdSlotImpl id='almad-post-content-1' className='ad-slot--post-content' />
        </Resize>
      </div>
    )
  }
}


/**
 * Mainly used on right sidebars etc. (desktop)
 */
export class BoxAd extends PureComponent {
  static propTypes = exact({
    adUnitId: PropTypes.string.isRequired,
  })

  render() {
    return (
      <div className='resize-container'>
        <Resize minBreakpoint={ 897 }>
          <AdSlotImpl id={ this.props.adUnitId } className='ad-slot--box' />
        </Resize>
      </div>
    )
  }
}


/**
 * In the news list. Originally thought out as newslike content
 * but ads varies from rectangular to news. (desktop, mobile)
 */
export class NativeAd extends PureComponent {
  static propTypes = exact({
    adUnitId: PropTypes.string.isRequired,
  })

  render() {
    return (
      <AdSlotImpl id={ this.props.adUnitId } className='ad-slot--nativead' />
    )
  }
}

/**
 * In the grouped list. (desktop)
 */
export class ListAd extends PureComponent {
  static propTypes = exact({
    adUnitId: PropTypes.string.isRequired,
  })

  render() {
    return (
      <div className='resize-container'>
        <Resize minBreakpoint={ 897 }>
          <AdSlotImpl id={ this.props.adUnitId } className='ad-slot--list' />
        </Resize>
      </div>
    )
  }
}


/**
 * Top ad ín the news list. (mobile)
 */
export class MobileLeaderboardAd extends PureComponent {
  render() {
    return (
      <div className='resize-container mobile-leaderboard-container'>
        <Resize maxBreakpoint={ 979 }>
          <AdSlotImpl id='almad-leaderboard-1' className='ad-slot--mob-leaderboard' />
        </Resize>
      </div>
    )
  }
}


/**
 * Multiple in the news list. (mobile)
 */
export class MobileListAd extends PureComponent {
  static propTypes = exact({
    adUnitId: PropTypes.string.isRequired,
  })

  render() {
    return (
      <div className='resize-container'>
        <Resize maxBreakpoint={ 896 }>
          <AdSlotImpl id={ this.props.adUnitId } className='ad-slot--mob-list' />
        </Resize>
      </div>
    )
  }
}

export class AlmaAjoListAd extends PureComponent {
  static propTypes = exact({
    adUnitId: PropTypes.string.isRequired,
  })

  render() {
    return (
      <div className='resize-container'>
        <Resize maxBreakpoint={ 896 }>
          <AdSlotImpl id={ this.props.adUnitId } className='ad-slot--ajo-list' />
        </Resize>
      </div>
    )
  }
}

export class AlmaAjoBoxAd extends PureComponent {
  static propTypes = exact({
    adUnitId: PropTypes.string.isRequired,
  })

  render() {
    return (
      <div className='resize-container'>
        <Resize minBreakpoint={ 897 }>
          <AdSlotImpl id={ this.props.adUnitId } className='ad-slot--ajo-side-box' />
        </Resize>
      </div>
    )
  }
}


/**
 * On the left after the category tree. (desktop)
 */
export class SkyscraperAd extends PureComponent {
  static propTypes = exact({
    adUnitId: PropTypes.string.isRequired,
  })

  render() {
    return (
      <div className='resize-container'>
        <Resize minBreakpoint={ 587 }>
          <AdSlotImpl id={ this.props.adUnitId } className='ad-slot--skyscraper' />
        </Resize>
      </div>
    )
  }
}

export class InterstitialAd extends PureComponent {
  render() {
    return (
      <AdSlotImpl id='almad-special-1' className='ad-slot--interstitial' />
    )
  }
}

export const BOX_AD_RIGHT_1 = 'almad-aside-right-1'
export const BOX_AD_RIGHT_2 = 'almad-aside-right-2'
export const BOX_AD_RIGHT_3 = 'almad-aside-right-3'
export const BOX_AD_RIGHT_4 = 'almad-aside-right-4'
export const BOX_AD_RIGHT_5 = 'almad-aside-right-5'
export const BOX_AD_CONTENT_1 = 'almad-content-1'

export const LIST_AD_1 = 'almad-list-1'

export const MOBILE_LIST_2 = 'almad-list-2'
export const MOBILE_LIST_3 = 'almad-list-3'
export const MOBILE_LIST_4 = 'almad-list-4'
export const MOBILE_LIST_5 = 'almad-list-5'
export const MOBILE_LIST_X = 'almad-list-'

export const SKYSCRAPER_1 = 'almad-aside-left-1'
export const SKYSCRAPER_2 = 'almad-aside-left-2'
