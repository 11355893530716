import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import './listTitle.pcss'

export default class ListTitle extends PureComponent {
  render() {
    const { heading, isTagView } = this.props

    return (
      <h1 className={ classNames('list-title', { 'list-title--tag': isTagView } ) }>
        { heading }
        { isTagView && <span className='list-title__tag-trailer'> | Uutiset</span> }
      </h1>
    )
  }
}

ListTitle.propTypes = exact({
  heading: PropTypes.string.isRequired,
  isTagView: PropTypes.bool,
})
