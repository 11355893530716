import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import classnames from 'classnames'
import CategoryItem from './CategoryItem'
import '../../styles/settings.pcss'
import { fetchUserProfile } from '../userActions'
import { selectCategoryTree } from '../../selectors/menuSelectors'
import { selectUserCategories } from '../../selectors/userSelector'

const CategorySettingsView = () => {
  const dispatch = useDispatch()

  const userCategories = useSelector(selectUserCategories)
  const items = useSelector(selectCategoryTree)

  useEffect(() => {
    dispatch(fetchUserProfile(5))
  }, [dispatch])

  return (
    <div>
      <Helmet title='Kategoriat' />
      <section className='content'>
        <div className='profile-settings-info-text'>
          <span>Valitse, mitä kategorioita et halua nähdä profiilissasi ja mitä haluat seurata Omat-listallasi.</span>
        </div>
        <div className='personal'>
          <div className='category-settings-container'>
            <CategoryList
              items={ items }
              userCategories={ userCategories }
              isRootList={ true }
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export default CategorySettingsView

export const CategoryList = ({ items, userCategories, isRootList }) => {
  return (
    <ul className={ classnames({ 'root-category-ul': isRootList }) }>
      { items.map((item) => (
        <CategoryItem
          key={ item.categoryId }
          item={ item }
          userCategories={ userCategories }
          isRootList={ isRootList }
        />
      )) }
    </ul>
  )
}

CategoryList.propTypes = exact({
  items: ImmutablePropTypes.list.isRequired,
  userCategories: ImmutablePropTypes.map.isRequired,
  isRootList: PropTypes.bool.isRequired,
})
