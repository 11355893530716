import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { removeNotification } from './notificationActions'

@connect(({ notifications }) => ({
  notifications: notifications,
}))

export default class Notifier extends Component {

  static propTypes = exact({
    notifications: ImmutablePropTypes.map.isRequired,
    dispatch: PropTypes.func.isRequired,
  })

  constructor(props) {
    super(props)

    this.state = {
      notifications: Immutable.Map(),
    }
  }

  componentDidUpdate(prevProps) {
    const { notifications } = this.props

    if (prevProps.notifications !== notifications) {
      notifications.forEach((notification, key) => {
        if (this.state.notifications.has(key)) return

        const children = <span className='notification-message'>{ notification.message }</span>

        const toastOptions = {
          position: 'top-center',
          type: notification.level,
          autoClose: 5000,
          closeButton: true,
          onClose: () => this.handleNotificationRemove(key),
        }

        toast(children, toastOptions)

        this.setState({
          notifications: this.state.notifications.set(key, children),
        })

      })
    }
  }

  handleNotificationRemove = (key) => {
    const { dispatch } = this.props

    this.setState({
      notifications: this.state.notifications.remove(key),
    })

    dispatch(removeNotification(key))
  }

  render() {
    return <ToastContainer className='notification-container' />
  }
}

