import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { connect } from 'react-redux'
import SidebarItems from '../lists/SidebarItems'
import { itemIdsToItems } from '../item/itemsTransformers'
import { fetchUserFeedItems } from '../item/itemsActions'


@connect((state) => {
  const { items } = state
  return {
    items: itemIdsToItems(state, items.userFeeds.get('itemIds')),
    itemIds:  items.userFeeds.get('itemIds'),
    loading: items.userFeeds.get('loading'),
    failedToLoad: items.userFeeds.get('failedToLoad'),
    shouldUpdate: items.userFeeds.get('shouldUpdate'),
  }
})


export default class SidebarUserFeed extends Component {

  static propTypes = exact({
    items: ImmutablePropTypes.orderedSet.isRequired,
    itemIds: ImmutablePropTypes.orderedSet.isRequired,
    loading: PropTypes.bool.isRequired,
    shouldUpdate: PropTypes.bool.isRequired,
    failedToLoad: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  })

  shouldComponentUpdate(nextProps) {
    if (nextProps.shouldUpdate) { // trigger fetch after changes to black/whitelists
      nextProps.dispatch(fetchUserFeedItems())
    }

    return this.props.itemIds !== nextProps.itemIds ||
           this.props.loading !== nextProps.loading ||
           this.props.failedToLoad !== nextProps.failedToLoad
  }

  componentDidMount() {
    if (this.props.shouldUpdate) { // triggers fetch after logging in
      this.props.dispatch(fetchUserFeedItems())
    }
    this.updateInterval = setInterval(this.dispatchRefresh, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval)
    this.updateInterval = null
  }

  dispatchRefresh = () => {
    if (!window.document.hidden) {
      this.props.dispatch(fetchUserFeedItems(300))
    }
  }

  render () {
    const {
      dispatch,
      items,
      loading,
      failedToLoad,
    } = this.props

    return (
      <SidebarItems
        title='Omat syötteet'
        linkTo='/omat'
        linkToTitle='Näytä kaikki omat uutiset'
        className='user-feeds'
        trackingClass='omat-syotteet-sidebar'
        dispatch={ dispatch }
        loading={ loading }
        failedToLoad={ failedToLoad }
        items={ items }
        emptyText='Lisäämilläsi lähteillä ei löytynyt sisältöä'
      />
    )
  }

}
