import { getSources } from '../../api/api'
import { logException } from '../../sentry'

export const FETCH_SOURCES = 'FETCH_SOURCES'
export const FETCH_SOURCES_SUCCESS = 'FETCH_SOURCES_SUCCESS'
export const FETCH_SOURCES_FAILURE = 'FETCH_SOURCES_FAILURE'

export function fetchSources() {
  return (dispatch) => {
    dispatch({
      type: FETCH_SOURCES,
    })

    return getSources().then(response => {
      dispatch({
        type: FETCH_SOURCES_SUCCESS,
        response,
      })
    }).catch((error) => {
      logException(error)
      dispatch({
        type: FETCH_SOURCES_FAILURE,
        error,
      })
    })
  }
}
