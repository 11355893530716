import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { connect } from 'react-redux'
import NewsListBoxItems from './NewsListBoxItems'
import { itemIdsToItems } from '../item/itemsTransformers'
import './newsListBox.pcss'

// We can't claim item is popular if it doesn't have at least some clicks
export const MIN_POPULAR_CLICKS = 25


@connect((state) => {
  const { popular } = state
  return {
    items: itemIdsToItems(state, popular.get('itemIds')),
    itemIds: popular.get('itemIds'),
    loading: popular.get('loading'),
    failedToLoad: popular.get('failedToLoad'),
  }
})


export default class PopularNewsListBox extends Component {

  static propTypes = exact({
    dispatch: PropTypes.func.isRequired,
    items: ImmutablePropTypes.orderedSet.isRequired,
    itemIds: ImmutablePropTypes.orderedSet.isRequired,
    loading: PropTypes.bool.isRequired,
    failedToLoad: PropTypes.bool.isRequired,
    category: ImmutablePropTypes.record,
    tag: ImmutablePropTypes.record,
  })

  shouldComponentUpdate(nextProps) {
    return this.props.itemIds !== nextProps.itemIds ||
           this.props.loading !== nextProps.loading ||
           this.props.failedToLoad !== nextProps.failedToLoad ||
           this.props.category !== nextProps.category ||
           this.props.tag !== nextProps.tag
  }

  render () {
    const {
      dispatch,
      items,
      loading,
      failedToLoad,
      category,
      tag,
    } = this.props

    if (items.size < 5 || items.last().get('clicks', 0) < MIN_POPULAR_CLICKS) {
      return null
    }

    let title = 'Luetuimpia'
    if (category) {
      title = 'Luetuimpia: ' + category.get('name')
    }
    if (tag) {
      title = 'Luetuimpia: #' + tag.get('name')
    }

    return (
      <NewsListBoxItems
        title={ title }
        linkTo='/luetuimmat'
        linkToTitle='Kaikki luetuimmat'
        className='popular'
        trackingClass='suosittuja-uutislista'
        dispatch={ dispatch }
        loading={ loading }
        failedToLoad={ failedToLoad }
        items={ items }
        emptyText=''
      />
    )
  }

}
