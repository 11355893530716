import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import MainContent from '../../components/wrappers/MainContent'
import SourceApplicationForm from './SourceApplicationForm'
import './infoViews.pcss'



export default class SourceApplicationView extends Component {
  render() {
    return (
      <MainContent>
        <article className='info-page'>
          <Helmet title='Mukaan Amppareihin?' />

          <h1>Mukaan Amppareihin?</h1>
          <p>
            Ampparit on Suomen suurin uutisaggregaatti, josta lähes 300&nbsp;000 käyttäjää etsi mielenkiintoista
            luettavaa viikoittain. Yksittäinen käyttäjä vierailee sivustolla keskimäärin 9 kertaa viikossa ja
            viettää sisältöjen parissa 9 minuuttia per vierailu. Mukana olevien uutislähteiden linkit avautuvat
            aina alkuperäisen lähteen omalle sivustolle. Medioiden kannattaa siis olla mukana Amppareissa &ndash;
            ohjaamme sivustolle liikennettä ja kauttamme tavoitat uusia käyttäjiä.
          </p>

          <h2>Haluatko uutislähteeksi Amppareihin?</h2>
          <p>
            Voit hakea mukaan Amppareiden uutislähteeksi, mutta tarkistathan ensin, että
            sivustosi täyttää sisällölle, otsikoille ja syötteelle asetetut
            <Link to='/tietoa/lahteille' target='_blank'> vaatimukset</Link>.
          </p>
          <p>
            Ampparit arvioi uusia lähdehakemuksia seuraavien toimintaperiaatteiden ja säädösten pohjalta:
          </p>
          <ol>
            <li>Uusien lähteiden tulee kertoa toiminnastaan seuraavanlaisesti:
              <ol type='a'>
                <li>Sivuston toimintaperiaate, sivuston perustaja, yhteyshenkilö, koska sivusto perustettu</li>
                <li>Kuinka monta toimittajaa/kirjoittajaa, kuinka aktiivista juttutuotanto on</li>
                <li>Ovatko artikkelit puhtaasti heidän omaa sisältöään</li>
              </ol>
            </li>
            <li>Ampparit päättää uusista ja poistettavista lähteistä sekä tekee päätöksiä sisältöjen näkyvyyksistä palvelussa. Lähdehakemuksia käsitellään kootusti neljä kertaa vuodessa.</li>
            <li>Ampparit arvioi ensisijaisesti julkaisijan sisällön toimivuutta Ampparit-uutispalvelun käyttäjän kannalta ja suhteessa palvelun nykyiseen uutistarjontaan.</li>
            <li>Päätöksestä informoidaan suoraan hakemuksen jättänyttä uutismediaa.</li>
            <li>Amppareiden lähteet sitoutuvat noudattamaan hyvää journalistista tapaa uutissisällöissään sekä yhteistyössä Amppareiden kanssa.</li>
            <li>Väärinkäytökset, vaarallinen sisältö ja epäasiallinen toiminta voivat johtaa lähteen erottamiseen Amppareista.</li>
            <li>Ampparit edellyttää, että lähteet noudattavat rahapelien markkinoinnissa arpajaislain ja kuluttajansuojalain säännöksiä.</li>
          </ol>
          <p>
            Hae Amppareiden uutislähteeksi alla olevalla lomakkeella. Huomaathan, että käsittelyprosessi
            voi kestää, eli annathan Amppareille riittävästi aikaa seurata lähteeksi pyrkivien toimintaa.
          </p>
          <p>
            Hakemalla Amppareiden lähteeksi hyväksyt, että Ampparit lukee uutislähteen RSS-syötteen sisältöä
            ja ohjaa liikennettä uutislähteen sivustolle lukemaan alkuperäisen sisällön.
          </p>

          <h2>Ampparit palvelun käyttäjien omat lähteet</h2>
          <p>
            Ampparit-uutispalvelussa on mahdollista luoda profiili, jonka avulla käyttäjä voi seurata muun
            sisällön lisäksi myös itse valitsemiaan lähteitä. Omat syötteet -toiminnon avulla käyttäjät voivat
            lisätä seurantaan lähteitä, jotka eivät muuten nouse Amppareiden listoille.
          </p>

          <h2>Hae lähteeksi Amppareihin</h2>
          <SourceApplicationForm />
        </article>
      </MainContent>
    )
  }
}
