import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMostVoted } from '../../components/item/itemsActions'
import GroupedItems from './GroupedItems'
import { PostContentAd } from '../../ad/Ad'
import { fetchPopular } from '../../components/item/popularActions'
import { selectMostVotedProps } from '../../selectors/topNewsSelector'


const MostVotedView = ({ shouldRefresh }) => {
  const dispatch = useDispatch()
  const props = useSelector(selectMostVotedProps)

  useEffect(() => {
    dispatch(fetchMostVoted())
    dispatch(fetchPopular(300))
  }, [dispatch])

  useEffect(() => {
    if (shouldRefresh) {
      dispatch(fetchMostVoted())
    }
  }, [shouldRefresh, dispatch])

  return (
    <React.Fragment>
      <GroupedItems
        { ...props }
        dispatch={ dispatch }
        title='Äänestetyimmät | Uutiset'
        meta={ [
          {name: 'description', content: 'Ampparit.com kertoo, mitkä ovat Suomen suosituimpia uutisia juuri nyt. Näet Äänestetyimmät-listalta yhdellä silmäyksellä, mitä uutisia Suomi lukee. Se kattavin uutistarjonta \u2013 Ampparit.com'},
          {property: 'og:title', content: 'Äänestetyimmät \u2013 Ampparit.com'},
          {property: 'og:description', content: 'Ampparit.com kertoo, mitkä ovat Suomen suosituimpia uutisia juuri nyt. Näet Äänestetyimmät-listalta yhdellä silmäyksellä, mitä uutisia Suomi lukee. Se kattavin uutistarjonta \u2013 Ampparit.com'},
        ] }
      />
      <PostContentAd />
    </React.Fragment>
  )
}

MostVotedView.propTypes = { // eslint-disable-line react/prefer-exact-props
  shouldRefresh: PropTypes.bool,
}

export default MostVotedView
