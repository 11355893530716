import { Map } from 'immutable'

export default Map({
  '0101': 'Uudenvuodenpäivä',
  '0201': 'Aapeli',
  '0301': 'Elmeri, Elmo, Elmer',
  '0401': 'Tiitus, Ruut, Naomi, Noomi',
  '0501': 'Lea, Leea, Lia',
  '0601': 'Harri',
  '0701': 'Aku, Aukusti, August',
  '0801': 'Titta, Hilppa',
  '0901': 'Veikko, Veli, Veikka, Veijo',
  '1001': 'Nyyrikki',
  '1101': 'Kari, Karri',
  '1201': 'Toini',
  '1301': 'Nuutti',
  '1401': 'Saku, Sakari, Sasu',
  '1501': 'Solja',
  '1601': 'Ilmari, Ilmo',
  '1701': 'Toni, Anton, Antto, Anttoni',
  '1801': 'Laura',
  '1901': 'Henri, Henna, Heikki, Henrik, Henni, Henriikka, Henrikki',
  '2001': 'Sebastian',
  '2101': 'Oona, Netta, Aune, Auni',
  '2201': 'Visa',
  '2301': 'Enni, Enna, Eini, Eine',
  '2401': 'Senja',
  '2501': 'Pauli, Paavo, Paul, Paulus, Paavali',
  '2601': 'Joonatan',
  '2701': 'Viljo',
  '2801': 'Kalle, Kaarlo, Kaarle, Mies, Charlie, Carla',
  '2901': 'Valtteri',
  '3001': 'Irja',
  '3101': 'Alli',
  '0102': 'Riitta',
  '0202': 'Jemina, Lumi, Aamu',
  '0302': 'Hugo, Valo',
  '0402': 'Ronja, Armi',
  '0502': 'Asser',
  '0602': 'Tiia, Terhi, Teija, Tea, Terhikki',
  '0702': 'Riku, Rikhard',
  '0802': 'Laina',
  '0902': 'Raija, Raisa',
  '1002': 'Elina, Ella, Elle, Ellen, Elna',
  '1102': 'Iisa, Isabella, Talvikki',
  '1202': 'Elma, Elmi',
  '1302': 'Sulo, Sulho',
  '1402': 'Tino, Voitto, Valentin',
  '1502': 'Sipi, Sippo',
  '1602': 'Kai',
  '1702': 'Väinö, Rita, Karita, Väinämö',
  '1802': 'Kaino',
  '1902': 'Eija',
  '2002': 'Heli, Heljä, Helinä, Hely',
  '2102': 'Keijo',
  '2202': 'Tuuli, Tuulia, Hilda, Tuulikki',
  '2302': 'Aslak',
  '2402': 'Matti, Matias',
  '2502': 'Tuija, Tuire, Vanessa',
  '2602': 'Nestori',
  '2702': 'Torsti',
  '2802': 'Onni, Sisu',
  '2902': 'Karkauspäivä',
  '0103': 'Alpo, Alpi, Alvi',
  '0203': 'Virve, Fanni, Fiona, Virva',
  '0303': 'Kauko',
  '0403': 'Ari, Arsi, Atro',
  '0503': 'Leila, Laila',
  '0603': 'Tarmo',
  '0703': 'Tarja, Taru, Taika',
  '0803': 'Vilppu',
  '0903': 'Auvo, Edvin',
  '1003': 'Aurora, Aura, Auri',
  '1103': 'Kalervo',
  '1203': 'Reijo, Reko',
  '1303': 'Erno, Tarvo, Ernesti',
  '1403': 'Matilda, Malla, Mette, Tilda',
  '1503': 'Risto',
  '1603': 'Ilkka',
  '1703': 'Kerttu, Kerttuli',
  '1803': 'Eetu, Edvard',
  '1903': 'Juuso, Josefiina, Joose, Jooseppi, Joosef',
  '2003': 'Aki, Kim, Kimi, Joakim, Jooa, Jaakkima',
  '2103': 'Pentti',
  '2203': 'Vihtori, Viktor',
  '2303': 'Akseli, Selina, Celina',
  '2403': 'Kaapo, Gabriel, Kaapro, Kaappo',
  '2503': 'Aija, Aava',
  '2603': 'Manu, Manne, Immo, Immanuel',
  '2703': 'Sauli, Saul',
  '2803': 'Armas',
  '2903': 'Joni, Joonas, Jouni, Joona, Jonne, Jonni',
  '3003': 'Usko',
  '3103': 'Irma, Irmeli',
  '0104': 'Peppi, Raita, Pulmu',
  '0204': 'Pellervo',
  '0304': 'Veeti, Sampo',
  '0404': 'Ukko',
  '0504': 'Ira, Irina, Irene, Iro',
  '0604': 'Ville, Vili, Jami, Viljami, Vilho, Vilhelm',
  '0704': 'Allan, Ahvo',
  '0804': 'Suoma, Suometar',
  '0904': 'Elias, Eelis, Eeli, Eljas, Elia, Eliel',
  '1004': 'Tero',
  '1104': 'Minea, Minka, Verna',
  '1204': 'Julia, Julius, Janna, Juuli, Janni, Juliaana ',
  '1304': 'Tellervo',
  '1404': 'Taito',
  '1504': 'Linda, Tuomi',
  '1604': 'Patrik, Jalo',
  '1704': 'Otto',
  '1804': 'Valto, Valdemar',
  '1904': 'Pilvi, Pälvi',
  '2004': 'Nella, Neela, Lauha',
  '2104': 'Anssi, Anselmi',
  '2204': 'Aida, Alina',
  '2304': 'Jyrki, Jyri, Jori, Jiri, Yrjö, Yrjänä',
  '2404': 'Pertti, Albert, Altti',
  '2504': 'Marko, Markus, Markku',
  '2604': 'Teresa, Tessa, Terttu',
  '2704': 'Merja, Meea',
  '2804': 'Ilpo, Tuure, Ilppo',
  '2904': 'Teijo',
  '3004': 'Miia, Mira, Mirva, Mirka, Mirja, Mirella, Mirkka, Mirjami',
  '0105': 'Vappu, Valpuri',
  '0205': 'Viivi, Vuokko',
  '0305': 'Outi',
  '0405': 'Roosa, Rosa, Ruusu',
  '0505': 'Melina, Melissa, Maini',
  '0605': 'Ylermi',
  '0705': 'Helmi, Kastehelmi',
  '0805': 'Heino',
  '0905': 'Timo, Timi',
  '1005': 'Aino, Aini, Aina, Ainikki',
  '1105': 'Osmo',
  '1205': 'Lotta, Charlotte',
  '1305': 'Kukka, Floora',
  '1405': 'Tuula',
  '1505': 'Sonja, Sofia, Sohvi',
  '1605': 'Essi, Ester, Esteri',
  '1705': 'Maisa, Rebekka, Mailis, Maila, Maili ',
  '1805': 'Eero, Erkki, Eerika, Eerik, Erkka, Eerikki',
  '1905': 'Emma, Emilia, Milla, Emmi, Milja, Mila, Milka, Amalia, Amelia, Emmy',
  '2005': 'Karoliina, Lilja, Lilli, Lila, Caroline, Lily',
  '2105': 'Konsta, Kosti, Konstantin',
  '2205': 'Hemmo, Hemminki',
  '2305': 'Lyydia, Lyyli',
  '2405': 'Tuukka, Touko',
  '2505': 'Urpo',
  '2605': 'Minna, Minni, Vilma, Miina, Mimmi, Vilhelmiina',
  '2705': 'Ritva',
  '2805': 'Alma',
  '2905': 'Oliver, Olivia, Oiva, Oivi',
  '3005': 'Pasi',
  '3105': 'Helka, Helga',
  '0106': 'Teemu, Nikodemus',
  '0206': 'Venla',
  '0306': 'Viola, Orvokki',
  '0406': 'Toivo',
  '0506': 'Sulevi',
  '0606': 'Kyösti, Kustaa, Kustavi, Ava',
  '0706': 'Suvi, Roope, Robert, Robin',
  '0806': 'Salomon, Salomo',
  '0906': 'Ensio',
  '1006': 'Seppo',
  '1106': 'Immi, Impi',
  '1206': 'Esko',
  '1306': 'Raili, Raila',
  '1406': 'Pihla, Kielo, Morgan',
  '1506': 'Moona, Viena, Vieno',
  '1606': 'Päivi, Päivikki, Päivä',
  '1706': 'Urho',
  '1806': 'Tapio',
  '1906': 'Siiri',
  '2006': 'Into',
  '2106': 'Ahti, Ahto',
  '2206': 'Paula, Pauliina, Liina',
  '2306': 'Aatu, Aatto, Aadolf',
  '2406': 'Juha, Janne, Jani, Jukka, Juho, Jussi, Johannes, Juhani, Juhana, Jack',
  '2506': 'Uuno',
  '2606': 'Jarkko, Jere, Jarno, Jarmo, Jorma, Jeremias',
  '2706': 'Elviira, Elvi',
  '2806': 'Leo',
  '2906': 'Petri, Pekka, Petra, Petteri, Peetu, Pietari, Pekko, Petja, Petrus',
  '3006': 'Päivö, Päiviö',
  '0107': 'Aaro, Aaron',
  '0207': 'Mari, Maria, Marika, Maija, Maiju, Meeri, Riia, Maaria, Kukka-Maaria, Maikki',
  '0307': 'Arvo',
  '0407': 'Ulla, Ulpu',
  '0507': 'Unto, Untamo',
  '0607': 'Esa, Esaias',
  '0707': 'Klaus, Launo, Claudia',
  '0807': 'Turo, Turkka',
  '0907': 'Jasmin, Jasmiina, Jade, Nanna, Ilta',
  '1007': 'Saimi, Saima',
  '1107': 'Noora, Elli, Nelli, Eleonoora',
  '1207': 'Hermanni, Herkko, Herman',
  '1307': 'Joel, Ilari, Lari',
  '1407': 'Alisa, Aliisa',
  '1507': 'Rauni, Rauna, Ramona',
  '1607': 'Reino, Rene',
  '1707': 'Ossi, Ossian',
  '1807': 'Riikka',
  '1907': 'Sari, Sara, Saara, Salla, Sarita, Salli',
  '2007': 'Maarit, Reetta, Reeta, Maaret, Marketta, Margareeta, Meja',
  '2107': 'Hanna, Johanna, Jenni, Jenna, Jonna, Hanne, Hannele, Joanna',
  '2207': 'Leena, Matleena, Lenita, Leeni',
  '2307': 'Olga, Oili',
  '2407': 'Tiina, Kirsi, Krista, Kristiina, Kiia, Kirsti, Tinja, Christine',
  '2507': 'Jaakko, Jimi, Jaakob, Jaakoppi',
  '2607': 'Martta',
  '2707': 'Heidi',
  '2807': 'Atso',
  '2907': 'Olli, Olavi, Oula, Uolevi, Uoti',
  '3007': 'Asta',
  '3107': 'Helena, Elena',
  '0108': 'Maire',
  '0208': 'Kimmo',
  '0308': 'Nea, Neea, Linnea, Vanamo',
  '0408': 'Veera',
  '0508': 'Salme, Sanelma',
  '0608': 'Keimo, Toimi',
  '0708': 'Lahja',
  '0808': 'Silva, Sylvi, Sylvia ',
  '0908': 'Erja, Nadja, Eira',
  '1008': 'Lauri, Lasse, Lassi',
  '1108': 'Sanna, Susanna, Sanni, Susanne',
  '1208': 'Kiira, Klaara',
  '1308': 'Jesse, Okko',
  '1408': 'Onerva, Kanerva',
  '1508': 'Jaana, Marjo, Marja, Marianne, Marjut, Marita, Marjaana, Marianna, Jatta, Marjukka, Maritta, Marjatta ',
  '1608': 'Aulis',
  '1708': 'Verneri, Venni',
  '1808': 'Leevi, Levi',
  '1908': 'Mauno, Maunu',
  '2008': 'Sami, Samuli, Samu, Samuel',
  '2108': 'Veini, Soini',
  '2208': 'Iivari, Iivo',
  '2308': 'Signe, Varma',
  '2408': 'Bertta, Perttu',
  '2508': 'Loviisa, Lova',
  '2608': 'Ilmi, Ilma, Ilmatar',
  '2708': 'Rauli',
  '2808': 'Tauno',
  '2908': 'Iina, Iines, Inari, Inna',
  '3008': 'Eemeli, Eemil, Eemi',
  '3108': 'Arvi',
  '0109': 'Pirkka',
  '0209': 'Sini, Justus, Sinikka',
  '0309': 'Soile, Soili, Soila',
  '0409': 'Ansa',
  '0509': 'Roni, Ron, Mainio',
  '0609': 'Asko, Felix',
  '0709': 'Miro, Milo, Arho, Arhippa',
  '0809': 'Taimi',
  '0909': 'Vertti, Isto, Eevert',
  '1009': 'Kalevi, Kaleva',
  '1109': 'Santeri, Santtu, Sandra, Aleksanteri, Ali, Aleksandra, Ale',
  '1209': 'Vilja, Valma, Nova, Neo',
  '1309': 'Orvo',
  '1409': 'Iida, Iita, Isla',
  '1509': 'Sirpa',
  '1609': 'Hilla, Hille, Hellevi, Hillevi',
  '1709': 'Aila, Aili',
  '1809': 'Tytti, Tyyne, Tyyni',
  '1909': 'Reija',
  '2009': 'Varpu, Vaula',
  '2109': 'Mervi',
  '2209': 'Mauri',
  '2309': 'Minja, Miisa, Mielikki',
  '2409': 'Alvar, Auno',
  '2509': 'Kullervo',
  '2609': 'Kuisma',
  '2709': 'Vesa',
  '2809': 'Arja, Lenni, Leona, Leon',
  '2909': 'Mika, Mikko, Miika, Mikael, Miikka, Miska, Miko, Mikaela',
  '3009': 'Siru, Sirja, Sorja',
  '0110': 'Rauno, Raine, Rainer, Raino',
  '0210': 'Valio',
  '0310': 'Raimo',
  '0410': 'Saija, Saila, Frans',
  '0510': 'Inka, Inkeri',
  '0610': 'Pinja, Minttu',
  '0710': 'Pirjo, Pirkko, Piritta, Pirita, Pipsa, Birgitta',
  '0810': 'Hilja',
  '0910': 'Ilona',
  '1010': 'Aleksi, Aleksis',
  '1110': 'Otso, Ohto',
  '1210': 'Aarre, Aarto',
  '1310': 'Tanja, Taina, Taija',
  '1410': 'Elsa, Elsi, Else',
  '1510': 'Helvi, Heta',
  '1610': 'Stella, Sirkku, Sirkka, Luna',
  '1710': 'Saana, Saaga, Saini',
  '1810': 'Satu, Luukas, Luka, Säde',
  '1910': 'Uljas',
  '2010': 'Kasper, Kasperi, Jasper, Jesper, Kauno, Melker',
  '2110': 'Ursula',
  '2210': 'Anniina, Annina, Anita, Anette, Anja, Nita, Anitta',
  '2310': 'Severi',
  '2410': 'Rasmus, Asmo',
  '2510': 'Sointu',
  '2610': 'Nina, Niina, Amanda, Ninni, Manta',
  '2710': 'Helli, Helle, Hellin, Hellä',
  '2810': 'Simo',
  '2910': 'Alfred, Urmas',
  '3010': 'Eila, Juni',
  '3110': 'Arttu, Arto, Artturi',
  '0111': 'Pyry, Lyly',
  '0211': 'Topi, Topias',
  '0311': 'Terho',
  '0411': 'Hertta',
  '0511': 'Reima',
  '0611': 'Mimosa',
  '0711': 'Taisto, Erin, Colin',
  '0811': 'Aatos',
  '0911': 'Teuvo, Teo',
  '1011': 'Martti',
  '1111': 'Panu',
  '1211': 'Virpi',
  '1311': 'Kristian, Ano',
  '1411': 'Iiris',
  '1511': 'Janina, Janika, Janette, Janita',
  '1611': 'Aarni, Aarne, Aarno',
  '1711': 'Eino, Einari',
  '1811': 'Max, Tenho, Jousia',
  '1911': 'Liisa, Elisa, Elise, Eliisa, Liisi, Elisabet',
  '2011': 'Jari, Jalmari',
  '2111': 'Hilma',
  '2211': 'Cecilia, Silja, Seela, Selja',
  '2311': 'Ismo',
  '2411': 'Lempi, Sivi, Lemmikki',
  '2511': 'Katja, Kati, Katri, Kaisa, Riina, Katariina, Katriina, Kaisu, Kaija, Kaarina',
  '2611': 'Sisko',
  '2711': 'Hilkka',
  '2811': 'Heini, Kaisla, Malte',
  '2911': 'Aimo',
  '3011': 'Antti, Atte, Antero, Andreas',
  '0112': 'Oskari, Oskar',
  '0212': 'Unelma, Anelma, Unna',
  '0312': 'Meri, Vellamo',
  '0412': 'Airi, Aira',
  '0512': 'Selma, Thelma',
  '0612': 'Niko, Niklas, Niilo, Niki, Nikolai, Niila, Nikolas, Nicolina',
  '0712': 'Sampsa',
  '0812': 'Kyllikki, Kylli',
  '0912': 'Anna, Anne, Anu, Anni, Annika, Annukka, Anneli, Annu, Annikki',
  '1012': 'Jutta',
  '1112': 'Tatu, Daniel, Daniela, Taneli',
  '1212': 'Tuovi',
  '1312': 'Seija',
  '1412': 'Jouko',
  '1512': 'Nooa, Heimo',
  '1612': 'Aada, Adele, Auli, Aulikki',
  '1712': 'Raakel',
  '1812': 'Aapo, Rami, Aappo',
  '1912': 'Iiro, Iikka, Iisakki, Isko',
  '2012': 'Benjamin, Kerkko',
  '2112': 'Tomi, Tuomas, Tommi, Tuomo',
  '2212': 'Raafael',
  '2312': 'Senni, Noel',
  '2412': 'Aatami, Eeva, Eveliina, Eevi',
  '2512': 'Joulupäivä',
  '2612': 'Teppo, Tapani, Tahvo, Stephanie',
  '2712': 'Hannu, Hannes, Hans',
  '2812': 'Piia',
  '2912': 'Rauha',
  '3012': 'Taavi, Taavetti, Daavid',
  '3112': 'Silvo, Sylvester',
})
