import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import moment from 'moment-timezone'
import classNames from 'classnames'
import wind from './icons/wind-arrow.svg'
import drop from './icons/drop.svg'
import rain from './icons/rain.svg'


const WeatherDay = ({ handleDayClick, dailyData, selectedDay, day, temperature, minTemperature }) => {

  const date = dailyData.get('date')

  const handleDayClickWithDate = useCallback(() => {
    handleDayClick(date)
  }, [handleDayClick, date])

  return (
    <button
      onClick={ handleDayClickWithDate }
      className='weekly-weather-day'
      type='button'
    >
      <time className='weather-time' title={ moment(date).format('dd D.M.') } dateTime={ moment(date).format('YYYY-MM-DD') }>
        { day.charAt(0).toUpperCase() + day.slice(1) }
        { selectedDay === date ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> }
      </time>
      <span className='weather-symbol'>
        <span className={ classNames(dailyData.get('symbol')) } />
      </span>

      <span className='weather-temperature-container'>
        <span className='weather-temperature'>{ temperature + String.fromCharCode(176) }</span>
        <span className='weather-min-temperature'>{ 'alin ' + minTemperature + String.fromCharCode(176) }</span>
      </span>

      <span className='weather-wind'>
        <span className={ classNames('weather-wind__image__wrapper weather-wind-direction', dailyData.get('windDirection')) } aria-hidden='true' >
          <img
            className='weather-wind__image'
            alt=''
            src={ wind }
            width={ 15 }
            height={ 15 }
          />
        </span>
        <span className='weather-value'>{ dailyData.get('windSpeed') + ' m/s' }</span>
      </span>

      <span className='weather-min-max-rain-change'>
        <span className='weather-min-max-rain-change__image__wrapper'>
          <img
            className='weather-min-max-rain-change__image'
            alt=''
            src={ drop }
            width={ 15 }
            height={ 15 }
          />
        </span>
        <span className='weather-min-max-rain-change__value weather-value'>{ dailyData.get('precipitation') + ' mm' }</span>
      </span>

      <span className='weather-precipitation-probability'>
        <span className='weather-precipitation-probability__image__wrapper'>
          <img
            className='weather-precipitation-probability__image'
            alt=''
            src={ rain }
            width={ 15 }
            height={ 15 }
          />
        </span>
        <span className='weather-value'>{ dailyData.get('precipitationProbability') + ' %' }</span>
      </span>
    </button>
  )

}

WeatherDay.propTypes = exact({
  dailyData: ImmutablePropTypes.map,
  selectedDay: PropTypes.string,
  handleDayClick: PropTypes.func,
  day: PropTypes.string,
  temperature: PropTypes.string,
  minTemperature: PropTypes.string,
})

export default WeatherDay
