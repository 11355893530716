import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classNames from 'classnames'

export default class ModalButton extends Component {
  render() {
    const {
      text,
      classes,
      disabled,
    } = this.props

    return (
      <button
        type='button'
        className={ classNames(
          'button', 'button_full-width', ...classes
        ) }
        disabled={ disabled }
        onClick={ this.handleClick }
      >
        { text }
      </button>
    )
  }

  handleClick = () => {
    this.props.callback()
  }
}

ModalButton.propTypes = exact({
  text: PropTypes.string.isRequired,
  classes: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  callback: PropTypes.func.isRequired,
})
