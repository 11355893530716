import * as actions from './tvActions'
import Immutable from 'immutable'
import { getTVFiltersTodayByProgram, TV_FILTER_PROGRAM_DEFAULT, TV_FILTER_PROGRAM_TYPE_DEFAULT } from './filters'

const defaultState = Immutable.Map({
  programming: Immutable.List(),
  programmingMinDate: '2000-01-01', // Default that forces a fetch on first load
  programmingTimestamp: null,
  controls: Immutable.Map({
    program: 'coming',
    programType: 'all',
    channel: 'free',
    date: null,
    textFilter: '',
  }),
  loadingPrograms: false,
  failedToLoadPrograms: false,
})

export default function tv(state = defaultState, action) {
  switch (action.type) {
    case actions.FETCH_TV_PROGRAMS:
      return state
        .set('loadingPrograms', true)
        .set('failedToLoadPrograms', false)

    case actions.FETCH_TV_PROGRAMS_SUCCESS:
      return state
        .set('programming', Immutable.fromJS(action.data.data))
        .set('programmingMinDate', action.minDate)
        .set('programmingTimestamp', action.timestamp)
        .set('loadingPrograms', false)
        .set('failedToLoadPrograms', false)

    case actions.FETCH_TV_PROGRAMS_FAILURE:
      return state
        .set('loadingPrograms', false)
        .set('failedToLoadPrograms', true)

    case actions.SET_CONTROL_PROGRAM:
      return state.setIn(['controls', 'program'], action.data)

    case actions.SET_CONTROL_PROGRAM_TYPE:
      return state.setIn(['controls', 'programType'], action.data)

    case actions.SET_CONTROL_CHANNEL:
      return state.setIn(['controls', 'channel'], action.data)

    case actions.SET_CONTROL_DATE:
      return state.setIn(['controls', 'date'], action.data)

    case actions.SET_CONTROL_TEXT_FILTER:
      return state.setIn(['controls', 'textFilter'], action.data)

    case actions.RESET_CONTROL_FILTERS:
      return state
        .setIn(['controls', 'program'], TV_FILTER_PROGRAM_DEFAULT)
        .setIn(['controls', 'programType'], TV_FILTER_PROGRAM_TYPE_DEFAULT)
        .setIn(['controls', 'channel'], action.channelFilter)
        .setIn(['controls', 'date'], getTVFiltersTodayByProgram(TV_FILTER_PROGRAM_DEFAULT))
        .setIn(['controls', 'textFilter'], '')

    default:
      return state
  }
}
