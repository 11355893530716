import Immutable from 'immutable'
import { combineReducers } from 'redux'
import {
  FETCH_TICKERS,
  FETCH_TICKERS_SUCCESS,
  FETCH_TICKERS_FAILURE,
  TICKER_COOKIE_PREFIX,
  CLOSE_TICKER,
} from './tickerActions'

/*
 * @todo refactor this reducer to use same pattern as other reducers
 * - state = Immutable.Map(..)
 * - add 'failedToLoad'
 * - no need for combineReducers
 */

const tickersInitialState = Immutable.List()

function tickers(state = tickersInitialState, action) {
  switch (action.type) {
    case FETCH_TICKERS_SUCCESS: {
      action.tickers.forEach(ticker => {
        ticker.closed = action.cookies != null && action.cookies.get(TICKER_COOKIE_PREFIX + ticker.id) != null
      })
      const newState = Immutable.fromJS(action.tickers)
      return state.equals(newState) ? state : newState
    }
    case CLOSE_TICKER: {
      const tickerIndex = state.findIndex( ticker => ticker.get('id') === action.tickerId)
      if (tickerIndex !== -1) {
        return state.setIn([tickerIndex, 'closed'], true)
      }
      return state
    }
    default:
      return state
  }
}

function timestamp(state = null, action) {
  switch (action.type) {
    case FETCH_TICKERS_FAILURE:
    case FETCH_TICKERS_SUCCESS:
      return action.timestamp

    default:
      return state
  }
}

function loading(state = false, action) {
  switch (action.type) {
    case FETCH_TICKERS:
      return true

    case FETCH_TICKERS_FAILURE:
    case FETCH_TICKERS_SUCCESS:
      return false

    default:
      return state
  }
}

export default combineReducers({
  tickers,
  timestamp,
  loading,
})
