import Immutable from 'immutable'
import { createSelector } from 'reselect'
import { selectUserBlacklistedCategoryIds } from './userSelector'
import { selectPresetBlacklistedCategoryIds } from './profilesSelector'

/** @return {Immutable.Map<string,CategoryRecord>} */
export const selectCategories = state => state.categories.categories

/** @return {?CategoryRecord} */
export const selectCategoryById = (state, id) => selectCategories(state).find(category => category.get('id') === id)

/** @return {?CategoryRecord} */
export const selectCategoryBySlug = (state, slug) => selectCategories(state).get(slug)

/** @return {?CategoryRecord} */
export const selectCategoryByName = (state, name) => selectCategories(state).find(category => category.get('name').toLowerCase() === name.toLowerCase())


/**
 * Return IDs of categories that are blacklisted by user profile or active preset profile
 * @type {(state) => Immutable.Set<number>}
 */
export const selectBlacklistedCategoryIds = createSelector(
  selectUserBlacklistedCategoryIds,
  selectPresetBlacklistedCategoryIds,
  (userBlacklist, presetBlacklist) => userBlacklist.union(presetBlacklist)
)


/**
 * Return true if category is blacklisted by user profile or active preset profile
 * @type {(state: object, categoryId: number) => boolean}
 */
export const selectIsCategoryBlacklisted = (state, categoryId) => {
  return selectUserBlacklistedCategoryIds(state).contains(categoryId) ||
         selectPresetBlacklistedCategoryIds(state).contains(categoryId)
}


/**
 * Return immediate child categories for category, sorted in their menu order
 * @type {(state: object, category: CategoryRecord) => Immutable.OrderedSet<CategoryRecord>}
 * @return {Immutable.OrderedSet<CategoryRecord>} [ChildCategory1, ChildCategory2, ...]
 */
export const selectChildCategories = createSelector(
  selectCategories,
  (state, category) => category.id,
  (categories, categoryId) => {
    const children = categories.filter(cat => cat.parentId === categoryId).toOrderedSet()
    return children.sort((a, b) => {
      if (a.ordering < b.ordering) return -1
      if (a.ordering > b.ordering) return 1
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
  }
)


/**
 * Return all descendant categories for category
 * @type {(state: object, int: categoryId) => Immutable.Set<CategoryRecord>}
 * @return {Immutable.Set<CategoryRecord>} [ChildCategory, GrandChildCategory, ...]
 */
export const selectDescendantCategories = createSelector(
  selectCategories,
  (state, categoryId) => categoryId,
  (categories, categoryId) => {
    const descendants = []
    categories = categories.toList()

    function recurseChildren(parentId) {
      for (const cat of categories) {
        if (cat.parentId === parentId) {
          descendants.push(cat)
          recurseChildren(cat.id)
        }
      }
    }

    recurseChildren(categoryId)
    return Immutable.Set(descendants)
  }
)


/**
 * Return related categories for category
 * @type {(state: object, category: CategoryRecord) => Immutable.OrderedSet<CategoryRecord>}
 * @return {Immutable.OrderedSet<CategoryRecord>} [RelatedCategory1, RelatedCategory2, ...]
 */
export const selectRelatedCategories = createSelector(
  selectCategories,
  (state, category) => category.relatedCategoryIds,
  (categories, relatedCategoryIds) => {
    return relatedCategoryIds.map(relatedId => categories.find(cat => cat.id === relatedId))
  }
)


/**
 * Returns hierarchy chain of category (including the category passed as parameter)
 * @type {(state: object, category: CategoryRecord) => Immutable.List<CategoryRecord>}
 * @return {Immutable.List<CategoryRecord>} [RootCategory, 2ndLevelCategory, ...]
 */
export const selectCategoryHierarchy = createSelector(
  selectCategories,
  (state, category) => category,
  (categories, category) => {
    const chain = []
    while (category) {
      chain.unshift(category)
      category = categories.find(cat => cat.id === category.parentId)
    }
    return Immutable.List(chain)
  }
)
