import React, { Component} from 'react'
import { connect } from 'react-redux'
import { canUseDOM } from 'exenv'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'


/**
 * If the user moves between pages quickly, we don't want to reload ads all the time.
 * Set this interval value to some reasonable value (ms) to wait before ad can be refreshed.
 */
const MIN_REFERSH_FREQUENCY = 3000

/**
  * AlmaAd-components currently mounted in DOM, ie. what can be refreshed
  */
const mountedAds = {}


/**
 * Debug ads with queryparam ?debugads
 */
const debugads = canUseDOM && window.location.search.includes('debugads')


/**
 * These logging functions can be called either as plain functions or as AlmaAd methods
 */
function conLog(...args) {
  if (this) {
    args.unshift(`[${this.props.id}]`)
  }
  if (debugads) {
    console.log(...args) // eslint-disable-line no-console
  }
}

function conGroup(...args) {
  if (this) {
    args.unshift(`[${this.props.id}]`)
  }
  if (debugads) {
    console.group(...args) // eslint-disable-line no-console
  }
}

function conGroupEnd() {
  if (debugads) {
    console.groupEnd() // eslint-disable-line no-console
  }
}


/**
 * The async nature of our app causes unpredictable timings on events. For ads to be delivered with
 * highest efficency, window.digitalData must be up to date. Unfortunately sometimes digitalData
 * updates before ad slots mount, sometimes it updates after. Sometimes a portion of the slots mount
 * before digitalData and portion after. Coming up with correct, predictable, and natural timing
 * sequence seems really hard.
 *
 * The workaround is this pause/resume mechanic. When paused, any call to load an ad will be delayed
 * until resume is called.
 */
let pauseAdLoading = false
export function pauseAds() {
  conGroup('Ads calls paused')
  pauseAdLoading = true
}
export function resumeAds() {
  conLog('Resuming ad calls')
  conGroupEnd()
  pauseAdLoading = false

  conGroup(`Loading ${ Object.values(mountedAds).filter(almaAd => almaAd.refreshPending).length } pending ads`)
  Object.values(mountedAds).filter(almaAd => almaAd.refreshPending).forEach(almaAd => {
    almaAd.refreshAd()
  })
  conGroupEnd()
}


/**
 * Mark all mounted ads to be refrehsed. There is a small delay, so react has time to
 * unmount ads if there was or will be also a DOM change as a result of whatever
 * called this function.
 * @todo this function should not be needed. Either shouldComponentUpdate needs
 *       to be smarter, or we need some entirely different solution.
 */
export function refreshAllAds() {
  conGroup('call to refreshAllAds()')
  Object.values(mountedAds).forEach(almaAd => {
    almaAd.refreshPending = true
    conLog(`Marked ${almaAd.props.id} for refresh`)
  })
  conGroupEnd()
  setTimeout(refreshMarkedAds, 250)
}

function refreshMarkedAds() {
  conGroup(`Refreshing ${ Object.values(mountedAds).filter(almaAd => almaAd.refreshPending).length } marked ads`)
  Object.values(mountedAds).filter(almaAd => almaAd.refreshPending).forEach(almaAd => {
    almaAd.refreshAd()
  })
  conGroupEnd()
}



/**
 * Finally the actual class!
 */
class AlmaAd extends Component {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    className: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.refreshPending = null
    this.loadedTimestamp = 0
  }

  render() {
    const { className, id } = this.props
    return (
      <div id={ id } className={ `ad-slot ad-slot--alma ${className}` } />
    )
  }

  componentDidMount() {
    this.conLog('componentDidMount')
    mountedAds[this.props.id] = this
    this.refreshAd()
  }

  shouldComponentUpdate(nextProps) {
    const shouldUpdate = this.props.path !== nextProps.path || this.props.id !== nextProps.id
    this.conLog('shouldComponentUpdate:', shouldUpdate, '(', this.props.path, '!==', nextProps.path, '||', this.props.id, '!==', nextProps.id, ')')
    return shouldUpdate
  }

  componentDidUpdate(prevProps) {
    this.conLog('componentDidUpdate')
    const oldDomId = prevProps.id
    const newDomId = this.props.id
    if (oldDomId !== newDomId) { // This should never happen but handle anyway..
      delete mountedAds[oldDomId]
      mountedAds[newDomId] = this
    }
    this.refreshAd()
  }

  componentWillUnmount() {
    this.conLog('componentWillUnmount')
    delete mountedAds[this.props.id]
  }

  /**
   * Load/reload ad
   */
  refreshAd = () => {
    if (!window.loadAlmaAd) {
      this.conLog('Skipping refresh because window.loadAlmaAd() has not been defined (adblock?)')
      this.refreshPending = true
      return
    }
    if (pauseAdLoading) {
      this.conLog('Skipping refresh until resumeAds() is called')
      this.refreshPending = true
      return
    }
    if (Date.now() - this.loadedTimestamp < MIN_REFERSH_FREQUENCY) {
      this.conLog('Skipping refresh due to min delay')
      return
    }

    this.conLog('Loading ad')
    this.loadedTimestamp = Date.now()
    this.refreshPending = false
    window.loadAlmaAd(this.props.id)
  }
}


/**
 * Attach logging functions to AlmaAd class. Adding methods through the
 * prototype will did work when using class decorator syntax for redux connect.
 * (Fails with "TypeError: Cannot set property 'conLog' of undefined").
 */
AlmaAd.prototype.conLog = conLog
AlmaAd.prototype.conGroup = conGroup
AlmaAd.prototype.conGroupEnd = conGroupEnd

/**
 * As noted above, connect-decorator does not work and we have to use
 * this more traditional syntax instead.
 */
function mapStateToProps(state) {
  return { path: state.status.get('path') }
}

export default withRouter(connect(mapStateToProps)(AlmaAd))

