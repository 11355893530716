import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'

export default class TopNotification extends Component {

  render() {
    return (
      <div className={ classnames('top-notification', this.props.className) }>
        { this.props.children }
      </div>
    )
  }
}

TopNotification.propTypes = exact({
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
})
