import { sendAdminEmail } from '../../api/api'
import { logException } from '../../sentry'

export const SEND_REMOVAL_REQUEST = 'SEND_REMOVAL_REQUEST'
export const SEND_REMOVAL_REQUEST_SUCCESS = 'SEND_REMOVAL_REQUEST_SUCCESS'
export const SEND_REMOVAL_REQUEST_FAILURE = 'SEND_REMOVAL_REQUEST_FAILURE'


export function sendAccountRemovalRequest(replyToEmail, postData) {
  return (dispatch) => {
    dispatch({
      type: SEND_REMOVAL_REQUEST,
    })

    return sendAdminEmail('Ampparit-tunnuksen poistopyyntö', replyToEmail, postData).then(response => {
      dispatch({
        type: SEND_REMOVAL_REQUEST_SUCCESS,
        response,
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: SEND_REMOVAL_REQUEST_FAILURE,
        error,
      })
    })
  }
}
