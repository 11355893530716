import { combineReducers } from 'redux-immutable'
import Immutable from 'immutable'
import * as actions from './userActions'
import * as itemsActions from '../components/item/itemsActions'
import { listQueueLimit, listSize } from '../lib/items'
import * as config from '../../config/config'

export const CustomFeedRecord = Immutable.Record({
  id: null,
  url: null,
  name: null,
  categoryId: null,
}, 'CustomFeedRecord')

function nightMode(state = false, action) {
  switch (action.type) {
    case actions.SET_NIGHT_MODE_SUCCESS:
      return !!action.active

    case actions.SET_TEMPORARY_PROFILE:
      return !!action.profileData.nightMode

    case actions.SET_ACTIVE_USER_PROFILE:
      // Night mode is currently only for temporary profile.
      // When using actual profile, we will respect the user selected theme.
      return false

    default:
      return state
  }
}

function frontPage(state = '/', action) {
  switch (action.type) {

    case actions.SET_ACTIVE_USER_PROFILE: {
      return action.profile.options['www-frontPage'] || state
    }

    case actions.LOGOUT_USER_SUCCESS:
      return '/'

    case actions.CHANGE_FRONT_PAGE_FAILURE:
      return state

    default:
      return state
  }
}

function theme(state = 'classic', action) {
  switch (action.type) {

    case actions.SET_ACTIVE_USER_PROFILE:
      return action.profile.options['www-theme'] || state

    case actions.LOGOUT_USER_SUCCESS:
      return 'classic'

    default:
      return state
  }
}

function layoutWidth(state = actions.LAYOUT_WIDTH_NORMAL, action) {
  switch (action.type) {

    case actions.SET_ACTIVE_USER_PROFILE:
      return action.profile.options['www-layoutWidth'] || state

    case actions.LOGOUT_USER_SUCCESS:
      return actions.LAYOUT_WIDTH_NORMAL

    default:
      return state
  }
}

function profiles(state = Immutable.Map(), action) {
  switch (action.type) {
    case actions.FETCH_READYMADE_PROFILES_SUCCESS: {
      let profiles = Immutable.Map()
      for (const profile of action.response.data) {
        profiles = profiles.set(profile.id, Immutable.Map({
          id: profile.id,
          name: profile.name,
          categoryBlacklist: Immutable.Set(profile.categoryBlacklist.map(c => c.id)),
        }))
      }
      return profiles
    }

    default:
      return state
  }
}

function activePresetProfile(state = null, action) {
  switch (action.type) {
    case actions.SET_ACTIVE_PRESET_PROFILE:
      return action.profileId

    case actions.SET_ACTIVE_USER_PROFILE:
    case actions.LOGOUT_USER_SUCCESS:
      return null

    default:
      return state
  }
}

function overlayIsOpen(state = false, action) {
  switch (action.type) {
    case actions.TOGGLE_OVERLAY_OPEN:
      return action.toggleState

    default:
      return state
  }
}

function showUserFeedsBoxInSidebar(state = true, action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE:
      return action.profile.options['www-showUserFeedsBoxInSidebar'] !== 'false'

    case actions.LOGOUT_USER_SUCCESS:
      return true

    default:
      return state
  }
}

function whitelistedCategories(state = Immutable.Set(), action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE:
      return Immutable.Set(action.profile.categoryWhitelist.map(category => category.id))

    case actions.SET_TEMPORARY_PROFILE:
      return Immutable.Set(action.profileData.whitelistedCats)

    case actions.LOGOUT_USER_SUCCESS:
      return Immutable.Set()

    default:
      return state
  }
}

function whitelistedTags(state = Immutable.Set(), action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE:
      return Immutable.Set(action.profile.tagWhitelist.map(tag => tag.id))

    case actions.SET_TEMPORARY_PROFILE:
      return Immutable.Set(action.profileData.whitelistedTags)

    case actions.LOGOUT_USER_SUCCESS:
      return Immutable.Set()

    default:
      return state
  }
}

function blacklistedSources(state = Immutable.Set(), action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE: {
      const ids = action.profile.sourceBlacklist.map(source => source.id)
      return state.intersect(ids).union(ids)
    }

    case actions.LOGOUT_USER_SUCCESS:
      return state.clear()

    default:
      return state
  }
}

function subscribedSources(state = Immutable.Set(), action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE: {
      const ids = action.profile.sourceSubscriptions.map(source => source.id)
      return state.intersect(ids).union(ids)
    }

    case actions.LOGOUT_USER_SUCCESS:
      return state.clear()

    default:
      return state
  }
}

const initialSubscribedLanguages = Immutable.Set(['fi'])
function subscribedLanguages(state = initialSubscribedLanguages, action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE: {
      const slugs = action.profile.languages.map(language => language.slug)
      return state.intersect(slugs).union(slugs)
    }

    case actions.LOGOUT_USER_SUCCESS:
      return initialSubscribedLanguages

    default:
      return state
  }
}


function blacklistedCategories(state = Immutable.Set(), action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE:
      return Immutable.Set(action.profile.categoryBlacklist.map(category => category.id))

    case actions.SET_TEMPORARY_PROFILE:
      return Immutable.Set(action.profileData.blacklistedCats)

    case actions.LOGOUT_USER_SUCCESS:
      return Immutable.Set()

    default:
      return state
  }
}

function blacklistedTags(state = Immutable.Set(), action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE:
      return Immutable.Set(action.profile.tagBlacklist.map(tag => tag.id))

    case actions.SET_TEMPORARY_PROFILE:
      return Immutable.Set(action.profileData.blacklistedTags)

    case actions.LOGOUT_USER_SUCCESS:
      return Immutable.Set()

    default:
      return state
  }
}

function recommendedCategories(state = Immutable.Map(), action) {
  switch (action.type) {

    case actions.FETCH_RECOMMENDED_CATEGORIES_SUCCESS: {
      const category = action.data.map(content => content.category)
        .reduce((acc, cat) => {
          return cat
        }, Immutable.Map())

      const items = action.data.map(content => content.items)
        .reduce((acc, item) => {
          return item.map(item => item.id)
        }, Immutable.List())

      return state
        .set('timestamp', action.timestamp)
        .set('category', Immutable.Map(category))
        .set('itemsIds', items)
    }
    default:
      return state
  }
}

function wordFilters(state = Immutable.OrderedSet(), action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE: {
      const expressions = action.profile.itemWordFilters.map(filter => filter.text)
      const newSet = Immutable.OrderedSet(expressions)
      return state.equals(newSet) ? state : newSet
    }

    case actions.LOGOUT_USER_SUCCESS:
      return state.clear()

    default:
      return state
  }
}

function savedSearches(state = Immutable.OrderedSet(), action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE: {
      const terms = action.profile.savedSearches.map(search => search.searchTerm)
      const newSet = Immutable.OrderedSet(terms)
      return state.equals(newSet) ? state : newSet
    }

    case actions.LOGOUT_USER_SUCCESS:
      return state.clear()

    default:
      return state
  }
}

function voteFilterThreshold(state = 0, action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE:
      return -Math.abs(action.profile.negativeVoteFilterThreshold)

    case actions.LOGOUT_USER_SUCCESS:
      return 0

    default:
      return state
  }
}

function customFeeds(state = Immutable.OrderedSet(), action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE: {
      const feeds = action.profile.customFeeds.map(feed => CustomFeedRecord({
        id: feed.id,
        url: feed.url,
        name: feed.name,
        categoryId: feed.category?.id,
      }))
      const newSet = Immutable.OrderedSet(feeds)
      return state.equals(newSet) ? state : newSet
    }

    case actions.LOGOUT_USER_SUCCESS:
      return state.clear()

    default:
      return state
  }
}

const initialItemsState = Immutable.Map({
  itemIds: Immutable.OrderedSet(),
  itemQueueIds: Immutable.OrderedSet(),
  batches: Immutable.Map(),
  loading: false,
  loadingNext: false,
  listEnd: false,
  relatedTags: Immutable.OrderedSet(),
  shouldRefresh: false,
})

function items(state = initialItemsState, action) {
  let items

  switch (action.type) {

    case actions.SET_ACTIVE_USER_PROFILE:
    case actions.SET_ACTIVE_PRESET_PROFILE:
    case actions.SET_TEMPORARY_PROFILE:
    case actions.LOGOUT_USER_SUCCESS:
    case actions.RESET_TEMPORARY_PROFILE:
      return state.set('shouldRefresh', true)

    case itemsActions.FETCH_USER_ITEMS:
      return initialItemsState
        .set('loading', true)
        .set('failedToLoad', false)
        .set('shouldRefresh', false)

    case itemsActions.FETCH_USER_ITEMS_FAILURE:
      return state
        .set('loading', false)
        .set('failedToLoad', true)

    case itemsActions.FETCH_USER_ITEMS_SUCCESS:
      items = action.response.data

      return state
        .set('loading', false)
        .set('failedToLoad', false)
        .set('itemIds', Immutable.OrderedSet(items.map(item => item.id)))
        .set('itemQueueIds', Immutable.OrderedSet([]))
        .set('relatedTags', Immutable.OrderedSet(items.reduce((a, item) => {
          return item.tags.reduce((a, tag) => {
            return a.set(tag.slug, a.get(tag.slug) + 1 || 1)
          }, a)
        }, Immutable.Map()).sort((a, b) => a < b).keys()))
        .set('batches', items.reduce((a, item) => {
          return a.set(item.batch.id, Immutable.Map({
            itemIds: Immutable.OrderedSet(),
            size: item.batch.size,
            loading: false,
          }))
        }, state.get('batches')))

    case actions.QUEUE_PERSONAL_ITEM: {
      const itemsQueuedTotal = state.get('itemsQueuedTotal', 0)

      if (itemsQueuedTotal >= listQueueLimit) {
        return state
      }

      return state
        .set('itemsQueuedTotal', itemsQueuedTotal + 1)
        .set('itemQueueIds', state.get('itemQueueIds')
          .reverse()
          .union(Immutable.OrderedSet([action.item.id]))
          .reverse()
        )
        .set('batches', state.get('batches').set(action.item.batch.id, Immutable.Map({
          itemIds: Immutable.OrderedSet(),
          size: action.item.batch.size,
          loading: false,
        })))
    }
    case actions.UNQUEUE_PERSONAL_ITEMS:
      return state
        .update('itemIds', itemIds => state.get('itemQueueIds').union(itemIds))
        .set('itemQueueIds', Immutable.OrderedSet([]))

    case itemsActions.FETCH_NEXT_USER_ITEMS:
      return state.set('loadingNext', true)

    case itemsActions.FETCH_NEXT_USER_ITEMS_FAILURE:
      return state.set('loadingNext', false)

    case itemsActions.FETCH_BATCH_USER_ITEMS:
      return state.setIn(['batches', action.batch, 'loading'], true)

    case itemsActions.FETCH_BATCH_USER_ITEMS_FAILURE:
      return state.setIn(['batches', action.batch, 'loading'], false)

    case itemsActions.FETCH_BATCH_USER_ITEMS_SUCCESS:
      return state
        .setIn(['batches', action.batch, 'itemIds'], Immutable.OrderedSet(action.response.data.map(item => item.id)))
        .setIn(['batches', action.batch, 'loading'], false)

    case itemsActions.FETCH_NEXT_USER_ITEMS_SUCCESS:
      items = action.response.data

      return state
        .set('loadingNext', false)
        .set('listEnd', items.length < listSize)
        .update('itemIds', itemIds => itemIds.union(Immutable.OrderedSet(items.map(item => item.id))))
        .set('batches', items.reduce((a, item) => {
          return a.set(item.batch.id, Immutable.Map({
            itemIds: Immutable.OrderedSet(),
            size: item.batch.size,
            loading: false,
          }))
        }, state.get('batches')))


    default:
      return state
  }
}

const userManagementState = Immutable.Map({
  loginStatus: null,
  logoutStatus: null,
  forgotPasswordStatus: null,
  forgotPasswordReason: null,
  newPasswordStatus: null,
  profileNameChangeFailed: false,
  profileNameReserved: false,
})

function userManagement(state = userManagementState, action) {
  switch (action.type) {
    case actions.LOGIN_USER:
      return state.set('loginStatus', 'loading')
    case actions.LOGIN_USER_SUCCESS:
      return state.set('loginStatus', 'success').set('logoutStatus', null)
    case actions.LOGIN_USER_FAILURE:
      return state.set('loginStatus', 'failure')

    case actions.LOGOUT_USER:
      return state.set('logoutStatus', 'loading')
    case actions.LOGOUT_USER_SUCCESS:
      return state.set('logoutStatus', 'success').set('loginStatus', null)
    case actions.LOGOUT_USER_FAILURE:
      return state.set('logoutStatus', 'failure')

    case actions.FORGOT_PASSWORD:
      return state
        .set('forgotPasswordStatus', 'loading')
        .set('forgotPasswordReason', null)
    case actions.FORGOT_PASSWORD_SUCCESS:
      return state.set('forgotPasswordStatus', 'success')
    case actions.FORGOT_PASSWORD_FAILURE:
      return state.set('forgotPasswordStatus', 'failure')
    case actions.FORGOT_PASSWORD_ERROR_EMAIL_NOT_FOUND:
      return state
        .set('forgotPasswordStatus', 'failure')
        .set('forgotPasswordReason', 'emailNotFound')
    case actions.FORGOT_PASSWORD_STATUS_RESET:
      return state
        .set('forgotPasswordStatus', null)

    case actions.NEW_PASSWORD:
      return state.set('newPasswordStatus', 'loading')
    case actions.NEW_PASSWORD_SUCCESS:
      return state.set('newPasswordStatus', 'success')
    case actions.NEW_PASSWORD_FAILURE:
      return state.set('newPasswordStatus', 'failure')

    default:
      return state
  }
}

const initialUserInfo = Immutable.fromJS({
  username: '',
  email: '',
  userId: null,
  userHash: '',
  almaIdHash: '',
  jwt: null,
})

function userInfo(state = initialUserInfo, action) {
  switch (action.type) {
    case actions.SET_LOGGED_IN_USER:
      return state
        .set('username', action.username)
        .set('email', action.email)
        .set('userId', action.userId)
        .set('userHash', action.userHash)
        .set('almaIdHash', action.almaIdHash)
        .set('jwt', action.jwt)
    case actions.CLEAR_USER_JWT:
      return state
        .set('jwt', null)
    case actions.LOGOUT_USER_SUCCESS:
      return initialUserInfo

    default:
      return state
  }
}

function defaultLocation(state = config.defaultLocation, action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE:
      return action.profile.weatherLocations.defaultLocation.id
    case actions.LOGOUT_USER_SUCCESS:
      return config.defaultLocation

    default:
      return state
  }
}

function locations(state = Immutable.OrderedSet(), action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE:
      return Immutable.fromJS(action.profile.weatherLocations.locations).toOrderedSet()
    case actions.LOGOUT_USER_SUCCESS:
      return Immutable.OrderedSet()

    default:
      return state
  }
}

function timestamp(state = 0, action) {
  switch (action.type) {
    case actions.SET_ACTIVE_USER_PROFILE:
      return action.timestamp
    case actions.LOGOUT_USER_SUCCESS:
      return 0
    default:
      return state
  }
}


export default combineReducers({
  categories: combineReducers({
    whitelisted: whitelistedCategories,
    blacklisted: blacklistedCategories,
  }),
  tags: combineReducers({
    whitelisted: whitelistedTags,
    blacklisted: blacklistedTags,
  }),
  wordFilters,
  savedSearches,
  voteFilterThreshold,
  customFeeds,
  items,
  activePresetProfile,
  profiles,
  userManagement,
  userInfo,
  blacklistedSources,
  subscribedSources,
  subscribedLanguages,
  overlayIsOpen,
  defaultLocation,
  locations,
  showUserFeedsBoxInSidebar,
  theme,
  nightMode,
  layoutWidth,
  frontPage,
  recommendedCategories,
  timestamp,
})
