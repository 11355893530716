import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import MainContent from '../../components/wrappers/MainContent'
import './infoViews.pcss'

export default class FaqView extends Component {
  render() {
    return (
      <MainContent>
        <article className='info-page'>
          <Helmet title='Usein kysytyt kysymykset' />

          <h1>Usein kysytyt kysymykset</h1>

          <h2>Miksi uutisotsikko ei nouse Amppareihin?</h2>

          <p>
            Tarkista löytyykö juttu hakumme kautta. Samasta aiheesta kertovat jutut
            niputetaan siitä ensimmäisenä kirjoittaneen lähteen alle. Mikäli juttu
            ei löydy, lähetä meille palautetta kertoen mitkä jutut puuttuvat.
            Huomioithan, että alle kolmen sanan tai 25 merkkiä lyhyempiä otsikoita
            ei julkaista.
          </p>

          <p>
            Tieto puuttuvista jutuista helpottaa ja nopeuttaa asian selvittämistä.
            Kaikkien kysymysten suhteen meille voi
            kirjoittaa <Link to='/palaute'>lomakkeen</Link> kautta.
          </p>

          <h2>Minulla on kehitysidea Amppareihin, mihin voin sen kertoa?</h2>

          <p>
            Otamme mielellämme vastaan kehitysideoita. Voit kertoa meille
            ideasi <Link to='/palaute'>palautelomakkeen</Link> kautta.
          </p>

          <h2>Minulla on palautetta Amppareissa nähdystä uutisotsikosta, mihin voin sen kertoa?</h2>

          <p>
            Ampparit ei itse kirjoita uutisia ollenkaan, vaan keräämme sivullemme
            otsikoita monista eri uutismedioista. Jos haluat antaa palautetta johonkin
            tiettyyn uutiseen, lähetäthän sen suoraan artikkelin julkaisseelle medialle.
          </p>

          <h2>Haluaisin ehdottaa Amppareiden uutisvirtaan uutta lähdettä, miten toimin?</h2>

          <p>
            Tutkimme mahdollisuudet uusien lähteiden liittämiseen tapamme mukaan.
            Katso <Link to='/tietoa/mukaan'>täältä</Link>, kuinka uusien lähteiden
            kanssa toimitaan.
          </p>

          <h2>Amppareissa on tekninen ongelma, mitä teen?</h2>

          <p>
            Lähetä meille palautetta ongelmasta palautelomakkeen kautta. Kerro
            viasta mahdollisimman tarkasti ja raportoithan myös, millä laitteella
            Amppareita käytät.
          </p>

          <h2>Uutiset nousevat Amppareihin viiveellä, mikä avuksi?</h2>

          <p>
            Uutiset eivät nouse Amppareihin aivan heti, kun ne on julkaistu, vaan
            uutisvirrassa voi olla viivettä teknisistä syistä. Kannattaa olla
            kärsivällinen ja tarkistaa asia vähän ajan päästä uudelleen. Mikäli
            uutinen ei jostain syystä nouse ollenkaan uutisvirtaan tai viive
            kasvaa erityisen pitkäksi, voit olla yhteydessä
            meihin <Link to='/palaute'>palautelomakkeen</Link> kautta.
          </p>

          <h2>Haluaisin julkaista tiedotteen Amppareissa, miten se onnistuu?</h2>

          <p>
            Amppareilla ei ole mahdollisuutta julkaista tiedotteita. Mikäli joku
            lähdemedioistamme tekee aiheesta jutun, se päätyy uutisotsikkona myös
            Amppareiden uutisvirtaan. Tämän hetkiset lähteet voit käydä
            katsomassa <Link to='/tietoa/lahteet'>täältä</Link>.
          </p>
        </article>
      </MainContent>
    )
  }
}
