import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

@connect((state) => {
  return {
    announcement: state.announcement.get('message'),
  }
}, {})

export default class AccessibilityAnnouncer extends Component {

  static propTypes = exact({
    announcement: PropTypes.string.isRequired,
  })

  render() {
    const {
      announcement,
    } = this.props

    return (
      <div className='sr-only' aria-live='polite' aria-atomic='true'>
        { announcement }
      </div>
    )
  }
}
