import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { CategoryList } from './CategorySettingsView'
import classnames from 'classnames'
import Switch from '../../components/ui/Switch'
import { menuItemToCategoryItem } from '../../components/item/categoryTransformers'


export default class CategoryItem extends Component {

  constructor(props) {
    super(props)

    this.state = {
      collapsibleOpen: false,
    }
  }

  static propTypes = exact({
    item: ImmutablePropTypes.record.isRequired,
    userCategories: ImmutablePropTypes.map.isRequired,
    isRootList: PropTypes.bool.isRequired,
  })

  render() {
    const {
      item,
      userCategories,
      isRootList,
    } = this.props

    const hasSubPages = !!item.get('pages') && !item.get('pages').isEmpty()
    return (
      <li className={ classnames({'list-style': isRootList, 'child-list': !isRootList}) }>

        <div className={ classnames('category-item',
          {'category-collapsible': hasSubPages},
          {'category-sub-item': !isRootList},
        ) }
        >
          <Switch tag={ menuItemToCategoryItem(item, userCategories) } />

          { hasSubPages && (
            <button
              className='open-collapsibles'
              type='button'
              onClick={ this.handleCollapsibleToggle }
              aria-controls={ `sub-category-list-${item.get('categoryId')}` }
              aria-expanded={ this.state.collapsibleOpen ? 'true' : 'false' }
            >
              <span>Alakategoriat </span>
              <span className={ classnames('fa fa-caret-down chevron-transition',
                {'fa fa-caret-up chevron-transition collapsibles-open': this.state.collapsibleOpen}
              ) }
              />
            </button>
          ) }

        </div>
        <section
          className={ classnames(
            {'category-collapsible-open': this.state.collapsibleOpen},
            {'category-collapsible-closed': !this.state.collapsibleOpen}
          ) }
          id={ `sub-category-list-${item.get('categoryId')}` }
        >
          { hasSubPages && (
            <CategoryList
              items={ item.get('pages') }
              userCategories={ userCategories }
              isRootList={ false }
            />
          ) }
        </section>
      </li>
    )
  }

  handleCollapsibleToggle = () => {
    this.setState({collapsibleOpen: !this.state.collapsibleOpen})
  }
}
