import React, { useEffect, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'
import {
  blacklistTag,
  unblacklistTag,
  unwhitelistTag,
  whitelistTag,
  blacklistCategory,
  unblacklistCategory,
  unwhitelistCategory,
  whitelistCategory,
} from '../../user/userActions'
import WhitelistToggle from './WhitelistToggle'
import BlacklistToggle from './BlacklistToggle'

const ListOptions = ({
  tag,
  isCategory,
  dispatch,
  whitelisted,
  blacklisted,

}) => {

  const [showActions, setShowActions] = useState(false)
  const menuContainerRef = useRef(null)
  const buttonRef = useRef(null)

  const handleToggleActions = useCallback(() => {
    setShowActions(prevShowActions => !prevShowActions)
  }, [])

  const getName = () => {
    const name = tag.name
    return isCategory ? name : '#' + name
  }

  const handleWhitelist = useCallback(() => {
    if (isCategory) {
      dispatch(whitelistCategory(tag.id))
    } else {
      dispatch(whitelistTag(tag.id))
    }
    setShowActions(false)
  }, [tag, dispatch, isCategory])

  const handleUnwhitelist = useCallback(() => {
    if (isCategory) {
      dispatch(unwhitelistCategory(tag.id))
    } else {
      dispatch(unwhitelistTag(tag.id))
    }
    setShowActions(false)
  }, [tag, dispatch, isCategory])

  const handleBlacklist = useCallback(() => {
    if (isCategory) {
      dispatch(blacklistCategory(tag.id))
    } else {
      dispatch(blacklistTag(tag.id))
    }
    setShowActions(false)
  }, [tag, dispatch, isCategory])

  const handleUnBlacklist = useCallback(() => {
    if (isCategory) {
      dispatch(unblacklistCategory(tag.id))
    } else {
      dispatch(unblacklistTag(tag.id))
    }
    setShowActions(false)
  }, [tag, dispatch, isCategory])

  const handleClickOutside = useCallback((e) => {
    if (
      showActions &&
        !menuContainerRef.current.contains(e.target) &&
        !buttonRef.current.contains(e.target)
    ) {
      setShowActions(false)
    }
  },
  [menuContainerRef, buttonRef, showActions])

  useEffect(() => {
    if (showActions) {
      window.document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      window.document.removeEventListener('mousedown',  handleClickOutside)
    }
  }, [showActions, handleClickOutside])

  return (
    <div className='list-options'>
      <button
        ref={ buttonRef }
        type='button'
        className={ classnames('list-options-toggle', {'list-options-toggle__whitelisted': whitelisted}, {'list-options-toggle__blacklisted': blacklisted} ) }
        onClick={ handleToggleActions }
        aria-label='Avaa listan toimintavalikko '
      >
        <i className='fa fa-ellipsis-v' aria-hidden='true' />
        <span className={ classnames({'fa fa-check-circle-o': whitelisted}, {'fa fa-times-circle-o': blacklisted} ) } />
      </button>

      { showActions &&
      <div ref={ menuContainerRef } className='category-switch__menu-container'>
        <WhitelistToggle
          isWhitelisted={ whitelisted }
          onWhitelist={ handleWhitelist }
          onUnwhitelist={ handleUnwhitelist }
          name={ getName() }
        />
        <BlacklistToggle
          isBlacklisted={ blacklisted }
          onBlacklist={ handleBlacklist }
          onUnblacklist={ handleUnBlacklist }
          name={ getName() }
        />
      </div>
      }

    </div>
  )
}

ListOptions.propTypes = exact({
  tag: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    h1: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.string,
    blacklisted: PropTypes.bool,
    whitelisted: PropTypes.bool,
    breaking: PropTypes.bool,
  }),
  isCategory: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  whitelisted: PropTypes.bool.isRequired,
  blacklisted: PropTypes.bool.isRequired,
})

export default (ListOptions)
