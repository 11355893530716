import Immutable from 'immutable'
import { defaultLocation } from '../../../config/config'
import * as actions from './weatherActions'
import * as locationActions from './weatherActions'
import { getNextForecast } from './forecastParser'
import { logException } from '../../sentry'


const initialState = Immutable.fromJS({
  selected: defaultLocation,
  timestamp: null,
  failedToLoad: false,
  loading: false,
})

export const initialLocationState = Immutable.fromJS({
  data: Immutable.List(),
  current: false,
})


/**
 * Adds 'windSpeed', 'windDirection', 'precipitation', 'precipitationProbability'
 * to daily weather data. The data object is edited in place.
 */
function calcDailyWeather(data) {
  try {
    for (const day of data) {
      let windSpeed = -1
      let windDirection = ''
      let precipitation = 0
      let precipitationProbability = 0
      for (const hour of day.forecasts) {
        precipitation += parseFloat(hour.precipitation)
        precipitationProbability = Math.max(precipitationProbability, parseInt(hour.precipitationProbability, 10))
        const hourWindSpeed = parseInt(hour.windSpeed, 10)
        if (hourWindSpeed > windSpeed) {
          windSpeed = hourWindSpeed
          windDirection = hour.windDirection
        }
      }
      day.windSpeed = windSpeed.toString()
      day.windDirection = windDirection
      day.precipitation = (Math.round(precipitation * 10) / 10).toString()
      day.precipitationProbability = precipitationProbability.toString()
    }
  } catch (error) {
    logException(error)
  }
  return data
}


function locationDataFromResponse(state = initialLocationState, data) {
  try {
    return state
      .set('data', Immutable.fromJS(calcDailyWeather(data)))
      .set('current', getNextForecast(Immutable.fromJS(data)))
  } catch (error) {
    logException(error)
    return initialLocationState
  }
}

export default function weatherReducer(state = initialState, action) {
  switch (action.type) {

    case actions.SET_SELECTED_WEATHER:
      return state.set('selected', action.locationId)

    case locationActions.FETCH_WEATHER_BY_COORDINATES_SUCCESS: {
      const coordLocationId = parseInt(action.response.data[0].locationId)
      return state
        .set('selected', coordLocationId)
        .set(coordLocationId, locationDataFromResponse(state.get(coordLocationId), action.response.data))
        .set('timestamp', action.timestamp)
        .set('loading', false)
    }
    case actions.FETCH_WEATHER:
    case actions.FETCH_WEATHER_BY_COORDINATES:
      return state
        .set('loading', true)
        .set('failedToLoad', false)

    case actions.FETCH_WEATHER_SUCCESS: {
      const locationId = parseInt(action.response.data[0].locationId)
      return state
        .set(locationId, locationDataFromResponse(state.get(locationId), action.response.data))
        .set('timestamp', action.timestamp)
        .set('loading', false)
    }
    case actions.FETCH_WEATHER_FAILURE:
    case actions.FETCH_WEATHER_BY_COORDINATES_FAILURE:
      return state
        .set('failedToLoad', true)
        .set('loading', false)
        .set('data', Immutable.List())

    default:
      return state
  }
}
