import { ANNOUNCE_SR_MESSAGE } from './accessibilityAnnouncerActions'
import Immutable from 'immutable'

const initialState = Immutable.Map({
  message: '',
})

export default function announcementReducer(state = initialState, action) {
  switch (action.type) {
    case ANNOUNCE_SR_MESSAGE:
      return state.set('message', action.message)
    default:
      return state
  }
}
