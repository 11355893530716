import React, { useEffect, useState, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import Toggle from 'react-toggle'
import classnames from 'classnames'
import {
  fetchUserProfile,
  subscribeUserLanguage,
  unSubscribeUserLanguage,
} from '../userActions'
import '../../styles/settings.pcss'
import { fetchSources } from '../../views/Info/sourcesActions'
import Loading from '../../components/util/Loading'
import SourceItem from './SourceItem'
import UnableToLoad from '../../components/messages/UnableToLoad'
import { selectSourcesOrderedByName, selectSourceLoading, selectSourceFailedToLoad } from '../../selectors/sourcesSelector'
import { selectUserBlacklistedSourceIds, selectUserSubscribedLanguageSlugs, selectUserSubscribedSourceIds } from '../../selectors/userSelector'
import Carousel from '../../components/carousel/Carousel'

const SourceSettingsView = () => {
  const dispatch = useDispatch()
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const loading = useSelector(selectSourceLoading)
  const sources = useSelector(selectSourcesOrderedByName)
  const blacklistedIds = useSelector(selectUserBlacklistedSourceIds)
  const subscribedIds = useSelector(selectUserSubscribedSourceIds)
  const failedToLoadSources = useSelector(selectSourceFailedToLoad)
  const subscribedLanguages = useSelector(selectUserSubscribedLanguageSlugs)

  useEffect(() => {
    dispatch(fetchSources())
    dispatch(fetchUserProfile(5))
  }, [dispatch])

  const isLanguageSubscribed = (languageSlug) => {
    return subscribedLanguages.includes(languageSlug)
  }

  const handleToggleChange = (e) => {
    if (!isLanguageSubscribed(e.target.value)) {
      dispatch(subscribeUserLanguage(e.target.value))
    } else {
      dispatch(unSubscribeUserLanguage(e.target.value))
    }
  }

  const handleCarouselChange = (index) => {
    setActiveTabIndex(index)
  }

  const handleLabelClick = (refs, key) => () => {
    const element = refs[key].current
    const labelsOffset = 160
    const offsetPosition = element.getBoundingClientRect().top + window.pageYOffset - labelsOffset

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }

  const renderLabels = (labels, refs) => {
    return (
      <div className='sources__labels__list'>
        { labels.map((char) => (
          <button
            type='button'
            className='sources__labels__list-button'
            key={ char }
            onClick={ handleLabelClick(refs, char) }
          >
            <span className='sources__labels__list-button-text'>{ char }</span>
          </button>
        )) }
      </div>
    )
  }

  const sourcesTabs = [
    {
      sources: sources.filter((source) => source.language === 'fi'),
      title: 'Suomenkieliset lähteet',
      slug: 'fi',
    },
    {
      sources: sources.filter((source) => source.language === 'en'),
      title: 'Englanninkieliset lähteet',
      slug: 'en',
    },
    {
      sources: sources.filter((source) => source.language === 'sv'),
      title: 'Ruotsinkieliset lähteet',
      slug: 'sv',
    },
  ]

  const tabTitle = sourcesTabs[activeTabIndex]['title']
  const groupedSources = sourcesTabs[activeTabIndex]['sources'].reduce((acc, source) => {
    const char = source.name[0].toUpperCase() // Change to capital if the source name does not begin with it (e.g. "eUrheilu")
    acc[char] ??= []
    acc[char].push(source)
    return acc
  }, {})

  const labels = Object.keys(groupedSources).sort()
  const refs = labels.reduce((acc, char) => {
    acc[char] = createRef()
    return acc
  }, {})

  return (
    <div>
      <Helmet title='Lähteet' />
      <section className='content'>
        <div className='profile-settings-info-text'>
          <p>
            Valitse, mitkä uutislähteet haluat piilottaa Amppareista.
            Kun piilotat uutislähteen, et näe sen julkaisuja enää millään Amppareiden uutislistalla.
          </p>
          <p>
            Mikäli lähteellä on maksullisia artikkeleita, voit erikseen valita, haluatko nähdä ne uutislistoilla.
            Oletusarvoisesti maksullista sisältöä ei näytetä Amppareissa.
          </p>
          <p>
            Voit seurata ulkomaisista lähteistä tällä hetkellä vain urheilu-uutisia.
            Jos otat ulkomaiset urheilu-uutiset käyttöön, löydät ne Urheilu-kategoriasta ja Uusimmat-listalta.
          </p>
        </div>

        <div className='source-settings__toggle-container'>
          <h3>Salli ulkomaiset lähteet:</h3>

          { sourcesTabs.filter((tab) => tab.slug !== 'fi').map((tab) => (
            <div className='source-settings__toggle' key={ tab.slug }>
              <label htmlFor={ `lang-toggle-${tab.slug}` }>{ tab.title }</label>
              <Toggle
                checked={ isLanguageSubscribed(tab.slug) }
                onChange={ handleToggleChange }
                value={ tab.slug }
                id={ `lang-toggle-${tab.slug}` }
              />
            </div>
          )) }
        </div>

        <div className='personal'>
          <div className='sources'>
            <Carousel
              className='source-settings__tabs'
              onChange={ handleCarouselChange }
            >
              { sourcesTabs.map((tab) => (
                <li
                  key={ tab.slug }
                  className={ classnames('source-settings__tabs__list-item', { 'disabled': !isLanguageSubscribed(tab.slug) }) }
                >
                  { tab.title }
                </li>
              )) }
            </Carousel>
            <div className='sources__labels-container'>
              { labels.length >= 10 && renderLabels(labels, refs) }
            </div>
            <div className='box-header'>
              <span className='box-header-text'>{ tabTitle }</span>
            </div>
            <Loading loading={ loading }>
              { failedToLoadSources ?
                <UnableToLoad errorText='Lähdelistan haku ei onnistunut.' />
                :
                labels.map((char) => (
                  <div className='sources__list-container' key={ char }>
                    <div className='sources__list-label-container'>
                      <span className='sources__list-label' ref={ refs[char] }>{ char }</span>
                    </div>
                    <ul className='sources__list'>
                      { groupedSources[char].map((source) => (
                        <li className='list-style' key={ source.id }>
                          <SourceItem
                            source={ source }
                            isBlacklisted={ blacklistedIds.includes(source.id) }
                            isSubscribed={ subscribedIds.includes(source.id) }
                            dispatch={ dispatch }
                          />
                        </li>
                      )) }
                    </ul>
                  </div>
                ))
              }
            </Loading>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SourceSettingsView
