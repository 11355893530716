import Immutable from 'immutable'

import * as actions from './locationActions'

import { FETCH_WEATHER_BY_COORDINATES_SUCCESS, FETCH_WEATHER_SUCCESS } from '../../views/Weather/weatherActions'

import { defaultLocation } from '../../../config/config'
import { logException } from '../../sentry'

const initialState = Immutable.Map({
  current: Immutable.Map({
    id: defaultLocation,
    coords: null,
  }),
  list: Immutable.OrderedSet(),
  geolocation: null,
  searchResults: Immutable.List(),
  loading: false,
})

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_GEOLOCATION:
      return state.set('loading', true)

    case actions.GET_GEOLOCATION_SUCCESS:
    case actions.GET_GEOLOCATION_FAILURE:
      return state.set('loading', false)

    case actions.UPDATE_COORDINATES:
      return state
        .setIn(['current', 'coords'], Immutable.Map({
          lat: action.coords.lat,
          lon: action.coords.lon,
        }))

    case actions.CLEAR_LOCATION_SEARCH:
    case actions.FETCH_SEARCH_LOCATION_ERROR_LENGTH:
    case actions.FETCH_SEARCH_LOCATION_FAILURE:
      return state.set('searchResults', Immutable.List())

    case actions.FETCH_SEARCH_LOCATION_SUCCESS:
      return state.set('searchResults', Immutable.fromJS(action.response.data.slice(0, 5).map((result) => {
        return {
          id: parseInt(result.id),
          name: result.name,
          slug: result.slug,
        }
      })))

    case FETCH_WEATHER_BY_COORDINATES_SUCCESS:
      try {
        const locationId = parseInt(action.response.data[0].locationId)
        const locationName = action.response.data[0].locationName
        const locationSlug = action.response.data[0].locationSlug
        const location = Immutable.fromJS({
          id: locationId,
          name: locationName,
          slug: locationSlug,
        })
        return state
          .setIn(['current', 'id'], locationId)
          .set('geolocation', location)
          .set('list', Immutable.OrderedSet([location]).union(state.get('list')))
      } catch (error) {
        logException(error)
        return state
      }

    case FETCH_WEATHER_SUCCESS:
      try {
        const locationId = parseInt(action.response.data[0].locationId)
        const locationName = action.response.data[0].locationName
        const locationSlug = action.response.data[0].locationSlug
        const location = Immutable.fromJS({
          id: locationId,
          name: locationName,
          slug: locationSlug,
        })
        return state.set('list', state.get('list').add(location))
      } catch (error) {
        logException(error)
        return state
      }

    default:
      return state
  }
}
