import React, { useState, useEffect } from 'react'
import { Link } from 'react-router'
import './item.pcss'
import { SVG_ICON_LOGIN } from '../util/svgIcons'

const TITLES = [
  'Ota Amppareista kaikki irti - kirjaudu ja muokkaa uutislistoja',
  'Haluatko seurata uutistapahtumaa tai suodattaa uutisia pois Amppareista? Luo Ampparit-tili',
  'Lue vain sinua kiinnostavia uutisia - kirjaudu Amppareihin',
  'Tee Amppareista juuri sinulle sopiva uutispalvelu - luo oma Ampparit-tili',
]

const LoginPromtItem = () => {
  const [title, setTitle] = useState('')

  useEffect(() => {
    const date = new Date().getDate()

    if (date % 5 === 0) {
      setTitle(TITLES[date % TITLES.length])
    }
  }, [])


  return title !== '' && (
    <article className='item'>
      <div className='item-row'>
        <div className='item-text login-item'>
          <h4 className='item-title'>
            <svg className='item-title__login' fill='#00A4C2'>
              <use href={ `#${SVG_ICON_LOGIN}` } />
            </svg>
            <Link to='tietoa/kirjautumisen-edut'>{ title }</Link>
          </h4>
        </div>
      </div>
    </article>
  )
}

export default LoginPromtItem
