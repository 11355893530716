import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import MainContent from '../../components/wrappers/MainContent'
import './infoViews.pcss'

export default class SourceHelpView extends Component {
  render() {
    return (
      <MainContent>
        <article className='info-page'>
          <Helmet title='Ohjeet lähdemedioille' />

          <h1>Ohjeet uutislähteille</h1>
          <h2>Sisältö</h2>
          <p>
            Ampparit hyväksyy uutisvirtaan toimitetut uutiset, kolumnit, pääkirjoitukset ja blogit. Uutisvirrasta suodatetaan pois
            esimerkiksi lukijoiden mielipidekirjoitukset, pelkkää videota tai ääntä sisältävät uutiset, sarjakuvat, kilpailut ja visat.
          </p>
          <p>
            Uutislähde vastaa sisällöstä ja linkistä, jonka se jakaa syötteen välityksellä
          </p>
          <p>
            Vaarallista sisältöä, kuten esimerkiksi haittaohjelmia jakavat lähteet poistetaan.
          </p>
          <p>
            Käyttäjän pitää päästä linkistä suoraan itse sisältöön. &quot;Katso video edetäksesi artikkeliin&quot;-tyyppiset
            ratkaisut eivät ole sallittuja.
          </p>
          <h3>Maksullinen sisältö</h3>
          <p>
            Ampparit hyväksyy myös maksulliset artikkelit mukaan uutistarjontaan. Maksulliset artikkelit
            tulee merkitä syötteessä avainsanalla, kuten <code>tilaajille</code> tai <code>maksumuuri</code>.
            Ns. mittaroidun maksumuurin sisältävät artikkelit eivät ole sallittuja.
          </p>
          <p>
            Omaa sisältöä mainostavat teaserit tai puhtaasti juttua puffaavat sisällöt eivät ole sallittuja.
            Tällaiset artikkelit tulee merkitä avainsanalla, mikäli niitä on mukana syötteessä.
          </p>
          <h3>Kaupallinen sisältö</h3>
          <p>
            Ampparit ei hyväksy kaupallisia artikkeleita uutisvirtaan. Lähteen syöte saa sisältää kaupallisia artikkeleita,
            mutta ne tulee olla merkittynä avainsanalla, kuten <code>kaupallinen yhteistyö</code> tai <code>mainos</code>.
          </p>


          <h2 id='title-requirements'>Otsikointi</h2>
          <p>
            Jotta uutisvirta säilyy helposti luettavana, Ampparit asettaa rajoituksia uutisten
            otsikoinnille. Uutisen tulee täyttää seuraavat ehdot:
          </p>
          <ul>
            <li>Kokonaispituus enintään 200 merkkiä</li>
            <li>Kokonaispituus vähintään 15 merkkiä</li>
            <li>Vähintään kolme yli kolmen merkin mittaista sanaa, tai kokonaispituus yli 30 merkkiä</li>
            <li>Otsikko ei saa olla kirjoitettu kokonaan isoin kirjaimin</li>
            <li>Ei häiritseviä erikoismerkkejä, kuten ★ tai emojit</li>
            <li>Ei häiritseviä tehosanoja kuten isolla kirjoitettu KOHUPALJASTUS tai JUURI NYT:</li>
            <li>Myös liiallinen kirosanojen käyttö saattaa johtaa otsikon suodattumiseen.</li>
          </ul>


          <h2>Ohjeita syötteiden toteuttamiseen</h2>
          <p>
            Ampparit tukee RSS- ja ATOM-muotoisia syötteitä. Syötteiden tulee olla teknisesti valideja, minkä voi tarkistaa { ' ' }
            <a href='https://validator.w3.org/feed/' target='_blank' rel='noopener noreferrer' >W3C Feed Validation Service</a> -palvelussa.
          </p>
          <ul>
            <li>
              RSS-syötteissä on oltava guid-elementti, joka on muuttumaton. Artikkelin otsikon tai osoitteen muuttuminen ei saa muuttaa
              guid-elementin arvoa.
            </li>
            <li>
              Ampparit käyttää syötteessä määriteltyjä avainsanoja jutun kategorisointiin ja suodatukseen. Suosittelemme sisällyttämään
              syötteeseen tiedot jutun kategorioista mediassasi (esim <code>&lt;category&gt;ulkomaat&lt;category&gt;</code>).
              Huomaathan että avainsanoja voi olla artikkelia kohden useampi kuin yksi. Myös tieto jutun maksullisuudesta tai kaupallisesta
              yhteistyöstä merkitään avainsanalla &ndash; tämä ei kuitenkaan ole suositus, vaan pakollinen toimintatapa.
            </li>
            <li>
              Suositus on, että jutun koko leipäteksti sisällytetään syötteeseen. Ampparit ei koskaan näytä
              leipätekstiä kävijöille, mutta se indeksoidaan sivuston hakuun. Leipäteksti vaikuttaa myös kategorisointiin
              ja <Link to='/aihetunnisteet'>#aihetunnisteilla</Link> löytämiseen. Mikäli syötteessä on maksullista sisältöä,
              jonka leipätekstin vuotaminen halutaan estää, lähde voi suojata syötteen HTTP Basic Auth kirjautumisella.
            </li>
            <li>
              Artikkelin URL-osoitteen maksimipituus on 300 merkkiä.
            </li>
            <li>
              Esimerkki suositukset täyttävästä syötteestä: { ' ' }
              <a href='https://rss.afterdawn.com/news_fin.cfm' target='_blank' rel='noopener noreferrer'>Afterdawn RSS</a>
            </li>
          </ul>


          <h2>Miksi uutinen ei näy?</h2>
          <p>
            Jos uutinen ei näy Amppareissa odotetusti, tarkista ensin seuraavat asiat. Mikäli
            tilanteen ratkaisemiseen ei löydy apua tästä listasta, <Link to='/palaute'>ota yhteyttä ylläpitoon</Link>.
          </p>
          <ul>
            <li>
              Puuttuuko uutinen varmasti? Amppareissa on paljon uutissisältöä, ja välillä uutinen saattaa eksyä
              väärään kategoriaan tai puuttua listalta suodatusten vuoksi. <Link to='/haku'>Tarkista haun avulla</Link>,
              onko uutinen todella jäänyt puuttumaan uutisvirrasta.
            </li>
            <li>
              Täyttääkö uutinen kaikki tämän sivun alussa sisällölle ja otsikoinnille asetetut vaatimukset?
              Yleisin syy jutun puuttumiseen on ohjeistuksen vastainen otsikko.
            </li>
            <li>
              Onko Amppareihin ilmoitettu RSS-syöte varmasti toiminnassa? Esimerkiksi sivustouudistuksissa
              syötteen osoite saattaa muuttua, mutta asiaa ei aina huomata ilmoittaa eteenpäin.
            </li>
            <li>
              Onko sivustosi RSS-syötteessä viivettä? Joskus otsikko tulee viiveellä Amppareihin sen takia,
              että syötettä on viivästetty lähdesivuston päässä. Tällaisia viiveitä on saatettu tehdä
              esimerkiksi siitä syystä, että virheiden korjaamiseen jää julkaisemien jälkeen vielä muutama
              minuutti aikaa ennen kuin uutinen lähtee eteenpäin.
            </li>
            <li>
              Onko uutinen julkaistu samalla otsikolla jo aiemmin jossain muussa mediassa?
              Otsikko saattaa olla Amppareissa niputettuna vastaavan jutun yhteyteen. Niputetuissa
              otsikoissa näkyy plus-merkki, jota klikkaamalla myöhemmin Amppareihin tulleet
              samankaltaiset jutut tulevat näkyviin. Tarkista, löydätkö uutisen haun kautta.
            </li>
          </ul>

        </article>
      </MainContent>
    )
  }
}
