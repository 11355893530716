import { getPopular } from '../../api/api'
import { addBlacklistParamsToRequest } from './itemsActions'
import { logException } from '../../sentry'
import { selectUserJWT } from '../../selectors/userSelector'
import { isTimestampFresh } from '../../lib/utils'

export const FETCH_POPULAR = 'FETCH_POPULAR'
export const FETCH_POPULAR_FAILURE = 'FETCH_POPULAR_FAILURE'
export const FETCH_POPULAR_SUCCESS = 'FETCH_POPULAR_SUCCESS'

export function fetchPopular(maxStaleSecs = 0, params = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const lastFetchTimestamp = state.popular.get('timestamp')
    const loading = state.popular.get('loading')
    const loadedCategoryId = state.popular.get('categoryId')
    const loadedTagId = state.popular.get('tagId')
    const categoryId = params.categoryId ?? null
    const tagId = params.tagId ?? null

    if ((loading || isTimestampFresh(lastFetchTimestamp, maxStaleSecs)) && loadedCategoryId === categoryId && loadedTagId === tagId) {
      return
    }

    const requestParams = {
      limit: 5,
      category: categoryId,
      tag: tagId,
    }

    addBlacklistParamsToRequest(state, requestParams)

    dispatch({
      type: FETCH_POPULAR,
      params: requestParams,
    })

    const jwt = selectUserJWT(state)
    return getPopular(jwt, requestParams).then(response => {
      dispatch({
        type: FETCH_POPULAR_SUCCESS,
        response,
        timestamp: Date.now(),
        categoryId,
        tagId,
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: FETCH_POPULAR_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}
