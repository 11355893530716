import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import '../../styles/modal.pcss'
import { TEMPORARY_PROFILE_SAVE_MODAL } from './Modals'
import GenericModal from './GenericModal'
import { resetTemporaryProfile } from '../../user/userActions'
import { Cookies } from 'react-cookie'
import { loginAlmaTunnus } from '../../user/almalogin/almaUserActions'

export default class ProfileSaveModal extends Component {
  render() {
    const { onClose } = this.props

    return (
      <GenericModal
        modalId={ TEMPORARY_PROFILE_SAVE_MODAL }
        onClose={ onClose }
        onOkClick={ this.handleOk }
        onCancelClick={ this.handleCancel }
        title='Tallenna tai tyhjennä omat valintasi'
        okButtonTxt='Tallenna valinnat luomalla profiili'
        cancelButtonTxt='Tyhjennä tekemäsi valinnat'
        requireUserInput={ false }
      >
        <p>Olet tehnyt väliaikaisen profiilin lisäämällä suosikkeja tai suodattamalla uutisvirtaa. Tallenna tai tyhjennä tekemäsi valinnat alla olevilla painikkeilla.</p>
        <br />
        <p>Voit myös jatkaa Amppareiden selailua sulkemalla tämän ikkunan.</p>
      </GenericModal>
    )
  }

  handleOk = () => {
    this.props.dispatch(loginAlmaTunnus())
  }

  handleCancel = () => {
    const cookies = new Cookies()
    this.props.dispatch(resetTemporaryProfile(cookies, true))
  }
}

ProfileSaveModal.propTypes = exact({
  onClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
})
