import React from 'react'

export const TV = (
  <svg width='30' height='24' viewBox='0 0 30 24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M28.9693 0H1.03131C0.461683 0 0 0.468076 0 1.04559V18.067C0 18.6445 0.461683 19.1126 1.03131 19.1126H12.8526L12.7165 20.254C12.6209 21.0616 11.9756 21.6879 11.1754 21.751L6.55332 22.1138C5.78541 22.1738 5.17061 22.7228 4.98172 23.4365C4.90311 23.7345 5.14421 24.0229 5.44751 23.9986L11.8131 23.4984C13.935 23.3318 16.0662 23.3318 18.1887 23.4984L24.5531 23.9986C24.8564 24.0223 25.0975 23.7339 25.0183 23.4365C24.83 22.7228 24.2146 22.1738 23.4467 22.1138L18.8252 21.751C18.0256 21.6879 17.3803 21.0616 17.2841 20.254L17.1486 19.1126H28.9693C29.5389 19.1126 30.0006 18.6445 30.0006 18.067V1.04559C30.0006 0.468076 29.5389 0 28.9693 0ZM27.938 17.0214H2.0632V2.09177H27.938V17.0214Z' fill='white' />
  </svg>
)

export const WEATHER = (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M12 7.5C9.52031 7.5 7.5 9.52031 7.5 12C7.5 14.4797 9.52031 16.5 12 16.5C14.4797 16.5 16.5 14.4797 16.5 12C16.5 9.52031 14.4797 7.5 12 7.5ZM23.55 11.2734L19.1109 9.05625L20.6812 4.35C20.8922 3.7125 20.2875 3.10781 19.6547 3.32344L14.9484 4.89375L12.7266 0.45C12.4266 -0.15 11.5734 -0.15 11.2734 0.45L9.05625 4.88906L4.34531 3.31875C3.70781 3.10781 3.10312 3.7125 3.31875 4.34531L4.88906 9.05156L0.45 11.2734C-0.15 11.5734 -0.15 12.4266 0.45 12.7266L4.88906 14.9437L3.31875 19.6547C3.10781 20.2922 3.7125 20.8969 4.34531 20.6813L9.05156 19.1109L11.2687 23.55C11.5687 24.15 12.4219 24.15 12.7219 23.55L14.9391 19.1109L19.6453 20.6813C20.2828 20.8922 20.8875 20.2875 20.6719 19.6547L19.1016 14.9484L23.5406 12.7313C24.15 12.4266 24.15 11.5734 23.55 11.2734ZM16.2422 16.2422C13.9031 18.5812 10.0969 18.5812 7.75781 16.2422C5.41875 13.9031 5.41875 10.0969 7.75781 7.75781C10.0969 5.41875 13.9031 5.41875 16.2422 7.75781C18.5812 10.0969 18.5812 13.9031 16.2422 16.2422Z' fill='white' />
  </svg>
)

export const SPORT = (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10.8074 11.3923C10.7703 9.6334 10.3975 7.89773 9.70918 6.27864C5.31633 8.32993 2.08942 12.2003 0.986374 16.7673C1.6319 18.2463 2.56802 19.5806 3.73916 20.6909C4.85288 16.8182 7.37392 13.5017 10.8074 11.3923ZM9.02219 4.89015C8.28639 3.60817 7.3487 2.45321 6.24522 1.46973C1.84269 3.87902 -0.760123 8.86694 0.197789 14.1548C1.81366 10.1345 4.95832 6.80114 9.02219 4.89015ZM18.0982 12.8776C18.5142 8.04933 16.7774 3.32266 13.3812 0.0860779C12.67 0.00383295 10.8848 -0.180009 8.59645 0.497303C11.3962 3.39855 13.0098 7.242 13.1199 11.2724C14.6617 12.1202 16.3515 12.6651 18.0982 12.8776ZM12.0653 13.3372C10.5605 14.2508 9.24365 15.4429 8.18523 16.8495C12.1523 19.6217 17.1112 20.4828 21.6105 19.1621C22.5783 17.8654 23.2729 16.3857 23.6521 14.8128C22.3728 15.1388 21.0585 15.3077 19.7382 15.3159C17.0967 15.3111 14.4697 14.6338 12.0653 13.3372ZM7.30956 18.1606C6.5742 19.4185 6.06621 20.7925 5.75658 22.2342C7.90266 23.5514 10.4099 24.1572 12.9207 23.9652C15.4314 23.7732 17.8174 22.7932 19.7382 21.165C14.2762 21.9439 9.9027 19.9652 7.30956 18.1606ZM16.0324 0.71501C18.7078 4.12092 20.0237 8.51377 19.6415 12.984C21.1153 12.9871 22.5803 12.7552 23.9811 12.297C23.9811 12.2003 23.9956 12.1035 23.9956 12.0068C23.9956 6.79146 20.672 2.37442 16.0324 0.71501Z' fill='white' />
  </svg>
)

export const TAG = (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M23.6072 8.04155L23.9898 5.8987C24.0602 5.50468 23.7572 5.14286 23.357 5.14286H19.3493L20.1327 0.755839C20.203 0.361822 19.9001 0 19.4998 0H17.3231C17.1722 2.74107e-06 17.0261 0.053089 16.9104 0.149963C16.7947 0.246837 16.7168 0.381324 16.6903 0.529875L15.8665 5.14286H10.5825L11.3659 0.755839C11.4363 0.361822 11.1333 0 10.733 0H8.55633C8.40543 2.74107e-06 8.25934 0.053089 8.14364 0.149963C8.02794 0.246837 7.95001 0.381324 7.92349 0.529875L7.09972 5.14286H2.86243C2.71153 5.14286 2.56545 5.19595 2.44975 5.29282C2.33405 5.38969 2.25612 5.52418 2.2296 5.67273L1.84693 7.81559C1.7766 8.20961 2.07954 8.57143 2.47977 8.57143H6.48745L5.26297 15.4286H1.02568C0.874785 15.4286 0.728695 15.4817 0.612997 15.5785C0.497299 15.6754 0.419366 15.8099 0.392845 15.9584L0.0101845 18.1013C-0.0601548 18.4953 0.242792 18.8571 0.643024 18.8571H4.6507L3.86733 23.2442C3.79699 23.6382 4.09993 24 4.50017 24H6.67688C6.82778 24 6.97387 23.9469 7.08957 23.85C7.20527 23.7532 7.2832 23.6187 7.30972 23.4701L8.13354 18.8571H13.4175L12.6341 23.2442C12.5637 23.6382 12.8667 24 13.267 24H15.4437C15.5946 24 15.7407 23.9469 15.8564 23.85C15.9721 23.7532 16.05 23.6187 16.0765 23.4701L16.9003 18.8571H21.1376C21.2885 18.8571 21.4346 18.8041 21.5503 18.7072C21.666 18.6103 21.7439 18.4758 21.7704 18.3273L22.1531 16.1844C22.2234 15.7904 21.9205 15.4286 21.5202 15.4286H17.5126L18.737 8.57143H22.9743C23.1252 8.57143 23.2713 8.51834 23.387 8.42146C23.5027 8.32459 23.5806 8.1901 23.6072 8.04155ZM14.0298 15.4286H8.74581L9.97029 8.57143H15.2543L14.0298 15.4286Z' fill='white' />
  </svg>
)

export const FEEDBACK = (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M19.2 7.2H4.8V4.8H19.2V7.2ZM14.4 14.4H4.8V12H14.4V14.4ZM4.8 8.4H19.2V10.8H4.8V8.4ZM21.6 0H2.4C1.76404 0.00180929 1.15464 0.255247 0.704942 0.704942C0.255247 1.15464 0.00180929 1.76404 0 2.4V24L4.8 19.2H21.6C22.236 19.1982 22.8454 18.9448 23.2951 18.4951C23.7448 18.0454 23.9982 17.436 24 16.8V2.4C23.9982 1.76404 23.7448 1.15464 23.2951 0.704942C22.8454 0.255247 22.236 0.00180929 21.6 0Z' fill='white' />
  </svg>
)
