import React, { useEffect, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { Link } from 'react-router'
import SimpleItem from '../item/SimpleItem'
import './breakingNews.pcss'
import { fetchBreakingNews } from './breakingNewsActions'

/**
 * Because trendingTags and breakingItems comes from different endpoints,
 * there is possibility that news and topic dont match, if topic is changed
 * on the fly.
 */

const BreakingNews = memo(({
  dispatch,
  breakingTopic,
  breakingItems,
  shouldUpdate,
}) => {

  const dispatchRefresh = useCallback(() => {
    if (!window.document.hidden) {
      dispatch(fetchBreakingNews(300))
    }
  }, [dispatch])

  useEffect(() => {
    const updateNews = setInterval(() => {
      dispatchRefresh()
    }, 5000)

    if (shouldUpdate) {
      dispatch(fetchBreakingNews())
    }

    return () => clearInterval(updateNews)
  }, [shouldUpdate, dispatch, dispatchRefresh])

  return (
    <div className='breaking-news-box'>
      <div className='breaking-news-box-header'>
        <Link to={ `/t/${breakingTopic.get('slug')}` }>
          <span className='breaking-news-box-header__title'>{ `Juuri nyt: #${breakingTopic.get('name')}` }</span>
        </Link>
      </div>
      <div className='breaking-news-box-container'>
        <div className='breaking-news'>
          { breakingItems.map(item => (
            <SimpleItem
              key={ item.get('id') }
              dispatch={ dispatch }
              item={ item }
              trackingClass='pinnalla-news'
              gaItemClickUiElement='Juuri nyt'
            />
          )) }
        </div>
      </div>
    </div>
  )
})

BreakingNews.displayName = 'BreakingNews'

BreakingNews.propTypes = exact({
  dispatch: PropTypes.func.isRequired,
  breakingTopic: ImmutablePropTypes.map.isRequired,
  breakingItems: ImmutablePropTypes.orderedSet.isRequired,
  shouldUpdate: PropTypes.bool.isRequired,
})

export default BreakingNews
