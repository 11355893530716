import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import Item from '../../components/item/Item'
import './search.pcss'
import MoreItems from '../../components/lists/MoreItems'
import createItemsWithLabels from '../../lib/createItemsWithLabels'

export default class SearchList extends Component {

  render() {
    const {
      items,
      timestamp,
      totalCount,
      onMoreItemsClick,
      loadingNext,
      dispatch,
      loggedIn,
    } = this.props

    const createItem = item => {
      return (
        <Item
          key={ item.get('id') }
          item={ item }
          timestamp={ timestamp }
          onFetchBatchItems={ this.handleFetchBatchItems }
          dispatch={ dispatch }
          loggedIn={ loggedIn }
        />
      )
    }

    const labeledItems = createItemsWithLabels(items, timestamp, createItem)

    return (
      <div className='item-list'>
        { labeledItems }

        { items.size < totalCount && (
          <MoreItems onMoreItemsClick={ onMoreItemsClick } loading={ loadingNext } />
        ) }
      </div>
    )
  }
}

SearchList.propTypes = exact({
  items: ImmutablePropTypes.orderedSet,
  timestamp: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onMoreItemsClick: PropTypes.func.isRequired,
  loadingNext: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
})
