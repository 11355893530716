import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { connect } from 'react-redux'
import Button from '../../components/ui/Button'
import { validateEmail } from '../../user/validate'
import { resetFeedback, sendFeedback } from './feedbackActions'
import './infoPageForms.pcss'


@connect(state => {
  return {
    loading: state.feedback.get('loading'),
    sent: state.feedback.get('sent'),
    error: state.feedback.get('error'),
  }
})

export default class FeedbackForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      emailValue: '',
      emailValid: null,
      messageValue: '',
      messageValid: null,
    }
  }

  static propTypes = exact({
    loading: PropTypes.bool.isRequired,
    sent: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  })

  render() {
    const { loading, sent, error } = this.props
    const { emailValue, emailValid, messageValue, messageValid } = this.state

    if (sent) {
      return (
        <section className='info-page-form-thanks'>
          <h2>Kiitos palautteestasi!</h2>
          <p>Jos jätit sähköpostiosoitteesi, vastaamme sinulle mahdollisimman pian.</p>
          <Button type='button' onClick={ this.handleMoreFeedbackClick }>Anna lisää palautetta</Button>
        </section>
      )
    }

    if (error) {
      return (
        <section className='info-page-form-error'>
          <h2>Virhe!</h2>
          <p>
            Lomakkeen lähetys epäonnistui. Voit yrittää myöhemmin uudelleen tai lähettää palautetta sähköpostitse
            <a href='mailto:ampparit@ampparit.com?Subject=Ampparit%20palaute'>ampparit@ampparit.com</a>
          </p>
        </section>
      )
    }

    return (
      <form className='info-page-form info-page-form--feedback' noValidate onSubmit={ this.handleSubmit }>
        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='email'>Sähköposti</label><br />
            <span className='form-group-label__info'>(Ei pakollinen)</span>
          </div>
          <div className='form-group-inputs'>
            <input
              type='email'
              id='email'
              placeholder='esimerkki@esimerkki.com'
              value={ emailValue }
              onChange={ this.handleEmailChange }
              onInput={ this.handleEmailChange }
              onBlur={ this.handleEmailChange }
            />
            { emailValid === false && (
              <div className='form-validation-message'>Sähköpostiosoite on virheellinen</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
            <label htmlFor='message'>Viestisi</label>
          </div>
          <div className='form-group-inputs'>
            <textarea id='message' onChange={ this.handleMessageChange } value={ messageValue }></textarea>
            { messageValid === false && (
              <div className='form-validation-message'>Viesti on liian lyhyt</div>
            ) }
          </div>
        </div>

        <div className='form-group-container'>
          <div className='form-group-label'>
          </div>
          <div className='form-group-inputs'>
            <Button type='submit' loading={ loading } text='Lähetä palaute' />
          </div>
        </div>
      </form>
    )
  }

  handleEmailChange = e => {
    const value = e.target.value
    const valid = value.trim() === '' || validateEmail(value) || (e.type === 'blur' ? false : null)
    this.setState({
      emailValue: value,
      emailValid: valid,
    })
  }

  handleMessageChange = e => {
    const value = e.target.value
    const valid = value.trim().length > 5 || (e.type === 'blur' ? false : null)
    this.setState({
      messageValue: value,
      messageValid: valid,
    })
  }

  handleMoreFeedbackClick = e => {
    this.setState({
      messageValue: '',
      messageValid: null,
    })
    this.props.dispatch(resetFeedback())
  }

  formIsValid() {
    const stateUpdate = {
      messageValid: !!this.state.messageValid,
      emailValid: this.state.emailValid || this.state.emailValid == null, // Must be valid OR EMPTY
    }
    this.setState(stateUpdate)
    return Object.values(stateUpdate).every(v => v === true)
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.formIsValid() || this.props.loading) {
      return
    } else {
      this.submit()
    }
  }

  submit() {
    const { emailValue, messageValue } = this.state
    const emailBody = {
      'Viesti': messageValue,
      'Sähköposti': emailValue,
      'Selain': window?.navigator?.userAgent ?? '?',
      'Ruutukoko': window.innerWidth + '\u00d7' + window.innerHeight + 'px',
    }
    this.props.dispatch(sendFeedback(emailValue, emailBody))
  }
}
