import React, { useState, useEffect, useRef, useCallback } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { requestGeolocation } from './locationActions'
import * as keyCodes from '../../lib/keyCodes'
import Loading from '../util/Loading'
import Star from '../../views/Weather/icons/star'

const LocationSearchDialog = ({
  results,
  onResult,
  onChange,
  dispatch,
  loading,
  onFavoriteClick,
  userLocations,
}) => {
  const [active, setActive] = useState(0)
  const searchInput = useRef(null)

  useEffect(() => {
    const activeMax = Math.max(results.size - 1, 0)
    if (active > activeMax) {
      setActive(activeMax)
    }
  }, [results, active])

  const handleKeyDown = useCallback((event) => {
    if (event.keyCode === keyCodes.enter) {
      onResult(results.get(active))
    } else if (event.keyCode === keyCodes.upArrow && active > 0) {
      event.preventDefault()
      setActive(active - 1)
    } else if (event.keyCode === keyCodes.downArrow && active < results.size - 1) {
      event.preventDefault()
      setActive(active + 1)
    }
  }, [active, results, onResult])

  const handleInputChange = useCallback((event) => {
    onChange(event.target.value)
  }, [onChange])

  const handleResultClick = useCallback((event) => {
    const index = event.currentTarget.dataset.index
    onResult(results.get(index))
  }, [results, onResult])

  const handleGeolocationClick = useCallback(() => {
    dispatch(requestGeolocation())
  }, [dispatch])

  return (
    <div className='location-search-dialog'>
      <div className='location-search-dialog__search-container'>
        <input
          className='location-search-dialog__input'
          type='text'
          ref={ searchInput }
          onChange={ handleInputChange }
          onKeyDown={ handleKeyDown }
          placeholder='Hae kaupunki'
          aria-label='Hae kaupunki'
        />
        { results.size > 0 && (
          <button
            className='location-search-dialog__clear-button'
            onClick={ handleInputChange }
            aria-label='Clear input'
          >
            &times;
          </button>
        ) }
        { results.size > 0 ?
          <div className='location-search-dialog__results'>
            { results.map((result, i) => (
              <div className='location-search-dialog__results-location' key={ result.get('id') }>
                <button
                  data-index={ i }
                  onClick={ handleResultClick }
                  className={ classNames('location-search-dialog__results-location-name', { active: i === active }) }
                  aria-label='Valitse sijainti'
                >
                  { result.get('name') }
                </button>
                <button
                  type='button'
                  className='weather-favorite-locations__current-location-button'
                  onClick={ onFavoriteClick }
                  data-location-id={ result.get('id') }
                  data-location-slug={ result.get('slug') }
                  aria-label='Lisää suosikkeihin'
                >
                  <Star
                    className={ classNames('location-search-dialog__results-location-favorite', { 'active': userLocations.some(loc => loc.get('id') === result.get('id')) } ) }
                  />
                </button>
              </div>
            )) }
          </div> : null }
      </div>

      <div className='location-search-dialog__geolocation'>
        <Loading loading={ loading }>
          <button
            type='button'
            className='weather-add-geolocation'
            onClick={ handleGeolocationClick }
            aria-label='Paikanna sijainti'
            title='Paikanna sijainti'
          >
            <svg
              className='weather-add-geolocation__icon'
              xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 43 43'
              fill='none'
            >
              <rect width='40' height='40' rx='4' fill='none' />
              <path d='M20.5412 34.2089C12.9773 23.005 11.5732 21.8551 11.5732 17.7375C11.5732 12.0973 16.0482 7.52502 21.5685 7.52502C27.0887 7.52502 31.5637 12.0973 31.5637 17.7375C31.5637 21.8551 30.1597 23.005 22.5957 34.2089C22.0993 34.9415 21.0376 34.9415 20.5412 34.2089ZM21.5685 21.9927C23.8686 21.9927 25.7331 20.0876 25.7331 17.7375C25.7331 15.3874 23.8686 13.4823 21.5685 13.4823C19.2684 13.4823 17.4038 15.3874 17.4038 17.7375C17.4038 20.0876 19.2684 21.9927 21.5685 21.9927Z' fill='white' />
            </svg>
          </button>
        </Loading>
      </div>
    </div>
  )
}

LocationSearchDialog.propTypes = exact({
  results: ImmutablePropTypes.list.isRequired,
  onResult: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onFavoriteClick: PropTypes.func.isRequired,
  userLocations: ImmutablePropTypes.orderedSet.isRequired,
})

export default LocationSearchDialog
