import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ListTitle from './ListTitle'
import LanguagePicker from './LanguagePicker'
import './itemsListHeader.pcss'

export default class ItemsListHeader extends Component {

  render() {
    const {
      heading,
      dispatch,
      selectedLanguage,
      showLanguageOptions,
      children,
      isTagView,
    } = this.props

    return (
      <div className='items-list-header'>
        <ListTitle heading={ heading } isTagView={ isTagView } />
        { children }
        { showLanguageOptions &&
          <LanguagePicker
            dispatch={ dispatch }
            selectedLanguage={ selectedLanguage }
          />
        }
      </div>
    )
  }
}

ItemsListHeader.propTypes = exact({
  heading: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  showLanguageOptions: PropTypes.bool,
  selectedLanguage: PropTypes.string,
  children: PropTypes.node,
  isTagView: PropTypes.bool,
})
