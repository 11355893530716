import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import {
  changeActiveList,
  fetchUserItems,
  fetchUserItemsBatch,
  fetchUserItemsNext,
  LIST_NAME_PERSONAL,
  LIST_TYPE_MAIN,
} from '../../components/item/itemsActions'
import { unqueuePersonalItems } from '../../user/userActions'
import AbstractListView, { connectGenericProps } from './AbstractListView'
import { selectUserWhitelistedTagIds, selectUserWhitelistedCategoryIds } from '../../selectors/userSelector'
import Immutable from 'immutable'
import { categoriesToCategoryItems } from '../../components/item/categoryTransformers'
import PersonalItemsEmpty from './PersonalItemsEmpty'
import PersonalItemsInstructions from './PersonalItemsInstructions'
import { fetchPopular } from '../../components/item/popularActions'
import { selectTrendingTagItems } from '../../selectors/tagsSelector'

@provideHooks({
  fetch: ({ dispatch }) => {

    dispatch(changeActiveList(LIST_TYPE_MAIN, LIST_NAME_PERSONAL))

    return Promise.all([
      dispatch(fetchUserItems()),
      dispatch(fetchPopular(300)),
    ])
  },
})

export class PersonalView extends AbstractListView {
  constructor (props) {
    super(props)

    this.state = Object.assign(this.state, {
      showInstructions: this.nothingWhitelisted(),
    })
  }

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    dispatch: PropTypes.func.isRequired,
    activePage: PropTypes.shape({
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    trendingCategories: ImmutablePropTypes.list.isRequired,
    trendingFailedToLoad: PropTypes.bool,
    showLanguageOptions: PropTypes.bool.isRequired,
  }

  refreshList() {
    const { dispatch } = this.props
    dispatch(fetchUserItems())
  }

  fetchNext(timestamp) {
    return (dispatch) => dispatch(fetchUserItemsNext(timestamp))
  }

  unqueueItems() {
    const { dispatch } = this.props
    dispatch(unqueuePersonalItems())
  }

  onListDidUpdate(prevProps, props) {
    // When instructions are shown once, let's not change the visibility anymore until component is unmounted
    if (!this.state.showInstructions) {
      this.setState({
        showInstructions: this.nothingWhitelisted(),
      })
    }
  }

  handleFetchBatchItems = (batch) => {
    const { dispatch } = this.props
    dispatch(fetchUserItemsBatch(batch))
  }

  renderInstructions() {
    const { loggedIn, trendingTags, trendingCategories } = this.props

    if (this.state.showInstructions) {
      return (
        <PersonalItemsInstructions
          loggedIn={ loggedIn }
          trendingTags={ trendingTags }
          trendingCategories={ trendingCategories }
        />
      )
    }
  }

  renderEmpty() {
    const { loggedIn } = this.props

    if (!this.state.showInstructions) {
      return <PersonalItemsEmpty loggedIn={ loggedIn } />
    }
  }

  setActiveList() {
    const { dispatch } = this.props

    dispatch(changeActiveList(LIST_TYPE_MAIN, LIST_NAME_PERSONAL))
  }

  nothingWhitelisted() {
    const { whitelistedCategories, whitelistedTagIds } = this.props

    return (!whitelistedCategories || whitelistedCategories.isEmpty()) &&
      (!whitelistedTagIds || whitelistedTagIds.isEmpty())
  }

  getMetaDescription() {
    return 'Ampparit.com tarjoaa kattavasti koko Suomen uutisoinnin, mutta Omat aiheet -listalla päätät itse, mitä ' +
      'uutisaiheita seuraat. Voit myös lisätä mukaan omia uutislähteitä. Se kattavin uutistarjonta \u2013 Ampparit.com'
  }

  getTitle() {
    return 'Omat aiheet | Tuoreimmat uutiset | Ampparit.com'
  }

  getH1() {
    return 'Omat aiheet | Uutiset'
  }
}

export default connect(state => {
  const { user, trending } = state

  const items = user.get('items')

  return Object.assign(connectGenericProps(state, items), {
    activePage: {
      title: 'Omat aiheet',
      to: '/omat',
    },
    trendingTags: selectTrendingTagItems(state),
    trendingCategories: trending.get('categories') && Immutable.List(
      categoriesToCategoryItems(trending.get('categories'), user.get('categories'))
    ),
    whitelistedCategories: selectUserWhitelistedCategoryIds(state),
    whitelistedTagIds: selectUserWhitelistedTagIds(state),
    personalListShouldRefresh: items.get('shouldRefresh'),
    trendingFailedToLoad: trending.get('failedToLoad'),
    showLanguageOptions: false,
  })
})(PersonalView)
