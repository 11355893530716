import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router'
import UnableToLoad from '../messages/UnableToLoad'
import Switch from '../ui/Switch'
import './trendingTagsBox.pcss'


const TrendingTagsBox = React.memo(({
  trending,
  failedToLoad,
}) => (
  <div className='sidebox'>
    <div className='sidebox-header'>
      <Link to='/aihetunnisteet' title='Näytä listaus aihetunnisteista'>
        <span>Pinnalla</span>
        <i className='fa-stack link-to'>
          <i className='fa fa-circle-thin fa-stack-1x' />
          <i className='fa fa-angle-right fa-stack-1x' />
        </i>
      </Link>
    </div>

    <div className='sidebox-content'>
      { failedToLoad && trending.size === 0 &&
        <UnableToLoad errorText='Aiheita ei saatu ladattua.' />
      }

      <div className='trending-tags-list'>
        { trending.map((tag, i) => (
          <Switch key={ tag.get('id') } tag={ tag } />
        )) }
        <Link to='/aihetunnisteet' className='trending-tags-all-tags-link'>Katso kaikki <i className='fa fa-angle-double-right' aria-hidden='true' /></Link>
      </div>
    </div>
  </div>
))

TrendingTagsBox.displayName = 'TrendingTagsBox'

TrendingTagsBox.propTypes = exact({
  trending: ImmutablePropTypes.list.isRequired,
  failedToLoad: PropTypes.bool.isRequired,
})

export default TrendingTagsBox
