import React, { Component } from 'react'
import { connect } from 'react-redux'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { removeModal } from './modalActions'
import RedirectModal from './RedirectModal'
import NotificationModal from './NotificationModal'
import NewUserModal from './NewUserModal'
import MigrateUserModal from './MigrateUserModal'
import ProfileInformationModal from './ProfileInformationModal'
import ProfileSaveModal from './ProfileSaveModal'
import UrlCopyModal from './UrlCopyModal'
import AlmaTunnusVerificationModal from './AlmaTunnusVerificationModal'
import { logWarning } from '../../sentry'

export const NOTIFICATION_MODAL = 'notificationModal'
export const NEW_USER_MODAL = 'newUserModal'
export const MIGRATE_USER_MODAL = 'migrateUserModal'
export const REDIRECT_MODAL = 'redirectModal'
export const TEMPORARY_PROFILE_MODAL = 'temporaryProfileModal'
export const TEMPORARY_PROFILE_SAVE_MODAL = 'temporaryProfileSaveModal'
export const LOGIN_REQUIRED_MODAL = 'loginRequiredModal'
export const URL_COPY_MODAL = 'urlCopyModal'
export const ALMA_TUNNUS_VERIFICATION_MODAL = 'almaTunnusVerificationModal'

@connect((state) => {
  const { modals } = state

  const queue = modals.get('queue')
  let activeModal = queue && queue.size > 0 ? queue.first() : null
  let activeModalData = null

  // Map some data based on the active modal type
  if (activeModal) {
    switch (activeModal.get('id')) {
      case REDIRECT_MODAL: {
        const itemId = activeModal.get('data').get('itemId')
        const item = state.items.items.get(itemId)
        if (item) {
          const link = state.items.items.get(itemId).get('link')
          activeModalData = Immutable.Map({
            redirectUrl: link,
          })
        } else {
          logWarning('Invalid item id: ' + itemId)
          activeModal = null
        }
        break
      }
      case NOTIFICATION_MODAL:
        activeModalData = Immutable.Map({
          message: activeModal.get('data').get('message'),
          title: activeModal.get('data').get('title'),
        })
        break

      case NEW_USER_MODAL:
        activeModalData = Immutable.Map({
          message: activeModal.get('data').get('message'),
          title: activeModal.get('data').get('title'),
        })
        break

      case MIGRATE_USER_MODAL:
        activeModalData = Immutable.Map({
          title: activeModal.get('data').get('title'),
        })
        break

      case ALMA_TUNNUS_VERIFICATION_MODAL:
        break

      default:
        activeModalData = activeModal.get('data')
    }
  }

  return {
    activeModal: activeModal,
    activeModalData: activeModalData,
  }
})

export default class Modals extends Component {

  static propTypes = exact({
    activeModal: PropTypes.instanceOf(Immutable.Map),
    activeModalData: PropTypes.instanceOf(Immutable.Map),
    dispatch: PropTypes.func.isRequired,
    cookies: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  })

  constructor() {
    super()

    this.state = {
      activeModal: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeModal !== this.props.activeModal) {
      this.setState({
        activeModal: this.getActiveModal(),
      })
    }
  }

  getActiveModal() {
    const { dispatch, activeModal, activeModalData, cookies } = this.props

    if (activeModal) {
      switch (activeModal.get('id')) {
        case REDIRECT_MODAL:
          return (
            <RedirectModal
              onClose={ this.handleModalClose }
              redirectUrl={ activeModalData.get('redirectUrl') }
            />
          )

        case NOTIFICATION_MODAL:
          return (
            <NotificationModal
              onClose={ this.handleModalClose }
              message={ activeModalData.get('message') }
              title={ activeModalData.get('title') }
            />
          )

        case NEW_USER_MODAL:
          return (
            <NewUserModal
              onClose={ this.handleModalClose }
              message={ activeModalData.get('message') }
              title={ activeModalData.get('title') }
              cookies={ cookies }
              dispatch={ dispatch }
            />
          )

        case MIGRATE_USER_MODAL:
          return (
            <MigrateUserModal
              onClose={ this.handleModalClose }
              title={ activeModalData.get('title') }
              dispatch={ dispatch }
            />
          )

        case TEMPORARY_PROFILE_MODAL:
          return (
            <ProfileInformationModal
              onClose={ this.handleModalClose }
              dispatch={ dispatch }
            />
          )

        case TEMPORARY_PROFILE_SAVE_MODAL:
          return (
            <ProfileSaveModal
              onClose={ this.handleModalClose }
              dispatch={ dispatch }
            />
          )

        case URL_COPY_MODAL:
          return (
            <UrlCopyModal
              onClose={ this.handleModalClose }
              url={ activeModalData.get('url') }
              title={ activeModalData.get('title') }
            />
          )

        case ALMA_TUNNUS_VERIFICATION_MODAL:
          return (
            <AlmaTunnusVerificationModal
              onClose={ this.handleModalClose }
              dispatch={ dispatch }
            />
          )
      }
    }

    return null
  }

  handleModalClose = (modalId) => {
    this.props.dispatch(removeModal(modalId))
  }

  render() {
    return this.state.activeModal
  }
}
