import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'

export default class ArrowToggle extends Component {
  render() {
    const {
      toggleUp,
      onToggleAction,
      screenReaderMessage,
    } = this.props

    return (
      <button
        type='button'
        className='arrow-toggle__button'
        onClick={ onToggleAction }
        role='switch'
        aria-checked={ toggleUp ? 'true' : 'false' }
      >
        <span className={ classnames('fa', {'fa-caret-down': !toggleUp, 'fa-caret-up': toggleUp }) } aria-hidden='true' />
        <span className='sr-only'>{ screenReaderMessage }</span>
      </button>
    )
  }
}

ArrowToggle.propTypes = exact({
  toggleUp: PropTypes.bool.isRequired,
  onToggleAction: PropTypes.func.isRequired,
  screenReaderMessage: PropTypes.string.isRequired,
})
