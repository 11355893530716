import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

export default class Countdown extends Component {

  constructor() {
    super()

    this.state = {
      timeRemaining: 0,
    }
  }

  tick = () => {
    const { onComplete, timeStep } = this.props
    this.setState({
      timeRemaining: this.state.timeRemaining - timeStep,
    })
    if (this.state.timeRemaining <= 0) {
      clearInterval(this.interval)

      if (onComplete) {
        onComplete()
      }
    }
  }

  componentDidMount() {
    const { time, timeStep } = this.props
    this.setState({
      timeRemaining: time,
    })
    this.interval = setInterval(this.tick, timeStep)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    return (
      <span className='countdown'>
        { this.state.timeRemaining / 1000 }
        { this.props.children }
      </span>
    )
  }
}

Countdown.propTypes = exact({
  onComplete: PropTypes.func.isRequired,
  timeStep: PropTypes.number.isRequired,
  time: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
})
