import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import '../../styles/modal.pcss'
import { TEMPORARY_PROFILE_MODAL } from './Modals'
import GenericModal from './GenericModal'
import { loginAlmaTunnus } from '../../user/almalogin/almaUserActions'

export default class ProfileInformationModal extends Component {
  render() {
    const { onClose } = this.props

    const profileInfo = (
      <div>
        <ul>
          <li>
            Helppo tapa kokeilla uutislistan ja TV-ohjelmaoppaan räätälöintiä.
          </li>
          <li>
            Muokkaa uutislistaa avaamalla valikko aiheen tai kategorian vieressä
            olevalla <span className='fa fa-ellipsis-v text-icon'></span>-merkillä
            ja valitsemalla seuraa tai piilota.
          </li>
        </ul>
        <br />
        Väliaikaiset asetukset häviävät evästeiden mukana, joten suosittelemme lämpimästi rekisteröitymistä ja oman profiilin luomista.
      </div>
    )

    return (
      <GenericModal
        modalId={ TEMPORARY_PROFILE_MODAL }
        onClose={ onClose }
        onCancelClick={ this.handleLoginClick }
        title='Haluatko tehdä väliaikaisen profiilin?'
        okButtonTxt='Ok, jatka käyttöä'
        cancelButtonTxt='Kirjaudu tai luo profiili'
        requireUserInput={ false }
      >
        { profileInfo }
      </GenericModal>
    )
  }

  handleLoginClick = () => {
    this.props.dispatch(loginAlmaTunnus())
  }
}

ProfileInformationModal.propTypes = exact({
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
})
