import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'

export default class SwitchToggle extends Component {
  render() {

    const {
      isWhitelisted,
      isBlacklisted,
      name,
    } = this.props

    return (
      <button
        className={ classnames('switch-toggle', {
          'switch-toggle_whitelisted': isWhitelisted,
          'switch-toggle_blacklisted': isBlacklisted,
        })
        }
        type='button'
        onClick={ this.props.onClick }
        aria-label={ `Muokkaa aiheen ${name} näkyvyyttä uutislistalla` }
        title={ `Muokkaa aiheen ${name} näkyvyyttä uutislistalla` }
      >
        <i className='fa fa-ellipsis-v' aria-hidden='true' />
      </button>
    )
  }
}

SwitchToggle.propTypes = exact({
  isWhitelisted: PropTypes.bool.isRequired,
  isBlacklisted: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
})
